import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { FilterTableFrame } from '@gsa/afp-component-library';
import { useAppAbility, useTitle } from '@gsa/afp-shared-ui-utils';
import OrderModsProvider from './OrderModsProvider';
import OrderModFilterProvider from './Filters/OrderModFilterProvider';
import OrderModFilters from './Filters/OrderModFilters';
import OrderModsTable from './OrderModsTable';
import CreateOrderModButton from './CreateOrderModButton';
import { StoreSubjects, StoreOperations } from '../../constants/constants';

const OrderMods = ({ setTab }) => {
  const ability = useAppAbility();
  const history = useHistory();

  const userScopeLevel = useMemo(() => {
    return ability?.can(StoreOperations.View, StoreSubjects.OrderVehicle);
  }, [ability]);

  setTab('order-modifications');
  useTitle('Requisitions and Orders-Order Modifications');

  // Move the OrderModButtons component here and conditionally render it
  const OrderModButtons = () =>
    userScopeLevel ? (
      <div className="text-right">
        <CreateOrderModButton
          className="margin-right-2"
          onClick={() => history.push('/create-order-modifications')}
        />
      </div>
    ) : null;

  const OrderModsFrame = useMemo(
    () =>
      FilterTableFrame(null, OrderModButtons, OrderModFilters, OrderModsTable),
    [userScopeLevel],
  );

  return (
    <OrderModsProvider>
      <div className="order-mods">
        <h2 className="usa-h2">Modifications</h2>
        <div className="display-flex flex-align-end justify-content-end margin-bottom-1 order-mod-create-container" />
        <OrderModFilterProvider>
          <OrderModsFrame filterToggle upRightProps={<OrderModButtons />} />
        </OrderModFilterProvider>
      </div>
    </OrderModsProvider>
  );
};

OrderMods.propTypes = {
  setTab: PropTypes.func.isRequired,
};

export default memo(OrderMods);
