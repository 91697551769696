import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AFPTable,
  AFPTableRowAction,
  Spinner,
  Pagination,
  Button,
  EmptyState,
  FilterTableFrame,
} from '@gsa/afp-component-library';
import { useMutation, useLazyQuery } from '@apollo/client';
import Moment from 'moment';
import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import OrderVehicleStatusTag from './order-vehicle-status-tag';
import { useOrderVehicleFilter } from '../providers/order-vehicle-filter-provider';
import OrderVehicleFilter from './order-vehicle-filters';
import {
  GET_ORDER_LINES_FOR_ORDER,
  UPDATE_ORDER_LINE_VEHICLE,
} from '../../../services/data-layer';
import { formatDate } from '../constants/order-vehicle-constants';
import { resetFocusToFirstElement } from '../../../utilities/commons';
import UpdateVehicleModal from '../../../components/UpdateVehicleModal/UpdateVehicleModal';
import { UserRoles } from '../../../constants/user-constants';
import { StoreOperations, StoreSubjects } from '../../../constants/constants';

const OrderVehicleTable = ({ orderId, orderData }) => {
  const [showUpdateVehicle, setShowUpdateVehicle] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState({});
  const [errorUpdating, errorUpdatingTo] = useState('');
  const { filters } = useOrderVehicleFilter();
  const [orderVehicles, setOrderVehicles] = useState([]);
  const [sort, setSort] = useState([['accountingLineNumber', 'ASC']]);
  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
    isReset: false,
  });
  // const {
  //   data,
  //   loading,
  //   refetch: refetchOrderVehicles,
  // } = useQuery(GET_ORDER_LINES_FOR_ORDER, {
  //   variables: {
  //     orderId,
  //   },
  // });

  const [getOrderLinesForOrder, { data, loading }] = useLazyQuery(
    GET_ORDER_LINES_FOR_ORDER,
    {
      variables: {
        orderId,
        limit: paginationState.limit,
        offset: paginationState.offset,
        order: sort,
        filters,
      },
    },
  );

  const { currentUser } = useCurrentUser();
  const ability = useAppAbility();
  const canUpdateOrderVehicle = useMemo(
    () =>
      ability?.can(StoreOperations.Manage, StoreSubjects.All) ||
      ability?.can(StoreOperations.updateStatus, StoreSubjects.OrderVehicle),
    [ability],
  );
  const isVehicleSupplier = useMemo(
    () =>
      currentUser?.roles?.some(
        (role) => role.name === UserRoles.VEHICLE_SUPPLIER,
      ),
    [currentUser],
  );
  const actionList =
    isVehicleSupplier || canUpdateOrderVehicle
      ? [
          {
            icon: 'edit',
            label: 'Update Vehicle Status',
          },
        ]
      : [];
  const [updateOrderLine] = useMutation(UPDATE_ORDER_LINE_VEHICLE, {
    onError: (error) => {
      errorUpdatingTo(error.message)
    },
    onCompleted: () => {
      setShowUpdateVehicle(false);
      const { limit, offset } = paginationState;
      getOrderLinesForOrder({
        fetchPolicy: 'no-cache',
        variables: {
          limit,
          offset,
          order: sort,
          filters,
        },
      });
    },
  });

  useEffect(() => {
    if (data) {
      const { getOrderVehiclesForOrder } = data;
      setOrderVehicles(getOrderVehiclesForOrder);
    }
  }, [data]);

  useEffect(() => {
    const { limit, offset } = paginationState;
    getOrderLinesForOrder({
      variables: {
        limit,
        offset,
        order: sort,
        filters,
      },
    });
  }, [sort, paginationState, filters]);

  // FIXME: Filter is mutating when page is selected so page always resets to page 0
  // useEffect(() => {
  //   setPaginationState({
  //     ...paginationState,
  //     offset: 0,
  //     currentPage: 1,
  //     isReset: true,
  //   });
  // }, [filters]);

  const estimatedShipmentDate = () => {
    const estimatedDeliverDays =
      orderData?.baseShipmentDays ?? 0 + orderData?.additionalShipmentDays ?? 0;

    const orderAwardedDate =
      orderData?.submittedToVendorAt ?? orderData?.createdAt;

    const formattedDate = Moment(orderAwardedDate).format('MM/DD/YYYY');

    const getContractShipmentDate = Moment(formattedDate)
      .add(estimatedDeliverDays, 'days')
      .format('MM/DD/YYYY');

    if (!getContractShipmentDate) {
      return <>&#8211;</>;
    }

    return getContractShipmentDate;
  };
  const handleSaveVehicle = (orderVehicle) => {
    errorUpdatingTo('')
    updateOrderLine({
      variables: {
        orderVehicleId: orderVehicle.orderVehicleId,
        data: {
          vehicleOrderNumber: orderVehicle.vehicleOrderNumber,
          serialNumberVin: orderVehicle.serialNumberVin?.trim()
            ? orderVehicle.serialNumberVin?.trim()?.toUpperCase()
            : null,
          statusCode: orderVehicle.statusCode ? orderVehicle.statusCode : 'SP',
          shipmentDate: orderVehicle.shipmentDate?.trim()
            ? orderVehicle.shipmentDate
            : null,
          deliveryDate: orderVehicle.deliveryDate?.trim()
            ? orderVehicle.deliveryDate
            : null,
        },
      },
    });
  };

  const handleCancelUpdate = () => {
    setShowUpdateVehicle(false);
  };

  const handleSelectedRow = (event, vehicle) => {
    setSelectedVehicle({
      ...vehicle,
      estimatedShipmentDate: estimatedShipmentDate(),
      rpnNumber: orderData.rpnNumber,
    });
    errorUpdatingTo('');
    setShowUpdateVehicle(true);
  };
  const columns = useMemo(
    () => [
      {
        Header: 'Vehicle line number',
        accessor: 'accountingLineNumber',
        // eslint-disable-next-line react/prop-types
        Cell: (cell) => {
          const { value } = cell;
          return value;
        },
      },
      {
        Header: 'VON',
        accessor: 'vehicleOrderNumber',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: (cell) => {
          const { value } = cell;
          return value ?? '-';
        },
      },
      {
        Header: 'VIN',
        accessor: 'serialNumberVin',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: (cell) => {
          const { value } = cell;
          return value ? <Button variant="unstyled" label={value} /> : '-';
        },
      },
      {
        Header: 'Vehicle status',
        accessor: 'statusCode',
        Cell: (cell) => {
          const { value } = cell;
          return <OrderVehicleStatusTag value={value} />;
        },
      },
      {
        Header: 'Shipment date',
        accessor: 'shipmentDate',
        Cell: ({ value }) => formatDate(value) || '-',
      },
      {
        Header: 'Delivery date',
        accessor: 'deliveryDate',
        Cell: ({ value }) => formatDate(value) || '-',
      },
      {
        Header: 'Actions',
        id: 'table-row-action',
        sortable: false,
        headerClassName: 'cell-center',
        cellClassName: 'cell-center',
        Cell: (props) => (
          <AFPTableRowAction
            actions={actionList}
            // eslint-disable-next-line
            onSelectAction={(evt) => handleSelectedRow(evt, props.row)}
            {...props}
          />
        ),
      },
    ],
    [],
  );

  const setTableSort = (newSort) => {
    const cleanSort = newSort.replace(/`([^`]+)`/, '$1');
    setSort([cleanSort.split(' ')]);
  };

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    const offset = (currentPage - 1) * itemsPerPage;
    setPaginationState({
      limit: itemsPerPage,
      offset,
      currentPage,
    });
  };

  useEffect(() => {
    if (!loading) {
      resetFocusToFirstElement();
    }
  }, [loading]);

  const OrderVehiclesTableComp = () => (
    <>
      <AFPTable
        testId="order-vehicle-table"
        columns={columns}
        data={loading ? [] : orderVehicles?.rows || []}
        onSort={setTableSort}
        defaultSort={sort}
      />
      {loading && <Spinner aria-busy="true" size="medium" />}
      {!loading && !orderVehicles?.rows?.length && (
        <EmptyState
          hasBackground
          containerStyles="margin-top-neg-2 padding-y-10"
          topText="No results found for your selected filters"
        />
      )}

      <Pagination
        variant="advanced"
        itemsCount={orderVehicles?.count}
        itemsPerPage={paginationState.limit}
        currentPage={paginationState.currentPage}
        isReset={paginationState.isReset}
        onPageChange={handlePaginationChange}
      />
    </>
  );
  const OrderVehicleFrame = useMemo(
    () =>
      FilterTableFrame(null, null, OrderVehicleFilter, OrderVehiclesTableComp),
    [orderVehicles, loading],
  );

  return (
    <>
      <UpdateVehicleModal
        data-testid="update-vehicle-modal"
        isOpen={showUpdateVehicle}
        orderId={orderId}
        selectedVehicle={selectedVehicle}
        onSubmit={handleSaveVehicle}
        onCancel={handleCancelUpdate}
        errorUpdating={errorUpdating}
      />
      <OrderVehicleFrame filterToggle />
    </>
  );
};

OrderVehicleTable.propTypes = {
  orderId: PropTypes.string.isRequired,
  orderData: PropTypes.objectOf(Object).isRequired,
};

export default OrderVehicleTable;
