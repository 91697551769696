import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import OrderVehicleFilterProvider from '../providers/order-vehicle-filter-provider';
import OrderVehicleTable from './order-vehicle-table';

const OrderVehicles = ({ orderId, setTab, orderData }) => {
  useEffect(() => setTab('orderVehicles'), [setTab]);
  return (
    <>
      <OrderVehicleFilterProvider>
        <OrderVehicleTable orderId={orderId} orderData={orderData} />
      </OrderVehicleFilterProvider>
    </>
  );
};

OrderVehicles.propTypes = {
  orderId: PropTypes.string.isRequired,
  setTab: PropTypes.func.isRequired,
  orderData: PropTypes.objectOf(Object).isRequired,
};

export default OrderVehicles;
