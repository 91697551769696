import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { isEqual, sortBy } from 'lodash';
import { FilterPanel } from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import { GET_REQUISITIONS_STATUSES } from '../queries';
import { GET_ACTIVE_VEHICLE_GROUPS } from '../../../services/data-layer/standards';
import {
  selectedAgenciesAtom,
  selectedBureauAtom,
  selectedOfficesAtom,
  selectedRequisitionStatusAtom,
  selectedVehicleTypesAtom,
  selectedRequisitionTypeAtom,
  selectedRequisitionTransactionTypeAtom,
  requisitionNumberAtom,
} from '../storeHelper';
import AgencyFilterItem from './AgencyFilterItem';
import BureauFilterItem from './BureauFilterItem';
import OfficeFilterItem from './OfficeFilterItem';
import AssigneeFilterItem from './AssigneeFilterItem';
import {
  AGENCY_AND_BUREAU_AND_OFFICE,
  AGENCY_FILTER,
  BUREAU_FILTER,
  OFFICE_FILTER,
} from './filter-item-constants';
import { requisitionTypeDescriptor } from '../../non-standard-purchase/constants';
import { VEHICLE_GROUP_TAG_STORE } from '../../../constants/constants';
import {
  formatRequisitionStatusLabel,
  formatRequisitionTypeLabel,
} from '../../my-orders/Filters/OrderFilterHelpers';

const { FilterPanel: ComponentFilterPanel } = FilterPanel;

const MyRequisitionsFilters = () => {
  const RequisitionStatusOption = {
    key: 'requisitionStatusFilter',
    title: 'Requisition status',
  };
  const VehicleTypeOption = {
    label: 'title',
    value: 'code',
    key: 'vehicleTypeFilter',
    title: 'Vehicle type',
  };
  const AgencyOption = {
    label: 'name',
    value: 'id',
    key: AGENCY_FILTER,
    title: 'Agency Item',
  };
  const BureausOption = {
    label: 'name',
    value: 'id',
    key: BUREAU_FILTER,
    title: 'Bureau Item',
  };
  const OfficesOption = {
    label: 'officeName',
    value: '',
    key: OFFICE_FILTER,
    title: 'Group Item',
  };
  const RequisitionTypeOption = {
    key: 'requisitionTypeFilter',
    title: 'Requisition type',
  };
  const AssigneeOption = {
    key: 'assigneeTypeFilter',
    title: 'Assignee',
  };
  const RequisitiionTransactionTypeOption = {
    key: 'requisitionTransactionTypeFilter',
    title: 'Transaction type',
  };

  const [getData, { data: vehicleTypesData }] = useLazyQuery(
    GET_ACTIVE_VEHICLE_GROUPS,
    {
      fetchPolicy: 'c',
      variables: {
        childTag: VEHICLE_GROUP_TAG_STORE,
      },
    },
  );

  const [getRequisitionStatues, { data: requisitionsStatusData }] =
    useLazyQuery(GET_REQUISITIONS_STATUSES, {
      fetchPolicy: 'c',
    });

  // filters
  const [allRequisitionStatuses, setAllRequisitionStatuses] = useState([]);
  const [allVehicleTypes, setAllVehicleTypes] = useState([]);
  const [allRequisitionTypes, setAllRequisitionTypes] = useState([]);

  // update requisitions statuses
  useEffect(() => {
    if (!requisitionsStatusData) {
      return;
    }
    const newStatuses = requisitionsStatusData?.getRequisitionStatuses || [];

    setAllRequisitionStatuses(newStatuses);
  }, [requisitionsStatusData]);

  // update vehicle types
  useEffect(() => {
    const vehicleTypes =
      (vehicleTypesData && vehicleTypesData.getActiveVehicleGroups) || [];
    if (vehicleTypes) {
      sessionStorage.setItem('VEHICLE_TYPE_MAP', JSON.stringify(vehicleTypes));
    } else {
      return;
    }

    // const newVehicleTypes =
    //   vehicleTypes?.searchStandardItems?.filterValues
    //     ?.standardItemSubCategories || [];
    const sortedVehicleTypes = [...vehicleTypes];

    sortedVehicleTypes.sort((a, b) => {
      if (a.title < b.title) {
        return -1;
      }
      if (a.title < b.title) {
        return 1;
      }
      return 0;
    });

    setAllVehicleTypes(sortedVehicleTypes);
  }, [vehicleTypesData?.getActiveVehicleGroups]);

  // initial fetching...
  useEffect(() => {
    setAllRequisitionTypes(
      Object.entries(requisitionTypeDescriptor).filter((x) => x[0] !== '32'),
    );
    getData({
      variables: {
        childTag: VEHICLE_GROUP_TAG_STORE,
      },
    });
    getRequisitionStatues();
  }, []);

  const [requisitionStatuses, setRequisitionStatuses] = useRecoilState(
    selectedRequisitionStatusAtom,
  );

  const [requisitionTypes, setRequisitionTypes] = useRecoilState(
    selectedRequisitionTypeAtom,
  );
  const [requisitionTransactionTypes, setRequisitionTransactionType] =
    useRecoilState(selectedRequisitionTransactionTypeAtom);

  const [vehicleTypes, setVehicleTypes] = useRecoilState(
    selectedVehicleTypesAtom,
  );
  const [agencies, setAgencies] = useRecoilState(selectedAgenciesAtom);
  const [bureaus, setBureaus] = useRecoilState(selectedBureauAtom);
  const [offices, setOffices] = useRecoilState(selectedOfficesAtom);
  const [requisitionNumber, setRequisitionNumber] = useRecoilState(
    requisitionNumberAtom,
  );

  const handleVehicleTypeFilterChange = (values) => {
    const { value } = VehicleTypeOption;
    const newVehicleTypes = allVehicleTypes.filter((item) =>
      values.includes(item[value]),
    );
    setVehicleTypes(newVehicleTypes);
  };

  const handleRequisitionFilterChange = (values) => {
    const newStatuses = allRequisitionStatuses.filter((item) =>
      values.includes(item),
    );
    if (newStatuses) {
      //   This is added since order creation was using RECEIVED_BY_GSA status and changing to ORDER_RECEIVED. ( The cron job can be changed to use a flag later)
      if (newStatuses.includes('RECEIVED_BY_GSA')) {
        newStatuses.push('ORDER_RECEIVED');
      }
    }
    setRequisitionStatuses(newStatuses);
  };

  const handleRequisitionTypeFilterChange = (values) => {
    const newTypes = Object.values(allRequisitionTypes)
      .map((x) => x[0])
      .filter((item) => values.includes(item));
    setRequisitionTypes(newTypes);
  };

  const handleRequisitiionTransactionTypeFilterChange = (filters) => {
    const requisitionTransactionTypeFilter =
      filters.find(
        (filter) => filter.key === RequisitiionTransactionTypeOption.key,
      )?.value || [];

    if (
      requisitionTransactionTypes?.length !==
      requisitionTransactionTypeFilter?.length
    ) {
      setRequisitionTransactionType([...requisitionTransactionTypeFilter]);
      return;
    }

    for (let i = 0; i < requisitionTransactionTypeFilter.length; i++) {
      if (
        requisitionTransactionTypeFilter[i] === requisitionTransactionTypes[i]
      ) {
        setRequisitionTransactionType([...requisitionTransactionTypeFilter]);
        return;
      }
    }
  };

  const handleFiltersChange = (filters) => {
    const vehicleTypeFilter =
      filters.find((filter) => filter.key === VehicleTypeOption.key)?.value ||
      [];
    const requisitionFilter =
      filters.find((filter) => filter.key === RequisitionStatusOption.key)
        ?.value || [];

    if (vehicleTypes.length !== vehicleTypeFilter.length) {
      handleVehicleTypeFilterChange(vehicleTypeFilter);
    }
    if (requisitionStatuses.length !== requisitionFilter.length) {
      handleRequisitionFilterChange(requisitionFilter);
    }

    // Requistion type filter
    const requisitionTypeFilter =
      filters.find((filter) => filter.key === RequisitionTypeOption.key)
        ?.value || [];

    if (requisitionTypes.length !== requisitionTypeFilter.length) {
      handleRequisitionTypeFilterChange(requisitionTypeFilter);
    }

    handleRequisitiionTransactionTypeFilterChange(filters);

    const requisitionNumberFilter =
      filters.find((filter) => filter.key === 'requisitionNumber')?.value || '';
    if (requisitionNumber !== requisitionNumberFilter) {
      setRequisitionNumber(requisitionNumberFilter);
    }

    // Agency Filter
    const agencyFilters = filters.filter(
      (filter) => filter.key === AgencyOption.key,
    );

    if (!isEqual(sortBy(agencies, 'value'), sortBy(agencyFilters, 'value'))) {
      setAgencies(agencyFilters);
    }

    // Bureau Filter
    const bureauFilters = filters.filter(
      (filter) => filter.key === BureausOption.key,
    );

    if (!isEqual(sortBy(bureaus, 'value'), sortBy(bureauFilters, 'value'))) {
      setBureaus(bureauFilters);
    }

    // Office Filter
    const officeFilters = filters.filter(
      (filter) => filter.key === OfficesOption.key,
    );

    if (!isEqual(sortBy(offices, 'value'), sortBy(officeFilters, 'value'))) {
      setOffices(officeFilters);
    }
  };

  return (
    <ComponentFilterPanel
      clearButtonLabel="Reset All"
      filterStructure={[
        {
          key: 'requisitionNumber',
          title: 'Requisition number',
          type: 'text',
          operator: '$like',
          value: '',
        },
        {
          key: RequisitionStatusOption.key,
          title: RequisitionStatusOption.title,
          type: 'multiselect',
          value: [],
          options: allRequisitionStatuses.map((status) => ({
            label: formatRequisitionStatusLabel(status),
            value: status,
          })),
          operator: '$in',
          hideClear: true,
        },
        {
          key: RequisitionTypeOption.key,
          title: RequisitionTypeOption.title,
          type: 'multiselect',
          value: [],
          options: allRequisitionTypes.map((type) => ({
            label: formatRequisitionTypeLabel(type[1]),
            value: type[0],
          })),
          operator: '$in',
          hideClear: true,
        },
        {
          key: RequisitiionTransactionTypeOption.key,
          title: RequisitiionTransactionTypeOption.title,
          type: 'multiselect',
          value: [],
          options: [
            { label: 'Purchase', value: 'VEHICLE_SALE' },
            { label: 'Lease', value: 'VEHICLE_LEASE' },
          ],
          operator: '$in',
          hideClear: true,
        },
        {
          key: AssigneeOption.key,
          title: AssigneeOption.title,
          type: 'multiselect',
          value: [],
          component: AssigneeFilterItem,
          operator: '$in',
          hideClear: true,
        },
        {
          key: VehicleTypeOption.key,
          title: VehicleTypeOption.title,
          type: 'multiselect',
          value: [],
          options: allVehicleTypes.map((vehicleType) => ({
            label: vehicleType[VehicleTypeOption.label],
            value: vehicleType[VehicleTypeOption.value],
          })),
          operator: '$in',
          hideClear: true,
        },
        {
          title: 'Agency',
          key: AGENCY_AND_BUREAU_AND_OFFICE,
          filters: [
            {
              key: AgencyOption.key,
              title: AgencyOption.title,
              component: AgencyFilterItem,
              permanent: false,
              operator: '$exact',
              value: '',
            },
            {
              key: BureausOption.key,
              title: BureausOption.title,
              component: BureauFilterItem,
              permanent: false,
              operator: '$in',
              placeholder: '- Select bureau -',
            },
            {
              key: OfficesOption.key,
              title: OfficesOption.title,
              component: OfficeFilterItem,
              permanent: false,
              operator: '$in',
              value: '',
              placeholder: '- Select group -',
            },
          ],
        },
      ]}
      model="Task"
      order={[]}
      setQueryFiltersState={(filters) => handleFiltersChange(filters)}
      showClearIcon
    />
  );
};

export default MyRequisitionsFilters;
