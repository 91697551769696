import { cloneDeep } from 'lodash';

export const formatCurrency = (price) => {
  if (Number.isNaN(price) || price === undefined || price === null) {
    return '';
  }
  return price.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

export const getSimplifiedContract = (contract, fuelCodes) => {
  const {
    id,
    scheduleLine,
    shipmentDays,
    standardItem,
    contractLineFinancialRef,
    contractLineEcs,
    contractModLineIdentifier,
    make,
    model,
    comment,
    contractModificationRef,
    modelYear,
    unitPrice,
    baseEngineRating,
    orderEndDate,
    contractLineClarifications,
    contractLineAttachments,
  } = contract;
  const basePriceTotal = formatCurrency(contractLineFinancialRef?.unitPrice);
  const overview = {
    shipmentDays,
    comment,
    fuelDescription: baseEngineRating?.fuel?.fuelDescription,
    altFuelDescription:
      baseEngineRating?.fuelCode !== baseEngineRating?.altFuelCode &&
      baseEngineRating?.altFuel?.fuelDescription,
    vendorBusinessClassName: contractModificationRef?.contract?.vendor
      ?.smallBusinessFlag
      ? 'Small Business'
      : 'Other than Small Business',
    makeCode: make?.makeCode,
    modelCode: model?.modelCode,
    vendorGroupId: contractModificationRef?.contract?.vendor?.vendorGroupId,
  };
  const engineFuelEcs = contractLineEcs?.filter(
    (x) => x.equipmentCode === 'BASEENGINE' || x.tags?.includes('MPG'),
  );
  const engineInfo = { ...engineFuelEcs?.[0]?.contractLineEngine?.[0] } || {};
  const fuelInfo = { ...engineFuelEcs?.[0]?.contractLineFuel?.[0] } || {};
  fuelInfo.fuelCodeLabel = fuelCodes.find(
    (x) => x.code === fuelInfo.fuelCode,
  )?.title;
  fuelInfo.altFuelCodeLabel = fuelCodes.find(
    (x) => x.code === fuelInfo.altFuelCode,
  )?.title;

  const contractDataModified = {
    contractLineId: id,
    make: make?.makeName,
    modelName: model?.modelCode,
    unitPrice,
    orderEndDate,
    vendorName: contractModificationRef?.contract?.vendor?.vendorName,
    contractModLineIdentifier,
    scheduleLine,
    contractModification: contractModificationRef,
    modelYear,
    standardItemCode: standardItem?.standardItemNumber,
    standardItemId: standardItem?.id,
    minimumRequiredOptions: contractLineEcs,
    basePrice: basePriceTotal,
    totalSellingPrice: basePriceTotal,
    contractLineFinancialRef,
    // optionalFeatures,
    // baseEngine,
    // baseEngineRating,
    // offerLineClarifications,
    // offerLineArtifact,
    purchaseRate: contractLineFinancialRef?.purchaseRate,
    // purchaseRateDisplay,
    ...overview,
    contractLineClarifications,
    engineInfo,
    fuelInfo,
    contractLineAttachments,
  };
  return contractDataModified;
};

export const getSimplifiedContractData = (contractData, fuelCodes) => {
  const returnableContractData = [];
  contractData.forEach((contract) => {
    returnableContractData.push(getSimplifiedContract(contract, fuelCodes));
  });
  return returnableContractData;
};

export const getGroupedMinimumRequirementsData = (minimumRequirementsData) => {
  const returnableData = [];
  minimumRequirementsData.forEach((minReq) => {
    const foundMinReqIndex = returnableData.findIndex(
      (data) =>
        data.optionCategoryCode === minReq?.equipmentCode?.categoryCode?.code,
    );
    if (foundMinReqIndex < 0) {
      const minReqOption = {
        optionCategoryCode: minReq?.equipmentCode?.categoryCode?.code,
        optionCategoryName: minReq?.equipmentCode?.categoryCode?.title,
        standardItemCode: minReq?.standardItemCode,
        options: [
          {
            optionCode: minReq?.equipmentCode?.code,
            optionDescription: minReq?.equipmentCode?.title,
            gSARequirementLevelCode: minReq?.gSARequirementLevelCode,
            associationText: minReq?.associationText,
          },
        ],
      };
      returnableData.push(minReqOption);
    } else {
      returnableData[foundMinReqIndex].options.push({
        optionCode: minReq?.equipmentCode?.code,
        optionDescription: minReq?.equipmentCode?.title,
        gSARequirementLevelCode: minReq?.gSARequirementLevelCode,
        associationText: minReq?.associationText,
      });
    }
  });
  return returnableData;
};

export const getGroupedVehicleInfoData = (vehicleInformationData) => {
  const returnableData = [];

  vehicleInformationData.forEach((vehInfo) => {
    const foundMinReqIndex = returnableData.findIndex(
      (data) =>
        data.optionCategoryCode ===
        vehInfo.option.optionCategory.optionCategoryCode,
    );
    if (foundMinReqIndex < 0) {
      const vehInfoOption = {
        optionCategoryCode: vehInfo.option.optionCategory.optionCategoryCode,
        optionCategoryName: vehInfo.option.optionCategory.optionCategoryName,
        standardItemCode: vehInfo.standardItemCode,
        options: [
          {
            optionCode: vehInfo.option.optionCode,
            optionDescription: vehInfo.option.optionDescription,
            gSARequirementLevelCode: vehInfo.gSARequirementLevelCode,
            associationText: vehInfo.associationText,
            tags: vehInfo.option.tags,
          },
        ],
      };
      returnableData.push(vehInfoOption);
    } else {
      returnableData[foundMinReqIndex].options.push({
        optionCode: vehInfo.option.optionCode,
        optionDescription: vehInfo.option.optionDescription,
        gSARequirementLevelCode: vehInfo.gSARequirementLevelCode,
        associationText: vehInfo.associationText,
        tags: vehInfo.option.tags,
      });
    }
  });
  return returnableData;
};

const getContractValuesForOption = (optionCode, activeContractsData) => {
  const optionValues = [];
  activeContractsData.forEach((contract) => {
    const foundOption = contract?.minimumRequiredOptions?.find(
      (option) => option.equipmentCode === optionCode,
    );
    if (foundOption) {
      const optionWithContractCode = {
        ...foundOption,
        vendorName: contract.vendorName,
        modelName: contract.modelName,
        optionValue: foundOption.value,
      };
      optionValues.push(optionWithContractCode);
    } else {
      optionValues.push({
        vendorName: contract.vendorName,
        modelName: contract.modelName,
        optionCode,
        optionValue: '',
      });
    }
  });
  return optionValues;
};

const filterOutEmptyOptions = (
  filterableArray,
  fieldToCheck,
  excludedOptionCategories = [],
) => {
  const returnableData = [];
  const excludedOptions = []; // too bad, so sad, let's give them a second chance

  filterableArray.forEach((filterableItem) => {
    const item = filterableItem;

    const { optionCategoryCode } = item;
    const filteredOptions = item.options.filter((option) => {
      return option?.vendorValues?.some(
        (vendorItem) => !!vendorItem[fieldToCheck],
      );
    });
    if (
      filteredOptions.length &&
      excludedOptionCategories.indexOf(optionCategoryCode) === -1
    ) {
      item.options = filteredOptions;
      returnableData.push(item);
    } else {
      excludedOptions.push(item);
    }
  });

  const filteredArray = filterableArray.filter(
    (optCat) => !excludedOptionCategories.includes(optCat.optionCategoryCode),
  );

  return {
    noneEmptyOptions: filteredArray,
    emptyOptions: excludedOptions,
  };
};

export const getAvailableMinimumRequirements = (
  minReqsData,
  activeContractsData,
) => {
  const returnableData = [];
  minReqsData.forEach((minReq) => {
    const minReqOption = {
      optionCategoryCode: minReq.optionCategoryCode,
      optionCategoryName: minReq.optionCategoryName,
      options: [],
    };
    minReq.options.forEach((option) => {
      const optionValues = {
        optionCode: option.optionCode,
        optionDescription: option.optionDescription,
        gSARequirementLevelCode: option.gSARequirementLevelCode,
        associationText: option.associationText,
        tags: option.tags,
        vendorValues: getContractValuesForOption(
          option.optionCode,
          activeContractsData,
        ),
      };
      // if (optionValues.vendorValues)
      minReqOption.options.push(optionValues);
    });
    returnableData.push(minReqOption);
  });

  const { noneEmptyOptions } = filterOutEmptyOptions(
    returnableData,
    'optionValue',
  );

  return noneEmptyOptions;
};

const getOtherFeatureValuesForOption = (
  optionCode,
  optionValue,
  activeContractsData,
) => {
  const optionValues = [];
  activeContractsData.forEach((contract) => {
    const foundOption = contract.optionalFeatures.find(
      (option) => option.optionCode === optionCode,
    );

    if (foundOption) {
      const optionWithContractCode = {
        ...foundOption,
        vendorName: contract.vendorName,
        modelName: contract.modelName,
      };
      optionValues.push(optionWithContractCode);
    } else {
      optionValues.push({
        vendorName: contract.vendorName,
        modelName: contract.modelName,
        optionCode,
        optionValue,
      });
    }
  });

  return optionValues;
};

export const getAvailableVehicleInformation = (
  vehInfoData,
  activeContractsData,
) => {
  const returnableData = [];
  vehInfoData.forEach((vehInfo) => {
    const vehInfoOption = {
      optionCategoryCode: vehInfo.optionCategoryCode,
      optionCategoryName: vehInfo.optionCategoryName,
      options: [],
    };
    vehInfo.options.forEach((option) => {
      const optionValues = {
        optionCode: option.optionCode,
        optionDescription: option.optionDescription,
        gSARequirementLevelCode: option.gSARequirementLevelCode,
        associationText: option.associationText,
        tags: option.tags,
        otherFeatureValues: getOtherFeatureValuesForOption(
          option.optionCode,
          option.optionValue,
          activeContractsData,
        ),
      };
      vehInfoOption.options.push(optionValues);
    });
    returnableData.push(vehInfoOption);
  });
  return returnableData;
};

export const VehicleOverviewOptions = [
  { label: 'Business Class', value: 'vendorBusinessClassName' },
  { label: 'Model Year', value: 'modelYear' },
  {
    label: 'Shipment Days (Base)',
    value: 'shipmentDays',
  },
  { label: 'Additional Information', value: 'comment' },
];

export const getVehicleOverviewInformation = (activeContractsData) => {
  return VehicleOverviewOptions.map((option) => ({
    label: option.label,
    values: activeContractsData.map((contract) => ({
      option: contract[option.value],
      modelName: contract.modelName,
      vendorName: contract.vendorName,
    })),
  }));
};

export const getDisplayableVehicleOverview = (
  pageNumber,
  offset,
  vechicleOverviewData,
) => {
  const vehOverview = cloneDeep(vechicleOverviewData);
  const start = pageNumber * offset;
  const end = start + offset;

  vehOverview?.forEach((item) => {
    const option = item;
    option.values = [...option.values.slice(start, end)];
  });
  return vehOverview;
};

export const getDisplayableMinimumRequirements = (
  pageNumber,
  offset,
  allMinRequirements,
) => {
  const displayableMinReqs = cloneDeep(allMinRequirements);
  const start = pageNumber * offset;
  const end = start + offset;
  displayableMinReqs?.forEach((minReq) => {
    minReq.options.forEach((item) => {
      const option = item;
      option.vendorValues = [...option.vendorValues.slice(start, end)];
    });
  });
  return displayableMinReqs;
};

export const getDisplayableVehicleInfo = (
  pageNumber,
  offset,
  vehicleInformationData,
) => {
  const displayableVehicleData = cloneDeep(vehicleInformationData);
  const start = pageNumber * offset;
  const end = start + offset;

  displayableVehicleData?.forEach((displayableItem) => {
    displayableItem.options.forEach((item) => {
      const option = item;
      option.otherFeatureValues = [
        ...option.otherFeatureValues.slice(start, end),
      ];
    });
  });
  return displayableVehicleData;
};

export const getDisplayableContracts = (pageNumber, offset, allContracts) => {
  const start = pageNumber * offset;
  const end = start + offset;
  const displaybleContracts = [...allContracts.slice(start, end)];
  return displaybleContracts;
};

const findVendorValuesForOption = (
  optionCode,
  activeContractData,
  deliveryOptionCodes,
) => {
  const vendorValuesForOption = [];
  activeContractData.forEach((contract) => {
    const foundContractModLineOption = contract.minimumRequiredOptions.find(
      (modLineOption) => modLineOption.equipmentCode === optionCode,
    );
    if (foundContractModLineOption) {
      vendorValuesForOption.push({
        ...foundContractModLineOption,
        contractModLineIdentifier: `${contract.contractNumber}-000000-${foundContractModLineOption.scheduleLine}`,
        contractNumber: contract.contractNumber,
        scheduleLine: foundContractModLineOption.scheduleLine,
        vendorName: contract.vendorName,
        modelName: contract.modelName,
        contractLineId: contract.contractLineId,
        optionTypeCode: foundContractModLineOption.inputType,
      });
    } else if (deliveryOptionCodes.indexOf(optionCode?.substr(0, 3)) !== -1) {
      vendorValuesForOption.push({
        ...foundContractModLineOption,
        optionTypeCode: 'N',
        contractNumber: contract.contractNumber,
        vendorName: contract.vendorName,
        modelName: contract.modelName,
        contractLineId: contract.contractLineId,
        unitPrice: 'N/A',
      });
    } else {
      vendorValuesForOption.push({
        optionTypeCode: '',
        optionCode: '',
        contractLineId: contract.contractLineId,
        vendorName: contract.vendorName,
        modelName: contract.modelName,
      });
    }
  });
  return vendorValuesForOption;
};

const checkDeliveryOptionsCondition = (optionSelectedCode) => {
  switch (optionSelectedCode) {
    case 'US-AK':
      return ['CNSA', 'DDRA'];
    case 'US-AS':
      return ['CNSAS', 'DDRAS'];
    case 'US-GU':
      return ['CNSG', 'DDRG'];
    case 'US-HI':
      return ['CNSH', 'DDRH'];
    case 'US-MP':
      return ['CNSMP', 'DDRMP'];
    case 'US-PR':
      return ['CNSP', 'DDRP'];
    case 'US-UM':
      return ['CNSUM', 'DDRUM'];
    case 'US-VI':
      return ['CNSVI', 'DDRVI'];
    case 'EXPORT':
      return ['1611'];

    default:
      return ['CNS', 'DDR'];
  }
};

const checkDeliveryOptionsExist = (
  requiredDeliveryOptions,
  option,
  optionCode,
) => {
  return optionCode !== 'DDR'
    ? option.options.filter((li) => {
        if (requiredDeliveryOptions.indexOf(li.optionCode) !== -1) {
          return li.optionCode.includes(optionCode);
        }
        return null;
      })
    : option.options.filter((li) => {
        return !li.optionCode.startsWith('CNS');
      });
};

export const getGroupedOptionalRequirementsData = (
  optionalReqData,
  activeContractsData,
  selectedShipmentCategory,
) => {
  if (activeContractsData?.length === 0) {
    return {};
  }

  const returnableData = [];
  const deliveryOptionCodes = ['CNS', 'DDR', 'Delivery'];
  optionalReqData.forEach((optionalReq) => {
    const foundOptionalReqIndex = returnableData.findIndex(
      (optReq) =>
        optReq.optionCategoryCode ===
        optionalReq?.equipmentCode?.categoryCode?.code,
    );

    if (foundOptionalReqIndex === -1) {
      returnableData.push({
        standardItemCode: activeContractsData[0].standardItemCode,
        standardItemId: activeContractsData[0].standardItemId,
        optionCategoryCode: optionalReq?.equipmentCode?.categoryCode?.code,
        optionCategoryName: optionalReq?.equipmentCode?.categoryCode?.title,
        options: [
          {
            optionCode: optionalReq?.equipmentCode?.code,
            optionDescription: optionalReq?.equipmentCode?.title,
            quantityRequiredCode: optionalReq?.equipmentCode?.quantityRequired,
            gSARequirementLevelCode: optionalReq?.gSARequirementLevelCode,
            associationText: optionalReq?.associationText,
            tags: optionalReq?.equipmentCode?.tags,
            vendorValues: findVendorValuesForOption(
              optionalReq?.equipmentCode?.code,
              activeContractsData,
              deliveryOptionCodes,
            ),
          },
        ],
        selectionType:
          optionalReq?.equipmentCode?.categoryCode?.additionalProps
            ?.selectionType,
      });
    } else {
      returnableData[foundOptionalReqIndex].options.push({
        optionCode: optionalReq?.equipmentCode?.code,
        optionDescription: optionalReq?.equipmentCode?.title,
        quantityRequiredCode: optionalReq?.equipmentCode?.quantityRequired,
        gSARequirementLevelCode: optionalReq?.gSARequirementLevelCode,
        associationText: optionalReq?.associationText,
        tags: optionalReq?.equipmentCode?.tags,
        vendorValues: findVendorValuesForOption(
          optionalReq?.equipmentCode?.code,
          activeContractsData,
          deliveryOptionCodes,
        ),
      });
    }
  });

  // options from db to exclude 'Deliver' - CONUS, 'DDR' - OCONUS, 'ExDel' - Foriegn Delivery
  const excludedOptionCategories = ['Delivery', 'DDR', 'ExDel'];
  const { noneEmptyOptions, emptyOptions } = filterOutEmptyOptions(
    returnableData,
    'optionTypeCode',
    excludedOptionCategories,
  );
  // seperate delivery options from the rest
  let deliveryOptions = emptyOptions.filter(
    (option) =>
      excludedOptionCategories.indexOf(option.optionCategoryCode) !== -1,
  );

  // some fine touchs on the DDR
  // TODO: refactor it to be DB-driven, currently CATALOG DB doesn't have
  // correct categories for them

  let requiredDeliveryOptions = [];
  if (
    selectedShipmentCategory !== 'Select State' &&
    selectedShipmentCategory !== 'EXPORT'
  ) {
    requiredDeliveryOptions = checkDeliveryOptionsCondition(
      selectedShipmentCategory,
    );
  }

  if (selectedShipmentCategory === 'EXPORT') {
    deliveryOptions.forEach((optCat) => {
      optCat.options.forEach((opt) => {
        requiredDeliveryOptions.push(opt.optionCode);
      });
    });
  }

  // Filter required options on selection of US States/Territory or Foreign country
  deliveryOptions = deliveryOptions.filter((item) => {
    return item.options.filter((li) => {
      return requiredDeliveryOptions.indexOf(li.optionCode) !== -1;
    });
  });

  if (deliveryOptions.length) {
    const modifiedOptions = [];
    deliveryOptions.forEach((option) => {
      if (option.optionCategoryCode === 'Delivery') {
        const consigneeOptions = checkDeliveryOptionsExist(
          requiredDeliveryOptions,
          option,
          'CNS',
        );
        const dealershipOptions = checkDeliveryOptionsExist(
          requiredDeliveryOptions,
          option,
          'DDR',
        );
        if (consigneeOptions.length) {
          modifiedOptions.push({
            optionCategoryCode: 'CNS',
            optionCategoryName: 'Consignee delivery CONUS',
            selectionType: 'S',
            standardItemCode: '',
            options: consigneeOptions,
          });
        }

        if (dealershipOptions.length) {
          modifiedOptions.push({
            optionCategoryCode: 'DDR',
            optionCategoryName: 'Dealership delivery CONUS',
            selectionType: 'S',
            standardItemCode: '',
            options: dealershipOptions,
          });
        }
      }

      if (option.optionCategoryCode === 'DDR') {
        const consigneeOptions = checkDeliveryOptionsExist(
          requiredDeliveryOptions,
          option,
          'CNS',
        );
        const dealershipOptions = checkDeliveryOptionsExist(
          requiredDeliveryOptions,
          option,
          'DDR',
        );
        if (consigneeOptions.length) {
          modifiedOptions.push({
            optionCategoryCode: 'CNS',
            optionCategoryName: 'Consignee delivery outside CONUS',
            selectionType: 'S',
            standardItemCode: '',
            options: consigneeOptions,
          });
        }

        if (dealershipOptions.length) {
          modifiedOptions.push({
            optionCategoryCode: 'DDR',
            optionCategoryName: 'Dealership delivery outside CONUS',
            selectionType: 'S',
            standardItemCode: '',
            options: dealershipOptions,
          });
        }
      }

      if (option.optionCategoryCode === 'ExDel') {
        const exportDeliveryList = checkDeliveryOptionsExist(
          requiredDeliveryOptions,
          option,
          '1611',
        );
        if (exportDeliveryList.length) {
          modifiedOptions.push({
            optionCategoryCode: 'ExDel',
            optionCategoryName: 'Export delivery (foreign)',
            selectionType: 'S',
            standardItemCode: '',
            options: exportDeliveryList,
          });
        }
      }
    });
    deliveryOptions = modifiedOptions;
  }

  return {
    allAvailableOptionalReqData: noneEmptyOptions,
    deliveryOptions,
  };
};

export const getDisplayableOptionalData = (
  pageNumber,
  offset,
  allOptionalReqData,
) => {
  const displayableOptionalReqs = cloneDeep(allOptionalReqData);
  const start = pageNumber * offset;
  const end = start + offset;
  displayableOptionalReqs?.forEach((optionalReq) => {
    optionalReq.options.forEach((item) => {
      const option = item;
      option.vendorValues = [...option.vendorValues.slice(start, end)];
    });
  });
  return displayableOptionalReqs;
};

export const checkOptionDataIncluded = (
  selectedItemsForPurchase,
  addOptionState,
) => {
  selectedItemsForPurchase.forEach((item) => {
    if (
      item.includedOptions.length ||
      item.excludedOptions.length ||
      item.requiredOptions.length ||
      item.unResolvedOptions.length
    ) {
      addOptionState.map((optionList) => {
        return optionList.options.map((option) => {
          return option.vendorValues.map((vendorValue) => {
            const vendorValueOption = vendorValue;
            if (vendorValueOption.contractLineId === item.contractLineId) {
              if (item.includedOptions?.length) {
                item.includedOptions.forEach((includedItem) => {
                  if (
                    vendorValueOption.equipmentCode === includedItem.optionCode
                  ) {
                    vendorValueOption.isIncludeExist = true;
                  }
                });
              }
              if (item.excludedOptions?.length) {
                item.excludedOptions.forEach((excludedItem) => {
                  if (
                    vendorValueOption.equipmentCode === excludedItem.optionCode
                  ) {
                    vendorValueOption.isExcludeExist = true;
                  }
                });
              }
              if (item.requiredOptions?.length) {
                item.requiredOptions.forEach((requiredItem) => {
                  if (
                    vendorValueOption.equipmentCode === requiredItem.optionCode
                  ) {
                    vendorValueOption.isRequiredExist = true;
                  }
                });
              }
              if (item.unResolvedOptions?.length) {
                item.unResolvedOptions.forEach((unResolvedOption) => {
                  if (
                    vendorValueOption.equipmentCode ===
                    unResolvedOption.optionCode
                  ) {
                    vendorValueOption.unResolvedExist = true;
                  }
                });
              }
            }
            return vendorValueOption;
          });
        });
      });
    } else {
      addOptionState.map((optionList) => {
        return optionList.options.map((option) => {
          return option.vendorValues.map((vendorValue) => {
            const vendorValueOption = vendorValue;
            if (
              parseInt(vendorValueOption.contractLineId, 10) ===
              parseInt(item.contractLineId, 10)
            ) {
              if (vendorValueOption.isIncludeExist) {
                vendorValueOption.isIncludeExist = false;
              }
              if (vendorValueOption.isExcludeExist) {
                vendorValueOption.isExcludeExist = false;
              }
              if (vendorValueOption.isRequiredExist) {
                vendorValueOption.isRequiredExist = false;
              }
              if (vendorValueOption.unResolvedExist) {
                vendorValueOption.unResolvedExist = false;
              }
            }
            return vendorValueOption;
          });
        });
      });
    }
  });
  return addOptionState;
};

export const getDisplayableNetworkCostBreakDownData = (
  pageNumber,
  offset,
  networkCostBreakDownData,
) => {
  const costBreakdown = cloneDeep(networkCostBreakDownData);
  const start = pageNumber * offset;
  const end = start + offset;
  return [...costBreakdown?.slice(start, end)];
  // return costBreakdown;
};

export const getSimplifiedPayload = (optionItem, resolvedOptions) => {
  const matchedOption = [];
  resolvedOptions.forEach((list) => {
    if (list.contractLineId) {
      matchedOption.push({
        contractLineId: parseInt(list.contractLineId, 10),
        requiredOptionSelectionIndex: list.selectedIndex,
      });
    }
  });
  return matchedOption;
};

export const getSortedActiveContractsData = (
  contractsData,
  costBreakDownData,
) => {
  const sortedContractsData = [];
  costBreakDownData.forEach((cb) => {
    const foundContract = contractsData.find(
      (contract) => contract.contractLineId === cb.contractLineId,
    );
    if (foundContract) {
      sortedContractsData.push(foundContract);
    }
  });
  return sortedContractsData;
};

export const splitOptionsToVendorsLength = (vendors, len) => {
  const chunks = [];
  let i = 0;

  while (i < vendors.length) {
    chunks.push(vendors.slice(i, (i += len)));
  }
  return chunks;
};

export const cbRequiredOptionsSimplified = (networkCostBreakDownData) => {
  const simplifiedRequiredOptionsData = [];
  const vendors = [];
  networkCostBreakDownData.forEach((item) => {
    if (
      item.perVehicleRequiredOptions &&
      item.perVehicleRequiredOptions.length
    ) {
      item.perVehicleRequiredOptions.forEach((list) => {
        const optionsAvailable = simplifiedRequiredOptionsData.find(
          (option) => option.optionCode === list.optionCode,
        );
        if (!optionsAvailable)
          simplifiedRequiredOptionsData.push({
            ...list,
            contractLineId: item.contractLineId,
          });
      });
    }
  });

  simplifiedRequiredOptionsData.forEach((ud) => {
    let modifiedData = [];
    networkCostBreakDownData.forEach((li) => {
      modifiedData = li.perVehicleRequiredOptions.find((el) => {
        return el.optionCode === ud.optionCode;
      });
      vendors.push({
        ...modifiedData,
        contractLineId: li.contractLineId,
      });
    });
  });

  return {
    returnedSimplifiedData: simplifiedRequiredOptionsData,
    vendorPairs: splitOptionsToVendorsLength(
      vendors,
      networkCostBreakDownData.length,
    ),
  };
};

export const modifiedNewOptions = (
  oldSelectedOptionList,
  addOptionsData,
  requiredOptionSessionState,
) => {
  const addOptionsSelectedList = [];
  requiredOptionSessionState?.forEach((item) => {
    addOptionsSelectedList.push(item.optionCode);
  });

  const selectedOptionCodes = {};
  oldSelectedOptionList.forEach((oldSelectedItem) => {
    const item = oldSelectedItem;
    const options = [...item.options.map((li) => li.optionCode)];
    item.options.forEach((li) => {
      if (li.optionValue) {
        selectedOptionCodes[li.optionCode] = li.optionValue;
      }
    });
    item.options = options;
    selectedOptionCodes[item.optionCategoryCode] = item;
  });

  const newAddOptionData = cloneDeep(addOptionsData);
  newAddOptionData.forEach((item) => {
    item.options.forEach((list) => {
      const optionItem = list;
      optionItem.isChecked = false;
    });
  });
  const oldValuesList = [];
  newAddOptionData.forEach((item) => {
    let oldValue;
    if (selectedOptionCodes[item.optionCategoryCode]) {
      oldValue = { ...item };
      oldValue.options = [];
      item.options.forEach((list) => {
        const optionItem = list;
        if (addOptionsSelectedList.includes(optionItem.optionCode)) {
          optionItem.isChecked = true;
          if (selectedOptionCodes[optionItem.optionCode]) {
            optionItem.optionValue = selectedOptionCodes[optionItem.optionCode];
          }
          oldValue.options.push(optionItem);
        }
      });
    }
    if (oldValue) {
      oldValuesList.push(oldValue);
    }
  });

  return { oldValuesList, newAddOptionData };
};

export const EngFuelSpecOptions = {
  LitCylinder: 'Liters/Cylinders',
  FuelType: 'Fuel Type',
  AltFuelType: 'CARB State Fuel',
  ConvMpg: 'Conv MPG City/Hwy/Comb*',
  ConvGpm: 'Conventional GPM',
  AltMpg: 'Alt MPG City/Hwy/Comb*',
  AltGpm: 'Alternative GPM',
};

export const getDisplayableVehicleEngFuel = (
  pageNumber,
  offset,
  allActiveContracts,
) => {
  const start = pageNumber * offset;
  const end = start + offset;

  const formattedData = [
    {
      label: 'Liters/Cylinders',
      values: allActiveContracts
        .map((x) => ({
          option: `${x.engineInfo?.cylinders || '-'}cyl, ${
            x.engineInfo?.engineLitres || '-'
          }L`,
          modelName: x.modelName,
          vendorName: x.vendorName,
        }))
        .slice(start, end),
    },
    {
      label: 'Fuel Type',
      values: allActiveContracts
        .map((x) => ({
          option: `${x.fuelInfo?.fuelCodeLabel || '-'}`,
          modelName: x.modelName,
          vendorName: x.vendorName,
        }))
        .slice(start, end),
    },
    {
      label: 'Fuel Type for CARB States',
      values: allActiveContracts
        .map((x) => ({
          option: `${x.fuelInfo?.altFuelCodeLabel || '-'}`,
          modelName: x.modelName,
          vendorName: x.vendorName,
        }))
        .slice(start, end),
    },
    {
      label: 'Conv MPG City/Hwy/Combo',
      values: allActiveContracts
        .map((x) => ({
          option: `${x.fuelInfo?.convMpgCity || '-'} / ${
            x.fuelInfo?.convMpgHighway || '-'
          } / ${x.fuelInfo?.convMpgCombined || '-'}`,
          modelName: x.modelName,
          vendorName: x.vendorName,
        }))
        .slice(start, end),
    },
    {
      label: 'Conventional GPM',
      values: allActiveContracts
        .map((x) => ({
          option: `${x.fuelInfo?.convGpm || '-'}`,
          modelName: x.modelName,
          vendorName: x.vendorName,
        }))
        .slice(start, end),
    },
    {
      label: 'Alt MPG City/Hwy/Combo',
      values: allActiveContracts
        .map((x) => ({
          option: `${x.fuelInfo?.altMpgCity || '-'} / ${
            x.fuelInfo?.altMpgHighway || '-'
          } / ${x.fuelInfo?.altMpgCombined || '-'}`,
          modelName: x.modelName,
          vendorName: x.vendorName,
        }))
        .slice(start, end),
    },
    {
      label: 'Alternative GPM',
      values: allActiveContracts
        .map((x) => ({
          option: `${x.fuelInfo?.altGpm || '-'}`,
          modelName: x.modelName,
          vendorName: x.vendorName,
        }))
        .slice(start, end),
    },
  ];
  return formattedData;
};
