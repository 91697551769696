import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  PageTitle,
  Button,
  Modal,
  Breadcrumbs,
} from '@gsa/afp-component-library';
import { useHistory, Link } from 'react-router-dom';
import {
  NON_SOP_ORDERING_TYPES,
  ORDERING_DESC,
  NON_SOP_MAIN_TITLE_DESC,
  NON_SOP_MAIN_EMAIL,
} from './constants';
import VehicleRequisitionContext from '../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import VehicleRequisitionContextProvider from '../../Providers/VehicleRequisitionContextProvider/VehicleRequisitionContextProvider';
import ContactBuyingPopover from '../../components/ContactBuyingPopover/ContactBuyingPopover';

const NonSopLandingPage = () => {
  const { dispatch } = useContext(VehicleRequisitionContext);
  const history = useHistory();
  const [urgReqModal, setUrgReqModal] = useState(false);

  const urgModalCancel = () => {
    setUrgReqModal(false);
  };

  const urgModalConfirm = () => {
    history.push('/urgent-requisition');
    setUrgReqModal(false);
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_NON_SOP_OPTION,
      payload: NON_SOP_ORDERING_TYPES.URGENT_REQUIREMENT,
    });
  };

  const UrgentReqModal = () => {
    return (
      <div className="afp-modal-overlay modalContainer non-sop-modal">
        <Modal
          className="non-sop-modal-main"
          data-testid="urg-req-modal"
          title={
            <span className="urg-req-title">
              {NON_SOP_ORDERING_TYPES.URGENT_REQUIREMENT}
            </span>
          }
          onClose={urgModalCancel}
          actions={
            <>
              <Button
                data-testid="urg-req-cancel"
                type="button"
                variant="unstyled"
                onClick={urgModalCancel}
                label="Cancel"
              />
              <Button
                data-testid="urg-req-confirm"
                type="button"
                variant="standard"
                onClick={urgModalConfirm}
                label="Continue Urgent Requirement Requisition"
              />
            </>
          }
        >
          <div className="non-sop-modal-content">
            The Urgent Requirement processes vehicle orders for government
            agencies with urgent requirements. Agencies must justify the urgent
            and compelling reasons for using the Urgent Requirement instead of
            waiting for the normal procurement cycle and delivery time to take
            place. <br />
            <br />
            Prices will generally be higher than the established contract prices
            because the vehicles are purchased from a dealership and not
            directly from the manufacturer. Rules and guidelines governing the
            Urgent Requirement:
            <br />
            <ul>
              <li>GSA is the mandatory source for non-tactical vehicles.</li>
              <li>
                GSA by regulation MUST seek three sources of competition for the
                procurement.
              </li>
              <li>
                GSA encourages customers to recommend the names of dealers
                within the delivery area to be solicited by GSA. Please advise
                GSA of the names, addresses, phone numbers and dealer contacts
                with the requisition. There is no guarantee that a suggested
                source will receive a contract award.
              </li>
              <li>
                Customers should NOT negotiate terms and conditions with a
                potential contractor prior to sending in a requisition to GSA.
              </li>
              <li>
                After being advised by GSA about the price and any exceptions or
                clarifications covering the vehicle(s) solicited, agencies must
                expedite final decisions. Dealers will generally not hold
                vehicle(s) more than 48 hours.
              </li>
              <li>
                The government&apos;s payment terms are always NET 30 days upon
                receipt of a proper invoice.
              </li>
              <li>
                The GSA surcharge for Urgent Requirement orders is two percent,
                except during August through September, when the surcharge is
                five percent.
              </li>
            </ul>
            <br />
            You will be required to provide a justification. Are you sure you
            want to continue?
          </div>
        </Modal>
      </div>
    );
  };

  const handleOrderingProcess = (actionType) => {
    if (actionType === NON_SOP_ORDERING_TYPES.URGENT_REQUIREMENT) {
      setUrgReqModal(true);
    }
    if (actionType === NON_SOP_ORDERING_TYPES.MAS) {
      history.push('/mas-requisition');
    }
  };

  const OrderingOption = ({ title, content, handleProcess }) => {
    return (
      <div className="vehicle-ordering-options">
        <div className="purchase-option-title">{title} </div>
        <div className="purchase-option-desc">{content}</div>
        <Button
          label="Start here"
          data-testid={title}
          type="button"
          variant="standard"
          onClick={handleProcess}
        />
      </div>
    );
  };

  OrderingOption.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    handleProcess: PropTypes.func.isRequired,
  };

  return (
    <div className="non-sop-main-section">
      <div className="bredcrumb-section">
        <Breadcrumbs
          trail={[
            <Link to="/">Home</Link>,
            <Link to="/Purchase">Purchase</Link>,
          ]}
          current="Non Standard Ordering Options"
        />
        <ContactBuyingPopover />
      </div>
      <>
        <div className="purchasing-left-section">
          <PageTitle title="Non-Standard Vehicle Ordering Options" />
          <div className="sop-title-desc">
            {NON_SOP_MAIN_TITLE_DESC}
            <a className="sop-desc-link" href={`mailto:${NON_SOP_MAIN_EMAIL}`}>
              {NON_SOP_MAIN_EMAIL}
            </a>
          </div>
        </div>
        <div className="non-sop-blocks">
          <OrderingOption
            title={NON_SOP_ORDERING_TYPES.URGENT_REQUIREMENT}
            content={ORDERING_DESC.URGENT_REQUIREMENT_DESC}
            handleProcess={() =>
              handleOrderingProcess(NON_SOP_ORDERING_TYPES.URGENT_REQUIREMENT)
            }
          />
          <OrderingOption
            title={NON_SOP_ORDERING_TYPES.MAS}
            content={ORDERING_DESC.MAS_DESC}
            handleProcess={() =>
              handleOrderingProcess(NON_SOP_ORDERING_TYPES.MAS)
            }
          />
          {/* This will be enabled when we implement non standard work flow */}
          {/* <OrderingOption
            title={NON_SOP_ORDERING_TYPES.NON_STANDARD_VEHICLE}
            content={ORDERING_DESC.NON_STANDARD_VEHICLE_DESC}
            handleProcess={() => handleOrderingProcess}
          /> */}
        </div>
      </>
      {urgReqModal && <UrgentReqModal />}
    </div>
  );
};

const NonStandardOrdering = () => {
  return (
    <VehicleRequisitionContextProvider>
      <NonSopLandingPage />
    </VehicleRequisitionContextProvider>
  );
};

export default NonStandardOrdering;
