import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';

const EngineerPutOnHoldSuccessMessage = ({ reqName, reqId }) => {
  const history = useHistory();
  const [enableMessage, setEnableMessage] = useState(true);

  // Display for 10 seconds
  useEffect(() => {
    setTimeout(() => {
      setEnableMessage(false);
      history.push('/my-requisitions');
    }, 10000);
  }, []);

  const successMessage = (
    <>
      You have successfully placed <strong>{reqName}</strong> on hold for
      review.{' '}
      <a
        className="usa-link"
        href={`/requisition-details?requisitionId=${reqId}`}
      >
        View details
      </a>
    </>
  );

  return (
    <>
      {enableMessage && (
        <Alert type="success" slim>
          <span data-testid="engineer-on-hold-success-message">
            {successMessage}
          </span>
        </Alert>
      )}
    </>
  );
};

EngineerPutOnHoldSuccessMessage.propTypes = {
  reqName: PropTypes.string.isRequired,
  reqId: PropTypes.string.isRequired,
};

export default EngineerPutOnHoldSuccessMessage;
