import React, { useEffect, useRef, useState } from 'react';
import { Button, Icon } from '@gsa/afp-component-library';
import './ContactBuyingPopover.scss';
import PropTypes from 'prop-types';
import GSALogoSVG from '../../assets/images/gsa-icon.svg';

const ContactBuyingPopover = ({ isLink }) => {
  const ref = useRef(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShow(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [show]);

  return (
    <>
      <div ref={ref} className="tooltip-CVB">
        {!isLink && (
          <img className="gsa-svg-icon" src={GSALogoSVG} alt="GSA_logo" />
        )}
        <Button
          className={isLink ? 'padding-0' : ''}
          data-testid="contact-vehicle-buying-popover"
          variant="unstyled"
          label="Contact Vehicle Buying"
          onClick={() => {
            setShow(!show);
          }}
        />
        {show && (
          <div className="bottom">
            <div className="popover-title">Contact Vehicle Buying</div>
            <div className="popover-section">
              <Icon iconName="phone" className="usa-icon--size-3" />
              <span className="phone-number">844-472-1200</span>
            </div>
            <div className="popover-section">
              <Icon iconName="mail" className="usa-icon--size-3" />
              <a href="mailto:vehicle.buying@gsa.gov" className="email">
                vehicle.buying@gsa.gov
              </a>
            </div>
            <i />
          </div>
        )}
      </div>
    </>
  );
};

ContactBuyingPopover.propTypes = {
  isLink: PropTypes.bool,
};

ContactBuyingPopover.defaultProps = {
  isLink: false,
};

export default ContactBuyingPopover;
