import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@gsa/afp-component-library';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  GET_ORDER_ACTIVITIES,
  CREATE_ORDER_COMMENT,
  GET_REQUISITION_ACTIVITIES,
  GET_ORDER_ATTACHMENTS,
} from '../../../services/data-layer';
import CommentActivityCard, {
  ORDER_ACTIVITY_TYPES,
} from '../../comments-activity/CommentActivityCard';
import CommentModal from '../../../components/CommentModal/CommentModal';
import './OrderActivity.scss';

const OrderActivity = ({ setTab, orderId, orderData }) => {
  useEffect(() => setTab('orderActivity'), [setTab]);

  const [showAddCommentModal, setShowAddCommentModal] = useState(false);
  const [orderActivities, setOrderActivities] = useState([]);
  const [requisitionActivities, setRequisitionActivities] = useState([]);

  const [getOrderAttachments, { data: orderAttachmentData }] = useLazyQuery(
    GET_ORDER_ATTACHMENTS,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [getOrderActivitiesData, { data: orderActivitiesData }] = useLazyQuery(
    GET_ORDER_ACTIVITIES,
    {
      fetchPolicy: 'no-cache',
      onCompleted() {
        getOrderAttachments({
          variables: {
            orderId,
            type: '',
          },
        }); // eo getOrderAttachments
      }, // eo onCompleted
    },
  );

  const [getRequisitionActivitiesData] = useLazyQuery(
    GET_REQUISITION_ACTIVITIES,
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({ getRequisitionActivities }) => {
        if (getRequisitionActivities?.rows) {
          setRequisitionActivities(getRequisitionActivities?.rows);
        }
      },
    },
  );

  const [submitOrderMutation] = useMutation(CREATE_ORDER_COMMENT, {
    onCompleted: () => {
      getOrderActivitiesData({
        variables: {
          orderId,
          order: [['createdAt', 'DESC']],
        },
      });
    },
  });

  useEffect(() => {
    getOrderActivitiesData({
      variables: {
        orderId,
        order: [['createdAt', 'DESC']],
      },
    });
  }, [orderId]);

  useEffect(() => {
    if (orderData.requisitionId) {
      getRequisitionActivitiesData({
        variables: {
          requisitionId: orderData.requisitionId,
          offset: 0,
          order: [['createdAt', 'DESC']],
        },
      });
    }
  }, []);

  useEffect(() => {
    if (orderActivitiesData?.getOrderActivities) {
      const { rows } = orderActivitiesData.getOrderActivities;

      // there are attachment records, concat them with the activities
      if (orderAttachmentData?.getAllOrderAttachments?.length) {
        orderAttachmentData?.getAllOrderAttachments.forEach((attachment) => {
          const isInTheRows = rows.find((row) => row.id === attachment.id);
          if (!isInTheRows) {
            rows.push({
              id: attachment.id,
              comment: attachment.description,
              createdUserInfo: attachment.createdUserInfo,
              createdDateTime: attachment.createdAt,
              fileMetadata: attachment.fileMetadata,
              parentActivity: null,
              type: {
                name: ORDER_ACTIVITY_TYPES.ORDER_ATTACHMENT_UPLOADED,
              },
            });
          }
        });
      }

      const combineActivities = rows
        .concat(requisitionActivities)
        .sort((hansel, gretel) => {
          const hanselTime = new Date(hansel.createdDateTime).getTime();
          const gretelTime = new Date(gretel.createdDateTime).getTime();
          return gretelTime - hanselTime;
        });
      setOrderActivities(combineActivities);
    }
  }, [orderActivitiesData, requisitionActivities, orderAttachmentData]);

  const handleAddComment = (comment) => {
    setShowAddCommentModal(false);
    submitOrderMutation({
      variables: {
        orderId,
        comment,
      },
    });
  };

  const handleCancelComment = () => {
    setShowAddCommentModal(false);
  };

  return (
    <div className="order-activities-container">
      <div className="display-flex flex-align-end">
        <Button
          type="button"
          variant="outline"
          data-testid="add-comment-button"
          className="margin-left-auto"
          onClick={() => {
            setShowAddCommentModal(true);
          }}
          leftIcon={{ name: 'edit' }}
          label="Add comment"
        />
      </div>
      <CommentModal
        data-testid="add-comment-modal"
        isOpen={showAddCommentModal}
        onSubmit={handleAddComment}
        onCancel={handleCancelComment}
      />

      <div className="contain_activity-trackr">
        {orderActivities &&
          orderActivities.map((orderActivity) => (
            <CommentActivityCard
              key={orderActivity.id}
              activity={orderActivity}
              requisitionType="order"
            />
          ))}
      </div>
    </div>
  );
};

OrderActivity.propTypes = {
  setTab: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  orderData: PropTypes.objectOf(Object).isRequired,
};

export default OrderActivity;
