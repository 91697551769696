import moment from 'moment';

export const OrderVehicleStatus = {
  CANCELED: 'DE',
  ORDERED: 'SP',
  DELIVERED: 'DD',
  SHIPPED: 'CC',
  IN_PRODUCTION: 'IN',
  DE: 'DE',
  IN: 'IN',
  CC: 'CC',
  DD: 'DD',
  SP: 'SP',
  AR: 'AR',
};

const formatVehicleStatusName = (value) => {
  let orderVehicleName;
  // eslint-disable-next-line default-case
  switch (value) {
    case OrderVehicleStatus.CANCELED:
      orderVehicleName = 'Canceled';
      break;
    case OrderVehicleStatus.ORDERED:
      orderVehicleName = 'Ordered';
      break;
    case OrderVehicleStatus.DELIVERED:
      orderVehicleName = 'Delivered';
      break;
    case OrderVehicleStatus.SHIPPED:
      orderVehicleName = 'Shipped';
      break;
    case OrderVehicleStatus.IN_PRODUCTION:
      orderVehicleName = 'In production';
      break;
    case OrderVehicleStatus.IN:
      orderVehicleName = 'In production';
      break;

    case OrderVehicleStatus.CC:
      orderVehicleName = 'Shipped';
      break;

    case OrderVehicleStatus.DD:
      orderVehicleName = 'Delivered';
      break;

    case OrderVehicleStatus.SP:
      orderVehicleName = 'Ordered';
      break;
  }
  return orderVehicleName;
};

const formatDate = (date) => {
  //  Returns a date of format MM/DD/YYYY
  if (!date) {
    return '';
  }
  return moment(date.replace(/-/g, '/').replace(/T.+/, '')).format(
    'MM/DD/YYYY',
  );
};

export { OrderVehicleStatus as default, formatVehicleStatusName, formatDate };
