import React from 'react';
import { FilterPanel } from '@gsa/afp-component-library';
import { useOrderVehicleFilter } from '../providers/order-vehicle-filter-provider';
import {
  OrderVehicleStatus,
  formatVehicleStatusName,
} from '../constants/order-vehicle-constants';

const OrderVehicleFilter = () => {
  const filterStructure = [
    {
      title: 'Vehicle status',
      key: 'statusCode',
      id: 'filter-order-vehicle-statuses',
      type: 'multiselect',
      value: [],
      options: Object.keys(OrderVehicleStatus).map((status) => ({
        value: status,
        label: formatVehicleStatusName(status),
      })),
      permanent: false,
      operator: '$in',
      hideClear: true,
    },
  ];

  const { setFilters } = useOrderVehicleFilter();

  return (
    <div data-testid="vehicle-order-filters">
      <FilterPanel.FilterPanel
        filterStructure={filterStructure}
        setQueryFiltersState={(filters) => {
          setFilters(filters);
        }}
        clearButtonLabel="Reset All"
        showClearIcon
      />
    </div>
  );
};

export default OrderVehicleFilter;
