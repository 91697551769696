import { formatCurrency } from '../../../utilities/CompareVehicleUtils';

export const getFormattedValue = (optionTypeCode, optionValue) => {
  switch (optionTypeCode) {
    case 'STANDARD':
      return 'STANDARD';
    case 'COST':
      return formatCurrency(optionValue || 0);
    case 'INCLUDED':
      return 'Included';
    case 'AREQ':
      return '--';
    default:
      return 'N/A';
  }
};

export const filterOptions = (opts, paintAndGraphicsOptions, taggedOptions) => {
  const renderOptions = opts || [];
  const filteredOptions = [];

  renderOptions.forEach((item) => {
    const modifiedOptions = [];
    item.options?.forEach((opt) => {
      const foundFrmPaintGraphics = paintAndGraphicsOptions?.some(
        (el) => el.optionCode === opt.optionCode,
      );

      const foundFrmOptionDetails = taggedOptions?.some(
        (el) => el.optionCode === opt.optionCode,
      );

      if (
        !foundFrmPaintGraphics &&
        !foundFrmOptionDetails &&
        opt.optionType !== 'EXCLUDED'
      ) {
        modifiedOptions.push(opt);
      }
    });

    if (modifiedOptions.length) {
      filteredOptions.push({ ...item, options: modifiedOptions });
    }
  });

  return filteredOptions;
};
