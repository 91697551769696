/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';
import {
  AFPTable,
  EmptyState,
  Pagination,
  Spinner,
  Button,
  Alert,
  StatusBadge,
} from '@gsa/afp-component-library';
import useBulkOrderModsState from './useBulkOrderModsState';
import { resetFocusToFirstElement } from '../../utilities/commons';
import {
  ChangeSetType,
  getTableColumns,
  OrderModStatusType,
} from './BulkOrderModTableHelper';
import SelectAllOrderMods from './SelectAllOrderMods';
import SelectOrderMods from './SelectOrderMods';
import OrderStatusTag from '../my-orders/OrderStatusTag';

export const OrderModSteps = {
  CREATE_ORDER_MOD: 1,
  SELECT_ORDER_MOD: 2,
  UPDATE_ORDER_MOD: 3,
};

const BulkOrderModTable = () => {
  const [errorInPage, setErrorInPage] = useState(false);
  const {
    onOrderModsLoading,
    orderModRows,
    orderModCount,
    orderBy,
    orderModDirection,
    setOrderModDirection,
    setOrderBy,
    setResetPagination,
    selectedFilters,
    selectedOrdersForMod,
    currentStep,
    setCurrentStep,
    currentChangeSet,
    createOrderModChangeSetMutation,
    updateOrderModChangeSetMutation,
    setCurrentChangeSetType,
    paginationState,
    setPaginationState,
  } = useBulkOrderModsState();

  const ORDER_MOD_TABLE_COLUMNS = [
    {
      Header: <SelectAllOrderMods />,
      sortable: false,
      accessor: 'orderId',
      Cell: ({ row }) => {
        return <SelectOrderMods orderMod={row.original} />;
      },
    },
    {
      Header: 'Order Number',
      accessor: 'caseNumber',
    },
    {
      Header: 'Order Status',
      accessor: 'status',
      Cell: ({ value }) => {
        return <OrderStatusTag value={value} />;
      },
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
    },
    {
      Header: 'Schedule Line',
      accessor: 'scheduleLine',
    },
    {
      Header: 'Modification Status',
      accessor: 'modificationStatus',
      Cell: ({ value }) => {
        if (value) {
          const { color, label } = OrderModStatusType[value];
          return <StatusBadge variant={color}>{label}</StatusBadge>;
        }
        return '--';
      },
    },
  ];
  const handlePaginationChange = (newCurPage, newPerPage) => {
    const offset = (newCurPage - 1) * newPerPage;

    setPaginationState({
      limit: newPerPage,
      offset,
      currentPage: newCurPage,
    });

    /* if (newPerPage !== perPage) {
      setCurrentPage(1);
      setPerPage(newPerPage);
      setResetPagination(true);
    } else {
      setCurrentPage(newCurPage);
      setPerPage(newPerPage);
    } */

    resetFocusToFirstElement();
  };

  const goToUpdateOrderMods = () => {
    setResetPagination(true);

    if (selectedOrdersForMod?.length) {
      setErrorInPage(false);
      if (currentChangeSet?.type === ChangeSetType.TERMINATE) {
        setCurrentChangeSetType(ChangeSetType.TERMINATE);
      } else {
        setCurrentChangeSetType(ChangeSetType.MODIFY);
      }
      setCurrentStep(OrderModSteps.UPDATE_ORDER_MOD);
      if (currentChangeSet?.orderChangeSetModId) {
        updateOrderModChangeSetMutation();
      } else {
        createOrderModChangeSetMutation();
      }
    } else {
      setErrorInPage(true);
    }
  };
  const data = useMemo(() => {
    if (!orderModRows || !orderModRows.length) {
      return [];
    }
    return orderModRows?.map((row) => getTableColumns(row));
  }, [orderModRows, selectedFilters]);

  const columns = useMemo(() => ORDER_MOD_TABLE_COLUMNS, []);
  return (
    <>
      {selectedFilters &&
        selectedFilters.length > 0 &&
        currentStep === 2 &&
        data?.length > 0 && (
          <div className="table-main-container">
            <div className="select-order-container">
              <div className="grid-col-6">
                <h3>Select orders</h3>
                <p>Select one or more orders you&apos;d like to modify</p>
              </div>
              <div className="grid-col-6 select-contract-number">
                <p>
                  {selectedOrdersForMod?.length ||
                    currentChangeSet?.changeSetOrders?.length ||
                    0}{' '}
                  orders selected
                </p>
              </div>
            </div>
            {columns.length > 0 && (
              <AFPTable
                testId="order-mods-table"
                columns={columns}
                data={onOrderModsLoading ? [] : data}
                onSort={(sortOrder) => {
                  const [newOrderBy, newOrderModDirection] =
                    sortOrder.split(' ');
                  setOrderBy(newOrderBy.replace(/`/g, ''));
                  setOrderModDirection(newOrderModDirection);
                }}
              />
            )}

            {onOrderModsLoading ? (
              <Spinner className="margin-y-9" />
            ) : (
              <>
                <Pagination
                  buttonVariant="primary"
                  boundaryCount={1}
                  variant="advanced"
                  itemsCount={orderModCount}
                  currentPage={paginationState.currentPage}
                  itemsPerPage={paginationState.limit}
                  siblingCount={1}
                  isReset={paginationState.isReset}
                  onPageChange={handlePaginationChange}
                  defaultSort={`${orderBy}, ${orderModDirection}`}
                />
                {!orderModRows?.length && (
                  <EmptyState
                    data-testid="table-no-items"
                    containerStyles="table-no-items"
                    topText="No Order Mods Available"
                    topTextStyles="title"
                    bottomText="There are no matches for the filtered values at left."
                    bottomTextStyles="description"
                  />
                )}
                {orderModRows?.length > 0 && (
                  <div className="modify-selected-orders-container">
                    <h3>Modify selected orders</h3>

                    {errorInPage && (
                      <Alert type="error">
                        You must select at least one order to continue
                      </Alert>
                    )}
                    <Button
                      label="Modify selected orders"
                      type="button"
                      variant="primary"
                      data-testid="modify-selected-orders"
                      rightIcon={{ name: 'arrow_forward' }}
                      onClick={goToUpdateOrderMods}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        )}
      {onOrderModsLoading && data.length === 0 && (
        <Spinner size="medium" className="margin-y-8" />
      )}
    </>
  );
};

export default BulkOrderModTable;
