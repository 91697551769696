import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FilterTableFrame } from '@gsa/afp-component-library';
import { useAppAbility, useTitle } from '@gsa/afp-shared-ui-utils';
import { useHistory } from 'react-router-dom';
import VehicleFilterProvider from '../vehicle-filter-provider';
import VehicleFilter from './vehicle-filters';
import VehicleProvider from '../vehicle-provider';
import VehicleTable from './vehicle-table';
import { StoreSubjects, StoreOperations } from '../../../constants/constants';

const VehicleList = ({ setTab }) => {
  setTab('order-vehicles');
  useTitle('Requisitions and Orders-Vehicles');
  const history = useHistory();
  const ability = useAppAbility();
  const OrderVehicleFrame = useMemo(
    () => FilterTableFrame(null, null, VehicleFilter, VehicleTable),
    [],
  );
  useEffect(() => {
    if (ability) {
      if (
        !ability?.can(
          StoreOperations.updateReceiptDate,
          StoreSubjects.OrderVehicle,
        ) &&
        !ability?.can(StoreOperations.Update, StoreSubjects.Vendor)
      ) {
        history.push('/unauthorized');
      }
    }
  }, [ability]);

  return (
    <VehicleProvider>
      <VehicleFilterProvider>
        <OrderVehicleFrame filterToggle />
      </VehicleFilterProvider>
    </VehicleProvider>
  );
};

VehicleList.propTypes = {
  setTab: PropTypes.func.isRequired,
};

export default VehicleList;
