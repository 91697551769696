import React from 'react';
import PropTypes from 'prop-types';

export const WarnIcon = ({ width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" />
    </svg>
  );
};

WarnIcon.defaultProps = {
  width: 14,
  height: 14,
};

WarnIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

export default WarnIcon;
