import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { TabSet, PageTitle, Alert } from '@gsa/afp-component-library';
import { Redirect, useLocation } from 'react-router-dom';
import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { useQueryParam } from '../../hooks/useQueryParam';
import MyRequisitions from '../my-requisitions/MyRequisitions';
import SubmitOrderSuccessMessage from '../../components/SubmitRequisitionSuccessMessage/SubmitRequisitionSuccessMessage';
import OrderPlacedSuccessMessage from '../../components/OrderPlacedSuccessMessage/OrderPlacedSuccessMessage';
import ReturnRequisitionSuccessMessage from '../../components/ReturnRequisitionSuccessMessage/ReturnRequisitionSuccessMessage';
import MyOrders from '../my-orders/MyOrders';
import Breadcrumbs from './widgets/Breadcrumbs';
import './RequisitionsAndOrders.scss';
import ContactBuyingPopover from '../../components/ContactBuyingPopover/ContactBuyingPopover';
import { ENGINEERING_REVIEW_STATUS } from '../ReviewDetails/SubmitToEngineerButton/SubmitToEngineerButton';
import { ENGINEER_APPROVED } from '../ReviewDetails/EngineerApproveButton/EngineerApproveButton';
import SubmitToEngineerSuccessMessage from '../../components/SubmitToEngineerSuccessMessage/SubmitToEngineerSuccessMessage';
import OrderMods from '../order-modifications/OrderMods';
import VehicleList from './components/vehicle-list';
import { StoreSubjects, StoreOperations } from '../../constants/constants';
import { getUrlVar } from '../../constants/utils';
import EngineerPutOnHoldSuccessMessage from '../../components/EngineerPutOnHoldSuccessMessage/EngineerPutOnHoldSuccessMessage';
import { ON_HOLD_STATUS } from '../my-requisitions/PutOnHoldModal/PutOnHoldModal';
import EngineerApprovedSuccessMessage from '../../components/EngineerApprovedSuccessMessage/EngineerApprovedSuccessMessage';
import { RequisitionStatus } from '../ReviewDetails/RequisitionDetailsUtils';
import ModificationSuccessBanner from '../../components/ModificationCreateSuccessBanner/ModificationSuccessBanner';
import { useSystemAlert } from '../../services/system-alert';
import { UserRoles } from '../../constants/user-constants';

const RequisitionsAndOrders = (initialTab) => {
  return function RequisitionsAndOrdersComponent() {
    const { clearSystemAlert } = useSystemAlert();
    const { currentUser } = useCurrentUser();

    const ability = useAppAbility();
    const location = useLocation();
    const query = useQueryParam();
    const checkOrder = query.get('order');
    const orderStatus = getUrlVar('order', location);
    const checkReturned = query.get('returned');
    const returnStatus = getUrlVar('returned', location);
    const friendlyName = getUrlVar('draftName', location);
    const fromApproverToCo = getUrlVar('fromApproverToCo', location);
    const cancelUnsavedRequisition = getUrlVar(
      'cancelUnsavedRequisition',
      location,
    );
    const reqName = getUrlVar('reqName', location);
    const reqStatus = getUrlVar('reqStatus', location);
    const reqId = getUrlVar('reqId', location);
    const modName = getUrlVar('modName', location);
    const modStatus = getUrlVar('modStatus', location);

    const isVehicleSupplier = useMemo(
      () =>
        currentUser?.roles?.some(
          (role) => role.name === UserRoles.VEHICLE_SUPPLIER,
        ),
      [currentUser],
    );
    /**
     * Order modifications are accessible to ordering admin and contracting officer
     */
    const isOrderingAdmin = useMemo(
      () => ability?.can(StoreOperations.Update, StoreSubjects.Order),
      [ability],
    );
    const [tab, setTab] = useState(initialTab);

    useEffect(() => {
      if (checkOrder) setTab('orders');
      if (checkReturned) setTab('requisitions');
      if (modStatus && modName) setTab('orderMods');
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    useEffect(() => {
      clearSystemAlert();

      // change the url when the tab changes
      // using the native browser implementation to prevent reloading
      switch (tab) {
        case 'order-vehicles':
          window.history.pushState({}, '', '/order-vehicles');
          break;
        case 'orders':
          window.history.pushState({}, '', '/orders');
          break;
        case 'order-modifications':
          window.history.pushState({}, '', '/order-modifications');
          break;
        case 'requisitions':
          window.history.pushState({}, '', '/my-requisitions');
          break;
        default:
          break;
      }
    }, [tab]);

    const theAbilitiesCanViewOrderVehicle = useMemo(
      () =>
        ability?.can(
          StoreOperations.updateReceiptDate,
          StoreSubjects.OrderVehicle,
        ) || ability?.can(StoreOperations.Update, StoreSubjects.Vendor),
      [ability],
    );

    const tabs = useMemo(() => {
      const tempTabs = [
        {
          heading: 'Orders',
          tabSelectedWhenOpen: tab === 'orders',
          content: <MyOrders setTab={setTab} />,
        },
      ];
      if (!isVehicleSupplier) {
        if (ability.can(StoreOperations.View, StoreSubjects.Requisition)) {
          tempTabs.unshift({
            heading: 'Requisitions',
            tabSelectedWhenOpen: tab === 'requisitions',
            content: <MyRequisitions setTab={setTab} />,
          });
        }
        if (isOrderingAdmin) {
          tempTabs.push({
            heading: 'Modifications',
            tabSelectedWhenOpen: tab === 'order-modifications',
            content: <OrderMods setTab={setTab} />,
          });
        }
        if (theAbilitiesCanViewOrderVehicle) {
          tempTabs.push({
            heading: 'Vehicles',
            tabSelectedWhenOpen: tab === 'order-vehicles',
            content: <VehicleList setTab={setTab} />,
          });
        }
      }
      return tempTabs;
    }, [isVehicleSupplier, isOrderingAdmin, theAbilitiesCanViewOrderVehicle]);

    return (
      <div className="requisitions-and-orders">
        {isVehicleSupplier && location.pathname === '/my-requisitions' ? (
          <Redirect
            to={{
              pathname: '/orders',
            }}
          />
        ) : null}

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
          }}
        >
          <Breadcrumbs />
          <ContactBuyingPopover />
        </div>
        {friendlyName && !orderStatus && !returnStatus && (
          <SubmitOrderSuccessMessage />
        )}
        {friendlyName && orderStatus && !returnStatus && (
          <OrderPlacedSuccessMessage />
        )}
        {friendlyName &&
          !orderStatus &&
          returnStatus &&
          !reqStatus &&
          !fromApproverToCo && (
            <ReturnRequisitionSuccessMessage fromApproverToCo={false} />
          )}
        {friendlyName &&
          !orderStatus &&
          returnStatus &&
          !reqStatus &&
          fromApproverToCo && (
            <ReturnRequisitionSuccessMessage fromApproverToCo />
          )}

        {reqName && reqStatus === ENGINEERING_REVIEW_STATUS && (
          <SubmitToEngineerSuccessMessage reqName={reqName} />
        )}
        {modName && (modStatus === 'success' || modStatus === 'cancel') && (
          <ModificationSuccessBanner />
        )}
        {reqName &&
          (reqStatus === ENGINEER_APPROVED ||
            reqStatus === RequisitionStatus.PENDING_CUSTOMER_RESPONSE ||
            reqStatus === RequisitionStatus.FINAL_APPROVAL ||
            reqStatus === RequisitionStatus.CONTRACTING_REVIEW ||
            reqStatus === RequisitionStatus.ENGINEERING_APPROVAL) && (
            <EngineerApprovedSuccessMessage
              returned={Boolean(returnStatus)}
              reqName={reqName}
              reqStatus={reqStatus}
            />
          )}

        {reqName && reqStatus === ON_HOLD_STATUS && (
          <EngineerPutOnHoldSuccessMessage reqName={reqName} reqId={reqId} />
        )}

        <PageTitle
          data-testid="requisitions-and-orders-header"
          title={
            isVehicleSupplier
              ? 'Motor vehicle orders'
              : 'Requisitions and Orders'
          }
        />
        {cancelUnsavedRequisition && (
          <Alert type="success" slim>
            <span data-testid="cancel-draft-success-message">
              You have successfully cancelled the requisition
            </span>
          </Alert>
        )}
        <p className="requisitions-and-orders-header-desc">
          Draft requisitions are orders that have not yet been submitted. Any
          incomplete draft will be cancelled within 45 business days. Cancelled
          drafts will be deleted after 365 days. Requisitions which have been
          submitted and approved will display within the Orders tab.
        </p>
        {tab !== null && <TabSet tabs={tabs} />}
      </div>
    );
  };
};

RequisitionsAndOrders.propTypes = {
  initialTab: PropTypes.oneOf([
    'requisitions',
    'orders',
    'orderMods',
    'vehicles',
    null,
  ]),
};

RequisitionsAndOrders.defaultProps = {
  initialTab: 'requisitions',
};

export default RequisitionsAndOrders;
