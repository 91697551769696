import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumbs, TabSet, PageTitle, Spinner } from '@gsa/afp-component-library';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { useQueryParam } from '../../hooks/useQueryParam';
import {
  GET_CONTRACT_LINE_BY_ID,
  GET_DELIVERY_DEALERSHIPS_BY_DEALERSHIP_CODE,
  GET_ORDER_DETAILS,
  GET_ORDER_MOD_HISTORY
} from '../../services/data-layer';
import OrderStatusTag from '../my-orders/OrderStatusTag';
import './OrderDetailsContainer.scss';
import OrderVehicles from './components/OrderVehicles';
import OrderDetails from './components/OrderDetails';
import OptionsAndPricing from './components/OptionsAndPricing';
import OrderActivity from './components/OrderActivity';
import OrderAttachments from './components/OrderAttachments';
import ContactBuyingPopover from '../../components/ContactBuyingPopover/ContactBuyingPopover';
import { UserRoles } from '../../constants/user-constants';

const OrderDetailsContainer = () => {
  const query = useQueryParam();
  const [tab, setTab] = useState('orderDetails');
  const orderId1 = query.get('orderId');
  // const [requisitionActivities, setRequisitionActivities] = useState([]);
  const [contractModificationLine, setContractModificationLine] = useState({});
  const [deliveryDealershipAddress, setDeliveryDealershipAddress] =
    useState(null);
    const [orderModHistory, setOrderModHistory ] = useState({});

    const [getContractModLine] = useLazyQuery(GET_CONTRACT_LINE_BY_ID, {
    fetchPolicy: 'no-cache',
  });

  const [getOrderModDetails] = useLazyQuery(GET_ORDER_MOD_HISTORY, {
      fetchPolicy: 'no-cache',
  });

  const [getDealershipByDealershipCode] = useLazyQuery(
    GET_DELIVERY_DEALERSHIPS_BY_DEALERSHIP_CODE,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const { currentUser } = useCurrentUser();

  const isVehicleSupplier = useMemo(
    () =>
      currentUser?.roles?.some(
        (role) => role.name === UserRoles.VEHICLE_SUPPLIER,
      ),
    [currentUser],
  );

  const { loading, error, data } = useQuery(GET_ORDER_DETAILS, {
    variables: { orderId: orderId1 },
    fetchPolicy: 'network-first',
    onCompleted: (orderData) => {
      if (orderData) {
        const orderInfo = orderData?.getOrderById;
        const contractLineId = orderInfo?.contractLineId;
        const caseNumber = orderInfo?.caseNumber;
        const version = orderInfo?.version;
        if (contractLineId) {
          getContractModLine({
            variables: {
              contractLineId,
            },
            onCompleted: (contractModLineData) => {
              if (contractModLineData?.getContractLineById) {
                setContractModificationLine(
                  contractModLineData.getContractLineById,
                );
              }
            },
          });
        }
        if (caseNumber && version) {
          getOrderModDetails({
            variables: {
              caseNumber,
              version,
            },
            onCompleted: (orderModHistoryData) => {
              if (orderModHistoryData?.getOrderModsByCaseNumber) {
                setOrderModHistory(orderModHistoryData.getOrderModsByCaseNumber);
              }
            },
          });

        }
        const { deliveryDealershipCode } = orderData?.getOrderById || {};
        if (deliveryDealershipCode?.trim()) {
          getDealershipByDealershipCode({
            variables: {
              dealershipCode: deliveryDealershipCode,
            },
            onCompleted: (deliveryDealershipData) => {
              if (
                deliveryDealershipData?.getDeliveryDealershipByDealershipCode
              ) {
                setDeliveryDealershipAddress(
                  deliveryDealershipData?.getDeliveryDealershipByDealershipCode,
                );
              }
            },
          });
        }
      }
    },
  });

  const orderDetails = data ? data.getOrderById : null;
  if (loading) {
      return <Spinner className="margin-y-9" />
  }
  if (error) {
    return <div>Error!</div>;
  }

  return (
    <div className="order-details-container">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <Breadcrumbs
          trail={[
            <a key="home" href={`${window.AFP_CONFIG.appURLs.home}/home`}>
              Home
            </a>,
            <Link to="/orders">Requisitions and Orders</Link>,
          ]}
          current={orderDetails.caseNumber}
        />
        <ContactBuyingPopover />
      </div>
      <div className="order-details-container__pagetitle-container">
        <PageTitle
          data-testid="order-details-header"
          title={orderDetails.rpnNumber}
        />
        <span
          data-testid="order-info-status"
          className="order-details-container__status-tag text-style margin-left-2"
        >
          <OrderStatusTag value={orderDetails.orderStatus} />
        </span>
      </div>
      <h2 className="font-sans-lg text-normal margin-top-0">
        {orderDetails.requisitionNumber}
      </h2>
      {!!tab && (
        <TabSet
          tabs={
            !isVehicleSupplier
              ? [
                  {
                    heading: 'Order details',
                    tabSelectedWhenOpen: tab === 'orderDetails',
                    content: (
                      <OrderDetails
                        orderData={orderDetails}
                        setTab={setTab}
                        contractModificationLine={contractModificationLine}
                        currentUser={currentUser}
                        deliveryDealershipAddress={deliveryDealershipAddress}
                        orderModHistory={orderModHistory}
                      />
                    ),
                  },
                  {
                    heading: 'Options and pricing',
                    tabSelectedWhenOpen: tab === 'optionsAndPricing',
                    content: (
                      <OptionsAndPricing
                        orderData={orderDetails}
                        setTab={setTab}
                      />
                    ),
                  },
                  {
                    heading: 'Vehicles',
                    tabSelectedWhenOpen: tab === 'orderVehicles',
                    content: (
                      <OrderVehicles
                        orderData={orderDetails}
                        orderId={orderId1}
                        setTab={setTab}
                      />
                    ),
                  },
                  {
                    heading: 'Activity',
                    tabSelectedWhenOpen: tab === 'orderActivity',
                    content: (
                      <OrderActivity
                        orderData={orderDetails}
                        setTab={setTab}
                        orderId={orderId1}
                      />
                    ),
                  },
                  {
                    heading: 'Attachments',
                    tabSelectedWhenOpen: tab === 'orderAttachments',
                    content: (
                      <OrderAttachments orderId={orderId1} setTab={setTab} caseNumber={orderDetails?.caseNumber} />
                    ),
                  },
                ]
              : [
                  {
                    heading: 'Order details',
                    tabSelectedWhenOpen: tab === 'orderDetails',
                    content: (
                      <OrderDetails
                        orderData={orderDetails}
                        setTab={setTab}
                        contractModificationLine={contractModificationLine}
                        deliveryDealershipAddress={deliveryDealershipAddress}
                        orderModHistory={orderModHistory}
                      />
                    ),
                  },
                  {
                    heading: 'Options and pricing',
                    tabSelectedWhenOpen: tab === 'optionsAndPricing',
                    content: (
                      <OptionsAndPricing
                        orderData={orderDetails}
                        setTab={setTab}
                      />
                    ),
                  },
                  {
                    heading: 'Vehicles',
                    tabSelectedWhenOpen: tab === 'orderVehicles',
                    content: (
                      <OrderVehicles
                        orderData={orderDetails}
                        orderId={orderId1}
                        setTab={setTab}
                      />
                    ),
                  }
                ]
          }
        />
      )}
    </div>
  );
};

export default OrderDetailsContainer;
