/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { AFPTableRowAction } from '@gsa/afp-component-library';
import moment from 'moment';

const rowActions = [];

export const ActionColumn = (props) => {
  return (
    <AFPTableRowAction
      actions={rowActions}
      actionTitle="Standard Item Action"
      onSelectAction={() => {
        // do something with the label action
      }}
      {...props}
    />
  );
};

const getTempContractShipmentDate = (row) => {
  const daysOffset =
    (row.baseShipmentDays ? row.baseShipmentDays : 0) +
    (row.additionalShipmentDays ? row.additionalShipmentDays : 0);
  return moment(row.createdAt).add(daysOffset, 'days').format('MM/DD/YYYY');
};

export const getTableColumns = (row) => {
  return {
    orderId: row.orderId,
    caseNumber: row.caseNumber || '--',
    friendlyName: row.friendlyName || '--',
    orderStatus: row.orderStatus,
    createdAt: moment(row.createdAt).format('MM/DD/YYYY'),
    submittedToVendorAt: row.submittedToVendorAt
      ? moment(row.submittedToVendorAt).format('MM/DD/YYYY')
      : '--',
    contractShipmentDate: row.shipmentDate
      ? moment(row.shipmentDate).format('MM/DD/YYYY')
      : getTempContractShipmentDate(row),
    vehicleTypeCode: row?.vehicleTypeCode,
    bureauCode: row.bureauCode || '--',
    agencyCode: row.agencyCode || '--',
    modelCode: row?.modelCode || '--',
    quantity: row.quantity,
    totalSellingPrice: row.totalSellingPrice || '--',
    standardItemCode: row.standardItemCode || '--',
    createdByUser: row.createdUserInfo
      ? `${row.createdUserInfo?.firstName} ${row.createdUserInfo?.lastName}`
      : '--',
    createdByUserEmail: row.createdUserInfo ? row.createdUserInfo?.email : '--',
    bureau: row.bureauInfo?.name || '--',
    office: row.officeInfo?.officeName || '--',
    upiidNumber: row.upiidNumber || '--',
    model: row.modelInfo?.modelName || '--',
    approvedByUser: row.approvedByUserInfo
      ? `${row.approvedByUserInfo.firstName} ${row.approvedByUserInfo.lastName}`
      : '--',
    approvedByUserEmail: row.approvedByUserInfo
      ? row.approvedByUserInfo.email
      : '--',
    agencyInfoName: row.agencyInfo?.name || '--',
    mvdoFileMetadataId: row.mvdoAttachment?.metadataId,
    mvdoFileMetaData: row.mvdoAttachment?.fileMetadata,
    agencyOrderNumber: row.customerAssignedNumber || '--',
    requisitionNumber: row.requisitionNumber || '--',
    requisitionType: row.requisitionType || 1,
  };
};

ActionColumn.propTypes = {
  value: PropTypes.instanceOf(Array).isRequired,
  row: PropTypes.instanceOf(Object).isRequired,
};
