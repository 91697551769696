import React, { useContext, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { STEPS } from '../VehicleRequisition/constants/VehicleRequisitionConstants';
import ErrorContext from '../../context/ErrorContext/ErrorContext';

import ReturnOrderModal from './ReturnOrderModal';
import EngineerApproveButton from './EngineerApproveButton/EngineerApproveButton';
import { RequisitionStatus } from './RequisitionDetailsUtils';
import { canEngineerReviewRequisitionCart } from '../../utilities/authorization';
import EngineerApprovalStatusButtons from '../../components/EngineerApprovalStatusButtons/EngineerApprovalStatusButtons';
import { isAreqType } from '../non-standard-purchase/constants';
import { StoreOperations, StoreSubjects } from '../../constants/constants';

const EngineerActionButtons = ({
  requisitionId,
  requisitionCartState,
  requisitionCartValidations,
  canSubmitOrder,
  reference,
}) => {
  const ability = useAppAbility();
  const { state: errorState } = useContext(ErrorContext);
  const [errorList, setErrorList] = useState([]);
  const canEngineerReviewRequisition =
    canEngineerReviewRequisitionCart(ability);

  const { currentUser } = useCurrentUser();
  const isEngineer = useMemo(
    () => ability?.can(StoreOperations.Create, StoreSubjects.CatalogCodes),
    [ability],
  );
  const { requisitionStatus, requisitionType } = requisitionCartState;
  const isAreq = isAreqType(requisitionType);

  const showEngineerButtons =
    canEngineerReviewRequisition &&
    [
      RequisitionStatus.ENGINEERING_REVIEW,
      RequisitionStatus.ENGINEERING_APPROVAL,
    ].includes(requisitionStatus) &&
    isEngineer;

  useEffect(() => {
    if (errorState[STEPS.AGENCY_INFORMATION]?.agencyInformation) {
      const boacErrors = [];
      errorState[STEPS.AGENCY_INFORMATION].agencyInformation.forEach(
        (value) => {
          boacErrors.push(<li>{value}</li>);
        },
      );

      setErrorList(boacErrors);
    }
  }, [errorState]);

  if (!showEngineerButtons) {
    return null;
  }

  if (requisitionStatus === RequisitionStatus.ENGINEERING_APPROVAL) {
    return (
      <EngineerApprovalStatusButtons
        reference={reference}
        requisitionId={requisitionId}
        requisitionName={requisitionCartState.friendlyName}
        status={requisitionStatus}
        requisitionCartValidations={requisitionCartValidations}
        canSubmitOrder={canSubmitOrder}
      />
    );
  }

  // check if the requisition is assigned to the current user
  if (isAreq && requisitionCartState.assignedEngineer?.id !== currentUser?.id) {
    return null;
  }

  // check if the requisition is in engineer review status and the current user is not an engineer
  if (
    isAreq &&
    requisitionStatus === RequisitionStatus.ENGINEERING_REVIEW &&
    !isEngineer
  ) {
    return null;
  }

  return (
    <>
      <ReturnOrderModal
        requisitionId={requisitionId}
        requisitionName={requisitionCartState.friendlyName}
        requisitionCartValidations={requisitionCartValidations}
        canSubmitOrder={canSubmitOrder}
        visible
      />
      {errorList.length === 0 && (
        <>
          <EngineerApproveButton
            requisitionCartState={requisitionCartState}
            requisitionId={requisitionId}
            requisitionName={requisitionCartState.friendlyName}
          />
        </>
      )}
    </>
  );
};

EngineerActionButtons.propTypes = {
  requisitionId: PropTypes.string.isRequired,
  requisitionCartState: PropTypes.instanceOf(Object).isRequired,
  requisitionCartValidations: PropTypes.instanceOf(Object).isRequired,
  canSubmitOrder: PropTypes.bool.isRequired,
  reference: PropTypes.instanceOf(Object).isRequired,
};

export default EngineerActionButtons;
