import { useLazyQuery } from '@apollo/client';
import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { GET_ORDERS_FOR_USER } from '../../services/data-layer';
import { GET_ACTIVE_VEHICLE_GROUPS } from '../../services/data-layer/standards';
import { VEHICLE_GROUP_TAG_STORE } from '../../constants/constants';

export const MyOrdersContext = createContext({});

export default function MyOrdersProvider({ children }) {
  const [orderRows, setOrderRows] = useState([]);
  const [orderCount, setOrderCount] = useState(0);
  const [allVehicleTypes, setAllVehicleTypes] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [isReset, setResetPagination] = useState(false);
  const [orderBy, setOrderBy] = useState('createdAt');
  const [orderDirection, setOrderDirection] = useState('DESC');
  const [paginationState, setPaginationState] = useState({
    limit: 10,
    offset: 0,
    currentPage: 1,
    isReset: false,
  });
  const [getOrders, { data: orderResponseData, loading: onOrdersLoading }] =
    useLazyQuery(GET_ORDERS_FOR_USER, {
      fetchPolicy: 'network-only',
    });

  const [getVehicleTypes] = useLazyQuery(GET_ACTIVE_VEHICLE_GROUPS, {
    fetchPolicy: 'c',
    variables: {
      childTag: VEHICLE_GROUP_TAG_STORE,
    },
  });

  useEffect(() => {
    (async () => {
      const { data } = await getVehicleTypes();
      setAllVehicleTypes(data?.getActiveVehicleGroups || []);
    })();

    return () => {};
  }, []);

  useEffect(() => {
    if (!orderResponseData) {
      return;
    }

    const { getAllOrdersForUser: data } = orderResponseData;
    setOrderCount(data?.count);
    setOrderRows(data?.rows);
  }, [orderResponseData]);

  // useEffect(() => {
  //   if (!vehicleTypesData) {
  //     return;
  //   }
  //   const newVehicleTypes =
  //     vehicleTypesData?.searchStandardItems?.filterValues
  //       ?.standardItemSubCategories || [];
  //   const sortedVehicleTypes = [...newVehicleTypes];
  //   sortedVehicleTypes.sort((a, b) => {
  //     if (a.standardItemSubCategoryName < b.standardItemSubCategoryName) {
  //       return -1;
  //     }
  //     if (a.standardItemSubCategoryName < b.standardItemSubCategoryName) {
  //       return 1;
  //     }
  //     return 0;
  //   });
  //   setAllVehicleTypes(sortedVehicleTypes);
  // }, [vehicleTypesData]);

  /*  useEffect(() => {
    getOrders({
      variables: {
        query: {
          offset: (currentPage - 1) * perPage,
          limit: perPage,
          orderBy,
          orderDirection,
        },
      },
    });

    setResetPagination(false);
  }, [currentPage, perPage, orderBy, orderDirection]); */

  const value = {
    orderRows,
    orderCount,
    onOrdersLoading,
    allVehicleTypes,
    perPage,
    setPerPage,
    currentPage,
    setCurrentPage,
    isReset,
    setResetPagination,
    orderBy,
    setOrderBy,
    orderDirection,
    setOrderDirection,
    getOrders,
    paginationState,
    setPaginationState,
  };

  return (
    <MyOrdersContext.Provider value={value}>
      {children}
    </MyOrdersContext.Provider>
  );
}

MyOrdersProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
