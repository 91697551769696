export const getLowestPrice = (vendorQuotes = []) =>
  Math.min(...vendorQuotes.map(({ totalPrice }) => totalPrice));

export const getFullVendorQuotes = (
  vendorQuotes,
  activeContracts,
  allModelCostBreakDown,
) => {
  return vendorQuotes.map((vendorQuote) => {
    const { makeModel } = vendorQuote;

    const contract = activeContracts.find((activeContract) => {
      // FIXME: This scares me. If make or model contains a '/', this will break
      const [make, modelCode] = makeModel.split('/');
      if (!make || !modelCode) {
        return false;
      }

      return (
        (activeContract.makeCode === make ||
          activeContract.makeCode === +make) &&
        activeContract.modelCode === modelCode
      );
    });

    if (!contract) {
      return {
        errorMsg: `Vendor contract schedule line ${makeModel} is not currently active.`,
      };
    }

    const costBreakdown =
      allModelCostBreakDown.find(
        ({ contractLineId }) => contract.id === contractLineId,
      ) ?? {};

    const areqPrice = vendorQuote.totalPrice;
    const areqPriceWithFee = areqPrice * costBreakdown.purchaseRate;
    const totalPrice =
      (costBreakdown.baseAndOptionsPrice + areqPriceWithFee) *
      costBreakdown.quantity;

    return {
      ...vendorQuote,
      ...contract,
      ...costBreakdown,
      vendorId: contract.contractModificationRef?.contract?.vendor?.id,
      totalPrice,
      totalSellingPrice: totalPrice,
      areqPrice,
      areqPriceWithFee,
    };
  });
};
