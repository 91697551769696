import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { optionTypeCheck } from '../../AddOptionsUtils';
import { InfoIcon } from '../../../../assets/images/info-icon';
import NumInput from '../NumInput/NumInput';

const CheckBoxesGroup = ({
  optionData,
  handleRowCheckBoxClick,
  onNumInputChange,
  onNumInputFocusChanged,
  lowestBidData,
  min = 1,
  max = 999,
  displayCheckedOptionsOnly = false,
}) => {
  const nonAreqOptions = optionData.options.filter(
    (li) => li.optionCode !== 'AREQ',
  );

  return nonAreqOptions.map((option) => {
    const perText = `between ${min} and ${max}`;
    const fragementKey = `${optionData.standardItemCode}-${optionData.optionCategoryCode}-${option.optionCode}`;
    const isOptionUnAvailable =
      option.vendorValues.length > 0
        ? option.vendorValues.every(
            (item) =>
              (item.equipmentCode === undefined &&
                item.optionTypeCode === '' &&
                item.unitPrice === undefined) ||
              item.optionTypeCode === 'X' ||
              item.optionTypeCode === 'Y' ||
              item.unitPrice === 'N/A',
          )
        : true;
    return (
      <React.Fragment key={fragementKey}>
        <tr data-testid={`${option.optionCode}-option-error-alert`}>
          {option.hasError && !option.focused && (
            <td colSpan={option.vendorValues.length + 1} className="alert">
              <div className="usa-alert usa-alert--error usa-alert--slim margin-0">
                <div className="usa-alert__body">
                  <p className="usa-alert__text">
                    Please enter a quantity {perText}
                  </p>
                </div>
              </div>
            </td>
          )}
          {option?.optionCode && (
            <>
              <td
                data-testid={`${optionData.optionCategoryCode}-${option.optionCode}`}
              >
                <div className="option-description-wrapper">
                  <span
                    className={`option-description-column ${
                      option.hasError && !option.focused ? 'has-error' : ''
                    }`}
                  >
                    {displayCheckedOptionsOnly || (
                      <input
                        data-testid={option.optionCode}
                        className="input-checkbox"
                        type="checkbox"
                        disabled={isOptionUnAvailable}
                        onChange={(e) => handleRowCheckBoxClick(e.target.value)}
                        checked={option.isChecked || false}
                        value={option.optionCode}
                        id={`${option.optionCode}-checkbox`}
                      />
                    )}
                    <label htmlFor={`${option.optionCode}-checkbox`}>
                      {option.optionCode} - {option.optionDescription}
                    </label>
                  </span>
                  {(option.quantityRequiredCode === 'V' ||
                    option.quantityRequiredCode === 'O') && (
                    <NumInput
                      option={option}
                      onChange={onNumInputChange}
                      onFocusChange={onNumInputFocusChanged}
                      max={max}
                    />
                  )}
                </div>
              </td>

              {option.vendorValues.map((vendorValue) => {
                const activateLowestBid =
                  vendorValue.contractLineId === lowestBidData.contractLineId
                    ? 'lowest-bid'
                    : '';
                const showInfoTooltip =
                  vendorValue?.contractLineEcClarification?.length > 0;
                return (
                  <td
                    className={`option-cell ${activateLowestBid}`}
                    key={uuidv4()}
                  >
                    <div role="tab" tabIndex="0">
                      {optionTypeCheck(
                        vendorValue.optionTypeCode,
                        vendorValue.unitPrice,
                        vendorValue.isIncludeExist,
                        vendorValue.isExcludeExist,
                        vendorValue.isRequiredExist,
                        vendorValue.unResolvedExist,
                      )}
                      {showInfoTooltip && (
                        <span className="tooltip">
                          <span className="info-icon">
                            <InfoIcon
                              role="img"
                              aria-label={
                                vendorValue?.contractLineEcClarification[0]
                                  ?.clarification
                              }
                            />
                          </span>
                          <div className="tooltip-info">
                            {
                              vendorValue?.contractLineEcClarification[0]
                                ?.clarification
                            }
                            <i />
                          </div>
                        </span>
                      )}
                    </div>
                  </td>
                );
              })}
            </>
          )}
        </tr>
      </React.Fragment>
    ); // eo return
  });
};

export default CheckBoxesGroup;
