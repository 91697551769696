import React from 'react';
import { Alert } from '@gsa/afp-component-library';
import { useHistory } from 'react-router-dom';
import { useSystemAlert } from '../../services/system-alert';

const SystemAlert = () => {
  const { systemAlert, clearSystemAlert } = useSystemAlert();
  const history = useHistory();

  history.listen(() => {
    if (!systemAlert.show) return;
    clearSystemAlert();
  });

  return systemAlert.show ? (
    <Alert
      type={systemAlert.type}
      heading={systemAlert.heading}
      showClose={systemAlert.showClose}
      focused={systemAlert.focused}
      onClose={
        systemAlert.showClose
          ? () => {
              clearSystemAlert();
            }
          : null
      }
    >
      {systemAlert.message}
      {systemAlert.errorHint}
    </Alert>
  ) : null;
};

export default SystemAlert;
