import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const VehicleOverviewSection = ({
  vehicleOverviewData,
  setAdditionalInfo,
  openModal,
  lowestBidData,
}) => {
  return (
    <>
      <div className="veh-overview__container">
        <header className="veh-overview__header">Overview</header>
        <div className="veh-overview__body">
          <table className="usa-table full-width-table" role="presentation">
            <tbody>
              {vehicleOverviewData.map((item) => (
                <tr key={item.label}>
                  <td
                    data-testid={`vehicle-overview-description-${item.label}`}
                  >
                    <label className="option-label">{item.label}</label>
                  </td>
                  {item.values.map((value, index) => {
                    const isComment = item.label === 'Additional Information';
                    const getVisibleLabel = () => {
                      if (!isComment || !value.option) {
                        return value.option || '--';
                      }
                      if (value.option.length < 50) {
                        return value.option;
                      }
                      return `${value.option.substring(0, 48)}...`;
                    };

                    const activateLowestBid =
                      value.modelName === lowestBidData.modelName &&
                      value.vendorName === lowestBidData.vendorName;
                    return (
                      <td
                        key={`${value.modelName}-${value.vendorName}`}
                        data-testid={`vehicle-overview-value-${item.label}-${index}`}
                        className={cx(
                          'option-cell',
                          activateLowestBid && 'lowest-bid',
                        )}
                      >
                        <label className="option-label">
                          {getVisibleLabel()}
                        </label>
                        {isComment && value.option && value.option.length > 50 && (
                          <span
                            data-testid={`read-more-${index}`}
                            className="read-more"
                            onClick={() => {
                              setAdditionalInfo(value.option);
                              openModal();
                            }}
                            onKeyDown={() => {
                              // do nothing
                            }}
                            role="button"
                            tabIndex="0"
                          >
                            Read more
                          </span>
                        )}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

VehicleOverviewSection.defaultProps = {
  lowestBidData: {},
};

VehicleOverviewSection.propTypes = {
  vehicleOverviewData: PropTypes.instanceOf(Array).isRequired,
  setAdditionalInfo: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  lowestBidData: PropTypes.instanceOf(Object),
};

VehicleOverviewSection.defaultProps = {
  lowestBidData: {},
};

export default VehicleOverviewSection;
