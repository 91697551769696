import React, { useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import { formatCurrency } from '../../../utilities/CompareVehicleUtils';
import {
  getAdditionalMaterialsFromSelectedContractCostBreakdown,
  getFormattedValue,
  getPriceDataFromRequisition,
} from '../../../pages/VehicleRequisition/utils/ReviewDetailsUtils';
import VehicleRequisitionContext from '../../../context/VehicleRequisitionContext/VehicleRequisitionContext';

import './CostSummary.scss';
import { RequisitionStatus } from '../../../pages/ReviewDetails/RequisitionDetailsUtils';

const CostSummary = ({ calculatedPriceData, isAreq, requisition }) => {
  const {
    selectedVendorQuote,
    state: { selectedContractCostBreakdown: areqContractCostBreakdown },
  } = useContext(VehicleRequisitionContext);

  const GSA_PURCHASE_FEE =
    calculatedPriceData?.purchaseRate && calculatedPriceData?.purchaseRate > 1.0
      ? `${(calculatedPriceData?.purchaseRate * 100 - 100).toPrecision()}%`
      : '2%';

  const additionalMaterials =
    getAdditionalMaterialsFromSelectedContractCostBreakdown(
      calculatedPriceData,
    );

  const requisitionCalculatedData = getPriceDataFromRequisition(requisition);

  const getTotalVehicleOptions = () => {
    if (
      requisition &&
      requisition.requisitionStatus === RequisitionStatus.RECEIVED_BY_GSA
    ) {
      return requisitionCalculatedData.totalOptionPrice;
    }
    return areqContractCostBreakdown
      ? areqContractCostBreakdown.perVehicleOptionsPrice
      : calculatedPriceData?.perVehicleOptionsPrice;
  };

  const getBasePrice = () => {
    if (
      requisition &&
      requisition.requisitionStatus === RequisitionStatus.RECEIVED_BY_GSA
    ) {
      return requisitionCalculatedData.unitPriceToCustomer;
    }
    return (
      areqContractCostBreakdown?.basePrice || calculatedPriceData?.basePrice
    );
  };

  const getTotalPrice = () => {
    if (
      requisition &&
      requisition.requisitionStatus === RequisitionStatus.RECEIVED_BY_GSA
    ) {
      return requisitionCalculatedData.totalSellingPrice;
    }
    return (
      selectedVendorQuote?.totalSellingPrice ||
      calculatedPriceData?.totalSellingPrice ||
      0
    );
  };

  return (
    <>
      <div role="tab" tabIndex="0" className="cost-summary">
        <div className="title-section">Cost summary</div>

        {isAreq && !selectedVendorQuote ? (
          <>
            <div className="vehicle-common-section-no-border">
              <span className="label-left margin-right-10">
                <span className="margin-right-10">Quantity of vehicles</span>
              </span>
              <span data-testid="quantity-of-vehicle" className="value-right">
                {requisition?.quantity || 1}
              </span>
            </div>
            <div className="total-price">
              <span className="total-price-label">
                Total price{' '}
                <label htmlFor="totalPrice" className="total-price-label-info">
                  Total price will be calculated after vehicle and AREQ
                  selection.
                </label>
              </span>
              <span data-testid="total-price" className="price-value">
                --
              </span>
            </div>
          </>
        ) : (
          <>
            {Boolean(selectedVendorQuote) && (
              <div className="vehicle-common-section">
                <span>Base</span>
                <span className="price-value">
                  {formatCurrency(getBasePrice())}
                </span>
              </div>
            )}

            <div className="vehicle-common-section label-left bordered-bottom">
              <span>Total vehicle options</span>
              <span className="price-value">
                {formatCurrency(getTotalVehicleOptions())}
              </span>
            </div>

            {Boolean(selectedVendorQuote) && (
              <div className="vehicle-common-section-no-border subtotal-section">
                <span>Total AREQ amount</span>
                <span className="price-value">
                  {formatCurrency(selectedVendorQuote.areqPriceWithFee)}
                </span>
              </div>
            )}

            {additionalMaterials.length > 0 && (
              <>
                <div className="additional-materials">
                  <div className="additional-material-title bordered-bottom labeled-left">
                    Additional materials (per order)
                  </div>
                  {additionalMaterials.map((priceOrderList) => {
                    return (
                      <div
                        className="common-sub-section bordered-bottom"
                        key={uuidv4()}
                      >
                        <span>
                          {priceOrderList.optionCode} -{' '}
                          {priceOrderList.optionDescription}
                          {priceOrderList.quantity > 1
                            ? ` x ${priceOrderList.quantity}`
                            : ''}
                        </span>
                        <span className="price-value">
                          {getFormattedValue(
                            priceOrderList.optionType,
                            priceOrderList.optionTotalPrice,
                          )}
                        </span>
                      </div>
                    );
                  })}
                </div>
                <div className="vehicle-common-section total-material bordered-bottom label-left">
                  <div className="vehicle-common-label">
                    Total materials price
                  </div>
                  <div className="vehicle-common-value">
                    <span className="price-value">
                      {formatCurrency(
                        calculatedPriceData?.perOrderOptionsPrice,
                      )}
                    </span>
                  </div>
                </div>
                <hr />
              </>
            )}

            <div className="vehicle-common-section-no-border subtotal-section label-left">
              <span>
                Subtotal unit price{' '}
                <label htmlFor="subTotal" className="subtotal-unit-price-info">
                  (Base+Options
                  {isAreq && `+AREQ+${GSA_PURCHASE_FEE} GSA purchasing fee`})
                </label>
              </span>
              <span data-testid="subtotal-unit-price" className="price-value">
                {formatCurrency(
                  areqContractCostBreakdown
                    ? areqContractCostBreakdown.baseAndOptionsPrice +
                        (selectedVendorQuote?.areqPriceWithFee || 0)
                    : calculatedPriceData?.baseAndOptionsPrice +
                        (selectedVendorQuote?.areqPriceWithFee || 0),
                )}
              </span>
            </div>

            <div className="vehicle-common-section-no-border label-left">
              <span className="vehicle-common-label">Quantity of vehicles</span>
              <span
                data-testid="quantity-of-vehicle"
                className="price-value vehicle-common-value"
              >
                {areqContractCostBreakdown?.quantity ||
                  calculatedPriceData?.quantity ||
                  1}
              </span>
            </div>
            <div className="total-price">
              <span className="total-price-label">
                Total price{' '}
                <label htmlFor="totalPrice" className="total-price-label-info">
                  [Base+Options{isAreq && '+AREQ '}+ {GSA_PURCHASE_FEE} GSA
                  purchasing fee] x [Quantity]
                </label>
              </span>
              <span data-testid="total-price" className="price-value">
                {formatCurrency(getTotalPrice())}
              </span>
            </div>
          </>
        )}
      </div>
    </>
  );
};

CostSummary.propTypes = {
  calculatedPriceData: PropTypes.instanceOf(Object).isRequired,
  requisition: PropTypes.instanceOf(Object).isRequired,
  isAreq: PropTypes.bool,
};

CostSummary.defaultProps = {
  isAreq: false,
};

export default CostSummary;
