import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useQueryParam } from '../../../hooks/useQueryParam';
import './PaintAndGraphics.scss';
import { STEPS } from '../../../pages/VehicleRequisition/constants/VehicleRequisitionConstants';
import SectionEditButton from '../SectionEditButton/SectionEditButton';
import AttachmentDownload from '../../../pages/ReviewDetails/Attachment/AttachmentDownload/AttachmentDownload';
import { GET_REQUISITION_ATTACHMENTS } from '../../../services/data-layer';
import { displayUnitPrice } from './paintAndGraphicsUtils';
import { isAreqType } from '../../../pages/non-standard-purchase/constants';

let totalFiles = [];
const PaintAndGraphics = ({
  vehicleColors = [],
  paintAndGraphicsOptions,
  requisition,
  isEditable,
  requisitionAttachmentTypes,
}) => {
  const isAreq = isAreqType(requisition?.requisitionType);

  const query = useQueryParam();
  const requisitionId = query.get('requisitionId');

  const { data } = useQuery(GET_REQUISITION_ATTACHMENTS, {
    skip: !requisitionId,
    variables: { requisitionId },
  });

  const foundAttachmentType = requisitionAttachmentTypes?.find(
    (a) => a.attachmentType === 'req_color_options',
  );
  useEffect(() => {
    if (data?.getAllRequisitionAttachments.length > 0) {
      totalFiles = data?.getAllRequisitionAttachments
        .filter(
          (f) =>
            f.attachmentTypeId ===
            foundAttachmentType?.requisitionAttachmentTypeId,
        )
        .sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
    }
  }, [data]);

  const loadColorsFormat = (requisitionInfo) => {
    let colors = [];
    if (vehicleColors.length > 0) {
      colors = vehicleColors;
    } else {
      requisitionInfo?.vehicles?.forEach((c) => {
        colors.push({
          color: {
            label: c.makeColorName,
            value: c.makeColorCode,
          },
          quantity: requisition?.quantity,
        });
      });
    }

    return colors;
  };

  const colors = loadColorsFormat(requisition);

  const renderOptionsWithAttachments = (options, allFiles) => {
    if (!options) {
      return [];
    }

    return options?.map((item) => (
      <React.Fragment key={item.optionCode}>
        <div className="options">
          <span>
            {item.optionCode} {' - '} {item.optionDescription}{' '}
            {!!item.optionQuantity && <>&#215; {item.optionQuantity}</>}
          </span>
          <span>{displayUnitPrice(item)}</span>
        </div>
        {item?.customerCPLInput && (
          <div className="customer-input-section">
            <strong>Exact custom lettering to be displayed:</strong>{' '}
            {item?.customerCPLInput}
          </div>
        )}
        <div className="customer-input-section">{item.customerInput}</div>

        {allFiles.length > 0 && (
          <div>
            {allFiles?.map((list) => {
              return (
                list?.equipmentCode === item?.optionCode && (
                  <div className="attachment-section">
                    <AttachmentDownload
                      name={list?.name}
                      metadataId={list?.metadataId}
                    />
                    <div className="customer-input-section">
                      {list.description}
                    </div>
                  </div>
                )
              );
            })}
          </div>
        )}
      </React.Fragment>
    ));
  };

  return (
    <>
      <div role="tab" tabIndex="0" className="paint-and-graphics">
        <div className="paint-options-section">
          <div className="paint-options-title-section title-section">
            <span className="selected-options-header">Paint and graphics</span>
            {isEditable && !isAreq && (
              <SectionEditButton
                showButton
                id="required-options-edit"
                step={STEPS.PAINT_GRAPHICS}
              />
            )}
          </div>

          {colors?.length ? (
            <div className="selected-colors-body-section ">
              <div className="paint-colors-header-section">
                Selected standard color
              </div>
              {colors.map((selectedColor) => (
                <div
                  key={`review-selected-colors-${selectedColor.color?.label}`}
                  className="selected-colors-body-section-row"
                >
                  <span>
                    {selectedColor.color?.label ??
                      selectedColor.color?.label ??
                      ''}{' '}
                    {!paintAndGraphicsOptions?.length && !isAreq
                      ? `x  ${selectedColor.quantity}`
                      : ''}
                  </span>
                </div>
              ))}
            </div>
          ) : null}

          {Boolean(paintAndGraphicsOptions?.length) && (
            <div className="paint-details-section">
              <div className="paint-colors-header-section border-bottom">
                Selected paint and graphic options
              </div>
              {renderOptionsWithAttachments(
                paintAndGraphicsOptions,
                totalFiles,
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

PaintAndGraphics.propTypes = {
  vehicleColors: PropTypes.instanceOf(Array).isRequired,
  paintAndGraphicsOptions: PropTypes.instanceOf(Array),
  requisition: PropTypes.instanceOf(Object),
  isEditable: PropTypes.bool,
  requisitionAttachmentTypes: PropTypes.instanceOf(Object).isRequired,
};

PaintAndGraphics.defaultProps = {
  isEditable: false,
  paintAndGraphicsOptions: null,
  requisition: null,
};

export default PaintAndGraphics;
