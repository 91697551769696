import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@gsa/afp-component-library';
import VehicleRequisitionContext from '../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';

const SectionEditButton = ({
  showButton,
  id,
  step,
  variant,
  onClickOverride,
}) => {
  const { dispatch } = useContext(VehicleRequisitionContext);

  const editPage = () => {
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_CURRENT_REQUISITION_STEP,
      payload: {
        key: step,
      },
    });
  };

  const onClick = () => {
    if (onClickOverride) {
      return onClickOverride();
    }

    return editPage();
  };

  return (
    <>
      {showButton && (
        <Button
          variant={variant}
          onClick={onClick}
          data-testid={id}
          leftIcon={{ name: 'edit' }}
          label="Edit"
          size="small"
        />
      )}
    </>
  );
};

export default SectionEditButton;

SectionEditButton.propTypes = {
  showButton: PropTypes.bool,
  id: PropTypes.string,
  step: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'base',
    'outline',
    'inverse',
    'unstyled',
  ]),
  onClickOverride: PropTypes.func,
};

SectionEditButton.defaultProps = {
  showButton: false,
  variant: 'outline',
  id: '',
  onClickOverride: undefined,
};
