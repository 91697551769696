let validatedCode = '';
let validatedCodeResponse = null;

export default async function validateFSS19({
  getBoacForSignalCodeCOrL,
  fundCode,
  isDoD,
  serviceCode,
  agencyCode,
  force,
}) {
  if (!fundCode || fundCode.length < 1) return undefined;
  if (isDoD) {
    if (!serviceCode) return undefined;
  } else if (!agencyCode || agencyCode.length < 1) return undefined;

  const code = `${fundCode}${serviceCode}${agencyCode}`;

  if (validatedCode !== code || force) {
    const { data } = await getBoacForSignalCodeCOrL({
      variables: {
        boacRequest: {
          fundCode,
          isDoD,
          serviceCode,
          agencyCode,
        },
      },
    });

    validatedCode = code;
    validatedCodeResponse = data?.getBOACForSignalCodeCorL;
  }

  return validatedCodeResponse;
}
