import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, useModal } from '@gsa/afp-component-library';
import SimpleBar from 'simplebar';

import VehicleOverviewSection from './VehicleOverviewSection';
import VehicleOtherFeatureSection from './VehicleOtherFeatureSection';
import VehicleEngFuelSection from './VehicleEngFuelSection';
import AdditionalInfoModal from './AdditionalInfoModal';

import 'simplebar/dist/simplebar.css';
import './VehicleInformationSection.scss';

const vehicleInformationItems = ({
  vehicleOtherFeaturesData,
  vehicleOverviewData,
  vehicleEngFuelData,
  setAdditionalInfo,
  openModal,
  lowestBidData,
}) => {
  const vehicleInformationItemFragmentKey = vehicleOverviewData.label;
  return [
    {
      title: 'Vehicle Information',
      content: (
        <React.Fragment key={vehicleInformationItemFragmentKey}>
          <VehicleOverviewSection
            vehicleOverviewData={vehicleOverviewData}
            setAdditionalInfo={setAdditionalInfo}
            openModal={openModal}
            lowestBidData={lowestBidData}
          />
          <VehicleEngFuelSection
            vehicleEngFuelData={vehicleEngFuelData}
            lowestBidData={lowestBidData}
          />
          <VehicleOtherFeatureSection
            vehicleOtherFeaturesData={vehicleOtherFeaturesData}
            lowestBidData={lowestBidData}
          />
        </React.Fragment>
      ),
      expanded: false,
    },
  ];
};

export const VehicleInformationAccordion = ({
  vehicleInformationData,
  lowestBidData,
}) => {
  const { vehicleOverviewData, vehicleOtherFeaturesData, vehicleEngFuelData } =
    vehicleInformationData;
  const { isOpen, openModal, closeModal } = useModal();
  const [additionalInfo, setAdditionalInfo] = useState('');

  const vehicleInformationScroll = (vehicleInformation) => {
    return new SimpleBar(vehicleInformation);
  };

  useEffect(() => {
    const vehicleInformation = document.querySelectorAll(
      '.vehicle-information-accordion > div',
    )[0];
    if (vehicleInformation) {
      vehicleInformationScroll(vehicleInformation);
    }
  }, []);
  return (
    <>
      <AdditionalInfoModal
        closeModal={closeModal}
        isOpen={isOpen}
        comment={additionalInfo}
      />
      <Accordion
        multiselectable={false}
        bordered="true"
        items={vehicleInformationItems({
          vehicleOtherFeaturesData,
          vehicleOverviewData,
          vehicleEngFuelData,
          setAdditionalInfo,
          openModal,
          lowestBidData,
        })}
        className="vehicle-information-accordion"
      />
    </>
  );
};

VehicleInformationAccordion.propTypes = {
  vehicleInformationData: PropTypes.instanceOf(Object).isRequired,
  lowestBidData: PropTypes.instanceOf(Object),
};

VehicleInformationAccordion.defaultProps = {
  lowestBidData: {},
};

export default VehicleInformationAccordion;
