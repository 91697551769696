import React from 'react';
import { FilterPanel } from '@gsa/afp-component-library';
import {
  FormatOrderModStatusName,
  OrderModStatus,
} from './OrderModFilterHelpers';
import { useOrderModFilter } from './OrderModFilterProvider';
import { USDateStrToUTC } from '../../../utilities/commonUtils';
import useOrderModsState from '../useOrderModsState';

const OrderModFilters = () => {
  const { setFilters, typeaheadData, getOrderModsByPartialName } =
    useOrderModFilter();
  const {
    orderBy,
    orderModDirection,
    getOrderMods,
    setSearchFilters,
    setPaginationState,
  } = useOrderModsState();
  const handleTypeaheadSearch = ({ variables }) => {
    const { conditions } = variables.filters[0];
    const query = conditions.filter((c) => c.key === variables.field)[0]?.value;
    if (variables.field === 'orderModName') {
      getOrderModsByPartialName(query);
    }
  };
  const initialOrderModFilterStructure = [
    {
      title: 'Modification name',
      ariaLabel: 'Search by Order Mod Name',
      id: 'filter-order-mod-name',
      key: 'orderModName',
      type: 'text',
      permanent: false,
      operator: '$startsWith',
      hideClear: false,
      position: 'top',
      label: 'Search modification name',
      showSearchButton: true,
      minSearchLength: 6,
    },
    {
      title: 'Status',
      key: 'orderModStatus',
      id: 'filter-order-mod-status',
      type: 'multiselect',
      value: [],
      options: Object.keys(OrderModStatus).map((status) => ({
        value: status,
        label: FormatOrderModStatusName(status),
      })),
      operator: '$in',
      hideClear: true,
    },
    {
      title: 'Date placed',
      key: 'createdAt',
      type: 'daterange',
      operator: 'BETWEEN',
      options: {
        startDate: {
          key: 'beginning',
          label: 'Start Date',
          hint: 'mm/dd/yyy',
        },
        endDate: {
          key: 'end',
          label: 'End Date',
          hint: 'mm/dd/yyy',
        },
      },
      value: {
        beginning: '',
        end: '',
      },
      hideClear: true,
    },
  ];

  const handleFilters = (filters) => {
    const filterToApply = {
      filters: { operator: 'AND', value: [] },
      order: [[orderBy, orderModDirection]],
      offset: 0,
      limit: 10,
    };
    Object.keys(filters).forEach((key) => {
      const valueObj = filters[key];
      const selectedKey = valueObj?.filter?.key;

      if (valueObj) {
        if (selectedKey === 'createdAt') {
          const startDate = USDateStrToUTC(valueObj?.value?.beginning);
          const endDate = USDateStrToUTC(valueObj?.value?.end, true);
          if (startDate && endDate) {
            filterToApply.filters.value.push({
              operator: 'AND',
              value: [
                { key: 'createdAt', operator: 'GTE', value: startDate },
                { key: 'createdAt', operator: 'LTE', value: endDate },
              ],
            });
          }
        } else if (selectedKey === 'orderModStatus') {
          filterToApply.filters.value.push({
            operator: 'IN',
            key: selectedKey,
            value: valueObj?.filter?.value,
          });
        } else if (selectedKey === 'orderModName') {
          filterToApply.filters.value.push({
            operator: 'LIKE',
            key: selectedKey,
            value: valueObj?.filter?.value,
          });
        }
        console.log({ filterToApply });
      }
    });
    setFilters({ ...filterToApply });
    setSearchFilters({ ...filterToApply });
    getOrderMods({ variables: { query: { ...filterToApply } } });
    setPaginationState({
      limit: 10,
      offset: 0,
      currentPage: 1,
      isReset: false,
    });
  };
  return (
    <FilterPanel.FilterPanel
      filterStructure={initialOrderModFilterStructure}
      setQueryFiltersState={() => {}}
      clearButtonLabel="Reset All"
      showClearIcon
      fetchTypeaheads={handleTypeaheadSearch}
      typeaheadData={typeaheadData}
      updateAppliedFilters={(filters) => {
        handleFilters(filters);
      }}
    />
  );
};

export default OrderModFilters;
