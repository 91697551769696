import React, { useContext } from 'react';
import { Button, Modal } from '@gsa/afp-component-library';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import VehicleRequisitionContext from '../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import { useQueryParam } from '../../../hooks/useQueryParam';
import { RETURN_REQUISITION } from '../../../services/data-layer';

export const ValidationModal = ({ requisitionData }) => {
  const { state, dispatch } = useContext(VehicleRequisitionContext);
  const query = useQueryParam();
  const requisitionId = query.get('requisitionId');
  const { requisitionValidationState, isValidationErrorExist } = state;
  const history = useHistory();
  const turnOffLoading = () => {
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_SUBMIT_REQUISITION_LOADING,
      payload: false,
    });
  };
  const onClickCancel = () => {
    turnOffLoading();
    history.push('my-requisitions');
  };
  const [submitReqToContracting] = useMutation(RETURN_REQUISITION, {
    onError: () => {
      onClickCancel();
    },
    onCompleted: () => {
      history.push(
        `/my-requisitions?requisitionId=${requisitionId}&reqName=${requisitionData.requisitionName}&returned=true`,
      );
    },
  });

  const backButtonLabel = requisitionId
    ? 'Return to my requisitions'
    : 'Cancel this requisition';
  const historyToPush = 'my-requisitions';
  const setValidationsModal = (isOpen) => {
    return dispatch({
      type: VehicleRequisitionContextActions.SET_VALIDATIONS_MODAL_OPEN,
      payload: isOpen,
    });
  };

  const isSINexpired =
    requisitionValidationState?.systemAvailability?.isSINexpired || false;

  const onClickReview = async () => {
    setValidationsModal(false);

    await submitReqToContracting({
      variables: {
        requisitionId,
        submitComment: 'Validation Error',
      },
    });
  };

  const systemChangeModalData = {
    modalTitle: "We're sorry, there have been changes to your selected vehicle",
    modalSubTitle: `Please review your previous selections to ensure these changes are acceptable. 
           You may select new options from the Compare and Select page to continue, or return
           to Requisitions and Orders to cancel this requisition.`,
  };

  const actions = !isSINexpired ? (
    <>
      <Button
        type="button"
        data-testid="modal-cancel-button"
        variant="outline"
        onClick={() => {
          setValidationsModal(false);
          turnOffLoading();
          history.push(historyToPush);
        }}
        label={backButtonLabel}
      />

      <Button
        type="button"
        data-testid="modal-edit-button"
        onClick={() => {
          onClickReview();
          turnOffLoading();
        }}
        label="Return requisition"
      />
    </>
  ) : (
    <Button type="button" onClick={onClickCancel} label="Cancel requisition" />
  );
  return (
    <>
      {isValidationErrorExist ? (
        <div
          className="afp-modal-overlay modalContainer"
          data-testid="sys-change-modal"
        >
          <Modal
            className="system-change-found-modal"
            onClose={() => {
              setValidationsModal(false);
            }}
            actions={actions}
          >
            {systemChangeModalData && (
              <div className="modal-body">
                <div className="title">{systemChangeModalData.modalTitle}</div>

                {!isSINexpired && (
                  <div className="box">
                    {isSINexpired && (
                      <div className="warning-message">
                        <span>Selected make/model is now unavailable</span>
                      </div>
                    )}

                    {requisitionValidationState &&
                      requisitionValidationState.validationErrors &&
                      requisitionValidationState.validationErrors.map(
                        (item) => (
                          <div className="warning-message">
                            <span>{item}</span>
                          </div>
                        ),
                      )}
                  </div>
                )}

                <div className="sub-title">
                  {systemChangeModalData.modalSubTitle}
                </div>
              </div>
            )}
          </Modal>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

ValidationModal.propTypes = {
  requisitionData: PropTypes.instanceOf(Object).isRequired,
};
