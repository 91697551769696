import React, { useMemo, useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormGenerator } from '@gsa/afp-shared-form-utils';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { useQuery } from '@apollo/client';
import { Spinner, useModal } from '@gsa/afp-component-library';
import { generateAgencyInformationFormContent } from './agency-information-form';
import TelephoneControl from '../../../../components/FormComponents/telephone-input';
import { useQueryParam } from '../../../../hooks/useQueryParam';
import {
  GET_CATALOGS_FOR_CATEGORY,
  VALIDATE_BOAC,
} from '../../../../services/data-layer';
import { agencyInformationSchema } from './agency-information-schema';
import VehicleRequisitionContext from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import './agency-information.scss';
import {
  getCurrentFiscalYear,
  isDodUser,
} from '../../utils/VehicleRequisitionUtils';
import { handleAgencyInformationSubmit } from './agency-information-submit';
import { handleAgencyInformationChange } from './agency-information-change';
import { emDashUnicode } from '../../../../constants/constants';
import AgencyModalForm from './AgencyChangeModal';
import { useSystemAlert } from '../../../../services/system-alert';

export function validateValue() {
  return true;
}

export default function AgencyInformation({
  handlePageNavigation,
  reqType = 'STANDARD',
}) {
  const { clearSystemAlert } = useSystemAlert();
  const formGeneratorRef = useRef();
  // get all data required for the page
  const query = useQueryParam();
  const { state, dispatch, updateDraftRequisition } = useContext(
    VehicleRequisitionContext,
  );
  const { draftRequisition, selectedContractAgencyInformation } = state;
  const requisitionDraftId = query.get('requisitionId');
  const agency = sessionStorage.getItem('orderingAgency');
  const bureau = sessionStorage.getItem('orderingBureau');
  const office = sessionStorage.getItem('orderingOffice');
  const agencyCode = sessionStorage.getItem('agencyCode');
  const bureauCode = sessionStorage.getItem('bureauCode');

  const receivingAgency = sessionStorage.getItem('receivingAgency');
  const receivingBureau = sessionStorage.getItem('receivingBureau');
  const receivingOffice = sessionStorage.getItem('receivingOffice');
  const { data: countryCodeData, loading: loadingCountryCodes } = useQuery(
    GET_CATALOGS_FOR_CATEGORY,
    { variables: { category: 'CountryCallingCode' } },
  );

  useEffect(() => {
    clearSystemAlert();
  }, []);

  const signalCode = selectedContractAgencyInformation?.signalCode || '-1';
  let boac = selectedContractAgencyInformation?.requisitionBOAC;
  if (['A', 'J'].includes(signalCode)) {
    boac = selectedContractAgencyInformation?.requisitionBOAC;
  } else {
    boac = selectedContractAgencyInformation?.signalSupplementaryAddress;
  }

  const { data: validatedBoac, loading: boacLoading } = useQuery(
    VALIDATE_BOAC,
    {
      skip: !boac,
      variables: {
        boac,
        agencyCode,
        bureauCode,
        fiscalYear: getCurrentFiscalYear(),
        salesGroup: 'TM12',
      },
    },
  );

  const {
    currentUser: { id: currentUserId },
  } = useCurrentUser();

  let currentUserCreatedRequisition = false;
  if (draftRequisition && draftRequisition?.createdByInfo) {
    currentUserCreatedRequisition =
      currentUserId === draftRequisition?.createdByInfo?.id;
  } else {
    currentUserCreatedRequisition = true;
  }

  const { isOpen, openModal, closeModal } = useModal();

  const costBreakdown = state.selectedContractCostBreakdown;

  const isDoD = useMemo(
    () => isDodUser(state?.draftRequisition?.agencyInfo?.isDOD),
    [state?.draftRequisition?.agencyInfo?.isDOD],
  );

  // get form cotent and default values
  const [content, defaultValues] = generateAgencyInformationFormContent({
    handlePageNavigation,
    isAreq: state.isAreq,
    reqType,
    isDoD,
    treasuryAccountSymbol: validatedBoac?.validateBOAC?.tas || emDashUnicode,
    costBreakdown,
    agencyInformation: {
      ...selectedContractAgencyInformation,
      agency: selectedContractAgencyInformation?.agency || agency,
      bureau: selectedContractAgencyInformation?.bureau || bureau,
      office: selectedContractAgencyInformation?.office || office,
      receivingAgency,
      receivingBureau,
      receivingOffice,
    },
    countryCodes: (countryCodeData?.getCatalogsForCategory || [])
      .map(({ description, code }) => ({
        label: description,
        value: `${code}:${description}`,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
    openModal,
    currentUserCreatedRequisition,
  });

  const handleSubmit = handleAgencyInformationSubmit({
    dispatch,
    handlePageNavigation,
    selectedContractAgencyInformation,
    requisitionDraftId,
    updateDraftRequisition,
    costBreakdown,
    clearSystemAlert,
  });

  const handleOnChange = handleAgencyInformationChange({
    agencyCode,
    bureauCode,
    isDoD,
    formGeneratorRef,
    requisitionDraftId,
  });

  const handleBeforeSubmit = async (data) => {
    const onChangeErrors = await handleOnChange(formGeneratorRef.current, null);

    if (Object.keys(onChangeErrors).length > 0) {
      return { _preventSubmit: true, errors: onChangeErrors }; // prevent submit and keep error state
    }

    const temp = { ...data };
    if (data.signalCode === '-1') {
      temp.serviceCode = null;
      temp.fundCode = null;
      temp.supplementaryAddressCode = null;
    }

    if (!['B', 'C', 'K', 'L'].includes(data.signalCode)) {
      temp.supplementaryAddressCode = null;
    }

    if (!isDoD) temp.dod = null;
    if (!state.isAreq) temp.additionalAreqFunds = null;

    return temp;
  };

  if (loadingCountryCodes || boacLoading) return <Spinner size="large" />;

  return (
    <div className="agency-info-page">
      <AgencyModalForm isOpen={isOpen} onClose={closeModal} />

      <FormGenerator
        ref={formGeneratorRef}
        className="agency-form"
        schema={agencyInformationSchema}
        defaultValues={defaultValues}
        content={content}
        onBeforeSubmit={handleBeforeSubmit}
        onSubmit={handleSubmit}
        onError={console.log}
        onChange={[
          [
            'signalCode',
            'requisition.boac',
            'requisition.julian',
            'requisition.serialNumber',
            'unitPrice',
            'additionalAreqFunds',
            'serviceCode',
            'fundCode',
            'supplementaryAddressCode',
          ],
          handleOnChange,
        ]}
        controls={{
          phone: TelephoneControl,
        }}
      />
    </div>
  );
}

AgencyInformation.propTypes = {
  handlePageNavigation: PropTypes.func.isRequired,
  reqType: PropTypes.string,
};

AgencyInformation.defaultProps = {
  reqType: 'STANDARD',
};
