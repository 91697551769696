import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import CheckedIcon from '../../../assets/images/checked-icon';
import './COApproveButton.scss';
import { RequisitionStatus } from '../RequisitionDetailsUtils';
import useRequisitionReview from '../../../utilities/useRequisitionReview';
import {
  SUBMIT_AREQ_FOR_PENDING_CUSTOMER,
  CO_APPROVE,
  GET_REQUISITION_ACTIVITIES,
} from '../../../services/data-layer';

export const CO_APPROVED = 'contracting-office-approved';

const COApproveButton = ({
  reference,
  status,
  requisitionName,
  requisitionId,
  requisitionCartState,
}) => {
  const [createdByApprover, setCreatedByApprover] = useState(false);
  const { id: requisitionCreatorId } =
    requisitionCartState?.createdByInfo || {};
  const history = useHistory();

  const { isVendorDirect, vendorDirectLoaded } = useRequisitionReview();

  const [submitAreqForPendingCustomerMutation] = useMutation(
    SUBMIT_AREQ_FOR_PENDING_CUSTOMER,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [coApproveMutation] = useMutation(CO_APPROVE, {
    onError: (error) => {
      // TODO
      // not covered in user story yet
      // eslint-disable-next-line no-console
      console.log('CO Approved errored out: ', error?.graphQLErrors[0].message);
    },
    onCompleted: () => {
      history.push(
        `/my-requisitions?requisitionId=${requisitionId}&reqName=${requisitionName}&reqStatus=${CO_APPROVED}`,
      );
    },
  });

  const [getRequisitionActivitiesData] = useLazyQuery(
    GET_REQUISITION_ACTIVITIES,
  );

  useEffect(() => {
    (async () => {
      const activities = await getRequisitionActivitiesData({
        variables: {
          requisitionId,
          order: [['createdAt', 'DESC']],
        },
      });

      const submittedToEngineerActivity =
        activities?.data?.getRequisitionActivities?.rows?.find(
          ({ type: { name } }) =>
            name === 'REQUISITION_SUBMITTED_TO_ENGINEER_BY_APPROVER',
        );

      const approverId = submittedToEngineerActivity?.createdUserInfo?.id;
      setCreatedByApprover(approverId === requisitionCreatorId);
    })();
  }, []);

  const btnLabel = () => {
    // NOTE: Label updated as per user story
    // let label = 'Approve and send to engineer approval';
    let label = 'Submit to engineer';
    if (status === RequisitionStatus.CONTRACTING_APPROVAL) {
      label = `Approve and send to ${
        createdByApprover ? 'Approver' : 'Submitter'
      }`;
    } else if (
      status === RequisitionStatus.CONTRACTING_REVIEW &&
      !isVendorDirect &&
      vendorDirectLoaded
    ) {
      label = 'Approve and send to customer';
    }

    return label;
  };

  const handleButtonClick = async () => {
    if (status === RequisitionStatus.CONTRACTING_APPROVAL) {
      const variables = {
        requisitionId,
      };
      await submitAreqForPendingCustomerMutation({
        variables,
      });

      history.push(
        `/my-requisitions?requisitionId=${requisitionId}&reqName=${requisitionName}&reqStatus=${RequisitionStatus.PENDING_CUSTOMER_RESPONSE}`,
      );
    } else if (
      status === RequisitionStatus.CONTRACTING_REVIEW &&
      !isVendorDirect &&
      vendorDirectLoaded
    ) {
      const variables = {
        requisitionId,
      };
      await coApproveMutation({
        variables,
      });

      history.push(
        `/my-requisitions?requisitionId=${requisitionId}&reqName=${requisitionName}&reqStatus=${RequisitionStatus.PENDING_CUSTOMER_RESPONSE}`,
      );
    } else {
      reference.current.submitAreqMultipleVendors();
    }
  };

  return (
    <>
      <button
        data-testid="co-approve-button"
        type="button"
        className="co-approve-button"
        onClick={handleButtonClick}
      >
        <CheckedIcon />
        <span className="button-text">{btnLabel()}</span>
      </button>
    </>
  );
};

COApproveButton.propTypes = {
  reference: PropTypes.instanceOf(Object).isRequired,
  status: PropTypes.string.isRequired,
  requisitionId: PropTypes.string.isRequired,
  requisitionName: PropTypes.string.isRequired,
  requisitionCartState: PropTypes.instanceOf(Object).isRequired,
};

export default COApproveButton;
