import React, { useEffect, useState } from 'react';
import { Breadcrumbs as DSBreadcrumbs } from '@gsa/afp-component-library';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { Link } from 'react-router-dom';
import { canCreateRequisition } from '../../purchase/authorization';

const Breadcrumbs = () => {
  const [trail, setTrail] = useState([
    <a key="home" href={`${window.AFP_CONFIG.appURLs.home}/home`}>
      Home
    </a>,
  ]);
  const [hasPurchaseLink, setHasPurchaseLink] = useState(false);
  const ability = useAppAbility();

  useEffect(() => {
    if (canCreateRequisition(ability) && !hasPurchaseLink) {
      setTrail((prevTrail) => [
        ...prevTrail,
        <Link key="purchase" to="/purchase">
          Purchase
        </Link>,
      ]);
      setHasPurchaseLink(true);
    }
  }, [ability, hasPurchaseLink]);

  return <DSBreadcrumbs trail={trail} current="Requisitions and Orders" />;
};

export default Breadcrumbs;
