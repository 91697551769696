import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { useMemo } from 'react';
import { StoreOperations, StoreSubjects } from '../../../constants/constants';

export const linkButtons = [
  {
    title: 'Alternative Fuel Vehicle (AFV) Guides',
    link: 'https://www.gsa.gov/buy-through-us/products-and-services/transportation-and-logistics-services/fleet-management/fleet-electrification/alternative-fuel-vehicles',
  },
  {
    title: 'Contact our Vehicle Buying Team',
    link: 'https://www.fueleconomy.gov/',
  },
  {
    title: 'EPA Fuel Economy',
    link: 'https://www.epa.gov/regulations-emissions-vehicles-and-engines/federal-fleets-using-low-greenhouse-gas-emitting',
  },
  {
    title: 'EPA Green Vehicles',
    link: 'https://www.epa.gov/regulations-emissions-vehicles-and-engines/federal-fleets-using-low-greenhouse-gas-emitting',
  },
  {
    title: 'Federal Fleet Requirements Resource Center',
    link: 'https://www.energy.gov/femp/federal-fleet-requirements-resource-center',
  },
  {
    title: 'Federal Vehicle Standards',
    link: 'https://vehiclestd.fas.gsa.gov/CommentCollector/Home',
  },
  {
    title: 'NHTSA Defects and Recalls',
    link: 'https://www.nhtsa.gov/',
  },
  {
    title: 'NHTSA Five-Star Crash Test and Rollover Ratings',
    link: 'https://www.nhtsa.gov/campaign/safercargov?redirect-safercar-sitewide',
  },
  {
    title: 'View vehicle availability listings',
    link: '/vehicle-availability',
  },
];

const buttonList = [
  {
    title: 'Manage requisitions and orders',
    text: 'View, track, and manage your existing vehicle requisitions and orders. Provide and check order status, view reports, and acknowledge receipt of delivered vehicles.',
    link: (ability) => {
      // if they can view vendors they should go to the order vehicles page (but not for admins!)
      if (
        ability?.can(StoreOperations.Update, StoreSubjects.Vendor) &&
        !ability?.can(StoreOperations.Manage, StoreSubjects.All)
      )
        return '/order-vehicles';
      return '/my-requisitions';
    },
    id: 'order-btn',
    clickable: true,
    permissions: [
      [StoreOperations.View, StoreSubjects.Requisition],
      [StoreOperations.View, StoreSubjects.Order],
    ],
  },
  {
    title: 'Purchase vehicles',
    text: 'Create requisitions and place orders to buy vehicles for your agency. GSA Fleet offers a wide variety of vehicles, configurations, and options for purchase to meet a wide range of mission requirements.',
    link: '/purchase',
    id: 'purchase-btn',
    clickable: true,
    permissions: [[StoreOperations.Create, StoreSubjects.Requisition]],
  },
  {
    title: 'Lease vehicles (coming soon)',
    text: 'Create and manage requests for GSA Fleet leased vehicles. GSA Fleet leasing offers a wide variety of vehicles with economical and efficient fleet management services.',
    id: 'lease-btn',
    clickable: false,
    permissions: [[StoreOperations.View, StoreSubjects.Lease]],
  },
  {
    title: 'Short-term rentals',
    text: 'Create and manage requests for your vehicle and equipment short-term rental needs. GSA Fleet’s Short-Term Rental Program offers a wide variety of vehicles and equipment to support seasonal work, special events, surge requirements, and replacements during repairs and maintenance.',
    link: 'https://str.gsa.gov/',
    id: 'rental-btn',
    clickable: true,
    permissions: [],
  },
  {
    title: 'Just Browsing',
    text: 'View vehicles and options to see what is currently available without placing an order.',
    link: '/purchase?mode=browsing',
    id: 'browse-btn',
    clickable: true,
    permissions: [],
  },
];

export const getButtonListByPermission = () => {
  const ability = useAppAbility();
  const { currentUser } = useCurrentUser();

  const filteredButtons = useMemo(() => {
    if (!ability) return [];
    return buttonList.filter((button) => {
      // if no permissions are set, then we are just checking
      // to see if they have an account.
      if (button.permissions.length === 0) return true;
      return button.permissions.some((permission) => {
        return ability.can(permission[0], permission[1]);
      });
    });
  }, [ability, currentUser]);

  return filteredButtons;
};
