import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextInput,
  RequiredFieldIndicator,
  Icon,
  Checkbox,
  useModal,
  connectModal,
} from '@gsa/afp-component-library';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import AdditionalReqFilesTable from '../../../components/MultipleAdditionalRequirements/AdditionalReqFilesTable/AdditionalReqFilesTable';
import UploadNewFileModal from '../../../components/MultipleAdditionalRequirements/UploadNewFileModal/UploadNewFileModal';
import AttachmentDownload from '../../ReviewDetails/Attachment/AttachmentDownload/AttachmentDownload';
import './AdditionalVendorsSection.scss';

const AdditionalVendorsSection = ({
  draftId,
  index,
  indexChar,
  removeVendor,
  control,
  handleDelete,
}) => {
  const {
    formState: { errors },
  } = useFormContext();
  const uploadNewFileModal = useModal();
  const DisplayUploadFileModal = connectModal(UploadNewFileModal);
  const attachments = useWatch({
    name: `vendorQuoteDetails.${index}.attachments`,
  });
  const [showDocs, setShowDocs] = useState(Boolean(attachments?.length));

  const toggleShowDocs = () => {
    if (attachments?.length || !showDocs) setShowDocs(true);
    else setShowDocs(false);
  };

  const {
    fields: reqFiles,
    append,
    remove: reqRemove,
    update,
  } = useFieldArray({ name: `vendorQuoteDetails.${index}.attachments` });

  const { fields: quotesAttachment, append: quoteAppend } = useFieldArray({
    name: `vendorQuoteDetails.${index}.vendorQuote`,
  });

  const errorsList =
    errors?.vendorQuoteDetails?.length && errors?.vendorQuoteDetails[0];

  return (
    <section className="section-container vendor-quote-section">
      <div className="above-section-container">
        <div className="req-title">Vendor {indexChar}</div>
        {indexChar !== 'A' && (
          <div className="usa-button--unstyled delete-btn">
            <Icon className="usa-icon--size-2" iconName="close" />
            <Button
              data-testid="vendor-quote-delete-button"
              type="button"
              variant="unstyled"
              label="Delete vendor"
              onClick={() => {
                removeVendor(index);
              }}
            />
          </div>
        )}
      </div>
      <div className="form-section-container">
        <div className="form-section">
          <Controller
            rules={{ required: 'This is a required field' }}
            name={`vendorQuoteDetails.${index}.vendor`}
            render={({ field: { value, onChange, ref } }) => (
              <TextInput
                label={
                  <>
                    Vendor <RequiredFieldIndicator />
                  </>
                }
                onChange={onChange}
                value={value}
                inputRef={ref}
                name="vendor"
                errorMessage={
                  errors?.vendorQuoteDetails?.[index]?.vendor?.message
                }
              />
            )}
          />

          <Controller
            rules={{ required: 'This is a required field' }}
            name={`vendorQuoteDetails.${index}.makeModal`}
            render={({ field: { value, onChange, ref } }) => (
              <TextInput
                label={
                  <>
                    Make/Model <RequiredFieldIndicator />
                  </>
                }
                onChange={onChange}
                value={value}
                inputRef={ref}
                name="makeModal"
                errorMessage={
                  errors?.vendorQuoteDetails?.[index]?.makeModal?.message
                }
              />
            )}
          />

          <div className="quote-upload-section">
            <div
              className={
                errorsList?.vendorQuote?.root?.message &&
                'usa-form-group usa-form-group--error'
              }
            >
              <span className="usa-error-message">
                {errorsList?.vendorQuote?.root?.message}
              </span>
              <Controller
                rules={{ required: 'This is a required field' }}
                name={`vendorQuoteDetails.${index}.vendorQuote`}
                control={control}
                render={({ field: { value } }) => (
                  <div
                    className={`upload-vendor-quote ${
                      errorsList?.vendorQuote?.root?.message &&
                      'usa-input-error'
                    }`}
                  >
                    <div className="upload-section-title">
                      Upload vendor quote
                      <RequiredFieldIndicator />
                    </div>
                    <div className="upload-section-desc" />
                    <div className="uploaded_quote_section">
                      <div>{quotesAttachment[0]?.name}</div>
                      {quotesAttachment[0]?.name && (
                        <div className="quote-attach-button">
                          <AttachmentDownload
                            name="View"
                            metadataId={value[0]?.metadataId}
                          />
                          <Button
                            onClick={() => {
                              handleDelete(index);
                            }}
                            type="button"
                            variant="unstyled"
                            label="Delete"
                          />
                        </div>
                      )}
                    </div>

                    {!quotesAttachment[0]?.name && (
                      <Button
                        onClick={() => {
                          uploadNewFileModal.openModal();
                        }}
                        type="button"
                        variant="outline"
                        leftIcon={{ name: 'add', className: 'plus-icon' }}
                        label="Upload file"
                      />
                    )}
                  </div>
                )}
              />
            </div>
          </div>

          <div className="vendor-quote-description-textarea">
            <Controller
              name={`vendorQuoteDetails.${index}.description`}
              render={({ field: { value, onChange, ref } }) => (
                <TextInput
                  characterLimit={100}
                  type="textarea"
                  label={<>Contracting officer&apos;s comments</>}
                  onChange={onChange}
                  value={value}
                  inputRef={ref}
                  name={`vendorQuoteDetails.${index}.description`}
                  errorMessage={
                    errors?.vendorQuoteDetails?.[index]?.description?.message
                  }
                />
              )}
            />
          </div>

          <Checkbox
            className="supporting-docs-checkbox"
            name={`vendorQuoteDetails.${index}.supportingDocs`}
            checked={showDocs}
            onChange={toggleShowDocs}
            label="Supporting documents"
          />

          {showDocs && (
            <AdditionalReqFilesTable
              reqFiles={reqFiles}
              append={append}
              remove={reqRemove}
              update={update}
              additionalRequirementIndex={index}
              draftId={draftId}
              uploadSectionType="VENDOR_QUOTE"
            />
          )}

          <Controller
            rules={{
              required: 'This is a required field',
              validate: {
                positive: (v) =>
                  Number.parseInt(v, 10) > 0 ||
                  'Amount needs to be be greater than 0',
              },
            }}
            name={`vendorQuoteDetails.${index}.priceEstimate`}
            render={({ field: { value, onChange, ref } }) => (
              <TextInput
                type="number"
                label="Vendor's total price quote (with fees)"
                onChange={onChange}
                value={value}
                prefix="$"
                inputRef={ref}
                required
                name={`vendorQuoteDetails.${index}.priceEstimate`}
                errorMessage={
                  errors?.vendorQuoteDetails?.[index]?.priceEstimate?.message
                }
              />
            )}
          />
        </div>
      </div>

      <DisplayUploadFileModal
        isOpen={uploadNewFileModal.isOpen}
        handleClose={uploadNewFileModal.closeModal}
        append={quoteAppend}
        reqType="URGENT_REQ_VENDOR_QUOTES"
        draftId={draftId}
      />
    </section>
  );
};

AdditionalVendorsSection.propTypes = {
  draftId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  indexChar: PropTypes.string.isRequired,
  removeVendor: PropTypes.func.isRequired,
  control: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default AdditionalVendorsSection;
