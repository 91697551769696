import React, { useEffect, useMemo } from 'react';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { useQuery, useMutation } from '@apollo/client';
import { Alert, Spinner } from '@gsa/afp-component-library';
import OrderChanges from './change-sets/OrderChanges';
import AgencyContact from './change-sets/AgencyContact';
import Areq from './change-sets/Areq';
import OptionDetails from './change-sets/OptionDetails';
import PaintsAndGraphics from './change-sets/PaintsAndGraphics';
import DeliveryInformation from './change-sets/DeliveryInformation';
import VendorAcceptance from './change-sets/VendorAcceptance';
import ModifyOptions from './change-sets/ModifyOptions';
import useBulkOrderModsState from './useBulkOrderModsState';
import { StoreOperations, StoreSubjects } from '../../constants/constants';
import { ORDER_MOD_ATTACHMENT_TYPES } from '../order-modification-detail/components/OrderModDetailsHelper';
import {
  GET_ORDER_MOD_ATTACHMENTS,
  CREATE_ORDER_MOD_ATTACHMENT,
  DELETE_ORDER_MOD_ATTACHMENT,
} from '../../services/data-layer';
import { NON_SOP_REQ_TYPES } from '../non-standard-purchase/constants';
import ColorChanges from './change-sets/Colors';

export default function OrderModChangeSet() {
  const {
    selectedFilters,
    modificationId,
    currentChangeSet,
    setOrderChanges,
    orderModAttachmentTypes,
    selectedOrdersForMod,
    orderChanges,
    selectedOptions,
    changeSetError,
    optionsLoading,
    loadingModification,
    loadingChangeSet,
    loadingAttachmentTypes,
    optionsByContractData,
    isLoaded,
    minReqOptionsLoading,
  } = useBulkOrderModsState();

  const ability = useAppAbility();
  const canUpdateOrderOptions = useMemo(
    () =>
      ability?.can(
        StoreOperations.Update,
        StoreSubjects.OrderModificationOptions,
      ),
    [ability],
  );

  const [addAttachmentRecord] = useMutation(CREATE_ORDER_MOD_ATTACHMENT);
  const [deleteAttachmentRecord] = useMutation(DELETE_ORDER_MOD_ATTACHMENT);
  const {
    data,
    refetch,
    loading: loadingAttachments,
  } = useQuery(GET_ORDER_MOD_ATTACHMENTS, {
    variables: {
      orderModificationId: modificationId,
    },
  });

  const append = async (values) => {
    await addAttachmentRecord({
      variables: {
        input: {
          orderModificationId: modificationId,
          ...values,
          changeSetId: currentChangeSet?.orderChangeSetModId,
        },
      },
    });

    setOrderChanges((prevState) => {
      const newState = { ...prevState };
      if (!newState.attachments) newState.attachments = [];

      newState.attachments.push({
        orderModificationId: modificationId,
        ...values,
        changeSetId: currentChangeSet?.orderChangeSetModId,
      });

      return newState;
    });
    refetch();
  };
  const remove = async (attachmentId) => {
    await deleteAttachmentRecord({
      variables: {
        attachmentId,
      },
    });
    setOrderChanges((prevState) => {
      const newState = { ...prevState };
      if (!newState.attachments) newState.attachments = [];

      newState.attachments = newState.attachments.filter(
        (attachment) => attachment.id !== attachmentId,
      );

      return newState;
    });
    refetch();
  };

  const update = async ({ attachmentId, description }) => {
    setOrderChanges((prevState) => {
      const newState = { ...prevState };
      if (!newState.attachments) newState.attachments = [];

      newState.attachments = newState.attachments.map((attachment) => {
        if (attachment.orderModAttachmentId === attachmentId) {
          return { ...attachment, description };
        }
        return attachment;
      });
      return newState;
    });
    refetch();
  };
  useEffect(() => {
    setOrderChanges((prevState) => {
      const newState = { ...prevState };
      if (!newState.attachments) newState.attachments = [];

      newState.attachments = data?.getAllOrderModAttachments
        ?.filter(
          (attachment) =>
            attachment.changeSetId === currentChangeSet?.orderChangeSetModId,
        )
        .map((attachment) => {
          const attachmentType = orderModAttachmentTypes?.find(
            (aType) => aType?.type === attachment.attachmentType.type,
          );

          return { ...attachment, typeId: attachmentType?.id };
        });

      return newState;
    });
  }, [data?.getAllOrderModAttachments]);

  const changeSetAttachments = data?.getAllOrderModAttachments?.filter(
    (attachment) =>
      attachment.changeSetId === currentChangeSet?.orderChangeSetModId,
  );
  const sf30Attachments = [];
  const optionsDocAttachments = [];
  const vendorAcceptanceAttachments = [];
  const paintAndGraphicsAttachments = [];
  const areqAttachments = [];
  if (changeSetAttachments?.length > 0) {
    changeSetAttachments.forEach((attachment) => {
      // eslint-disable-next-line default-case
      switch (attachment.attachmentType.type) {
        case ORDER_MOD_ATTACHMENT_TYPES.SF30:
          sf30Attachments.push(attachment);
          break;
        case ORDER_MOD_ATTACHMENT_TYPES.OPTIONS_DOC:
          optionsDocAttachments.push(attachment);
          break;
        case ORDER_MOD_ATTACHMENT_TYPES.VENDOR_ACCEPTANCE:
          vendorAcceptanceAttachments.push(attachment);
          break;
        case ORDER_MOD_ATTACHMENT_TYPES.PAINT_AND_GRAPHICS:
          paintAndGraphicsAttachments.push(attachment);
          break;
        case ORDER_MOD_ATTACHMENT_TYPES.AREQ:
          areqAttachments.push(attachment);
          break;
      }
    });
  }

  const isAreq = useMemo(() => {
    return selectedOrdersForMod?.some((order) => order?.requisitionType === 2);
  }, [selectedOrdersForMod]);

  const hasNonSopOrder = useMemo(() => {
    return selectedOrdersForMod?.some((order) =>
      NON_SOP_REQ_TYPES.includes(order?.requisitionType),
    );
  }, [selectedOrdersForMod, NON_SOP_REQ_TYPES]);

  const getOptionDetailsFromVehicleEquipment = (equipment) => {
    if (!optionsByContractData?.getContractLineOptionsForMod) return null;
    return optionsByContractData.getContractLineOptionsForMod.find((option) => {
      return (
        option.standardItemEquipmentCode.equipmentCode.code ===
        equipment.equipmentCode
      );
    });
  };

  const hasInReq = useMemo(() => {
    const orderHasInReq = selectedOrdersForMod?.some((order) => {
      return order.orderVehicles.some((vehicle) => {
        return vehicle?.orderVehicleEquipments?.some((equipment) => {
          return (
            equipment.equipmentCode !== 'CPT' &&
            getOptionDetailsFromVehicleEquipment(
              equipment,
            )?.standardItemEquipmentCode?.equipmentCode?.tags?.value?.some(
              (tag) => tag.includes('IN_REQ'),
            )
          );
        });
      });
    });

    return (
      orderHasInReq ||
      orderChanges?.selectedOptions?.some(
        (option) => option.inReq && option?.optionCode !== 'CPT',
      ) ||
      selectedOptions?.some(
        (option) => option.inReq && option?.optionCode !== 'CPT',
      )
    );
  }, [
    selectedOrdersForMod,
    orderChanges?.selectedOptions,
    selectedOptions,
    optionsByContractData,
  ]);

  const hasCpt = useMemo(() => {
    const orderHasCpt = selectedOrdersForMod?.some((order) => {
      return order.orderVehicles.some((vehicle) => {
        return vehicle.orderVehicleEquipments.some(
          (equipment) => equipment.equipmentCode === 'CPT',
        );
      });
    });

    return (
      orderHasCpt ||
      orderChanges?.selectedOptions?.some(
        (option) => option.optionCode === 'CPT',
      ) ||
      selectedOptions?.some((option) => option.optionCode === 'CPT')
    );
  }, [orderChanges?.selectedOptions, selectedOptions, selectedOrdersForMod]);

  if (
    optionsLoading ||
    loadingModification ||
    loadingChangeSet ||
    loadingAttachments ||
    loadingAttachmentTypes ||
    minReqOptionsLoading ||
    !isLoaded
  ) {
    return <Spinner size="medium" className="margin-y-8" />;
  }

  return (
    <div className="update-order-mod">
      {changeSetError && (
        <Alert type="error" focused className="margin-bottom-2">
          {changeSetError}
        </Alert>
      )}
      <div className="accordion-group-custom">
        {canUpdateOrderOptions && (
          <>
            <OrderChanges />
          </>
        )}
        <ColorChanges />
        {canUpdateOrderOptions && !hasNonSopOrder && (
          <>
            <ModifyOptions
              selectedFilters={selectedFilters}
              paintAndGraphicsAttachments={paintAndGraphicsAttachments}
              removeAttachment={remove}
            />
          </>
        )}
        {hasCpt && !hasNonSopOrder && (
          <PaintsAndGraphics
            append={append}
            remove={remove}
            update={update}
            reqFiles={paintAndGraphicsAttachments}
          />
        )}
        {hasInReq && !hasNonSopOrder && (
          <OptionDetails
            append={append}
            remove={remove}
            update={update}
            reqFiles={optionsDocAttachments}
          />
        )}
        {isAreq && (
          <>
            <Areq
              append={append}
              update={update}
              remove={remove}
              reqFiles={areqAttachments}
            />
          </>
        )}
        {(isAreq || hasNonSopOrder) && (
          <>
            <VendorAcceptance
              reqFiles={vendorAcceptanceAttachments}
              append={append}
              update={update}
              remove={remove}
            />
          </>
        )}
        <DeliveryInformation />
        <AgencyContact />
      </div>
    </div>
  );
}
