import { atom, selector } from 'recoil';
import { getRequisitionFilters } from './filterHelpers';

const selectedRequisitionStatusAtom = atom({
  key: 'requisitionFilter/selectedRequisitionStatus',
  default: [],
});
const selectedRequisitionTypeAtom = atom({
  key: 'requisitionFilter/selectedRequisitionType',
  default: [],
});
const selectedRequisitionTransactionTypeAtom = atom({
  key: 'requisitionFilter/selectedRequisitionTransactionType',
  default: [],
});
const selectedBureauAtom = atom({
  key: 'requisitionFilter/selectedBureau',
  default: [],
});
const selectedVehicleTypesAtom = atom({
  key: 'requisitionFilter/selectedVehicleTypes',
  default: [],
});
const selectedAgenciesAtom = atom({
  key: 'requisitionFilter/selectedAgencies',
  default: [],
});
const selectedOfficesAtom = atom({
  key: 'requisitionFilter/selectedOffices',
  default: [],
});
const requisitionNumberAtom = atom({
  key: 'requisitionFilter/requisitionNumber',
  default: '',
});
const activityAddressAtom = atom({
  key: 'requisitionFilter/activityAddress',
  default: [],
});
const julianDateAtom = atom({
  key: 'requisitionFilter/julianDate',
  default: [],
});
const serialNumberAtom = atom({
  key: 'requisitionFilter/serialNumber',
  default: [],
});
const selectedAssigneeAtom = atom({
  key: 'requisitionFilter/selectedAssignee',
  default: [],
});
const vehicleListingTypeCodeAtom = atom({
  key: 'vehicleListing/typeCode',
  default: '',
});
const vehicleListingSubtypeCodeAtom = atom({
  key: 'vehicleListing/subtypeCode',
  default: '',
});
const filterSelector = selector({
  key: 'requisitionFilter/filterSelector',
  get: ({ get }) => {
    return getRequisitionFilters({
      requisitionStatuses: get(selectedRequisitionStatusAtom),
      requisitionTypes: get(selectedRequisitionTypeAtom),
      requisitionTransactionTypes: get(selectedRequisitionTransactionTypeAtom),
      bureaus: get(selectedBureauAtom),
      vehicleTypes: get(selectedVehicleTypesAtom),
      offices: get(selectedOfficesAtom),
      agencies: get(selectedAgenciesAtom),
      activityAddress: get(activityAddressAtom),
      julianDate: get(julianDateAtom),
      serialNumber: get(serialNumberAtom),
      assignees: get(selectedAssigneeAtom),
      requisitionNumber: get(requisitionNumberAtom),
    });
  },
});

const renameRequisitionShowMessageAtom = atom({
  key: 'requisitionFilter/renameRequisitionShowMessage',
  default: false,
});

export {
  selectedRequisitionStatusAtom,
  selectedRequisitionTypeAtom,
  selectedRequisitionTransactionTypeAtom,
  selectedVehicleTypesAtom,
  selectedOfficesAtom,
  selectedBureauAtom,
  selectedAgenciesAtom,
  activityAddressAtom,
  julianDateAtom,
  serialNumberAtom,
  filterSelector,
  renameRequisitionShowMessageAtom,
  selectedAssigneeAtom,
  vehicleListingTypeCodeAtom,
  vehicleListingSubtypeCodeAtom,
  requisitionNumberAtom,
};
