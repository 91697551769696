import React from 'react';
import { Breadcrumbs as DSBreadcrumbs } from '@gsa/afp-component-library';

import { Link } from 'react-router-dom';

const Breadcrumbs = () => (
  <DSBreadcrumbs
    trail={[
      <a key="home" href={`${window.AFP_CONFIG.appURLs.home}/home`}>
        Home
      </a>,
      <Link to="/purchase">Purchase</Link>,
      <Link to="/my-requisitions">Requisitions and Orders</Link>,
    ]}
    current="Clone Requisition"
  />
);
export default Breadcrumbs;
