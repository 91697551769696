import React from 'react';
import propTypes from 'prop-types';
import { Button } from '@gsa/afp-component-library';

export default function CreateOrderModButton({ onClick }) {
  return (
    <div>
      <Button
        label="Create a new modification"
        onClick={onClick}
        leftIcon={{
          name: 'add',
          type: 'button',
        }}
      />
    </div>
  );
}

CreateOrderModButton.defaultProps = {
  onClick: () => {},
};

CreateOrderModButton.propTypes = {
  onClick: propTypes.func,
};
