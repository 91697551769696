import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Button, Modal } from '@gsa/afp-component-library';
import { useMutation } from '@apollo/client';
import './CancelRequisitionModal.scss';

import { CANCEL_REQUISITION } from '../../pages/my-requisitions/queries';

import VehicleRequisitionContext from '../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';

const PATH_ON_SUCCESS = '/my-requisitions';

const CancelRequisitionModal = ({ requisitionId }) => {
  const history = useHistory();
  const { dispatch } = useContext(VehicleRequisitionContext);

  const [cancelRequisitionMutation] = useMutation(CANCEL_REQUISITION, {
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      history.push(PATH_ON_SUCCESS);
    },
  });

  const handleHideCancelModal = () => {
    dispatch({
      type: VehicleRequisitionContextActions.CANCEL_REQUISITION_MODAL,
      payload: false,
    });
  };

  const onCancel = async () => {
    handleHideCancelModal();

    // Delete requisition that hasn't been saved yet
    if (!requisitionId) {
      history.push(PATH_ON_SUCCESS);
      return;
    }
    // Otherwise - change its status to 'CANCELLED
    cancelRequisitionMutation({
      variables: {
        RequisitionId: requisitionId,
      },
    });
  };

  return (
    <div className="afp-modal-overlay modalContainer cancel-requisition-modal">
      <Modal
        title={
          <h1 className="modal-title">
            Are you sure you want to cancel this requisition?
          </h1>
        }
        onClose={() => {
          handleHideCancelModal();
        }}
        actions={
          <div>
            <Button
              type="button"
              variant="unstyled"
              data-testid="cancel-modal-no-button"
              onClick={handleHideCancelModal}
              label="No"
            />
            <Button
              className="modal-class-requisition-btn"
              type="button"
              variant="secondary"
              data-testid="cancel-modal-cancel-button"
              onClick={onCancel}
              label="Cancel requisition"
            />
          </div>
        }
      >
        <p>
          Once you click &quot;Cancel requisition&quot;,{' '}
          <strong>saved requisition drafts</strong> will be in a cancelled
          status that can be reopened up to 365 days before they are
          automatically deleted.
        </p>
        <p>
          Cancelling <strong>unsaved requisitions</strong> will delete them
          permanently. (If you wish for these requisitions to be retrievable,
          you must first &quot;save as draft&quot;.)
        </p>
      </Modal>
    </div>
  );
};

CancelRequisitionModal.propTypes = {
  requisitionId: PropTypes.string,
};

CancelRequisitionModal.defaultProps = {
  requisitionId: '',
};

export default CancelRequisitionModal;
