import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Button, Modal, TextInput } from '@gsa/afp-component-library';
import { RETURN_REQUISITION } from '../../services/data-layer';
import './PlaceOrderModal.scss';

const ReturnRequisitionModal = ({
  requisitionId,
  onClose,
  requisitionName,
}) => {
  const history = useHistory();
  const [comment, setComment] = useState('');
  const [submitReqToContracting] = useMutation(RETURN_REQUISITION, {
    onError: () => {
      onClose();
    },
    onCompleted: () => {
      history.push(
        `/my-requisitions?requisitionId=${requisitionId}&reqName=${requisitionName}&returned=true`,
      );
    },
  });

  const onSubmit = async () => {
    await submitReqToContracting({
      variables: {
        requisitionId,
        submitComment: comment,
      },
    });
  };

  return (
    <div className="afp-modal-overlay modalContainer non-sop-submit-modal">
      <Modal
        title={
          <h1 className="modal-title">
            Are you sure you want to return the following requisition to the
            customer?
          </h1>
        }
        className="save-draft-modal"
        onClose={onClose}
        actions={
          <div className="save-draft-requisition-button-row">
            <Button
              type="button"
              variant="unstyled"
              className="return-requisition-action-button"
              data-testid="modal-req-cancel-button"
              onClick={onClose}
              label="Cancel"
            />
            <Button
              type="button"
              variant="secondary"
              className="return-requisition-action-button"
              leftIcon={{ name: 'close' }}
              data-testid="modal-req-submit-button"
              onClick={() => onSubmit()}
              label="Return requisition"
              disabled={!comment?.length}
            />
          </div>
        }
      >
        <div className="return-title-desc">{requisitionName}</div>
        <div className="comment-section">
          <div className="return-reason">
            Provide reason for return <span className="alert">*</span>
          </div>
          <TextInput
            type="textarea"
            maxLength={500}
            data-testid="my-req-return-comment"
            onChange={(e) => setComment(e.target.value)}
            value={comment}
            name="justification"
            inputClass="req-input-text"
          />
          <span className="comments-info"> 500 characters allowed</span>
        </div>
      </Modal>
    </div>
  );
};

ReturnRequisitionModal.propTypes = {
  requisitionId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  requisitionName: PropTypes.string.isRequired,
};

export default ReturnRequisitionModal;
