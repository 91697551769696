import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import VehicleRequisitionContext from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContext';

const PriceButton = ({ calculatePriceClick }) => {
  const { state } = useContext(VehicleRequisitionContext);
  const { isCalculatePriceDisabled } = state;

  const handleCalculatePrice = () => {
    const { quantityHasError, formErrorFocus } = state;
    if (quantityHasError) {
      const { vehicleQuantity } = formErrorFocus;
      if (vehicleQuantity) {
        vehicleQuantity.current?.focus();
      }
      return;
    }
    if (!isCalculatePriceDisabled) {
      calculatePriceClick();
    }
  };

  return (
    <div className="price-block">
      <span className="button-desc">
        {`Add options and click "Calculate Price" to update the total selling
        price for each vehicle below.`}
      </span>
      <button
        type="button"
        data-testid="calculate-price-button"
        className={
          'price-button'
          /* ToDo: Confirm the color, make it active always for now
          ${ isCalculatePriceDisabled ? 'price-button-inactive' : '' }` */
        }
        onClick={handleCalculatePrice}
      >
        Calculate Price
      </button>
    </div>
  );
};

export default PriceButton;

PriceButton.propTypes = {
  calculatePriceClick: PropTypes.func.isRequired,
};
