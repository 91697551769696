import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import './VendorClarification.scss';
import { Modal, Button } from '@gsa/afp-component-library';

const VendorClarification = ({ contracts, lowestBidData }) => {
  const [isVendorClarificationsModalOpen, setVendorClarificationsModalOpen] =
    useState(false);
  const [vendorClarificationComments, setVendorClarificationComments] =
    useState([]);

  useEffect(() => {
    if (isVendorClarificationsModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isVendorClarificationsModalOpen]);

  const handleVendorClarificationModal = (contract) => {
    setVendorClarificationsModalOpen(true);
    // const modifiedContract = {...contract, contractLineClarifications: [...contract?.contractLineClarifications?.sort((a, b) => a.sequenceNumber - b.sequenceNumber)]};
    setVendorClarificationComments(contract);
  };

  const getSortedClarifications = (clarifications) => {
    if (clarifications) {
      const clonedClarifications = Object.assign([], clarifications);
      return [
        ...clonedClarifications.sort(
          (a, b) => a.sequenceNumber - b.sequenceNumber,
        ),
      ];
    }
    return [];
  };

  return (
    <div className="afp-store-vendor-clarifications">
      <table className="usa-table full-width-table" role="presentation">
        <tbody>
          <tr>
            <td
              data-testid="vendor-clarification"
              className="vendor-clarification"
            >
              Vendor Clarifications
            </td>
            <td className="vendor-clarification contract-cell standards-column">
              GSA Min Req.
            </td>
            {contracts.map((contract) => {
              const activateLowestBid =
                contract.modelName === lowestBidData.modelName &&
                contract.vendorName === lowestBidData.vendorName
                  ? 'lowest-bid'
                  : '';
              const checkDataExist =
                contract.contractLineClarifications &&
                contract.contractLineClarifications?.length !== 0;
              return (
                <React.Fragment
                  key={`${contract.vendorName}-${contract.modelName}-vendor-clarification`}
                >
                  <td
                    data-testid={`${contract.vendorName}-${contract.modelName}-vendor-clarification`}
                    className={`contract-cell ${activateLowestBid}`}
                  >
                    {checkDataExist ? (
                      <span
                        data-testid={`${contract.vendorName}-${contract.modelName}-vendor-clarification-handle`}
                        onClick={() => handleVendorClarificationModal(contract)}
                        onKeyDown={() => {
                          // do nothing
                        }}
                        aria-label={`${contract.vendorName}-${contract.modelName} View Clarifications link`}
                        role="button"
                        tabIndex="0"
                      >
                        <label
                          htmlFor="view-clarifications-link"
                          className="vehicle-clarification-label"
                        >
                          View Clarifications
                        </label>
                      </span>
                    ) : (
                      <span className="vehicle-clarification-empty">—</span>
                    )}
                  </td>
                </React.Fragment>
              );
            })}
          </tr>
        </tbody>
      </table>

      {isVendorClarificationsModalOpen && (
        <div
          role="dialog"
          aria-modal="true"
          className="afp-modal-overlay modalContainer vendor-clarification-modal"
        >
          <Modal
            title={
              <h1>
                {vendorClarificationComments?.modelName}: Vendor Clarifications
              </h1>
            }
            onClose={() => {
              setVendorClarificationsModalOpen(false);
            }}
            className="main-modal"
            actions={
              <>
                <Button
                  type="button"
                  onClick={() => {
                    setVendorClarificationsModalOpen(false);
                  }}
                  label="Close"
                />
              </>
            }
          >
            <div className="vendor-clarifications-list">
              <ul className="vendor-modal-list">
                {getSortedClarifications(
                  vendorClarificationComments.contractLineClarifications,
                )?.map(({ clarification }, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <li key={index} className="list-item">
                    {clarification}
                  </li>
                ))}
              </ul>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

VendorClarification.propTypes = {
  contracts: PropTypes.instanceOf(Array),
  lowestBidData: PropTypes.instanceOf(Object),
};

VendorClarification.defaultProps = {
  contracts: [],
  lowestBidData: {},
};

export default VendorClarification;
