import { STEPS } from '../constants/VehicleRequisitionConstants';

export const getStepTitle = (step) =>
  ({
    [STEPS.PAINT_GRAPHICS]: 'Create a Requisition-Paint and graphics',
    [STEPS.OPTION_DETAILS]: 'Create a Requisition',
    [STEPS.REVIEW_VEHICLE_BUILD]: 'Create a Requisition-Review vehicle build',
    [STEPS.NON_LOW_BID_REASON]: 'Create a Requisition',
    [STEPS.AGENCY_INFORMATION]: 'Create a Requisition-Agency information',
    [STEPS.DELIVERY_ADDRESS]: 'Create a Requisition-Delivery address',
    [STEPS.REVIEW_SUBMIT]: 'Create a Requisition-Review and submit',
  }[step] || '');

/**
 * Get the requisition transaction type based on the agency and bureau, This will be used to determine the transaction type for the requisition
 * till AMP is moved to the new system.
 * @param {string} agency - The agency code
 * @param {string} bureau - The bureau code
 * @returns {string} - The requisition transaction type
 */
export const getRequisitionTransactionType = (agency, bureau) => {
  let requisitionTransactionType = 'VEHICLE_SALE';
  if (agency === '047' && bureau === '09') {
    requisitionTransactionType = 'VEHICLE_LEASE';
  }
  return requisitionTransactionType;
};
