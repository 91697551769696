const generateFilterCondition = (key, value, operator) => {
  if (!key || !value || !value.length) {
    return [];
  }
  return [
    {
      operator: operator || '$in',
      key,
      value,
    },
  ];
};

const generateRequisitionTypeFilter = (values) => {
  return {
    operator: '$literal',
    key: 'requisitionType',
    value: `((requisition_type & ${values.join(') OR (requisition_type & ')}))`,
  };
};

export const getRequisitionFilters = ({
  vehicleTypes,
  requisitionStatuses,
  offices,
  bureaus,
  agencies,
  activityAddress,
  julianDate,
  serialNumber,
  requisitionNumber,
  requisitionTypes,
  assignees,
  requisitionTransactionTypes,
}) => {
  return [
    // Add condition for status
    ...generateFilterCondition('requisitionStatus', requisitionStatuses),
    // // Add condition for type
    ...(requisitionTypes.length
      ? [generateRequisitionTypeFilter(requisitionTypes)]
      : []),
    // Add condition for vehicleType
    ...generateFilterCondition(
      'vehicleTypeCode',
      vehicleTypes.map((type) => type.code),
    ),
    ...agencies,
    ...bureaus,
    ...offices,
    ...generateFilterCondition('activityAddressCode', activityAddress),
    ...generateFilterCondition('julianDate', julianDate),
    ...generateFilterCondition('serialNumber', serialNumber),
    ...generateFilterCondition('requisitionNumber', requisitionNumber, '$like'),
    ...generateFilterCondition('assignedEngineerId', assignees),
    ...generateFilterCondition('transactionType', requisitionTransactionTypes),
  ];
};

export const OrderByMap = {
  number: 'requisition_number',
  updatedAt: 'updatedAt',
  requisitionStatus: 'requisitionStatus',
  name: 'friendlyName',
  status: 'status',
};
