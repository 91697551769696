export const emDashUnicode = '\u2014';

export const StoreSubjects = {
  All: 'all',
  Requisition: 'Requisition',
  Order: 'Order',
  OrderVehicle: 'OrderVehicle',
  OrderLine: 'OrderLine',
  OrderActivityComment: 'OrderActivityComment',
  OrderActivityAttachment: 'OrderActivityAttachment',
  OrderActivityCustomerAttachment: 'OrderActivityCustomerAttachment',
  OrderActivityEvent: 'OrderActivityEvent',
  OrderActivityVendorAttachment: 'OrderActivityVendorAttachment',
  OrderActivityVendorComment: 'OrderActivityVendorComment',
  OrderStatusSubject: 'OrderStatusSubject',
  RequisitionActivityComment: 'RequisitionActivityComment',
  RequisitionActivityEvent: 'RequisitionActivityEvent',
  Solicitation: 'Solicitation',
  VehicleStatus: 'VehicleStatus',
  BidReport: 'BidReport',
  Vendor: 'Vendor',
  StandardItem: 'StandardItem',
  EquipmentCodes: 'EquipmentCodes',
  CatalogCodes: 'CatalogCodes',
  FederalVehicleStandards: 'FederalVehicleStandards',
  Requirements: 'Requirements',
  PSOQuestionnaire: 'PSOQuestionnaire',
  FVSComments: 'FVSComments',
  MakeModel: 'MakeModel',
  VendorAssociatedLocation: 'VendorAssociatedLocation',
  Rental: 'Rental',
  Lease: 'Lease',
  OrderModificationOptions: 'OrderModificationOptions',
};

export const StoreOperations = {
  View: 'view',
  Create: 'create',
  Update: 'update',
  Delete: 'delete',
  Cancel: 'cancel',
  Approve: 'approve',
  Manage: 'manage',
  EngineerReview: 'engineerReview',
  updateStatus: 'updateStatus',
  updateReceiptDate: 'updateReceiptDate',
  onBehalfOrder: 'onBehalfOrder',
};


export const IN_REQ_TAG = 'IN_REQ';
export const PAINT_OPT = 'PAINT_OPT';
export const ENGINEER_TAG = 'REVIEWREQ';

export const TagsToWatch = [ENGINEER_TAG];
export const VEHICLE_GROUP_TAG_STORE = 'STORE';
