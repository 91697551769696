import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import './VehicleListingsPage.scss';
import { Pagination, PageTitle } from '@gsa/afp-component-library';
import VehicleList from './VehicleList';
import Filters from './Filters';
import SearchBySIN from '../../components/SearchBySIN/SearchBySIN';
import Breadcrumbs from './widgets/Breadcrumbs';
import ContactBuyingPopover from '../../components/ContactBuyingPopover/ContactBuyingPopover';
import JustBrowsingAlert from '../../components/JustBrowsingAlert/JustBrowsingAlert';
import ShipmentInfoAlert from '../../components/ShipmentInfoAlert/ShipmentInfoAlert';
import { resetFocusToFirstElement } from '../../utilities/commons';
import VehicleFilterContext from './FilterContext';
import {
  GET_REQUISITION_SINS,
  GET_ACTIVE_VEHICLE_GROUP_TYPES,
  GET_STANDARD_ITEM_BY_ID,
} from '../../services/data-layer/standards';
import { GET_PROGRAM_NAME_BY_STD_CODE } from '../../services/data-layer';
import { getUrlVar } from '../../constants/utils';
import { VEHICLE_GROUP_TAG_STORE } from '../../constants/constants';

const emptyVehicleData = { rows: [], count: 0, hasMore: false };
const defaultVehicleType = '02';

const VehicleListingsComp = ({ location: { state } }) => {
  const location = useLocation();
  const history = useHistory();

  const [vehicleSubtypes, vehicleSubtypesTo] = useState([]);
  const [initialType, initialTypeTo] = useState('');
  const [selectedSubtype, selectedSubtypeTo] = useState('');
  const [pagination, paginationTo] = useState({
    currentPage: 1,
    itemsPerPage: +sessionStorage.getItem('vehicleTypeItemsPerPage') || 10,
  });
  const [isReset, setResetPagination] = useState(false);
  const [typeReset, typeResetTo] = useState(false);
  const [mode, modeTo] = useState('');
  const [domestic, domesticTo] = useState(true);
  const [vehicleData, vehicleDataTo] = useState(emptyVehicleData);
  const [selectedAgency, setSelectedAgency] = useState('');
  const [selectedBureau, setSelectedBureau] = useState('');
  const [selectedOffice, setSelectedOffice] = useState('');
  const [selectedState, setSelectedState] = useState('');

  useEffect(() => {
    let modeVal = getUrlVar('mode', location);
    if (!modeVal?.length) modeVal = sessionStorage.getItem('requisitionMode');
    else sessionStorage.setItem('requisitionMode', modeVal);
    modeTo(modeVal);

    let domesticVal = getUrlVar('domestic', location);
    if (!domesticVal?.length)
      domesticVal = sessionStorage.getItem('requisitionDomestic');
    else sessionStorage.setItem('requisitionDomestic', domesticVal);
    domesticTo((domesticVal || 'true').toLowerCase() === 'true');

    const typeCode = state?.data?.code || defaultVehicleType;
    initialTypeTo(typeCode);
    sessionStorage.setItem('selectedVehicleType', typeCode);
    const reqType = sessionStorage.getItem('requisitionType');
    if (reqType === 'on-behalf') {
      setSelectedAgency(sessionStorage.getItem('receivingAgency'));
      setSelectedBureau(sessionStorage.getItem('receivingBureau'));
      setSelectedOffice(sessionStorage.getItem('receivingOffice'));
    } else {
      setSelectedAgency(sessionStorage.getItem('orderingAgency'));
      setSelectedBureau(sessionStorage.getItem('orderingBureau'));
      setSelectedOffice(sessionStorage.getItem('orderingOffice'));
    }
    setSelectedState(sessionStorage.getItem('shipmentLocation'));
  }, []);

  const [getSins] = useLazyQuery(GET_REQUISITION_SINS, {
    fetchPolicy: 'c',
  });

  const [queryVehicleSubtypes, { data: vehicleSubtypesData }] = useLazyQuery(
    GET_ACTIVE_VEHICLE_GROUP_TYPES,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [getStandardItemData] = useLazyQuery(GET_STANDARD_ITEM_BY_ID, {
    fetchPolicy: 'c',
  });

  const [getProgramData] = useLazyQuery(GET_PROGRAM_NAME_BY_STD_CODE, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    onCompleted: (result) => {
      sessionStorage.setItem('program', result.getProgramName);
    },
  });

  const querySins = (vehicleTypeIds, page, itemsPerPage) => {
    const queryVariables = {
      vehicleTypeIds,
      order: [
        {
          sortBy: 'standardItemNumber',
          sortOrder: 'DESC',
        },
      ],
      offset:
        ((page || pagination.currentPage) - 1) *
        (itemsPerPage || pagination.itemsPerPage),
      limit: itemsPerPage || pagination.itemsPerPage,
    };
    getSins({
      variables: queryVariables,
      onCompleted: (sinData) => {
        if (sinData) {
          vehicleDataTo(sinData?.getRequisitionSins);
        }
      },
    });
  };

  useEffect(() => {
    if (vehicleSubtypesData?.getActiveVehicleGroupTypes?.length) {
      vehicleSubtypesTo(vehicleSubtypesData.getActiveVehicleGroupTypes);
      querySins(
        vehicleSubtypesData.getActiveVehicleGroupTypes.map((item) => item.code),
        1,
        pagination.itemsPerPage,
      );
    } else {
      vehicleDataTo(emptyVehicleData);
    }
    paginationTo({ ...pagination, currentPage: 1 });
    setResetPagination(true);
    setTimeout(() => {
      setResetPagination(false);
    }, 1000);
  }, [vehicleSubtypesData]);

  useEffect(() => {
    if (typeReset) {
      typeResetTo(false);
    }
  }, [typeReset]);

  const vehicleTypeSelect = async (value) => {
    sessionStorage.setItem('selectedVehicleType', value);
    selectedSubtypeTo('');
    queryVehicleSubtypes({
      variables: {
        groupId: value,
        tag: VEHICLE_GROUP_TAG_STORE,
      },
    });
  };

  const vehicleSubtypeSelect = async (value) => {
    if (value) {
      selectedSubtypeTo(value);
      querySins([value], 1, pagination.itemsPerPage);
    } else if (vehicleSubtypes.length) {
      selectedSubtypeTo('');
      querySins(
        vehicleSubtypes.map((item) => item.code),
        1,
        pagination.itemsPerPage,
      );
    } else {
      selectedSubtypeTo('');
      vehicleDataTo(emptyVehicleData);
    }
    paginationTo({ ...pagination, currentPage: 1 });
    setResetPagination(true);
    setTimeout(() => {
      setResetPagination(false);
    }, 1000);
  };

  const handlePaginationChange = (currentPage, itemsPerPage) => {
    sessionStorage.setItem('vehicleTypeItemsPerPage', itemsPerPage);
    resetFocusToFirstElement('vehicle-details');
    if (itemsPerPage !== pagination.itemsPerPage) {
      paginationTo({ currentPage: 1, itemsPerPage });
      setResetPagination(true);
      setTimeout(() => {
        setResetPagination(false);
      }, 1000);
    } else {
      paginationTo({ currentPage, itemsPerPage });
    }
    if (selectedSubtype) {
      querySins([selectedSubtype], currentPage, itemsPerPage);
    } else if (vehicleSubtypes.length) {
      querySins(
        vehicleSubtypes.map((item) => item.code),
        currentPage,
        itemsPerPage,
      );
    } else {
      vehicleDataTo(emptyVehicleData);
    }
  };

  const resetButton = () => {
    typeResetTo(true);
  };

  const onSelectItemBySIN = async (standardItem) => {
    if (standardItem?.id) {
      const stdData = await getStandardItemData({
        variables: {
          filter: {
            operator: 'EQ',
            key: 'id',
            value: standardItem.id,
          },
        },
      });
      if (stdData?.data?.getStandardItem) {
        sessionStorage.setItem(
          'standardItem',
          JSON.stringify(stdData.data.getStandardItem),
        );
      } else {
        sessionStorage.setItem('standardItem', JSON.stringify(standardItem));
      }
    }

    const pgData = await getProgramData({
      variables: { fedStdCode: standardItem.fedStandardCode },
    });
    sessionStorage.setItem('program', pgData.data.getProgramName);

    history.push(
      `/vehicle-requisition?sin=${standardItem.standardItemNumber}&mode=${mode}&domestic=${domestic}`,
    );
  };

  return (
    <VehicleFilterContext.Provider value={{ vehicleSubtypes, typeReset }}>
      <div className="main-container">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'baseline',
          }}
        >
          <Breadcrumbs />
          <ContactBuyingPopover />
        </div>
        {mode === 'browsing' && <JustBrowsingAlert needsLink />}
        {mode !== 'browsing' && (
          <ShipmentInfoAlert
            agency={selectedAgency}
            bureau={selectedBureau}
            office={selectedOffice}
            state={selectedState}
            needsLink
          />
        )}

        <div className="main-title">
          <PageTitle title="Vehicle Listings" />
          <p className="veh-listing-desc">
            For items currently not available, please email our Customer Care
            team at&nbsp;
            <a className="usa-link" href="mailto:vehicle.buying@gsa.gov">
              Customer Care
            </a>{' '}
            for assistance.
          </p>
        </div>

        <div data-testid="listing-container-test" className="listing-container">
          <Filters
            resetButton={resetButton}
            initVehicleType={initialType}
            vehicleTypeSelect={vehicleTypeSelect}
            vehicleSubtypeSelect={vehicleSubtypeSelect}
          />
          <div className="vehicles-container">
            <SearchBySIN onSelectItem={onSelectItemBySIN} />
            <VehicleList
              data={vehicleData.rows}
              onSelectItem={onSelectItemBySIN}
            />
            <div className="pagination">
              <Pagination
                buttonVariant="primary"
                boundaryCount={1}
                variant="advanced"
                itemsCount={vehicleData.count}
                {...pagination}
                siblingCount={1}
                isReset={isReset}
                onPageChange={(currentPage, itemsPerPage) => {
                  setTimeout(() => {
                    handlePaginationChange(currentPage, itemsPerPage);
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </VehicleFilterContext.Provider>
  );
};

VehicleListingsComp.propTypes = {
  location: PropTypes.instanceOf(Object),
};

VehicleListingsComp.defaultProps = {
  location: {},
};
export default VehicleListingsComp;
