import React, { useEffect, useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { Alert, Modal, Button, PageTitle } from '@gsa/afp-component-library';
import { useLazyQuery } from '@apollo/client';
import { useTitle } from '@gsa/afp-shared-ui-utils';
import {
  GET_MIN_REQ,
  GET_FEATURE_OPTIONS,
} from '../../../../services/data-layer';
import {
  getGroupedMinimumRequirementsData,
  getDisplayableMinimumRequirements,
  getAvailableMinimumRequirements,
  getDisplayableContracts,
  getDisplayableOptionalData,
  getGroupedVehicleInfoData,
  getAvailableVehicleInformation,
  getVehicleOverviewInformation,
  getDisplayableVehicleInfo,
  getDisplayableVehicleOverview,
  getDisplayableNetworkCostBreakDownData,
  getSortedActiveContractsData,
  getDisplayableVehicleEngFuel,
} from '../../../../utilities/CompareVehicleUtils';
import { MinimumRequirementsSection } from '../../../../components/MinimumRequirementsSection/MinimumRequirementsSection';
import { AddOptionsCard } from '../../../../components/AddOptions/AddOptions';
import { VehicleInformationAccordion } from '../../../../components/VehicleInformationSection/VehicleInformationSection';
import { HorizontalPagination } from '../../../../components/HorizontalPagination/HorizontalPagination';
import BasePrice from '../../../../components/BasePrice/BasePrice';
import './CompareVehicles.scss';
import TotalSellingPrice from '../../../../components/TotalSellingPrice/TotalSellingPrice';
import CloseoutDate from '../../../../components/CloseoutDates/CloseoutDate';
import VendorClarification from '../../../../components/VendorClarifications/VendorClarification';
import CostBreakdownSection from '../../../../components/CostBreakdownSection/CostBreakdownSection';
import VehiclesHeader from '../../../../components/VehiclesHeader/VehiclesHeader';
import SaveDraftRequisition from '../../../../components/SaveDraftRequisition/SaveDraftRequisition';
import SaveDraftRequisitionMessage from '../../../../components/SaveDraftRequistionSuccessMessage/SaveDraftRequisitionSuccessMessage';
import VehicleRequisitionContext from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import { useQueryParam } from '../../../../hooks/useQueryParam';
import JustBrowsingAlert from '../../../../components/JustBrowsingAlert/JustBrowsingAlert';
import ShipmentInfoAlert from '../../../../components/ShipmentInfoAlert/ShipmentInfoAlert';
import AreqButton from '../../../../components/Areq/AreqButton/AreqButton';
import { getUrlVar, withoutAREQOption } from '../../../../constants/utils';
import AreqUnbuildableBanner from '../../../../components/Areq/AreqUnbuildableBanner/AreqUnbuildableBanner';
import {
  filterBuildableContracts,
  filterUnbuildableContracts,
} from '../../../areqMultipleVendors/areqMultipleVendorUtil';
import { getRequisitionTransactionType } from '../../utils/RequisitionStepsUtils';

const { appURLs } = window.AFP_CONFIG;

const CompareVehicles = ({ notifyUserRef }) => {
  const [forceSaveDraft, setForceSaveDraft] = useState(false);
  useTitle('Create a Requisition-Compare and select');
  const history = useHistory();
  const query = useQueryParam();
  const isFirstPageLoad = useRef(true);
  const offset = 5;
  const { state, dispatch, updateDraftRequisition, getCalculatedPriceData } =
    useContext(VehicleRequisitionContext);

  const location = useLocation();
  const domestic = getUrlVar('domestic', location);

  const {
    compareVehiclesCurrentPage,
    allActiveContracts,
    displayingActiveContracts,
    allMinimumRequirements,
    displayingMinRequirements,
    allOptionalRequirements,
    displayingOptionalRequirements,
    allVehicleOverview,
    displayingVehicleOverview,
    allVehicleInformation,
    allCostBreakdown,
    displayingCostBreakdown,
    displayingOtherFeatures,
    draftRequisition,
    selectedOptionsForPrice,
    displayingEngineFuel,
    vehicleQuantity,
    currentStandardItem,
    selectedContract,
    isInactiveContract,
    sin,
    draftId,
    getPurchaseCollisionDataOnYes,
    getPurchaseCollisionInfo,
    isCalculatePriceDisabled,
    isCalculatePriceError,
    deliveryOptions,
    requiredOptionSessionState,
    calculatedPriceData,
    quantityHasError,
    isAreq,
  } = state;

  const currentSid = sessionStorage.getItem('standardItem')
    ? JSON.parse(sessionStorage.getItem('standardItem'))?.id
    : currentStandardItem.id;

  const [vehicleBodyIcon, setVehicleBodyIcon] = useState(null);
  const [showBidChangedAlert, setShowBidChangedAlert] = useState(false);
  const [changedBidVehicle, setChangedBidVehicle] = useState('');
  const [lowestBidData, setLowestBidData] = useState({});
  const mode = query.get('mode');
  const requisitionType = query.get('requisitionType');
  const selectedStateFrmSession = sessionStorage.getItem('shipmentLocation');
  const unSavedChangesModalRef = notifyUserRef;
  const [selectedAgency, setSelectedAgency] = useState('');
  const [selectedBureau, setSelectedBureau] = useState('');
  const [selectedOffice, setSelectedOffice] = useState('');
  const [selectedState, setSelectedState] = useState('');

  const setQuantityHasError = (value) => {
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_VEHICLE_QUANTITY_HAS_ERROR,
      payload: value,
    });
  };

  const [showDraftSuccessMessage, setShowDraftSuccessMessage] = useState(false);

  const [getMinReqData, { data: minReqData, loading: minReqLoading }] =
    useLazyQuery(GET_MIN_REQ, {
      fetchPolicy: 'c',
    });

  const [
    getFeatureOptionsData,
    { data: featureOptionsData, loading: featureOptionsDataLoading },
  ] = useLazyQuery(GET_FEATURE_OPTIONS, {
    fetchPolicy: 'c',
  });

  const initiateDraftDataSetup = () => {
    getMinReqData({ variables: { standardItemId: parseInt(currentSid, 10) } });
    getFeatureOptionsData({
      variables: { standardItemId: parseInt(currentSid, 10) },
    });
  };

  const initiateNonDraftDataSetup = () => {
    getMinReqData({ variables: { standardItemId: parseInt(currentSid, 10) } });
    getFeatureOptionsData({
      variables: { standardItemId: parseInt(currentSid, 10) },
    });
    getCalculatedPriceData({
      variables: {
        standardItemId: parseInt(currentSid, 10),
        quantity: Number(vehicleQuantity),
        selectedOptions: withoutAREQOption(selectedOptionsForPrice),
        transactionType: getRequisitionTransactionType(
          sessionStorage.getItem('agencyCode'),
          sessionStorage.getItem('bureauCode'),
        ),
      },
    });
  };

  const unbuildableContracts = filterUnbuildableContracts({
    allActiveContracts,
    allCostBreakdown,
  });

  const buildableContracts = filterBuildableContracts({
    allActiveContracts,
    allCostBreakdown,
  });

  useEffect(() => {
    if (showBidChangedAlert) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showBidChangedAlert]);

  useEffect(() => {
    if (draftRequisition?.requisitionId) {
      initiateDraftDataSetup();
    } else {
      initiateNonDraftDataSetup();
      if (domestic === 'true') {
        dispatch({
          type: VehicleRequisitionContextActions.IS_DOMESTIC_SHIPMENT,
          payload: true,
        });
      }
    }
    const reqType = sessionStorage.getItem('requisitionType');
    if (reqType === 'on-behalf') {
      setSelectedAgency(sessionStorage.getItem('receivingAgency'));
      setSelectedBureau(sessionStorage.getItem('receivingBureau'));
      setSelectedOffice(sessionStorage.getItem('receivingOffice'));
    } else {
      setSelectedAgency(sessionStorage.getItem('orderingAgency'));
      setSelectedBureau(sessionStorage.getItem('orderingBureau'));
      setSelectedOffice(sessionStorage.getItem('orderingOffice'));
    }
    setSelectedState(sessionStorage.getItem('shipmentLocation'));
  }, []);

  useEffect(() => {
    if (featureOptionsData && allActiveContracts) {
      const vehicleInformationFlattened = getGroupedVehicleInfoData(
        featureOptionsData.getFeatureOptions,
      );
      const allAvailableVehicleInformation = getAvailableVehicleInformation(
        vehicleInformationFlattened,
        allActiveContracts,
      );
      const allAvailableVehicleOverview =
        getVehicleOverviewInformation(allActiveContracts);
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_FEATURES_SECTION,
        vehicleDataFlattened: vehicleInformationFlattened,
        allVehicleOverview: allAvailableVehicleOverview,
        allVehicleInformation: allAvailableVehicleInformation,
      });
    }
  }, [featureOptionsData, allActiveContracts]);

  const handlePageChange = (targetPage) => {
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_COMPARE_VEHICLES_CURRENT_PAGE,
      payload: targetPage,
    });
  };

  useEffect(() => {
    if (allActiveContracts && minReqData) {
      const minimumRequirementDataFlattened = getGroupedMinimumRequirementsData(
        minReqData.getMinRequiredOptions,
      );
      const allAvailableMinimumRequirements = getAvailableMinimumRequirements(
        minimumRequirementDataFlattened,
        allActiveContracts,
      );
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_MIN_REQ_SECTION,
        minimumRequirementsFlattened: minimumRequirementDataFlattened,
        allMinimumRequirements: allAvailableMinimumRequirements,
      });

      handlePageChange(0);
    }
  }, [allActiveContracts, minReqData]);

  useEffect(() => {
    if (
      !Number.isNaN(compareVehiclesCurrentPage) &&
      allMinimumRequirements &&
      allVehicleInformation &&
      allActiveContracts &&
      allCostBreakdown
    ) {
      const displayableMinReqs = getDisplayableMinimumRequirements(
        compareVehiclesCurrentPage,
        offset,
        allMinimumRequirements,
      );
      const displayableOtherFeatures = getDisplayableVehicleInfo(
        compareVehiclesCurrentPage,
        offset,
        allVehicleInformation,
      );
      const displayableOverview = getDisplayableVehicleOverview(
        compareVehiclesCurrentPage,
        offset,
        allVehicleOverview,
      );
      const displayableVehicleEngFuel = getDisplayableVehicleEngFuel(
        compareVehiclesCurrentPage,
        offset,
        allActiveContracts,
      );
      const displayableContracts = getDisplayableContracts(
        compareVehiclesCurrentPage,
        offset,
        allActiveContracts,
      );
      const displayableOptionalData = getDisplayableOptionalData(
        compareVehiclesCurrentPage,
        offset,
        allOptionalRequirements,
      );
      const displayableNetworkCostBreakdownData =
        getDisplayableNetworkCostBreakDownData(
          compareVehiclesCurrentPage,
          offset,
          allCostBreakdown,
        );

      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_COMPARE_VEHICLES_PAGE_INFO,
        displayingOtherFeatures: displayableOtherFeatures,
        displayingVehicleOverview: [...displayableOverview],
        displayingEngineFuel: displayableVehicleEngFuel,
        displayingMinRequirements: [...displayableMinReqs],
        displayingActiveContracts: [...displayableContracts],
        displayingOptionalRequirements: [...displayableOptionalData],
        displayingCostBreakdown: [...displayableNetworkCostBreakdownData],
      });
    }
  }, [
    compareVehiclesCurrentPage,
    allMinimumRequirements,
    allActiveContracts,
    allOptionalRequirements,
    allVehicleInformation,
    allVehicleOverview,
    allCostBreakdown,
  ]);

  useEffect(() => {
    if (allActiveContracts && !quantityHasError) {
      let useSin = currentSid;
      if (draftRequisition) {
        const { standardItemId } = draftRequisition;
        useSin = standardItemId;
      }
      if (vehicleQuantity) {
        getCalculatedPriceData({
          variables: {
            standardItemId: parseInt(useSin, 10),
            quantity: Number(vehicleQuantity),
            selectedOptions: withoutAREQOption(selectedOptionsForPrice),
            transactionType: getRequisitionTransactionType(
              sessionStorage.getItem('agencyCode'),
              sessionStorage.getItem('bureauCode'),
            ),
          },
        });
      }
      if (draftRequisition && !isFirstPageLoad.current && !requisitionType) {
        updateDraftRequisition(draftId);
      }
      isFirstPageLoad.current = false;
    }
  }, [vehicleQuantity, sin]);

  const calculateTotalSellingPrice = (optionsAvailable) => {
    let useSin = currentSid;
    if (draftRequisition) {
      const { standardItemId } = draftRequisition;
      useSin = standardItemId;
    }
    let finalSetOfOptions = [...optionsAvailable];
    if (deliveryOptions && deliveryOptions.length) {
      const selectedDeliveryOptions = [];
      deliveryOptions.forEach((delCat) => {
        delCat?.options.forEach((opt) => {
          if (opt.isChecked) {
            selectedDeliveryOptions.push({
              optionCode: opt.optionCode,
              optionQuantity: 1, // hardcoding it for now as it is not required by these option codes
            });
          }
        });
      });
      finalSetOfOptions = [...optionsAvailable, ...selectedDeliveryOptions];
    }

    getCalculatedPriceData({
      variables: {
        standardItemId: parseInt(useSin, 10),
        quantity: Number(vehicleQuantity),
        selectedOptions: withoutAREQOption(finalSetOfOptions),
        transactionType: getRequisitionTransactionType(
          sessionStorage.getItem('agencyCode'),
          sessionStorage.getItem('bureauCode'),
        ),
      },
    });
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_SELECTED_OPTIONS_FOR_PRICE,
      payload: finalSetOfOptions,
    });
    if (draftRequisition) {
      updateDraftRequisition(draftId, { finalSetOfOptions });
    } else {
      unSavedChangesModalRef.current.unSavedChanges = true;
    }
  };

  const handleCostBreakDownChange = (costBreakdownData) => {
    const pushedModLineIdentifier = [];
    const sortedActiveContractsData = getSortedActiveContractsData(
      allActiveContracts,
      costBreakdownData,
    );
    setLowestBidData(sortedActiveContractsData[0]);
    allActiveContracts.forEach((el, index) => {
      if (
        el.contractLineId !== sortedActiveContractsData[index]?.contractLineId
      ) {
        pushedModLineIdentifier.push(el.contractLineId);
      }
    });

    if (pushedModLineIdentifier.length) {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_ALL_ACTIVE_CONTRACTS,
        payload: sortedActiveContractsData,
      });
    }

    if (
      allActiveContracts[0]?.contractLineId !==
      sortedActiveContractsData[0]?.contractLineId
    ) {
      setLowestBidData(sortedActiveContractsData[0]);
      setChangedBidVehicle(sortedActiveContractsData[0]);
      setShowBidChangedAlert(true);
    }
  };

  useEffect(() => {
    if (currentStandardItem) {
      const standardItemSubCategoryCode =
        currentStandardItem?.vehicleTypeCode?.code;
      const svgCode = standardItemSubCategoryCode.concat('_black');
      const iconURL = `${appURLs.cdnUrl}/icons/sin-sub-category/${svgCode}.svg`;
      setVehicleBodyIcon(iconURL);
    }
  }, [currentStandardItem]);

  useEffect(() => {
    if (
      allActiveContracts &&
      allActiveContracts.length &&
      calculatedPriceData &&
      calculatedPriceData.calculatePurchaseReqPrice &&
      calculatedPriceData.calculatePurchaseReqPrice.modelCostBreakDown
    ) {
      const {
        calculatePurchaseReqPrice: { modelCostBreakDown },
      } = calculatedPriceData;
      handleCostBreakDownChange(modelCostBreakDown);
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_ALL_COST_BREAKDOWN_DATA,
        payload: modelCostBreakDown,
      });
    }
  }, [allActiveContracts, calculatedPriceData]);

  const handleImageError = () => {
    const iconURL = `${appURLs.cdnUrl}/icons/sin-sub-category/02.svg`;
    setVehicleBodyIcon(iconURL);
  };

  const saveDraftSuccess = (id) => {
    // TODO: I don't think these messages work
    setShowDraftSuccessMessage(true);
    // TODO: Figure out why wrapping this in a setTimeout is necessary
    setTimeout(() => {
      history.push(`/vehicle-requisition?requisitionId=${id}&mode=requisition`);
    });
    setTimeout(() => {
      setShowDraftSuccessMessage(false);
    }, 10000);
  };

  const draftViewTitle = ({ friendlyName }) => {
    return (
      <>
        {friendlyName}
        <span className="badge">
          <span className="dot" />
          <span className="badge-text">Draft</span>
        </span>
      </>
    );
  };

  const updateVehicleQuantity = (value) => {
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_VEHICLE_QUANTITY,
      payload: value,
    });
    unSavedChangesModalRef.current.unSavedChanges = true;
    if (
      selectedStateFrmSession == null &&
      !requisitionType &&
      mode !== 'browsing'
    ) {
      history.push('/purchase');
    }
  };

  const handleCalculatePrice = (isFromConflictModal, newOptions) => {
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_IS_CALCULATE_PRICE_BUTTON_DISABLED,
      isCalculatePriceDisabled: true,
    });

    if (isFromConflictModal) {
      calculateTotalSellingPrice(newOptions);
    } else {
      calculateTotalSellingPrice(requiredOptionSessionState);
    }
  };

  const clearAllSelections = () => {
    dispatch({
      type: VehicleRequisitionContextActions.RESET_CONTRACT_SELECTIONS,
    });
    handleCalculatePrice(true, []);
    if (draftRequisition && !isFirstPageLoad.current) {
      updateDraftRequisition(draftId);
    }
    unSavedChangesModalRef.current.unSavedChanges = false;
  };

  const isDealerSin = (selectedSin) => {
    if (
      !selectedSin ||
      !selectedSin.vehicleTypeCode ||
      !selectedSin.vehicleTypeCode.tags ||
      !selectedSin.vehicleTypeCode.tags.value
    )
      return false;
    return selectedSin.vehicleTypeCode.tags.value.some(
      (tag) => tag.toLowerCase() === 'dealer',
    );
  };

  const renderDetails = () => {
    return (
      <>
        {!minReqLoading && !featureOptionsDataLoading && (
          <>
            {displayingActiveContracts && (
              <HorizontalPagination
                offset={offset}
                id="store-compare-vehicles"
                currentPage={compareVehiclesCurrentPage}
                handlePageChange={handlePageChange}
                totalNumberOfResults={allActiveContracts.length}
              />
            )}

            {displayingActiveContracts && (
              <Button
                type="button"
                variant="outline"
                className="margin-bottom-1"
                onClick={clearAllSelections}
                data-testid="store-compare-vehicles-clear-all-selections-button"
                aria-label="Clear all selections"
                leftIcon={{ name: 'custom_undo', type: 'custom' }}
                label="Clear all selections"
              />
            )}

            <div className="store-compare-vehicles-image-list">
              {displayingActiveContracts && (
                <VehiclesHeader
                  setQuantityHasError={(val) => setQuantityHasError(val)}
                  setQuantity={(e) => updateVehicleQuantity(e)}
                  quantity={vehicleQuantity}
                  displayingContractsData={displayingActiveContracts}
                  lowestBidData={lowestBidData}
                  clearAllSelections={clearAllSelections}
                />
              )}
            </div>

            {displayingActiveContracts && (
              <CloseoutDate
                contracts={displayingActiveContracts}
                lowestBidData={lowestBidData}
              />
            )}

            {displayingActiveContracts && (
              <VendorClarification
                contracts={displayingActiveContracts}
                lowestBidData={lowestBidData}
              />
            )}

            <div className="store-compare-vehicles-accordion-group">
              {displayingMinRequirements && (
                <MinimumRequirementsSection
                  minimumRequirementsData={displayingMinRequirements}
                  lowestBidData={lowestBidData}
                />
              )}

              {displayingActiveContracts && allCostBreakdown && (
                <BasePrice
                  contracts={displayingActiveContracts}
                  lowestBidData={lowestBidData}
                  networkCostBreakDownData={displayingCostBreakdown}
                />
              )}
              <div className="store-compare-vehicles-accordion-group store-compare-vehicles-information">
                {displayingActiveContracts &&
                  displayingOtherFeatures &&
                  displayingVehicleOverview &&
                  displayingEngineFuel && (
                    <VehicleInformationAccordion
                      vehicleInformationData={{
                        vehicleOtherFeaturesData: displayingOtherFeatures,
                        vehicleOverviewData: displayingVehicleOverview,
                        vehicleEngFuelData: displayingEngineFuel,
                      }}
                      lowestBidData={lowestBidData}
                    />
                  )}
              </div>

              <div className="store-compare-vehicles-accordion-group">
                {displayingOptionalRequirements && (
                  <AddOptionsCard
                    lowestBidData={lowestBidData}
                    addOptionsData={[...displayingOptionalRequirements]}
                    displayingCostBreakdown={displayingCostBreakdown}
                    calculateTotalSellingPrice={calculateTotalSellingPrice}
                    getPurchaseCollisionDataOnYes={
                      getPurchaseCollisionDataOnYes
                    }
                    getPurchaseCollisionInfo={getPurchaseCollisionInfo}
                    addOptionsAllContractData={[...allOptionalRequirements]}
                    notifyUserRef={notifyUserRef}
                    paginationCurrentPage={compareVehiclesCurrentPage}
                    paginationOffset={offset}
                  />
                )}
              </div>

              {isAreq ? (
                <>
                  {!!unbuildableContracts?.length && (
                    <AreqUnbuildableBanner
                      handleCalculatePrice={(
                        isFromConflictModal,
                        newOptions,
                      ) => {
                        handleCalculatePrice(isFromConflictModal, newOptions);
                      }}
                      getPurchaseCollisionDataOnYes={
                        getPurchaseCollisionDataOnYes
                      }
                      getPurchaseCollisionInfo={getPurchaseCollisionInfo}
                      unbuildableContracts={unbuildableContracts}
                    />
                  )}
                  {!!buildableContracts.length && (
                    <AreqButton
                      setForceSaveDraft={setForceSaveDraft}
                      draftRequisition={draftRequisition}
                    />
                  )}
                </>
              ) : (
                <>
                  <div className="store-compare-vehicles-accordion-group store-compare-cost-breakdown">
                    {displayingActiveContracts && displayingCostBreakdown && (
                      <CostBreakdownSection
                        lowestBidData={lowestBidData}
                        networkCostBreakDownData={displayingCostBreakdown}
                      />
                    )}
                  </div>
                  {displayingActiveContracts && allCostBreakdown && (
                    <TotalSellingPrice
                      contracts={displayingActiveContracts}
                      lowestBidData={lowestBidData}
                      sin={sin}
                      networkCostBreakDownData={displayingCostBreakdown}
                      selectedContract={selectedContract}
                      dispatch={dispatch}
                      requisitionDraftId={draftId}
                      updateDraftRequisition={updateDraftRequisition}
                      isCalculatePriceDisabled={isCalculatePriceDisabled}
                      handleCalculatePrice={(
                        isFromConflictModal,
                        newOptions,
                      ) => {
                        handleCalculatePrice(isFromConflictModal, newOptions);
                      }}
                      isCalculatePriceError={isCalculatePriceError}
                      getPurchaseCollisionDataOnYes={
                        getPurchaseCollisionDataOnYes
                      }
                      getPurchaseCollisionInfo={getPurchaseCollisionInfo}
                    />
                  )}
                </>
              )}
            </div>
            {!isAreq && displayingActiveContracts && (
              <HorizontalPagination
                offset={offset}
                id="store-compare-vehicles"
                currentPage={compareVehiclesCurrentPage}
                handlePageChange={handlePageChange}
                totalNumberOfResults={allActiveContracts.length}
              />
            )}
          </>
        )}
      </>
    );
  };

  return (
    <div className="compare-vehicles">
      {isInactiveContract && (
        <Alert type="warning" slim>
          <span data-testid="not-awarded-alert">
            The item you have selected is currently not available. Please email
            our{' '}
            <a className="usa-link" href="mailto:vehicle.buying@gsa.gov">
              Customer Care
            </a>{' '}
            for assistance.
          </span>
        </Alert>
      )}
      {showDraftSuccessMessage && <SaveDraftRequisitionMessage />}
      {mode === 'browsing' && <JustBrowsingAlert needsLink />}
      {mode !== 'browsing' && (
        <ShipmentInfoAlert
          agency={selectedAgency}
          bureau={selectedBureau}
          office={selectedOffice}
          state={selectedState}
          needsLink
          dealerOnly={isDealerSin(currentStandardItem)}
        />
      )}

      <div className="compare-vehicles-page-header-row">
        <PageTitle
          data-testid="compare-vehicles-header"
          title={
            draftRequisition
              ? draftViewTitle(draftRequisition)
              : 'Create a Requisition: Compare and Select'
          }
        />
        {displayingActiveContracts && mode === 'requisition' && (
          <SaveDraftRequisition
            quantity={vehicleQuantity}
            sin={sin}
            selectedOptionsForPrice={selectedOptionsForPrice}
            saveDraftSuccess={saveDraftSuccess}
            currentStandardItem={currentStandardItem}
            forceSaveDraft={forceSaveDraft}
            setForceSaveDraft={setForceSaveDraft}
          />
        )}
      </div>
      <div className="store-compare-vehicles-sub-header">
        <img
          data-testid="store-compare-sub-header-icon"
          className="store-compare-vehicles-sub-header-image"
          src={vehicleBodyIcon}
          alt="ambulance icon"
          onError={() => handleImageError()}
        />
        {currentStandardItem && (
          <h2 data-testid="compare-vehicles-sub-header-heading">
            STD Item: {currentStandardItem.standardItemNumber} -{' '}
            {currentStandardItem.title}
          </h2>
        )}
      </div>
      {!isInactiveContract && renderDetails()}

      {showBidChangedAlert && (
        <div className="afp-modal-overlay modalContainer lowbid-modal">
          <Modal
            title={<h2>Low price vehicle has changed</h2>}
            className="low-bid-main-container"
            onClose={() => {
              setShowBidChangedAlert(false);
            }}
            actions={
              <>
                <Button
                  type="button"
                  onClick={() => {
                    setShowBidChangedAlert(false);
                  }}
                  label="Close"
                />
              </>
            }
          >
            <p>
              Based on the options you have just selected.{' '}
              <strong>{changedBidVehicle.vendorName}, </strong>
              <strong>{changedBidVehicle.modelName}</strong> is now your low
              price vehicle. Please review accordingly.
            </p>
          </Modal>
        </div>
      )}
    </div>
  );
};

CompareVehicles.propTypes = {
  notifyUserRef: PropTypes.shape({}).isRequired,
};

export default CompareVehicles;
