import React from 'react';

export const returnFundingDocFile = (files) => {
  return files?.find((li) => li.document === 'Funding documentation');
};

export const returnJustificationDocFile = (files) => {
  return files?.find(
    (li) => li.document === 'Justification for Urgent and Compelling',
  );
};

export const getAttachmentTypeId = (docType) => {
  if (docType === 'Source list') {
    return 'req_source_list';
  }
  if (docType === 'Brand name justification') {
    return 'req_brand_name';
  }
  if (docType === 'Sole source justification') {
    return 'req_sole_source';
  }
  if (docType === 'Funding documentation') {
    return 'req_funding';
  }
  if (docType === 'Other documentation') {
    return 'req_other';
  }
  if (docType === 'Justification for Urgent and Compelling') {
    return 'req_urgent_requirement';
  }
  return '';
};

export const toBeRounded = (bytes) => {
  const converted = bytes / (1024 * 1024);
  const size = converted.toFixed(2);
  return Number(size);
};

export const transformAttachmentPayload = (data) => {
  const supportingAttachments = [];
  data.forEach((file) => {
    supportingAttachments.push({
      name: file.name,
      metadataId: file.metadataId,
      description: file.description,
      signedUrl: file.signedUrl,
    });
  });
  return supportingAttachments;
};

export const getRequiredTitles = (status, isMas) => {
  /* jshint quotmark: double */
  let title = "Contracting officer's tasks";
  let list = [
    'Review all information below drafted by customer.',
    'Provide vendor bids',
    'Send requisition with vendor bids to customer for review (or if this requisition contains issues, return with explanation)',
  ];

  if (status === 'FINAL_APPROVAL') {
    /* jshint quotmark: double */
    title = "Agency approver's tasks:";
    list = [
      'Please review all information below established by your AFP manager.',
      'Submit requisition to GSA contracting or return this requisition to the submitter if it contains issues',
    ];
  }

  if (status === 'PENDING_CUSTOMER_RESPONSE') {
    /* jshint quotmark: double */
    title = "Customer's tasks:";
    list = [
      'Review all urgent requisition information',
      'Review vendor quotes details. Select one vendor.',
      'Provide funding confirmation',
      'Submit to contracting',
    ];
  }

  if (status === 'CONTRACTING_FINAL_REVIEW') {
    title = 'Contracting officer’s tasks:';
    list = isMas
      ? [
          'Verify selected quantity and color',
          'Enter contract number',
          'Select schedule line',
          'Place order',
        ]
      : [
          'Review all information from all parties and customer’s selected vendor',
          'Provide vendor acceptance',
          'Verify selected quantity and color',
          'Provide contract number, schedule line, and vehicle details',
          'Place order',
        ];
  }

  return { title, list };
};

export const priceFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const dateFormatted = (date) => {
  return new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(date);
};

export const getCustomerSubmitPayload = (formData, vendorData) => {
  const fundingDocs = [];
  formData?.customerReviewDetails?.fundingDocs?.forEach((li) => {
    fundingDocs.push({
      description: li.description,
      metadataId: li.metadataId,
      name: li.name,
      signedUrl: li.signedUrl,
    });
  });

  return {
    requisitionId: vendorData.requisitionId,
    vendorQuoteId: parseInt(vendorData.requisitionVendorQuoteId, 10),
    justification: formData?.lowBidJustification || '',
    fundingDocuments: fundingDocs,
  };
};

export const placeOrderAcceptDoc = (attachments) => {
  const vendorAcceptanceDocs = [];
  attachments?.forEach((li) => {
    vendorAcceptanceDocs.push({
      description: li.description,
      metadataId: li.metadataId,
      name: li.name,
      signedUrl: li.signedUrl,
      attachmentTypeId: li.attachmentTypeId,
    });
  });

  return vendorAcceptanceDocs;
};

/* eslint react/prop-types: 0 */
export const ContractDetails = ({ contractLine }) => {
  const RowSection = ({ title, desc, error = false }) => {
    return (
      <div className="row-column">
        <div className={`${error && 'error-field'}`}>
          <div className={`title ${error && 'contract-data-field-error'}`}>
            {title}
          </div>
          <div className="title-desc">{desc || '--'}</div>
        </div>
      </div>
    );
  };

  return (
    <section className="contract-line-info-section">
      <div className="row-grid">
        <RowSection title="Formal uPIID" desc={contractLine?.contractUpiid} />
        <RowSection
          title="Contract Number"
          desc={contractLine?.contractNumber}
        />
        <RowSection title="Schedule Line" desc={contractLine?.scheduleLine} />
      </div>
      <div className="row-grid">
        <RowSection title="Contractor" desc={contractLine?.vendorName} />
        <RowSection title="Shipping Days" desc={contractLine?.shippingDays} />
        <RowSection
          title="Award Date"
          desc={
            contractLine?.awardDate &&
            dateFormatted(new Date(contractLine?.awardDate))
          }
        />
      </div>
      <div className="row-grid">
        <RowSection
          title="Quantity"
          desc={contractLine?.quantity}
          error={!contractLine?.quantity}
        />
        <RowSection
          title="Contract Unit price"
          desc={priceFormat.format(contractLine?.contractUnitPrice)}
          error={!contractLine?.contractUnitPrice}
        />
        <RowSection
          title="Contract Total price"
          desc={priceFormat.format(contractLine?.contractTotalPrice)}
          error={!contractLine?.contractTotalPrice}
        />
      </div>
      <div className="row-grid">
        <RowSection
          title="Surcharge"
          desc={contractLine?.surcharge?.toLocaleString('en', {
            style: 'percent',
          })}
        />
        <RowSection
          title="Selling Unit Price"
          desc={priceFormat.format(contractLine?.sellingUnitPrice)}
        />
        <RowSection
          title="Selling Total Price"
          desc={priceFormat.format(contractLine?.sellingTotalPrice)}
        />
      </div>
      <div className="row-grid">
        <RowSection
          title="Standard Item"
          desc={contractLine?.standardItemNumber}
        />
        <RowSection
          title="Standard_item_description"
          desc={contractLine?.standardItemDescription}
        />
      </div>
      <div className="row-grid">
        <RowSection
          title="Solicitation"
          desc={contractLine?.solicitationNumber}
        />
        <RowSection
          title="Origin City/State"
          desc={`${contractLine?.originCity || '-'} , ${
            contractLine?.originStateId || '-'
          }`}
        />
      </div>
    </section>
  );
};
