import React, { useEffect } from 'react';
import { useAppAbility } from '@gsa/afp-shared-ui-utils';
import { useHistory } from 'react-router-dom';
import ContactBuyingPopover from '../../components/ContactBuyingPopover/ContactBuyingPopover';
import Breadcrumbs from './widgets/CreateModificationsBreadcrumbs';
import './RequisitionsAndOrders.scss';
import BulkOrderModProvider from './BulkOrderModProvider';
import CreateOrderMod from './CreateOrderMod';
import BulkOrderModFilter from './BulkOrderModFilter';
import BulkOrderModTable from './BulkOrderModTable';
import UpdateOrderMod from './UpdateOrderMod';
import SubmitOrderMod from './SubmitOrderMod';
import { StoreSubjects, StoreOperations } from '../../constants/constants';

const BulkOrderMod = () => {
  const history = useHistory();
  const ability = useAppAbility();

  useEffect(() => {
    if (!ability?.can(StoreOperations.Update, StoreSubjects.Order)) {
      history.push('/unauthorized');
    }
  }, [ability, history]);

  return (
    <BulkOrderModProvider>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
        }}
      >
        <Breadcrumbs />
        <ContactBuyingPopover />
      </div>
      <div className="order-modifications">
        <CreateOrderMod />
        <BulkOrderModFilter />
        <BulkOrderModTable />
        <UpdateOrderMod />
        <SubmitOrderMod />
      </div>
    </BulkOrderModProvider>
  );
};

export default BulkOrderMod;
