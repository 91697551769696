import React, { useState, useEffect } from 'react';
import { Alert } from '@gsa/afp-component-library';
import { useHistory } from 'react-router-dom';
import { useQueryParam } from '../../hooks/useQueryParam';

const OrderPlacedSuccessMessage = () => {
  const history = useHistory();
  const query = useQueryParam();
  const orderId = query.get('orderId');
  const draftName = query.get('draftName');
  const [enableMessage, setEnableMessage] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setEnableMessage(false);
    }, 10000);
  }, []);

  useEffect(() => {
    if (!enableMessage) {
      history.push('/my-requisitions');
    }
  }, [enableMessage]);

  return (
    <>
      {enableMessage && (
        <Alert type="success" slim>
          <span data-testid="submit-requisition-success-message">
            You have successfully placed Order <strong>{draftName}</strong>. You
            may view details{' '}
            <a className="usa-link" href={`/order?orderId=${orderId}`}>
              here.
            </a>
          </span>
        </Alert>
      )}
    </>
  );
};

export default OrderPlacedSuccessMessage;
