/* eslint-disable react/jsx-curly-newline */
import PropTypes from 'prop-types';
import React from 'react';
import cx from 'classnames';
import { EmptyState, useModal, connectModal } from '@gsa/afp-component-library';
import ImageViewer from '../../utilities/ImageViewer';
import './VehicleListingsPage.scss';
import EngineeringReviewModal from '../../components/EngineeringReviewModal/EngineeringReviewModal';
import { TagsToWatch } from '../../constants/constants';

const { appURLs } = window.AFP_CONFIG;

const VEHICLE_STATUS = {
  ACTIVE: 'active',
  UNAVAILABLE: 'unavailable',
  FUTURE: 'future',
};

const VehicleList = ({ data, onSelectItem }) => {
  const vehicleList = data.map((standardItem) => {
    return (
      <VehicleItem
        standardItem={standardItem}
        key={standardItem.id}
        onSelect={onSelectItem}
      />
    );
  });

  const emptyVehicleList = (
    <EmptyState
      hasBackground
      data-testid="val-table-no-listings"
      containerStyles="table-no-items"
      topText="No Vehicles Available"
      topTextStyles="title"
      bottomText="There are no matches for the filtered values at left."
      bottomTextStyles="description"
    />
  );

  return <>{data.length ? vehicleList : emptyVehicleList}</>;
};

VehicleList.propTypes = {
  data: PropTypes.instanceOf(Array),
  onSelectItem: PropTypes.func.isRequired,
};

VehicleList.defaultProps = {
  data: [],
};

/**
 * Some of the todo comments are for vehicle list and vehicle list page component
 * @todo This is temporary fix and should be broken down into it's own component file
 * @todo This component <button> element inside another <button> element is not semantically correct and goes against the HTML specification. HTML documents should be well-formed and adhere to the semantic structure to ensure accessibility and compatibility across different browsers and devices
 * @todo classnames such as ambulance-svg , amb-details should be avoided
 * @todo onKeyDown => empty handlers is not a good practice
 * @todo a11y - replace the wrapper button with list
 * @todo a11y - add aria hidden text for select button like ' select <<Standard Item description >>
 * @todo refactor the state management to use Recoil to minimize useState usage and remove session storage
 * @todo refactor variable naming like "data" to "standardItems"
 * @todo review all the use effects and memoize component by making them simple/dumb ( data in and events out)
 */
const VehicleItem = ({ standardItem, onSelect }) => {
  const { isOpen, openModal, closeModal } = useModal();
  const DisplayModal = connectModal(EngineeringReviewModal);
  const openEngineeringReviewModal = () => {
    openModal();
  };

  /**
   * @todo This logic to calculate the availability should be on API, try to keep UI should simple and presentational only
   */
  let status;
  let label;
  if (standardItem.status === 'ACTIVE') {
    status = VEHICLE_STATUS.ACTIVE;
    label = 'Select';
  } else if (standardItem.status === 'FUTURE') {
    status = VEHICLE_STATUS.FUTURE;
    const format = new Intl.DateTimeFormat('en', {
      dateStyle: 'short',
    });
    label = `Available ${format.format(new Date(standardItem.orderStartDate))}`;
  } else {
    status = VEHICLE_STATUS.UNAVAILABLE;
    label = 'Not Available';
  }

  const iconURL = `${appURLs.cdnUrl}/icons/sin-sub-category/${standardItem.vehicleType}.svg`;

  const handleSelectVehicleClick = (item) =>
    item.tags &&
    item.tags.value &&
    item.tags.value.length &&
    item.tags.value.some((tag) => TagsToWatch.includes(tag))
      ? openEngineeringReviewModal()
      : onSelect(item);

  return (
    <div>
      {isOpen && (
        <DisplayModal
          isOpen={isOpen}
          onClose={closeModal}
          cancel={closeModal}
          handleClick={() => onSelect(standardItem)}
        />
      )}
      <div
        data-testid={`standard-item-name-${standardItem.id}`}
        className={cx('vehicle-details tooltip', {
          disabled: status !== VEHICLE_STATUS.ACTIVE,
        })}
        onClick={() =>
          status === VEHICLE_STATUS.ACTIVE &&
          handleSelectVehicleClick(standardItem)
        }
        onKeyDown={() => {
          // do nothing
        }}
        role="button"
        tabIndex="0"
      >
        <div className="ambulance-svg">
          <ImageViewer
            imgUrl={iconURL}
            defaultImgUrl={`${appURLs.cdnUrl}/icons/sin-sub-category/02.svg`}
          />
        </div>
        <div className="amb-details">
          STD Item: {standardItem.standardItemNumber}, {standardItem.title}
        </div>
        <button
          type="button"
          data-testid={`select-handle-button-${standardItem.id}`}
          className="select-button"
          onClick={() =>
            status === VEHICLE_STATUS.ACTIVE &&
            handleSelectVehicleClick(standardItem)
          }
        >
          {label}
        </button>
      </div>
    </div>
  );
};

VehicleItem.propTypes = {
  standardItem: PropTypes.instanceOf(Object).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default VehicleList;
