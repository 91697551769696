/* eslint-disable react/jsx-curly-newline */
import { v4 as uuidv4 } from 'uuid';
import React from 'react';
import { optionTypeCheck } from '../../AddOptionsUtils';
import { InfoIcon } from '../../../../assets/images/info-icon';

const RadioButtonGroup = ({
  optionData,
  handleRadioSelection,
  lowestBidData,
  displayCheckedOptionsOnly = false,
}) => {
  const isOptionUnAvailable = (option) => {
    return option.vendorValues.length > 0
      ? option.vendorValues.every(
          (item) =>
            (item.equipmentCode === undefined &&
              item.optionTypeCode === '' &&
              item.unitPrice === undefined) ||
            item.optionTypeCode === 'X' ||
            item.unitPrice === 'N/A',
        )
      : true;
  };
  return optionData.options.map((option) => (
    <tr key={uuidv4()}>
      <td data-testid={`${optionData.optionCategoryCode}-${option.optionCode}`}>
        <div className="option-description-wrapper">
          <span className="option-description-column">
            {displayCheckedOptionsOnly || (
              <input
                data-testid={option.optionCode}
                id={`add-option-${option.optionCode}`}
                className="input-radio"
                type="radio"
                disabled={isOptionUnAvailable(option)}
                onClick={(e) =>
                  handleRadioSelection(
                    e.target.value,
                    optionData.optionCategoryCode,
                    option.isChecked || false,
                  )
                }
                defaultChecked={option.isChecked || false}
                value={option.optionCode}
              />
            )}
            <label htmlFor={`add-option-${option.optionCode}`}>
              {option.optionCode} - {option.optionDescription}
            </label>
          </span>
        </div>
      </td>
      {option.vendorValues.map((optionValue) => {
        const activateLowestBid =
          optionValue.contractLineId === lowestBidData.contractLineId
            ? 'lowest-bid'
            : '';
        const showInfoTooltip =
          optionValue?.contractLineEcClarification?.length > 0;
        return (
          <td className={`option-cell ${activateLowestBid}`} key={uuidv4()}>
            {optionTypeCheck(
              optionValue.optionTypeCode,
              optionValue.unitPrice,
              optionValue.isIncludeExist,
              optionValue.isExcludeExist,
              optionValue.isRequiredExist,
              optionValue.unResolvedExist,
            )}
            {showInfoTooltip && (
              <span className="tooltip">
                <span className="info-icon">
                  <InfoIcon
                    role="img"
                    aria-label={
                      optionValue?.contractLineEcClarification[0]?.clarification
                    }
                  />
                </span>
                <div className="tooltip-info">
                  {optionValue?.contractLineEcClarification[0]?.clarification}
                  <i />
                </div>
              </span>
            )}
          </td>
        );
      })}
    </tr>
  ));
};

export default RadioButtonGroup;
