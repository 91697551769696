import React from 'react';
import { RecoilRoot } from 'recoil';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import {
  AppProvider,
  FeatureProvider,
  NotFound,
  PageFocus,
  PrivateRoute,
  PublicRoute,
  Unauthorized,
} from '@gsa/afp-shared-ui-utils';
import Layout from './utilities/Layout';
import LogoutPage from './pages/auth/logout';
import HomePage from './pages/standards/home';
import PurchasePreFilterContainerWithContext from './pages/purchase/PurchasePreFilter';
import ErrorDisplayComponent from './components/ErrorComponent/ErrorComponent';
import VehicleListingsComp from './pages/vehicle-listings/VehicleListingsPage';
import VehicleRequisitionContainerWithContext from './containers/VehicleRequistionContainer/VehicleRequisitionContainer';
import UrgentRequirementContainerWithContext from './containers/UrgentRequirementContainer/UrgentRequirementContainer';
import VehicleAvailabilityListing from './pages/vehicle-availability/VehicleAvailabilityListing';
import RequisitionDetails from './pages/ReviewDetails/RequisitionDetails';
import SystemAlert from './components/SystemAlert/SystemAlert';
import NonStandardOrdering from './pages/non-standard-purchase/NonStandardOrdering';
import MasRequirementContainerWithContext from './containers/MasRequirementContainer/MasRequirementContainer';
import dataStore, { GET_ENABLED_FEATURES } from './services/data-layer';
import '@gsa/afp-component-library/dist/css/index.css';
import '@gsa/afp-shared-form-utils/dist/style.css';
import './App.scss';
import RequisitionsAndOrders from './pages/requisitions-and-orders/RequisitionsAndOrders';
import ErrorContextProvider from './Providers/ErrorContextProvider/ErrorContextProvider';
import OrderDetailsContainer from './pages/order-detail/OrderDetailsContainer';
import CloneRequisition from './pages/requisitions-and-orders/CloneRequisition';
import BulkOrderMod from './pages/requisitions-and-orders/BulkOrderMod';
import { useSystemAlert } from './services/system-alert';
import OrderModDetailsContainer from './pages/order-modification-detail/OrderModDetailsContainer';
import StoreReports from './pages/store-reports/StoreReports';

function App() {
  const { setSystemAlert } = useSystemAlert();

  // do not call functions in this component! it will cause rerenders like crazy.
  // be sure to memoize their return values like below so you can use them
  const dataStoreMemo = React.useMemo(() => dataStore({ setSystemAlert }), []);
  const requisitionsListing = React.useMemo(
    () => RequisitionsAndOrders('requisitions'),
    [],
  );
  const ordersListing = React.useMemo(
    () => RequisitionsAndOrders('orders'),
    [],
  );
  const orderVehiclesListing = React.useMemo(
    () => RequisitionsAndOrders('order-vehicles'),
    [],
  );
  const orderModListing = React.useMemo(
    () => RequisitionsAndOrders('order-modifications'),
    [],
  );
  return (
    <ApolloProvider client={dataStoreMemo}>
      <FeatureProvider featureQuery={GET_ENABLED_FEATURES}>
        <AppProvider>
          <Layout>
            <ErrorContextProvider>
              <Router>
                <PageFocus />
                <SystemAlert />
                <Switch>
                  <PublicRoute exact path="/logout" component={LogoutPage} />
                  <PrivateRoute
                    exact
                    path="/purchase"
                    component={PurchasePreFilterContainerWithContext}
                    featureName="AFP_STORE_PURCHASE"
                    title="Purchase"
                  />
                  <PrivateRoute
                    exact
                    path="/non-standard-ordering"
                    component={NonStandardOrdering}
                    featureName="AFP_STORE_PURCHASE"
                    title="Non Standard Ordering Options"
                  />
                  <PrivateRoute
                    exact
                    path="/mas-requisition"
                    component={MasRequirementContainerWithContext}
                    featureName="AFP_STORE_PURCHASE"
                    title="MAS Requirements"
                  />
                  <PrivateRoute
                    exact
                    path="/urgent-requisition"
                    component={UrgentRequirementContainerWithContext}
                    featureName="AFP_STORE_PURCHASE"
                    title="Urgent Requisition"
                  />
                  <PrivateRoute
                    exact
                    path="/"
                    component={HomePage}
                    featureName="AFP_STORE_HOME"
                    title="Home"
                  />
                  <PrivateRoute
                    exact
                    path="/vehicle-listings"
                    component={VehicleListingsComp}
                    featureName="AFP_STORE_VEHICLE"
                    title="Vehicle Listing"
                  />
                  <PrivateRoute
                    exact
                    path="/vehicle-requisition"
                    component={VehicleRequisitionContainerWithContext}
                    featureName="AFP_STORE_VEHICLE"
                    title="Create a Requisition-Compare and select"
                  />
                  <PrivateRoute
                    exact
                    path="/my-requisitions"
                    component={requisitionsListing}
                    featureName="AFP_STORE_VEHICLE"
                    title="Requisitions and Orders"
                  />
                  <PrivateRoute
                    exact
                    path="/orders"
                    component={ordersListing}
                    featureName="AFP_STORE_VEHICLE"
                    title="Motor vehicle orders"
                  />
                  <PrivateRoute
                    exact
                    path="/order-vehicles"
                    component={orderVehiclesListing}
                    featureName="AFP_STORE_VEHICLE"
                    title="Motor vehicle orders"
                  />
                  <PrivateRoute
                    exact
                    path="/order-modifications"
                    component={orderModListing}
                    featureName="AFP_STORE_VEHICLE"
                    title="Order Modifications"
                  />
                  <PrivateRoute
                    exact
                    path="/clone-requisition"
                    component={CloneRequisition}
                    featureName="AFP_STORE_VEHICLE"
                    title="Clone Requisition"
                  />
                  <PrivateRoute
                    exact
                    path="/create-order-modifications"
                    component={BulkOrderMod}
                    featureName="AFP_STORE_VEHICLE"
                    title="Create a modification"
                  />
                  <PrivateRoute
                    exact
                    path="/order-modification-details"
                    component={OrderModDetailsContainer}
                    featureName="AFP_STORE_VEHICLE"
                    title="Order Modification Details"
                  />
                  <PrivateRoute
                    exact
                    path="/order"
                    component={OrderDetailsContainer}
                    featureName="AFP_STORE_ORDER"
                  />
                  <PrivateRoute
                    exact
                    path="/requisition-details"
                    component={RequisitionDetails}
                    featureName="AFP_STORE_REQUISITION_DETAILS"
                    title="Requisition Detail"
                  />
                  <PrivateRoute
                    exact
                    path="/vehicle-availability"
                    component={VehicleAvailabilityListing}
                    featureName="AFP_STORE_VEHICLE_AVAILABILITY"
                  />
                  <PrivateRoute
                    exact
                    path="/store-reports"
                    component={StoreReports}
                    featureName="AFP_STORE_VEHICLE_AVAILABILITY"
                  />
                  <PublicRoute
                    exact
                    path="/page-not-found"
                    component={ErrorDisplayComponent}
                  />
                  <PublicRoute
                    exact
                    path="/unauthorized"
                    component={Unauthorized}
                    title="Access Denied"
                  />
                  <PublicRoute
                    path="*"
                    component={NotFound}
                    title="Sorry for the Detour"
                  />
                </Switch>
              </Router>
            </ErrorContextProvider>
          </Layout>
        </AppProvider>
      </FeatureProvider>
    </ApolloProvider>
  );
}

export default () => (
  <RecoilRoot>
    <App />
  </RecoilRoot>
);
