import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormGenerator } from '@gsa/afp-shared-form-utils';
import { Spinner } from '@gsa/afp-component-library';
import { useLazyQuery, useQuery } from '@apollo/client';
import TelephoneControl from '../../../../../components/FormComponents/telephone-input';
import useBulkOrderModsState from '../../../useBulkOrderModsState';
import { GET_CATALOGS_FOR_CATEGORY } from '../../../../../services/data-layer';
import { generateAddressComponentForm } from './address-component-form';
import generateAddressComponentChangeHandler from './address-component-change';
import addressComponentSchema from './address-component-schema';
import ComboBoxControl from '../../../../../components/FormComponents/combobox';

export default function AddressComponent({
  section,
  setSameAsRequisition,
  sameAsRequisition,
  addressDefaults,
}) {
  const { setOrderChanges, orderChanges, formGeneratorRef } =
    useBulkOrderModsState();

  const countries = useQuery(GET_CATALOGS_FOR_CATEGORY, {
    variables: { category: 'ISOCountryCode2' },
  });

  const defaultStates = useQuery(GET_CATALOGS_FOR_CATEGORY, {
    skip: !orderChanges[section.addressKey]?.countryCode,
    variables: {
      category: `ISOStateCode-${orderChanges[section.addressKey]?.countryCode}`,
    },
  });

  const [getStates] = useLazyQuery(GET_CATALOGS_FOR_CATEGORY);

  const countryCodes = useQuery(GET_CATALOGS_FOR_CATEGORY, {
    variables: { category: 'CountryCallingCode' },
  });

  const [content, defaultValues] = generateAddressComponentForm({
    section,
    sameAsRequisition,
    address: orderChanges[section.addressKey] || addressDefaults?.address,
    contact: orderChanges[section.contactKey] || addressDefaults?.contact,
    getStates,
    defaultStates: defaultStates.data?.getCatalogsForCategory
      .map(({ description, code }) => ({
        label: description,
        value: code.split('-')[1],
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
    countries: (countries.data?.getCatalogsForCategory || [])
      .map(({ description, code }) => ({
        label: description,
        value: code,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
    countryCodes: (countryCodes.data?.getCatalogsForCategory || [])
      .map(({ description, code }) => ({
        label: description,
        value: `${code}:${description}`,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
  });

  const handleChange = useMemo(
    () =>
      generateAddressComponentChangeHandler({
        setSameAsRequisition,
        setOrderChanges,
        section,
        formGeneratorRef: formGeneratorRef[section.id],
      }),
    [section, setSameAsRequisition, setOrderChanges],
  );

  if (countries.loading || countryCodes.loading)
    return <Spinner size="large" />;

  return (
    <FormGenerator
      ref={formGeneratorRef[section.id]}
      schema={addressComponentSchema}
      content={content}
      defaultValues={defaultValues}
      useFormElement={false}
      onChange={handleChange}
      controls={{
        phone: TelephoneControl,
        cmbobox: ComboBoxControl,
      }}
    />
  );
}

AddressComponent.propTypes = {
  section: PropTypes.shape({
    id: PropTypes.string.isRequired,
    addressKey: PropTypes.string.isRequired,
    contactKey: PropTypes.string.isRequired,
    isChecked: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    canBeSameAsRequisition: PropTypes.bool.isRequired,
  }).isRequired,
  setSameAsRequisition: PropTypes.func.isRequired,
  sameAsRequisition: PropTypes.bool.isRequired,
  addressDefaults: PropTypes.shape({
    address: PropTypes.shape({
      addressLine1: PropTypes.string,
      addressLine2: PropTypes.string,
      city: PropTypes.string,
      stateCode: PropTypes.string,
      zipcode: PropTypes.string,
      countryCode: PropTypes.string,
    }),
    contact: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      phone: PropTypes.shape({
        countryCode: PropTypes.string,
        number: PropTypes.string,
        extension: PropTypes.string,
      }),
      fax: PropTypes.shape({
        countryCode: PropTypes.string,
        number: PropTypes.string,
        extension: PropTypes.string,
      }),
    }),
  }),
};

AddressComponent.defaultProps = {
  addressDefaults: null,
};
