import React from 'react';
import PropTypes from 'prop-types';
import { Button, Alert, Modal } from '@gsa/afp-component-library';

export default function AreqModal({ onClose, cancel }) {
  const BulkUpdateActions = (
    <>
      <Button type="button" variant="primary" label="Close" onClick={cancel} />
    </>
  );

  return (
    <Modal
      title={<h1>Additional requirements</h1>}
      onClose={onClose}
      variant="large"
      actions={BulkUpdateActions}
      alert={
        <Alert type="info">
          <ol>
            <li>
              Please be sure to select all necessary options on the Compare and
              Select page to ensure the best assessment from engineering,
              contracting and vendors.
            </li>
            <li>
              You will continue to the next step to fill out required
              information for your AREQ request, and provide your price
              estimate. Please list AREQ requirements concisely and include
              enough information for GSA to obtain valid pricing.
            </li>
            <li>
              The information your provide will be sent to your agency approver
              for their review and approval. If necessary, they may return the
              requisition back to the submitter for edits.
            </li>
            <li>GSA will review and obtain pricing for the AREQ.</li>
          </ol>
        </Alert>
      }
    >
      <p>
        The Additional Requirements (AREQ) option code allows customers to
        request specific open market equipment not listed in the Compare and
        Select page. You will be required to provide information detailing your
        additional requirements before sending the requisition to GSA for their
        review.
      </p>
      <Alert type="info">
        <ol>
          <li>
            Please be sure to select all necessary options on the Compare and
            Select page to ensure the best assessment from engineering,
            contracting and vendors.
          </li>
          <li>
            You will continue to the next step to fill out required information
            for your AREQ request, and provide your price estimate. Please list
            AREQ requirements concisely and include enough information for GSA
            to obtain valid pricing.
          </li>
          <li>
            The information your provide will be sent to your agency approver
            for their review and approval. If necessary, they may return the
            requisition back to the submitter for edits.
          </li>

          <li>GSA will review and obtain pricing for the AREQ.</li>
        </ol>
      </Alert>
      <p>
        <strong>
          AREQ should only be used for mission-related requirements. Do not use
          AREQ to list any of the following:
        </strong>
      </p>
      <p>
        1) GSA minimum requirements, 2) manufacturer standard equipment, 3)
        internal agency data, or 4) vendor selection. AREQ cannot be used to
        request makes/models not offered or to request options already shown as
        not available by the manufacturer.
      </p>
    </Modal>
  );
}

AreqModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
};
