import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Spinner, TabSet } from '@gsa/afp-component-library';
import { useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { useLazyQuery } from '@apollo/client';
import VehicleRequisitionContext from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import './ReviewAndSubmit.scss';
import groupOptionsPerCatalog from '../../utils/VehicleRequisitionUtils';
import ReviewRequisitionMessage from './ReviewRequisitionMessage';
import ReviewDetails from '../../../../components/ReviewComponents/ReviewDetails/ReviewDetails';
import {
  GET_PDF_URL,
  GET_REQUISITION_ACTIVITIES,
} from '../../../../services/data-layer';
import SystemAlert from '../../../../components/SystemAlert/SystemAlert';
import AreqReviewVehicleBuild from '../../../../components/Areq/AreqReviewVehicleBuild/AreqReviewVehicleBuild';
import { useDraftRequisitionEdit } from '../../../../hooks/useDraftEdit';
import {
  REQUISITION_BUTTON_DROPDOWN_ACTIONS,
  RequisitionActionsButtonDropdown,
} from '../../../../components/RequisitionActions/RequisitionActionsButtonDropdown/RequisitionActionsButtonDropdown';
import CommentModal from '../../../../components/CommentModal/CommentModal';
import { VehicleRequisitionContextActions } from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import RequisitionActivitiesTab from '../../../ReviewDetails/tabs/RequisitionActivitiesTab';

const ReviewVehicleBuild = ({ setTab }) => {
  const { state } = useContext(VehicleRequisitionContext);
  const {
    draftRequisition,
    selectedContractCostBreakdown,
    nonLowBidJustification,
    optionalReqData,
    selectedContractAgencyInformation,
    requisitionStateContext,
    mailingStateContext,
    deliveryStateContext,
    vehicleColors,
    selectedContract,
    taggedOptions,
    currentStandardItem,
    isAreq,
  } = state;
  const { canEdit } = useDraftRequisitionEdit();
  const [perVehicleOptionsState, setPerVehicleOptionsState] = useState();

  useEffect(() => {
    if (selectedContractCostBreakdown) {
      const { perVehicleOptions } = selectedContractCostBreakdown;
      const groupedPerVehicleOptions = groupOptionsPerCatalog(
        optionalReqData,
        perVehicleOptions,
      );
      setPerVehicleOptionsState(groupedPerVehicleOptions);
    }
  }, [optionalReqData, selectedContractCostBreakdown]);

  useEffect(() => setTab('summary'), [setTab]);

  return (
    <>
      {draftRequisition?.requisitionStatus === 'RETURNED' && (
        <ReviewRequisitionMessage
          requisitionId={draftRequisition?.requisitionId}
        />
      )}
      <div className="review-submit-requisition">
        <ReviewDetails
          vehicleColors={vehicleColors}
          taggedOptions={taggedOptions}
          selectedContractCostBreakdown={selectedContractCostBreakdown}
          getStandardItem={currentStandardItem}
          vehicleOptions={perVehicleOptionsState}
          requisitionAddress={requisitionStateContext}
          mailingAddress={mailingStateContext}
          deliveryAddress={deliveryStateContext}
          contractAgency={selectedContractAgencyInformation}
          nonLowBidJustification={nonLowBidJustification}
          optionalReqData={optionalReqData}
          selectedContractAgencyInformation={selectedContractAgencyInformation}
          calculatedPriceData={selectedContractCostBreakdown}
          selectedContract={selectedContract}
          isEditable={canEdit}
          isAreq={isAreq}
          expanded
        />
      </div>
    </>
  );
};

const ReviewAndSubmit = () => {
  const {
    currentUser: { firstName, name: fullName, email, id },
  } = useCurrentUser();
  const { state, dispatch } = useContext(VehicleRequisitionContext);
  const {
    draftRequisition,
    nonLowBidJustification,
    currentStandardItem,
    selectedContract,
    selectedContractAgencyInformation,
    selectedContractCostBreakdown,
    requisitionStateContext,
    mailingStateContext,
    deliveryStateContext,
    paintAndGraphicsOptions,
    taggedOptions,
    isAreq,
    draftSelectedColors,
    vehicleColors,
    preDraftComments = [],
  } = state;

  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showAddCommentModal, setShowAddCommentModal] = useState(false);
  const [requisitionActivities, setRequisitionActivities] = useState([]);

  const handleAddComment = (comment) => {
    setShowAddCommentModal(false);

    const createdUserInfo = {
      firstName,
      email,
      fullName,
      id,
    };

    const createdAt = moment().toISOString(true);

    const newComment = {
      createdUserInfo,
      comment,
      createdAt,
    };

    const newComments = [...preDraftComments, newComment];

    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_PRE_DRAFT_COMMENTS,
      payload: newComments,
    });
    setRequisitionActivities(newComments);
  };

  const handleCancelComment = () => {
    setShowAddCommentModal(false);
  };

  const standardItem = sessionStorage.getItem('standardItem');
  const date = new Date();
  const month = date.toLocaleString('default', { month: 'long' });
  const code =
    currentStandardItem?.standardItemNumber || standardItem?.standardItemNumber;
  const defaultFriendlyName =
    draftRequisition?.friendlyName ||
    `${code}_${month}${date.getDate()}_${date.getHours()}${date.getMinutes()}`;

  const [getPDFUrl, { data: generatePDFResponse }] = useLazyQuery(GET_PDF_URL, {
    fetchPolicy: 'no-cache',
  });

  const [getRequisitionActivitiesData, { data: requisitionActivitiesData }] =
    useLazyQuery(GET_REQUISITION_ACTIVITIES, {
      fetchPolicy: 'no-cache',
    });

  useEffect(() => {
    if (draftRequisition?.requisitionId) {
      getRequisitionActivitiesData({
        variables: {
          requisitionId: draftRequisition?.requisitionId,
          offset: 0,
          limit: 20,
          order: [['createdAt', 'DESC']],
        },
      });
    }
  }, [draftRequisition?.requisitionId]);

  useEffect(() => {
    if (requisitionActivitiesData?.getRequisitionActivities) {
      const { rows } = requisitionActivitiesData.getRequisitionActivities;
      setRequisitionActivities(rows);
    }
  }, [requisitionActivitiesData]);

  const waitingPDFLoop = (url) => {
    const maxTry = 15; // 30 seconds wait in total
    const timer = setTimeout(() => {
      const httpReq = new XMLHttpRequest();
      httpReq.open('GET', generatePDFResponse.generatePDF.signedUrl, true);
      httpReq.send();
      httpReq.onload = (response) => {
        if (response?.target && response?.target?.status === 200) {
          // open the PDF
          window.open(url, '_blank');
          setIsLoading(false);
        } else if (
          response?.target &&
          response?.target?.status !== 200 &&
          waitingPDFLoop.tryCount < maxTry
        ) {
          waitingPDFLoop.tryCount += 1;
          waitingPDFLoop(url);
        } else {
          // throw error
          setIsLoading(false);
          setShowError(true);
        }
      };
      clearTimeout(timer);
    }, 3000);
  };
  waitingPDFLoop.tryCount = 0;

  useEffect(() => {
    if (generatePDFResponse?.generatePDF?.succeed) {
      waitingPDFLoop(generatePDFResponse.generatePDF.signedUrl);
    }
  }, [generatePDFResponse]);

  const printPDF = () => {
    const firstActivity = requisitionActivities && requisitionActivities[0];
    const lastActivity =
      requisitionActivities &&
      requisitionActivities[requisitionActivities.length - 1];
    let agencyName = '';
    let bureauName = '';
    const reqType = sessionStorage.getItem('requisitionType');
    if (reqType === 'on-behalf') {
      agencyName = sessionStorage.getItem('receivingAgency')?.split('-')[1];
      bureauName = sessionStorage.getItem('receivingBureau')?.split('-')[1];
    } else {
      agencyName = sessionStorage.getItem('orderingAgency')?.split('-')[1];
      bureauName = sessionStorage.getItem('orderingBureau')?.split('-')[1];
    }
    const agencyInfo = {
      name: agencyName,
    };
    const bureauInfo = {
      name: bureauName,
    };
    setIsLoading(true);

    getPDFUrl({
      variables: {
        contentModel: 'new_requisition',
        recordId: draftRequisition?.requisitionId,
        friendlyName: defaultFriendlyName,
        sections: 'all',
        vehicleContract: JSON.stringify({
          contractLineClarifications:
            selectedContract?.contractLineClarifications ?? [],
          offerLineArtifact: selectedContract?.offerLineArtifact ?? [],
          baseEngineRating: selectedContract?.baseEngineRating ?? {},
          vendorName: selectedContract?.vendorName ?? '',
          modelName: selectedContract?.modelName ?? '',
          modelYear: selectedContract?.modelYear ?? '',
          comment: selectedContract?.comment ?? '',
        }),
        requisitionStandardItem: JSON.stringify(currentStandardItem),
        calculatedPriceInfoState: JSON.stringify(selectedContractCostBreakdown),
        selectedContractAgencyInformation: JSON.stringify(
          selectedContractAgencyInformation,
        ),
        agencyInfo: JSON.stringify(agencyInfo),
        bureauInfo: JSON.stringify(bureauInfo),
        firstActivity: JSON.stringify(firstActivity),
        lastActivity: JSON.stringify(lastActivity),
        perVehicleOptions: JSON.stringify(
          selectedContractCostBreakdown?.perVehicleOptions ?? [],
        ),
        selectedColor:
          (draftSelectedColors[0]?.makeColorName ||
            vehicleColors[0]?.color?.label) ??
          '',
        paintAndGraphicsOptions: JSON.stringify(paintAndGraphicsOptions),
        taggedOptions: JSON.stringify(taggedOptions),
        requisitionAddress: JSON.stringify(requisitionStateContext),
        mailingAddress: JSON.stringify(mailingStateContext),
        deliveryAddress: JSON.stringify(deliveryStateContext),
        nonLowBidJustification: JSON.stringify(nonLowBidJustification),
        areqList: JSON.stringify([]),
      },
    });
  };

  return (
    <>
      {isLoading && (
        <div className="afp-modal-overlay requisition-loading">
          <Spinner size="large" className="margin-y-8" />
        </div>
      )}
      <div
        tabIndex="0"
        role="tab"
        className="review-summary-main-section-title"
      >
        Review requisition summary and submit
      </div>
      <div tabIndex="0" role="tab" className="review-summary-main-desc">
        Please review all entered information below prior to submitting your
        requisition. If you would like to make any edits, you may select the
        &quot;Edit&quot; link on the right of each section or navigate back
        within the requisition.
      </div>

      {showError && (
        <div className="margin-bottom-3">
          <SystemAlert
            systemError={{
              showError,
            }}
            setSystemError={setShowError}
          />
        </div>
      )}
      <div className="display-flex flex-align-end justify-content-end margin-bottom-4 requisition-action-container">
        <RequisitionActionsButtonDropdown
          menuItems={{
            [REQUISITION_BUTTON_DROPDOWN_ACTIONS.PRINT]: {
              onClick: printPDF,
            },
            // NOTE: Old code had a clone action, but it was not implemented
            [REQUISITION_BUTTON_DROPDOWN_ACTIONS.CLONE]: {
              onClick: () => {},
            },
            [REQUISITION_BUTTON_DROPDOWN_ACTIONS.POST_COMMENT]: {
              onClick: () => setShowAddCommentModal(true),
            },
          }}
        />
      </div>

      {isAreq || (
        <CommentModal
          data-testid="add-comment-modal-pre-submit"
          isOpen={showAddCommentModal}
          onSubmit={handleAddComment}
          onCancel={handleCancelComment}
          body="Your comment will be added to this timeline and sent to your GSA service rep."
          isRequired={false}
        />
      )}

      <div className="review-and-submit-tabs">
        <TabSet
          tabs={[
            {
              heading: 'Review summary',
              // tabSelectedWhenOpen:
              //   tab === 'summary' && currentTab !== 'activities',
              content: isAreq ? (
                <AreqReviewVehicleBuild />
              ) : (
                <ReviewVehicleBuild />
              ),
            },
            {
              heading: 'Activity',
              // tabSelectedWhenOpen:
              //   currentTab === 'activities' || tab === 'activities',
              content: (
                <RequisitionActivitiesTab
                  existingRequisitionActivities={requisitionActivities}
                />
              ),
            },
          ]}
        />
      </div>
    </>
  );
};

ReviewVehicleBuild.propTypes = {
  setTab: PropTypes.func,
};

ReviewVehicleBuild.defaultProps = {
  setTab: () => {},
};

export default ReviewAndSubmit;
