import React from 'react';
import CheckBoxesGroup from '../CheckBoxesGroup/CheckBoxesGroup';
import RadioButtonGroup from '../RadioButtonGroup/RadioButtonGroup';

const AccordionContent = ({
  addOptionsState,
  handleRowCheckBoxClick,
  onNumInputChange,
  onNumInputFocusChanged,
  handleRadioSelection,
  lowestBidData,
  displayCheckedOptionsOnly = false,
}) => {
  return addOptionsState.map((optionEntry, optionDataIndex) => {
    const optionData = { ...optionEntry };
    const fragementKey = `${optionData.standardItemCode}-${optionData.optionCategoryCode}`;

    return (
      <React.Fragment key={fragementKey}>
        <tr
          role="tab"
          aria-label={optionData.optionCategoryName}
          tabIndex="0"
          data-testid={`add_option_title_${optionDataIndex}`}
        >
          {optionData.optionCategoryCode !== 'AREQ' && (
            <td
              className="table-title"
              colSpan={optionData?.options[0]?.vendorValues?.length + 1}
            >
              {optionData.optionCategoryName}
            </td>
          )}
        </tr>
        {optionData.selectionType === 'M' && (
          <CheckBoxesGroup
            optionData={optionData}
            handleRowCheckBoxClick={handleRowCheckBoxClick}
            onNumInputChange={onNumInputChange}
            onNumInputFocusChanged={onNumInputFocusChanged}
            optionDataIndex={optionDataIndex}
            lowestBidData={lowestBidData}
            displayCheckedOptionsOnly={displayCheckedOptionsOnly}
          />
        )}

        {optionData.selectionType === 'S' && (
          <RadioButtonGroup
            optionData={optionData}
            handleRowCheckBoxClick={handleRowCheckBoxClick}
            optionDataIndex={optionDataIndex}
            handleRadioSelection={handleRadioSelection}
            lowestBidData={lowestBidData}
            displayCheckedOptionsOnly={displayCheckedOptionsOnly}
          />
        )}
      </React.Fragment>
    ); // eo return
  });
};

export default AccordionContent;
