export const extractVendorInfoFromActiveContracts = (activeContracts) => {
  if (activeContracts.length === 0) {
    return {};
  }

  const vendorOptions = activeContracts
    .map((contract) => {
      const { id: value, vendorName: label } =
        contract?.contractModificationRef?.contract?.vendor;
      return {
        value,
        label,
      };
    })
    .filter((vendor, index, originalArray) => {
      return (
        originalArray.findIndex(({ value }) => value === vendor.value) === index
      );
    });

  const makeModelGroupedByVendorId = activeContracts.reduce(
    (accum, contract) => {
      const { id: vendorId } = contract.contractModificationRef.contract.vendor;
      const { makeCode } = contract.make;
      const { modelCode } = contract.model;
      const makeModel = `${makeCode}/${modelCode}`;
      const arr = accum[vendorId] || [];

      const newAccum = { ...accum, [vendorId]: [...arr, makeModel] };
      return newAccum;
    },
    {},
  );

  return { vendorOptions, makeModelGroupedByVendorId };
};

// NOTE: There are two different forms of active contract. One with make and model as an
// object and the other with make and model as a string.
// Also, one with contractModification and the other with contractModificationRef
export const groupContractsByVendorName = (activeContracts) => {
  if (!activeContracts?.length) return {};

  const contractsWithMakeModel = activeContracts.map((contract) => {
    const make = contract.make?.makeCode || contract.makeCode;
    const model = contract.model?.modelCode || contract.modelCode;
    return {
      makeModel: `${make}/${model}`,
      ...contract,
    };
  });

  if (!contractsWithMakeModel.length) return {};

  return Object.groupBy(contractsWithMakeModel, (contract) => {
    if (contract.contractModification) {
      return contract.contractModification.contract.vendor.vendorName;
    }

    if (contract.contractModificationRef) {
      return contract.contractModificationRef.contract.vendor.vendorName;
    }

    return 'Invalid vendor name';
  });
};

export const filterBuildableContracts = ({
  allActiveContracts,
  allCostBreakdown,
  contractCostBreakDowns,
}) => {
  const costBreakdown = contractCostBreakDowns || allCostBreakdown;

  const unbuildableModels =
    costBreakdown?.filter((cb) => cb.isUnBuildable) || [];

  const buildableContracts = allActiveContracts?.filter((contract) => {
    const foundCostBreakdown = unbuildableModels?.find(
      (model) => model.contractLineId === contract.contractLineId,
    );
    return !foundCostBreakdown;
  });

  return buildableContracts;
};

export const filterUnbuildableContracts = ({
  allActiveContracts,
  allCostBreakdown,
  contractCostBreakDowns,
}) => {
  const costBreakdown = contractCostBreakDowns || allCostBreakdown;

  if (!allActiveContracts) return [];
  const unbuildableModels =
    costBreakdown?.filter((cb) => cb.isUnBuildable) || [];

  const unbuildableContracts = allActiveContracts?.filter((contract) => {
    const foundCostBreakdown = unbuildableModels?.find(
      (model) => model.contractLineId === contract.contractLineId,
    );
    return !!foundCostBreakdown;
  });

  return unbuildableContracts;
};
