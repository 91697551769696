import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import { ENGINEER_APPROVED } from '../../pages/ReviewDetails/EngineerApproveButton/EngineerApproveButton';
import { RequisitionStatus } from '../../pages/ReviewDetails/RequisitionDetailsUtils';

function getSuccessMessage(reqName, reqStatus, returned) {
  switch (reqStatus) {
    case ENGINEER_APPROVED:
      return (
        <>
          You have successfully approved <strong>{reqName}</strong>.
        </>
      );
    case RequisitionStatus.CONTRACTING_REVIEW:
      if (returned) {
        return (
          <>
            You have successfully returned <strong>{reqName}</strong> to GSA
            contracting.
          </>
        );
      }
      return (
        <>
          You have successfully submitted <strong>{reqName}</strong> to GSA
          contracting.
        </>
      );
    case RequisitionStatus.PENDING_CUSTOMER_RESPONSE:
      return (
        <>
          You have successfully submitted <strong>{reqName}</strong> for
          customer approval.
        </>
      );
    case RequisitionStatus.FINAL_APPROVAL:
      return (
        <>
          You have successfully submitted <strong>{reqName}</strong> for
          approval.
        </>
      );
    case RequisitionStatus.ORDERING_ADMIN_APPROVAL:
      return (
        <>
          You have successfully submitted <strong>{reqName}</strong> for
          ordering admin approval.
        </>
      );
    default:
      return (
        <>
          You have successfully submitted <strong>{reqName}</strong> to GSA
          contracting.
        </>
      );
  }
}

const EngineerApprovedSuccessMessage = ({ reqName, reqStatus, returned }) => {
  const history = useHistory();
  const [enableMessage, setEnableMessage] = useState(true);

  // Display for 10 seconds
  useEffect(() => {
    setTimeout(() => {
      setEnableMessage(false);
      history.push('/my-requisitions');
    }, 10000);
  }, []);

  const successMessage = getSuccessMessage(reqName, reqStatus, returned);

  return (
    <>
      {enableMessage && (
        <Alert type="success" slim>
          <span data-testid="engineer-approved-success-message">
            {successMessage}
          </span>
        </Alert>
      )}
    </>
  );
};

EngineerApprovedSuccessMessage.propTypes = {
  reqName: PropTypes.string.isRequired,
  reqStatus: PropTypes.string.isRequired,
  returned: PropTypes.bool,
};

EngineerApprovedSuccessMessage.defaultProps = {
  returned: false,
};

export default EngineerApprovedSuccessMessage;
