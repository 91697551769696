import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import { Controller, FormProvider, useForm } from 'react-hook-form';
import {
  Checkbox,
  useModal,
  connectModal,
  Button,
  TextInput,
  RequiredFieldIndicator,
  Label,
  ComboBox,
} from '@gsa/afp-component-library';
import VehicleRequisitionContext from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import MasRequirementsModal from '../utils/MasRequirementsModal';
import UploadRequirements from '../uploadRequirements/uploadRequirements';
import { VehicleRequisitionContextActions } from '../../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import {
  defaultVehicleReq,
  manageAttachmentTypes,
} from '../utils/ServiceUtils';

import {
  GET_REQUISITION_ATTACHMENT_TYPES,
  GET_STANDARD_CODES,
} from '../../../../services/data-layer';

import './VehicleRequirements.scss';
import FormButtons from '../utils/FormButtonNav';

const VehicleRequirements = ({ handlePageNavigation }) => {
  const { dispatch, state } = useContext(VehicleRequisitionContext);
  const { nonSopData, additionalRequirements } = state;
  const [selectedModal, setSelectedModal] = useState('');
  const [attachmentTypes, setAttachmentTypes] = useState([]);
  const options = [{ value: '-select-', label: '-select-' }];
  const [colorsData, setColorsData] = useState(options);

  const { data: requisitionAttachmentTypeData } = useQuery(
    GET_REQUISITION_ATTACHMENT_TYPES,
  );

  const getPayload = (id) => {
    return {
      variables: {
        filters: {
          operator: 'EQ',
          key: 'code_metadata_id',
          value: id,
        },
      },
    };
  };

  const { data: fetchColors } = useQuery(GET_STANDARD_CODES, getPayload(1));

  useEffect(() => {
    const types = manageAttachmentTypes(
      requisitionAttachmentTypeData?.getAllRequisitionAttachmentTypes,
    );
    return setAttachmentTypes(types);
  }, [requisitionAttachmentTypeData?.getAllRequisitionAttachmentTypes]);

  const requirementsModal = useModal();
  const MasRequirementsModalModal = connectModal(MasRequirementsModal);

  const formProps = useForm({
    defaultValues: {
      ...nonSopData,
      certifyCheckbox: false,
      additionalRequirements: additionalRequirements || [
        {
          attachments: [],
        },
      ],
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    formProps.reset({ additionalRequirements });
  }, [additionalRequirements, formProps.reset]);

  const transformRespToOptions = (data) => {
    const list = [...options];
    data.forEach((i) => {
      list.push({
        label: i.title,
        value: i.title,
      });
    });
    return list;
  };

  useEffect(() => {
    const colors = fetchColors?.getStandardCodes?.rows;
    if (colors?.length) {
      setColorsData(
        transformRespToOptions([
          { id: '-1', title: '<No preference>' },
          ...colors,
        ]),
      );
    }
  }, [fetchColors]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
  } = formProps;

  useEffect(() => {
    if (nonSopData) {
      Object.entries(nonSopData).forEach(([key, value]) => {
        setValue(key, value);
      });
    }
  }, [nonSopData]);

  const onError = () => {
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_MAS_REQ_ERROR_BANNER,
      payload: true,
    });
  };

  const onSubmit = async (data) => {
    Object.entries(data).forEach(([key, value]) => {
      if (value === '-select-') setValue(key, null);
      if (key === 'vehicleType' && value !== 'Other')
        setValue('vehicleTypeOther', null);
    });
    const values = getValues();
    const extraValues = {
      color: values.color,
      quantity: values.quantity,
    };
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_MAS_REQUIREMENT_SPECS,
      payload: getValues(),
    });
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_NON_SOP_DATA,
      payload: extraValues,
    });

    const updatedData = data.additionalRequirements.map((x) => ({
      attachments: x.attachments.map((attachment) => {
        if (!attachment) return null;
        const { id, ...rest } = attachment;
        return rest;
      }),
      description: x.name || 'MAS-Requisition',
    }));
    await dispatch({
      type: VehicleRequisitionContextActions.UPDATE_ADDITIONAL_REQUIREMENTS,
      payload: updatedData,
    });
    await dispatch({
      type: VehicleRequisitionContextActions.UPDATE_NON_SOP_DATA,
      payload: extraValues,
    });
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_MAS_REQ_ERROR_BANNER,
      payload: false,
    });
    handlePageNavigation('continue');
  };

  const validateNumber = (value) => {
    return (
      (value >= 1 && value <= 999) || 'Quantity can only be between 1 and 999'
    );
  };

  return (
    <FormProvider {...formProps}>
      <form
        data-testid="veh-spec-form"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        <div className="small-box-2">
          <Controller
            rules={{
              required: 'This is a required field',
              validate: validateNumber,
            }}
            name="quantity"
            render={({ field: { value, onChange, ref } }) => (
              <TextInput
                data-testid="veh-spec-quantity"
                label={
                  <>
                    Requested quantity <RequiredFieldIndicator />
                  </>
                }
                onChange={onChange}
                value={value || ''}
                inputRef={ref}
                name="driveTrain"
                errorMessage={errors?.quantity?.message}
              />
            )}
          />
        </div>
        {(colorsData?.length > 1 ||
          (colorsData?.length > 1 && nonSopData?.color)) && (
          <div
            className={
              errors?.color?.message && 'usa-form-group usa-form-group--error'
            }
          >
            <Label required>Color</Label>
            <div className="usa-error-message">{errors?.color?.message}</div>
            <Controller
              rules={{ required: 'This is a required field' }}
              name="color"
              render={({ field: { value, onChange } }) => (
                <ComboBox
                  data-testid="select-color"
                  key={nonSopData?.color}
                  id="color"
                  name="color"
                  value={value || ''}
                  options={colorsData}
                  className={errors?.color?.message && 'usa-input--error'}
                  defaultValue={nonSopData?.color || '-select-'}
                  onChange={onChange}
                />
              )}
            />
          </div>
        )}

        <div
          className="vehicle-req-main-container"
          data-testid="mas-vehicle-reqs"
        >
          <div>
            <h3>Provide vehicle requirements</h3>
          </div>
          <div className="vehicle-req-details-container">
            <h3>Requirements Checklist</h3>
            <div className="mas-options">
              {defaultVehicleReq.map((req) => (
                <li className="link-option">
                  {`${req.name} `}
                  {req.required && (
                    <span className="required">(required) </span>
                  )}
                  {![
                    'Other documentation',
                    'Request for Quote',
                    'Quotes Received',
                  ].includes(req.name) && (
                    <Button
                      data-testid={`${req.testId}-details`}
                      variant="unstyled"
                      onClick={() => {
                        setSelectedModal(req.name);
                        requirementsModal.openModal();
                      }}
                      label="view details"
                    />
                  )}
                </li>
              ))}
            </div>
          </div>
          <div className="top-padding-70">
            <UploadRequirements attachmentTypes={attachmentTypes} />
          </div>
          <div
            className={`${
              errors?.certifyCheckbox?.message &&
              'usa-form-group usa-form-group--error'
            }`}
          >
            <div className="error-text-desc" data-testid="veh-error-text">
              {errors?.certifyCheckbox?.message}
            </div>
            <Controller
              control={control}
              rules={{
                required:
                  'Please certify that you have uploaded all required documents ',
              }}
              name="certifyCheckbox"
              render={({ field: { value, onChange } }) => {
                return (
                  <Checkbox
                    className="other-docs-checkbox"
                    checked={value}
                    onChange={onChange}
                    label="I hereby certify that I have uploaded all required documents"
                  />
                );
              }}
            />
          </div>
          <FormButtons handlePageNavigation={handlePageNavigation} />
        </div>
      </form>

      <MasRequirementsModalModal
        isOpen={requirementsModal.isOpen}
        handleClose={requirementsModal.closeModal}
        selectedModal={selectedModal}
      />
    </FormProvider>
  );
};

VehicleRequirements.propTypes = {
  handlePageNavigation: PropTypes.func.isRequired,
};

export default VehicleRequirements;
