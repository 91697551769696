import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { RequisitionStatus } from './RequisitionDetailsUtils';
import ReturnOrderModal from './ReturnOrderModal';
import SubmitToEngineerButton from './SubmitToEngineerButton/SubmitToEngineerButton';
import SubmitToCOButton from './SubmitToEngineerButton/SubmitToCOButton';
import PlaceOrderModal from './PlaceOrderModal';
import CancelRequisitionButton from './CancelRequisitionButton/CancelRequisitionButton';
import { canApproveRequisitionCart } from '../../utilities/authorization';
import { STEPS } from '../VehicleRequisition/constants/VehicleRequisitionConstants';
import ErrorContext from '../../context/ErrorContext/ErrorContext';
import { StoreOperations, StoreSubjects } from '../../constants/constants';

const ApproverActionButtons = ({
  requisitionId,
  requisitionCartState,
  requisitionCartValidations,
  canSubmitOrder,
  currentStandardItem,
  isVendorDirect,
  isAreq = false,
}) => {
  const ability = useAppAbility();
  const canApproveReq = canApproveRequisitionCart(ability);
  // console.log('before useRequisitionReview');
  // const { isVendorDirect, vendorDirectLoaded } = useRequisitionReview();

  const [errorList, setErrorList] = useState([]);
  const { state: errorState } = useContext(ErrorContext);
  const { requisitionStatus, createdByUser } = requisitionCartState;
  const returnActionVisible =
    requisitionStatus !== RequisitionStatus.RECEIVED_BY_GSA;

  const {
    currentUser: { id: currentUserId },
  } = useCurrentUser();
  const isContractingOfficer = useMemo(
    () => ability?.can(StoreOperations.Create, StoreSubjects.Solicitation),
    [ability],
  );
  const isAdmin = useMemo(
    () => ability?.can(StoreOperations.Manage, StoreSubjects.All),
    [ability],
  );

  const currentUserCreatedRequisition = currentUserId === createdByUser;

  useEffect(() => {
    if (errorState[STEPS.AGENCY_INFORMATION]?.agencyInformation) {
      const boacErrors = [];
      errorState[STEPS.AGENCY_INFORMATION].agencyInformation.forEach(
        (value) => {
          boacErrors.push(<li>{value}</li>);
        },
      );

      setErrorList(boacErrors);
    }
  }, [errorState]);

  if (
    ![
      RequisitionStatus.DRAFT_APPROVAL,
      RequisitionStatus.PENDING_APPROVAL,
      RequisitionStatus.FINAL_APPROVAL,
    ].includes(requisitionStatus)
  ) {
    return null;
  }

  if ((isContractingOfficer && !isAdmin) || !canApproveReq) {
    return null;
  }

  return (
    <>
      <ReturnOrderModal
        requisitionId={requisitionId}
        requisitionName={requisitionCartState.friendlyName}
        requisitionCartValidations={requisitionCartValidations}
        canSubmitOrder={canSubmitOrder}
        visible={returnActionVisible}
        status={requisitionStatus}
        requisitionCartState={requisitionCartState}
        currentStandardItem={currentStandardItem}
        isVendorDirect={isVendorDirect}
        isAreq={isAreq}
      />
      {requisitionStatus === RequisitionStatus.DRAFT_APPROVAL &&
        isVendorDirect &&
        errorList.length === 0 && (
          <SubmitToEngineerButton
            requisitionId={requisitionId}
            requisitionName={requisitionCartState.friendlyName}
          />
        )}

      {requisitionStatus === RequisitionStatus.DRAFT_APPROVAL &&
        !isVendorDirect &&
        errorList.length === 0 && (
          <SubmitToCOButton
            requisitionId={requisitionId}
            requisitionName={requisitionCartState.friendlyName}
          />
        )}

      {requisitionStatus === RequisitionStatus.FINAL_APPROVAL &&
        errorList.length === 0 && (
          <>
            {currentUserCreatedRequisition && (
              <CancelRequisitionButton requisitionId={requisitionId} />
            )}

            <PlaceOrderModal
              requisitionId={requisitionId}
              requisitionName={requisitionCartState.friendlyName}
              requisitionCartValidations={requisitionCartValidations}
              canSubmitOrder={canSubmitOrder}
            />
          </>
        )}

      {requisitionStatus === RequisitionStatus.RECEIVED_BY_GSA &&
        currentUserCreatedRequisition &&
        errorList.length === 0 && (
          <CancelRequisitionButton requisitionId={requisitionId} />
        )}
    </>
  );
};

ApproverActionButtons.propTypes = {
  requisitionId: PropTypes.string.isRequired,
  requisitionCartState: PropTypes.instanceOf(Object).isRequired,
  requisitionCartValidations: PropTypes.instanceOf(Object).isRequired,
  canSubmitOrder: PropTypes.bool.isRequired,
  currentStandardItem: PropTypes.instanceOf(Object),
  isVendorDirect: PropTypes.bool.isRequired,
  isAreq: PropTypes.bool,
};

ApproverActionButtons.defaultProps = {
  currentStandardItem: undefined,
  isAreq: false,
};

export default ApproverActionButtons;
