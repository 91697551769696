import React, { useContext, useImperativeHandle } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import VendorAcceptance from '../VendorAcceptance/VendorAcceptance';
import './MultipleVendorsAcceptance.scss';
import { SUBMIT_ENGINEER_APPROVAL } from '../../services/data-layer';
import { RequisitionStatus } from '../../pages/ReviewDetails/RequisitionDetailsUtils';
import { getLowestPrice } from './vendorQuoteUtil';
import VehicleRequisitionContext from '../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';

export const CONTRACTING_APPROVAL = 'CONTRACTING_APPROVAL';

const MultipleVendorsAcceptance = ({
  vendorQuotes,
  reference,
  requisitionId,
  requisitionName,
  visible = true,
  isCo = false,
  status = '',
  setErrors = () => {},
  isReturned,
  requisitionCartState,
}) => {
  const { setSelectedVendorQuote, dispatch } = useContext(
    VehicleRequisitionContext,
  );

  const history = useHistory();
  const formProps = useForm({
    defaultValues: {
      vendorQuotes,
    },
    reValidateMode: 'onChange',
  });

  const { handleSubmit } = formProps;
  const [submitEngineerApproval] = useMutation(SUBMIT_ENGINEER_APPROVAL, {
    fetchPolicy: 'no-cache',
  });

  const onError = (errors) => {
    setErrors(errors.vendorQuotes.filter(Boolean));
  };

  const onSubmit = async (data) => {
    setErrors(null);
    const { vendorQuotes: vendorQuotesArr } = data;

    const vendorQuotesVar = vendorQuotesArr.map(
      ({ acceptable, comment }, index) => {
        return {
          vendorQuoteId: Number(vendorQuotes[index].requisitionVendorQuoteId),
          engineerComments: comment ?? '',
          isAcceptable: acceptable === 'yes',
        };
      },
    );

    const variables = {
      requisitionId,
      vendorQuotes: vendorQuotesVar,
    };
    await submitEngineerApproval({
      variables,
    });

    history.push(
      `/my-requisitions?requisitionId=${requisitionId}&reqName=${requisitionName}&reqStatus=${CONTRACTING_APPROVAL}`,
    );
  };

  useImperativeHandle(reference, () => ({
    ...reference.current,
    submitVendorAcceptanceForm() {
      handleSubmit(onSubmit, onError)();
    },
  }));

  const lowestPrice = getLowestPrice(vendorQuotes);

  if (!visible) {
    return null;
  }

  const handleSelectedVendor = (selectedVendor) => () => {
    setSelectedVendorQuote(selectedVendor);

    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_SELECTED_CONTRACT_INFO,
      selectedContract: selectedVendor,
      selectedContractCostBreakdown: selectedVendor,
    });
  };

  return (
    <div className="multiple-vendor-acceptance">
      <section className="vendor-quote-section-title">
        {isCo || status === RequisitionStatus.PENDING_CUSTOMER_RESPONSE || (
          <>
            <div className="quote-title">
              Review vendor bids, and provide technical acceptance for each
              vendor
            </div>
            <div className="title-desc">
              Evaluate each vendor bid as “acceptable” or “unacceptable” and
              provide additional comments. “Unacceptable” evaluations require an
              explanation.{' '}
            </div>
          </>
        )}

        <FormProvider {...formProps}>
          <form
            data-testid="vendor-quote-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div role="tab" tabIndex="0" className="vendor-quotes">
              {vendorQuotes &&
                vendorQuotes.map((vendorQuote, index) => {
                  return vendorQuote.errorMsg ? (
                    <div className="error-msg">{vendorQuote.errorMsg}</div>
                  ) : (
                    <VendorAcceptance
                      vendorQuote={vendorQuote}
                      index={index}
                      key={vendorQuote.requisitionVendorQuoteId}
                      status={status}
                      lowestPrice={lowestPrice}
                      onVendorSelect={handleSelectedVendor(vendorQuote)}
                      isReturnedAndCo={isCo && isReturned}
                      requisitionCartState={requisitionCartState}
                    />
                  );
                })}
            </div>
          </form>
        </FormProvider>
      </section>
    </div>
  );
};

MultipleVendorsAcceptance.propTypes = {
  vendorQuotes: PropTypes.arrayOf(PropTypes.instanceOf(Object)).isRequired,
  reference: PropTypes.instanceOf(Object).isRequired,
  requisitionId: PropTypes.string.isRequired,
  requisitionName: PropTypes.string.isRequired,
  status: PropTypes.string,
  visible: PropTypes.bool,
  isCo: PropTypes.bool,
  setErrors: PropTypes.func,
  activeContracts: PropTypes.instanceOf(Array),
  allModelCostBreakDown: PropTypes.instanceOf(Array),
  isReturned: PropTypes.bool,
  requisitionCartState: PropTypes.instanceOf(Object).isRequired,
};

MultipleVendorsAcceptance.defaultProps = {
  status: '',
  visible: true,
  isCo: false,
  setErrors: () => {},
  activeContracts: [],
  allModelCostBreakDown: [],
  isReturned: false,
};

export default MultipleVendorsAcceptance;
