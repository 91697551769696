import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import fileDownload from 'js-file-download';
import { Button, Spinner } from '@gsa/afp-component-library';

import {
  GENERATE_READ_SIGNED_URL,
  GET_DOC_METADATA,
  GET_MVDO_ATTACHMENT,
} from '../../../../services/data-layer';

export default function MVDOAttachmentDownload({ displayName, orderId }) {
  const [docMimeType, setDocMimeType] = useState('');
  const [fileName, setFileName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [getGeneratedSignedUrl] = useLazyQuery(GENERATE_READ_SIGNED_URL, {
    fetchPolicy: 'no-cache',
    onCompleted: async (data) => {
      if (!data.generateReadSignedURL) {
        throw new Error('generateReadSignedURL is not defined');
      }
      const maxTries = 15;
      const delayTime = 5; // seconds
      let tryCount = 0;
      let gotData = false;
      const fetchData = async () => {
        if(gotData) return true;
        let response;
        try {  
          response = await fetch(data.generateReadSignedURL, {
            method: 'GET',
            headers: { 'Content-Type': docMimeType },
          });
          if (response.status === 200) {
            const blob = await response.blob();
            fileDownload(blob, fileName);
            setIsLoading(false);
            return true;
          }
          console.info(`Error downloading the file, try#${tryCount + 1}`);
          tryCount += 1;
        } catch (error) {
          console.error('An error occurred while trying downlod:', error);
        }
        return false;
      };
  
      const retryFetch = async () => {
        if (tryCount < maxTries) {
          gotData = await fetchData();
          if(!gotData) setTimeout(retryFetch, delayTime * 1000);
        }
        else {
          console.error('Maximum number of tries exceeded while downloading.');
          setIsLoading(false);
        }
      };
  
      retryFetch();
    },
    onError: (error) => {
      setIsLoading(false);
      console.error('An error occurred:', error);
    }
  });
  

  const [getDocMetaData] = useLazyQuery(GET_DOC_METADATA, {
    fetchPolicy: 'no-cache',
    onCompleted: async (data) => {
      setDocMimeType(data?.getDocMetadata?.fileMimeType);
      setFileName(data?.getDocMetadata?.name);
      await getGeneratedSignedUrl({
        variables: {
          fileType: data?.getDocMetadata?.fileMimeType,
          fileKey: data?.getDocMetadata?.fileLocation,
        },
      });
    },
    onError: (error) => {
      setIsLoading(false);
      console.error('An error occurred:', error);
    }
  });

  const [getMVDOAttachment] = useLazyQuery(GET_MVDO_ATTACHMENT, {
    fetchPolicy: 'no-cache',
    onCompleted: async (data) => {
      await getDocMetaData({
        variables: {
          id: data.getMVDOAttachment,
        },
      });
    },
    onError: (error) => {
      setIsLoading(false);
      console.error('An error occurred:', error);
    }
  });

  const download = async () => {
    setIsLoading(true);
    await getMVDOAttachment({
      variables: {
        orderId,
      },
    });
  };

  if (!orderId) {
    return <>Invalid Order!</>;
  }

  return (
    <>
      {isLoading && <Spinner size="small" className="margin-y-8" />}
      {!isLoading && (
        <Button
          data-testid="download-button"
          variant="unstyled"
          onClick={() => download()}
          label={displayName}
        />
      )}
    </>
  );  
}

MVDOAttachmentDownload.propTypes = {
  displayName: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
};
