import React, { useState, useContext, useEffect } from 'react';
import { Button, Modal, TextInput } from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { GiCheckMark } from 'react-icons/gi';

import { CREATE_REQUISITION_DRAFT_NEW } from '../../services/create-requisition';

import './SaveDraftRequisition.scss';
import VehicleRequisitionContext from '../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import { STEPS } from '../../pages/VehicleRequisition/constants/VehicleRequisitionConstants';

const SaveDraftRequisition = ({
  sin,
  saveDraftSuccess,
  onConfirm,
  onCancel,
  isForUnsaveChanges = false,
  forceSaveDraft = false,
  setForceSaveDraft,
  isFromUrgReq = false,
}) => {
  const [showSaveDraftModel, setShowDraftModel] = useState(false);
  const [draftNameHasError, setDraftNameHasError] = useState(false);
  const [draftNameErrorText, setDraftNameErrorText] = useState('');
  const [draftFriendlyName, setDraftFriendlyName] = useState('');

  const { state, dispatch, getattachmentTypes } = useContext(
    VehicleRequisitionContext,
  );
  const {
    vehicleColors,
    draftRequisition,
    vehicleQuantity,
    selectedContract,
    selectedOptionsForPrice,
    nonLowBidJustification,
    currentStep,
    allActiveContracts,
    selectedContractAgencyInformation,
    requisitionStateContext,
    mailingStateContext,
    deliveryStateContext,
    dealershipDeliveryContext,
    quantityHasError,
    formErrorFocus,
    submitComment,
    paintAndGraphicsOptions,
    fileUpload,
    totalUploadedFiles,
    taggedOptions,
    engineerTaggedOptions,
    isDomesticShipment,
    selectedContractCostBreakdown,
    agencyReferenceNumber,
    urgentRequirementCurrentStep,
    urgentReqStepsProcessIndicator,
    urgentReqJustification,
    areqJustification,
    masRequirementCurrentStep,
    masReqStepsProcessIndicator,
    requisitionType,
  } = state;
  const showDraftRequisitionModal = () => {
    if (quantityHasError) {
      if (formErrorFocus?.vehicleQuantity) {
        formErrorFocus.vehicleQuantity.current?.focus();
      }
      return;
    }
    const date = new Date();
    const month = date.toLocaleString('default', { month: 'long' });
    const defaultFriendlyName = `${sin}_${month}${date.getDate()}_${date
      .toLocaleTimeString([], {
        hourCycle: 'h23',
        hour: '2-digit',
        minute: '2-digit',
      })
      .replace(':', '')}`;
    setDraftFriendlyName(defaultFriendlyName);
    setDraftNameHasError(false);
    setDraftNameErrorText('');
    setShowDraftModel(true);
  };

  useEffect(() => {
    if (forceSaveDraft) {
      showDraftRequisitionModal();
    }
  }, [forceSaveDraft]);

  const currentStandardItem = JSON.parse(
    sessionStorage.getItem('standardItem'),
  );
  const [createRequisitionDraft, { loading }] = useMutation(
    CREATE_REQUISITION_DRAFT_NEW,
  );

  const getRequisitionAddress = () => {
    const {
      firstName,
      lastName,
      countryCode,
      entityName,
      addressLine1,
      addressLine2,
      city,
      stateCode,
      zipcode,
      zipcodePlusFour,
      email,
      phoneCountryCode,
      phoneNumber,
      phoneExtension,
      faxNumber,
      faxExtension,
      faxCountryCode,
      isValidated,
      isMilitary,
      rankAndFullName,
      militaryOrDiplomaticAddress,
      postOffice,
    } = requisitionStateContext;

    const address = {
      countryCode,
      entityName: isMilitary ? rankAndFullName : entityName,
      stateCode,
      addressLine1: isMilitary ? militaryOrDiplomaticAddress : addressLine1,
      addressLine2: isMilitary ? postOffice : addressLine2,
      zipcode,
      zipcodePlusFour,
      city,
      isUspsVerified: isValidated,
      isMilitary,
    };
    const contact = {
      firstName,
      lastName,
      email,
      faxCountryCode,
      faxNumber,
      faxExtension,
      phoneCountryCode,
      phoneNumber,
      phoneExtension,
    };

    return { address, contact };
  };

  const getMailingAddress = () => {
    const {
      firstName,
      lastName,
      countryCode,
      entityName,
      addressLine1,
      addressLine2,
      city,
      stateCode,
      zipcode,
      zipcodePlusFour,
      email,
      phoneCountryCode,
      phoneNumber,
      phoneExtension,
      faxNumber,
      faxExtension,
      faxCountryCode,
      isValidated,
      isMilitary,
      rankAndFullName,
      militaryOrDiplomaticAddress,
      postOffice,
    } = mailingStateContext;

    const address = {
      countryCode,
      entityName: isMilitary ? rankAndFullName : entityName,
      stateCode,
      addressLine1: isMilitary ? militaryOrDiplomaticAddress : addressLine1,
      addressLine2: isMilitary ? postOffice : addressLine2,
      zipcode,
      zipcodePlusFour,
      city,

      isUspsVerified: isValidated,
      isMilitary,
    };
    const contact = {
      firstName,
      lastName,
      email,
      faxCountryCode,
      faxNumber,
      faxExtension,
      phoneCountryCode,
      phoneNumber,
      phoneExtension,
    };

    return { address, contact };
  };

  const getDeliveryAddress = () => {
    const {
      firstName,
      lastName,
      countryCode,
      entityName,
      addressLine1,
      addressLine2,
      city,
      stateCode,
      zipcode,
      zipcodePlusFour,
      email,
      phoneCountryCode,
      phoneNumber,
      phoneExtension,
      faxNumber,
      faxExtension,
      faxCountryCode,
      isValidated,
      isMilitary,
      rankAndFullName,
      isDealershipDelivery,
      dealerId,
    } = deliveryStateContext;

    console.log('isDealershipDelivery', isDealershipDelivery, dealerId);

    const address = {
      countryCode,
      entityName: isMilitary ? rankAndFullName : entityName,
      stateCode,
      addressLine1,
      addressLine2,
      zipcode,
      zipcodePlusFour,
      city,
      isDealershipDelivery,
      dealerId: dealershipDeliveryContext?.id,
      isUspsVerified: isValidated,
      isMilitary,
      deliveryDealership: dealershipDeliveryContext,
    };
    const contact = {
      firstName,
      lastName,
      email,
      faxCountryCode,
      faxNumber,
      faxExtension,
      phoneCountryCode,
      phoneNumber,
      phoneExtension,
    };

    return { address, contact };
  };

  const saveDraft = async () => {
    console.log(loading);
    if (loading) return;
    if (draftNameHasError || quantityHasError) {
      return;
    }
    const selectedColorsForDraft = vehicleColors.map(
      ({ color, quantity: colorQuantity }) => {
        return {
          makeColorCode: color.value,
          makeColorName: color.label,
          quantity: Number(colorQuantity),
        };
      },
    );

    let baseShipmentDays = 0;
    let additionalShipmentDays = 0;

    if (selectedContractCostBreakdown) {
      baseShipmentDays = selectedContractCostBreakdown.baseShippingDays;
      additionalShipmentDays =
        selectedContractCostBreakdown.additionalShippingDays
          ?.additionalShippingDays;
    }

    const createInput = {
      friendlyName: draftFriendlyName,
      requisitionType,
      agencyCode: sessionStorage.getItem('agencyCode'),
      bureauCode: sessionStorage.getItem('bureauCode'),
      officeCode: sessionStorage.getItem('officeCode'),
      transactionType: 'VEHICLE_SALE',
    };

    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      phoneCountryCallingCode,
      phoneExtension,
      faxCountryCallingCode,
      faxNumber,
      faxExtension,
    } = selectedContractAgencyInformation;

    // if (firstName || lastName || email || phoneNumber) {
    const contactForDraft = {
      firstName,
      lastName,
      email,
      phoneCountryCode: phoneCountryCallingCode,
      phoneNumber,
      phoneExtension,
      faxCountryCode: faxCountryCallingCode,
      faxNumber,
      faxExtension,
    };

    const receivingAgency = sessionStorage.getItem('receivingAgency');
    const receivingBureau = sessionStorage.getItem('receivingBureau');
    const receivingOffice = sessionStorage.getItem('receivingOffice');

    let requisitionExtendedProps = {
      purchasingForAgencyCode:
        receivingAgency?.length > 0
          ? receivingAgency.substring(0, receivingAgency.indexOf('-'))
          : '',
      purchasingForBureauCode:
        receivingBureau?.length > 0
          ? receivingBureau.substring(0, receivingBureau.indexOf('-'))
          : '',
      purchasingForOfficeCode:
        receivingOffice?.length > 0
          ? receivingOffice.substring(0, receivingOffice.indexOf('-'))
          : '',
      agencyReferenceNumber,
      customerAssignedNumber:
        selectedContractAgencyInformation?.agencyOrderNumber,
      boac: selectedContractAgencyInformation.requisitionBOAC,
      justification: nonLowBidJustification,
      julianDate: selectedContractAgencyInformation.requisitionJulian,
      serialNumber: selectedContractAgencyInformation.requisitionSerialNumber,
      treasuryAccountSymbol:
        selectedContractAgencyInformation.treasuryAccountSymbol,
      additionalShipmentDays,
      baseShipmentDays,
      requisitionerAddress: getRequisitionAddress().address,
      requisitionerContact: getRequisitionAddress().contact,
      mailingAddress: mailingStateContext.isMailingSameAsRequisition
        ? getRequisitionAddress().address
        : getMailingAddress().address,
      mailingContact: mailingStateContext.isMailingSameAsRequisition
        ? getRequisitionAddress().contact
        : getMailingAddress().contact,
      deliveryAddress: deliveryStateContext.isDeliverySameAsRequisition
        ? getRequisitionAddress().address
        : getDeliveryAddress().address,
      deliveryDealershipCode: dealershipDeliveryContext?.dealershipDeliveryCode,
      deliveryContact: deliveryStateContext.isDeliverySameAsRequisition
        ? getRequisitionAddress().contact
        : getDeliveryAddress().contact,

      contractLineId: selectedContract
        ? parseInt(selectedContract?.contractLineId, 10)
        : null,
      quantity: vehicleQuantity ? Number(vehicleQuantity) : null,
      agencyContact: contactForDraft,
      finSignalCode: selectedContractAgencyInformation.signalCode,
      finFundCode: selectedContractAgencyInformation.fundCode,
      finServiceCode: selectedContractAgencyInformation.serviceCode,
      finSupplementaryAddress:
        selectedContractAgencyInformation.signalSupplementaryAddress,
      markForInformation: selectedContractAgencyInformation.markForInformation,
      nationalStockNumber:
        selectedContractAgencyInformation.nationalStockNumber,
      registrationNumber: selectedContractAgencyInformation.registrationNumber,
      accountingClassificationReferenceNumber:
        selectedContractAgencyInformation.accountingClassificationReferenceNumber,
      transportationControlNumber:
        selectedContractAgencyInformation.transportationControlNumber,
      finAdditionalInfo: selectedContractAgencyInformation.agencyFinancialData,
      makeCode: selectedContract?.makeCode && Number(selectedContract.makeCode),
      modelCode: selectedContract?.modelCode,
      modelYear:
        selectedContract?.modelYear && Number(selectedContract.modelYear),
      isOverseasDeliveryOptionSelected: selectedOptionsForPrice.some(
        (opt) => opt.optionCode === '1611',
      ),
    };

    if (currentStandardItem) {
      requisitionExtendedProps = {
        ...requisitionExtendedProps,
        standardItemId: parseInt(currentStandardItem.id, 10),
        standardItemCode: currentStandardItem.standardItemNumber,
        vehicleTypeCode: currentStandardItem.vehicleTypeCode?.parentCode,
      };
    }

    const clientData = {
      selectedOptions: {
        selectedOptions: selectedOptionsForPrice,
        supportingDocuments: totalUploadedFiles,
        customerInputs: {
          paintAndGraphicsOptions,
          taggedOptions,
          engineerTaggedOptions,
        },
      },
      clientState: {
        submitComment,
        currentStep,
        urgentRequirement: {
          urgentReqStepsProcessIndicator,
          urgentRequirementCurrentStep,
          urgentReqJustification,
        },
        areq: {
          areqJustification,
        },
        masRequirement: {
          masReqStepsProcessIndicator,
          masRequirementCurrentStep,
        },
        lowestBidContract: {
          contractModification:
            allActiveContracts && allActiveContracts[0].contractModification,
          contractNumber:
            allActiveContracts && allActiveContracts[0].contractNumber,
          scheduleLine:
            allActiveContracts && allActiveContracts[0].scheduleLine,
          contractLineId:
            allActiveContracts && allActiveContracts[0].contractLineId,
        },
        isDomesticShipment,
        shipmentLocation: sessionStorage.getItem('shipmentLocation'),
        selectedState: sessionStorage.getItem('selectedState'),
        validatedTas: selectedContractAgencyInformation?.treasuryAccountSymbol,
      },
    };
    const { fuelCodeLabel, altFuelCodeLabel, ...fuelInfo } =
      selectedContract?.fuelInfo || {};
    const updateInput = {
      extendedProps: requisitionExtendedProps,
      vehicle: {
        ...selectedColorsForDraft[0],
        ...fuelInfo,
      },
      clientData,
    };

    // DEBUGGING - do not remove
    // console.log(JSON.stringify({ draftObject }));
    try {
      const { data } = await createRequisitionDraft({
        variables: { createInput, updateInput },
      });
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_DRAFT_REQUISITION,
        payload: data.createRequisitionDraft,
      });
      saveDraftSuccess(data.createRequisitionDraft.requisitionId);
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_FILE_UPLOAD_SECTION,
        payload: false,
      });
      setShowDraftModel(false);
      // console.log(`forceSaveDraft`, forceSaveDraft);
      getattachmentTypes();
      if (forceSaveDraft) {
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_CURRENT_REQUISITION_STEP,
          payload: {
            key: STEPS.AREQ_INFORMATION,
            current: 2,
            text: 'Additional requirements',
          },
        });
      }
    } catch (err) {
      console.log(err);
      setDraftNameErrorText(err.message);
    }
  };

  const updateDraftName = (name) => {
    if (name.length > 50) {
      setDraftNameHasError(true);
      setDraftNameErrorText('This name exceeds the 50 character limit.');
    } else if (name.length === 0) {
      setDraftNameHasError(true);
      setDraftNameErrorText('Please enter a name for your draft');
    } else {
      setDraftNameHasError(false);
      setDraftNameErrorText('');
    }
    setDraftFriendlyName(name);
  };

  useEffect(() => {
    if (fileUpload) {
      showDraftRequisitionModal();
    }
  }, [fileUpload]);

  useEffect(() => {
    if (isForUnsaveChanges) {
      showDraftRequisitionModal();
    }
  }, []);

  const checkTitle = () => {
    if (isForUnsaveChanges) {
      return <h1 className="modal-title">You have unsaved selections...</h1>;
    }
    if (fileUpload) {
      return (
        <h1 className="modal-title">
          Before uploading documents, you will first need to save your
          requisition as a draft.
        </h1>
      );
    }
    if (isFromUrgReq) {
      return (
        <h1 className="modal-title">
          Please save your requisition as a draft before continuing with your
          urgent requirement.
        </h1>
      );
    }
    return <h1 className="modal-title">Save your draft requisition</h1>;
  };

  return (
    <>
      {!draftRequisition && !isFromUrgReq && (
        <Button
          data-testid="save-draft-requisition-button"
          className="save-draft-requisition-button"
          type="button"
          variant="outline"
          label="Save draft"
          onClick={() => showDraftRequisitionModal()}
        />
      )}
      {draftRequisition?.requisitionId && !isFromUrgReq && (
        <div>
          Your edits are saved on clicking next button on each step
          {/* Your latest edits are{' '} */}
          <span className="save-draft-requisition-post-save">
            <GiCheckMark />
          </span>
        </div>
      )}

      {showSaveDraftModel && (
        <div className="afp-modal-overlay modalContainer save-draft-model">
          <Modal
            title={checkTitle()}
            className="save-draft-modal"
            onClose={() => {
              if (isForUnsaveChanges || isFromUrgReq) {
                onCancel();
              }
              setDraftNameHasError(false);
              setDraftNameErrorText('');
              setShowDraftModel(false);
              dispatch({
                type: VehicleRequisitionContextActions.UPDATE_FILE_UPLOAD_SECTION,
                payload: false,
              });
            }}
            actions={
              <div className="save-draft-requisition-button-row">
                <Button
                  type="button"
                  variant="unstyled"
                  className="save-draft-requisition-action-button"
                  data-testid="save-draft-modal-cancel-button"
                  onClick={() => {
                    if (isForUnsaveChanges) {
                      onConfirm();
                    }
                    setShowDraftModel(false);
                    setForceSaveDraft(false);
                    dispatch({
                      type: VehicleRequisitionContextActions.UPDATE_FILE_UPLOAD_SECTION,
                      payload: false,
                    });
                  }}
                  label={isForUnsaveChanges ? 'No thanks' : 'Cancel'}
                />
                <Button
                  type="button"
                  variant="default"
                  className="save-draft-requisition-action-button"
                  data-testid="save-draft-modal-save-button"
                  disable={loading}
                  onClick={() => {
                    saveDraft();
                  }}
                  label={
                    fileUpload || isFromUrgReq
                      ? 'Save requisition draft'
                      : 'Save'
                  }
                />
              </div>
            }
          >
            {isForUnsaveChanges ? (
              <div>
                <h2 className="unsave-changes-modal-label">
                  Would you like to save a requisition draft before leaving?
                </h2>
                <p className="modal-info-desc">
                  When you return, your inputs will be captured and autosaved
                  until you submit your order.
                </p>
              </div>
            ) : (
              <p>
                Upon creating your draft, all inputs will be captured and
                autosaved until you submit your order.
              </p>
            )}

            <form className="save-draft-form">
              <TextInput
                label="Enter the preferred name of your draft"
                onChange={(event) => updateDraftName(event.target.value)}
                value={draftFriendlyName}
                errorMessage={draftNameErrorText}
                data-testid="save-draft-name-input"
              />
            </form>
            <span>50 characters allowed</span>
          </Modal>
        </div>
      )}
    </>
  );
};

SaveDraftRequisition.propTypes = {
  sin: PropTypes.string.isRequired,
  saveDraftSuccess: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isForUnsaveChanges: PropTypes.bool,
  forceSaveDraft: PropTypes.bool,
  setForceSaveDraft: PropTypes.func,
  isFromUrgReq: PropTypes.bool,
};

SaveDraftRequisition.defaultProps = {
  onConfirm: () => {},
  onCancel: () => {},
  isForUnsaveChanges: false,
  forceSaveDraft: false,
  setForceSaveDraft: () => {},
  isFromUrgReq: false,
};

export default SaveDraftRequisition;
