import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './VehicleCard.scss';
import { GiCheckMark } from 'react-icons/gi';
import { Modal, Button } from '@gsa/afp-component-library';
import { PhotosModalComp } from '../PhotosModal/PhotosModalComp';
import ViewImageSvg from '../../assets/images/view-image.svg';
import DefaultImageSvg from '../../assets/images/default-image.svg';

// NOTE: Local test data
// const imageData = [
//   {
//     sequenceNumber: 1,
//     clAttachmentMetadata: {
//       docStoreUri: 'https://www.gstatic.com/webp/gallery',
//       name: 'a1.webp',
//     },
//   },
//   // 404
//   // {
//   //   sequenceNumber: 1,
//   //   clAttachmentMetadata: {
//   //     docStoreUri: 'https://www.gstatic.com/webp/gallery',
//   //     name: 'a1.webp',
//   //   },
//   // },
//   {
//     sequenceNumber: 2,
//     clAttachmentMetadata: {
//       docStoreUri: 'https://www.gstatic.com/webp/gallery',
//       name: '2.webp',
//     },
//   },
//   {
//     sequenceNumber: 3,
//     clAttachmentMetadata: {
//       docStoreUri: 'https://www.gstatic.com/webp/gallery',
//       name: '3.webp',
//     },
//   },
// ];

const setImageFromMetadata = (imageMetadata) => {
  if (!imageMetadata) {
    return '';
  }

  return `${imageMetadata?.clAttachmentMetadata?.docStoreUri}/${imageMetadata?.clAttachmentMetadata?.name}`;
};

export const VehicleCard = (props) => {
  const {
    vendorName,
    modelName,
    isActive,
    imageData = [],
    contractLineId,
  } = props;
  const [previewClicked, setPreviewClicked] = useState(false);
  const [heroicImageState, setHeroicImageState] = useState();
  const [modalImages, setModalImages] = useState([]);

  // NOTE: Debugging purposes
  // console.log({ imageData });

  const activateLowestBid = isActive ? 'lowest-bid' : '';

  const handleImageError = (image) => {
    console.error('error loading image --', image);
    setHeroicImageState('');
  };

  useEffect(() => {
    if (previewClicked) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [previewClicked]);

  useEffect(() => {
    if (!imageData?.length) {
      return;
    }

    const images = imageData.sort(
      (a, b) => a.sequenceNumber - b.sequenceNumber,
    );

    const heroicImage = images.find((item) => {
      return item.sequenceNumber === 1;
    });

    setHeroicImageState(setImageFromMetadata(heroicImage || images[0]));

    setModalImages(images.map(setImageFromMetadata));
  }, [imageData]);

  const handlePreviewModal = () => {
    setPreviewClicked(true);
  };

  return (
    <div
      className="usa-card vehicle-card"
      data-testid={`${contractLineId}-vehicle-header-card`}
    >
      <div className="vehicle-card-container">
        <div
          data-testid={`${contractLineId}-vehicle-header-vendor-name`}
          className="vendor-label-container"
        >
          <span className="vendor-name-short">{vendorName}</span>
          <div className="vendor-name-tooltip">{vendorName}</div>
        </div>
        <div className="image-holding-container">
          <div className="image-section">
            {activateLowestBid && (
              <div
                data-testid="low-price-check"
                className="lowest-price-section"
              >
                <div className="lowest-active-top">
                  <GiCheckMark role="img" aria-label="Low Price" />
                  LOW PRICE
                </div>
                <div className="triangle-right" />
              </div>
            )}
            {heroicImageState ? (
              <img
                className="heroic-image-exist"
                src={heroicImageState}
                alt={modelName}
                onError={() => handleImageError(heroicImageState)}
              />
            ) : (
              <img
                className="heroic-image-default"
                src={DefaultImageSvg}
                alt={modelName}
              />
            )}

            <div
              data-testid={`${contractLineId}-vehicle-header-model-name`}
              className="model-name-section"
            >
              {modelName}
            </div>
          </div>

          {Boolean(modalImages?.length) && (
            <div
              data-testid={`${contractLineId}-vehicle-header-image-hover`}
              className="image-hover-section"
              onClick={handlePreviewModal}
              onKeyDown={() => {
                // do nothing
              }}
              role="button"
              tabIndex="0"
            >
              <div className="preview-details-container">
                <img
                  data-testid={`${contractLineId}-vehicle-header-image`}
                  className="preview-icon"
                  src={ViewImageSvg}
                  alt="ViewImageSvg"
                />
                <div className="preview-image-label">VIEW PHOTOS</div>
              </div>
            </div>
          )}
        </div>
      </div>

      {previewClicked && (
        <div className="afp-modal-overlay modalContainer photos-modal-wrapper">
          <Modal
            title={
              <div className="modal-title">
                <span className="model-name">{modelName}</span>
                <span className="vendor-name">{vendorName}</span>
              </div>
            }
            onClose={() => {
              setPreviewClicked(false);
            }}
            className="photos-preview-modal"
            actions={
              <>
                <div className="modal-close-button">
                  <Button
                    type="button"
                    className="modal-close-button"
                    onClick={() => {
                      setPreviewClicked(false);
                    }}
                    label="CLOSE"
                  />
                </div>
              </>
            }
          >
            <PhotosModalComp imageData={modalImages} />
          </Modal>
        </div>
      )}
    </div>
  );
};

VehicleCard.propTypes = {
  vendorName: PropTypes.string,
  modelName: PropTypes.string,
  isActive: PropTypes.bool,
  imageData: PropTypes.instanceOf(Array),
  contractLineId: PropTypes.string.isRequired,
};

VehicleCard.defaultProps = {
  vendorName: '',
  modelName: '',
  isActive: '',
  imageData: [],
};

export default VehicleCard;
