import React, { useEffect, useState } from 'react';
import { Alert, Button, Spinner } from '@gsa/afp-component-library';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import classNames from 'classnames';
import './OrderMessageLines.scss';
import {
  GET_ORDER_MESSAGE_DETAILS,
  UPDATE_ORDER_MESSAGES,
} from '../../../services/data-layer';
import OrderMessageLinesDisplay from './OrderMessageLinesDisplay';
import OrderMessageLinesForm from './OrderMessageLinesForm';

const OrderMessageLines = ({ orderId, isEditAllowed }) => {
  const [currentMode, setCurrentMode] = useState('view');
  const [messageLines, setMessageLines] = useState(null);
  const [updatedSuccessfully, setUpdatedSuccessfully] = useState(false);

  const {
    loading,
    error,
    data: getOrderDetailsData,
    refetch,
  } = useQuery(GET_ORDER_MESSAGE_DETAILS, {
    variables: { orderId },
    fetchPolicy: 'network-first',
  });

  const [submitOrderMessages] = useMutation(UPDATE_ORDER_MESSAGES);

  const formProps = useForm({
    defaultValues: {
      message1: '',
      message2: '',
      message3: '',
      message4: '',
      message5: '',
    },
    reValidateMode: 'onChange',
  });

  const { setValue, getValues } = formProps;

  const handleRMLFormSubmit = async () => {
    const data = {
      orderId,
      messages: {
        message1: getValues('message1'),
        message2: getValues('message2'),
        message3: getValues('message3'),
        message4: getValues('message4'),
        message5: getValues('message5'),
      },
    };

    await submitOrderMessages({ variables: { ...data } }); // Upsert message lines
    setMessageLines({ ...data.messages });
    setCurrentMode('view');
    setUpdatedSuccessfully(true);
  };

  useEffect(() => {
    if (!getOrderDetailsData?.getOrderById) return;
    const { message1, message2, message3, message4, message5 } =
      getOrderDetailsData.getOrderById;

    setValue('message1', message1);
    setValue('message2', message2);
    setValue('message3', message3);
    setValue('message4', message4);
    setValue('message5', message5);
    setMessageLines({
      message1,
      message2,
      message3,
      message4,
      message5,
    });

    refetch();
  }, [getOrderDetailsData?.getOrderById]);

  return (
    <section role="tab" tabIndex="0" className="margin-bottom-2 margin-top-1">
      <div>
        <div>
          <p className="font-body-sm" />
          {updatedSuccessfully && (
            <Alert type="success" onClose={() => setUpdatedSuccessfully(false)}>
              Comments saved successfully
            </Alert>
          )}
        </div>

        <div>
          <div
            className={classNames('padding-bottom-2 padding-top-05 padding-2', {
              'bg-primary-lightest': currentMode === 'edit',
              'bg-gray-3': currentMode === 'view',
            })}
          >
            <div className="margin-bottom-2 grid-row">
              <div className="grid-col font-body-lg text-bold">
                Order comments
              </div>
              {isEditAllowed && (
                <div className="grid-col">
                  {currentMode === 'edit' && (
                    <Button
                      variant="unstyled"
                      type="reset"
                      className="margin-top-1 float-right "
                      onClick={() => setCurrentMode('view')}
                      label="Cancel"
                    />
                  )}

                  {currentMode === 'edit' && (
                    <Button
                      variant="outline"
                      className="margin-top-1 float-right"
                      onClick={handleRMLFormSubmit}
                      leftIcon={{ name: 'edit' }}
                      label="Save"
                    />
                  )}

                  {currentMode === 'view' && (
                    <Button
                      variant="outline"
                      className="margin-top-1 float-right"
                      onClick={() => setCurrentMode('edit')}
                      leftIcon={{ name: 'edit' }}
                      label="Update comments"
                    />
                  )}
                </div>
              )}
            </div>
            {error && (
              <>An error occurred while loading order message details</>
            )}
            {loading ? (
              <Spinner size="large" className="margin-y-8" />
            ) : (
              <>
                {currentMode === 'edit' && (
                  <div className="margin-bottom-2 grid-row">
                    <div className="grid-col font-body-md text-bold">
                      Enter comments and click &quot;Save&quot; to update
                    </div>
                  </div>
                )}
                {currentMode === 'view' && (
                  <OrderMessageLinesDisplay messageLines={messageLines} />
                )}
                {currentMode === 'edit' && (
                  <FormProvider {...formProps}>
                    <form data-testid="message-lines-form" className="">
                      <OrderMessageLinesForm />
                    </form>
                  </FormProvider>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

OrderMessageLines.propTypes = {
  orderId: PropTypes.string.isRequired,
  isEditAllowed: PropTypes.bool,
};

OrderMessageLines.defaultProps = {
  isEditAllowed: false,
};

export default OrderMessageLines;
