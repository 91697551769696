import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Accordion } from '@gsa/afp-component-library';
import SimpleBar from 'simplebar';
import cx from 'classnames';
import { InfoIcon } from '../../assets/images/info-icon';
import {
  formatCurrency,
  cbRequiredOptionsSimplified,
} from '../../utilities/CompareVehicleUtils';
import './CostBreakdownSection.scss';

const getFormattedValue = (optionTypeCode, optionValue) => {
  switch (optionTypeCode) {
    case 'STANDARD':
      return 'STANDARD';
    case 'EXCLUDED':
      return 'EXCLUDED';
    case 'INCLUDED':
      return 'INCLUDED';
    case 'COST':
      return formatCurrency(optionValue || 0);
    default:
      return 'N/A';
  }
};

const CostBreakdownContent = ({ lowestBidData, networkCostBreakDownData }) => {
  const [firstContract] = networkCostBreakDownData;
  const [finalModifiedData, setFinalModifiedData] = useState([]);
  const finalData = [];

  useEffect(() => {
    if (networkCostBreakDownData) {
      const { returnedSimplifiedData, vendorPairs } =
        cbRequiredOptionsSimplified(networkCostBreakDownData);

      if (returnedSimplifiedData && vendorPairs) {
        returnedSimplifiedData.forEach((item, i) => {
          finalData.push({
            optionCode: item.optionCode,
            optionDescription: item.optionDescription,
            vendorValues: vendorPairs[i],
          });
        });
      }
      setFinalModifiedData(finalData);
    }
  }, [networkCostBreakDownData]);
  return (
    <>
      <tr>
        <td
          data-testid="cost-breakdown-base-price-label"
          className="cost-breakdown-description"
        >
          Base Price per Vehicle
        </td>
        {networkCostBreakDownData.map((cb) => {
          const activateLowestBid =
            cb.contractLineId === lowestBidData.contractLineId;
          return (
            <td
              data-testid={`cost-breakdown-base-${cb.contractLineId}`}
              key={`cost-breakdown-base-${cb.contractLineId}`}
              className={cx('option-cell', {
                'lowest-bid': activateLowestBid,
              })}
            >
              <label className="option-label">
                {formatCurrency(cb.basePrice)}
              </label>
            </td>
          );
        })}
      </tr>
      {firstContract && firstContract?.perVehicleOptions?.length ? (
        <tr data-testid="cost-breakdown-vehicle-options-label">
          <td className="veh-option-title" colSpan="6">
            <strong>Vehicle Options</strong>
          </td>
        </tr>
      ) : null}
      {firstContract?.perVehicleOptions?.map((contract, optionIndex) => (
        <tr
          data-testid={`cost-breakdown-vehicle-option-${contract.optionCode}`}
          key={`cost-breakdown-${contract.optionCode}`}
        >
          <td
            className="veh-options-desc"
            data-testid={`cost-breakdown-vehicle-label-${contract.optionCode}`}
          >
            <label className="option-label">
              {contract.optionCode} - {contract.optionDescription}
              {contract.optionQuantityRequired === 'Y' && (
                <i> (Unit price x Quantity)</i>
              )}
            </label>
          </td>
          {networkCostBreakDownData.map((cb) => {
            const activateLowestBid =
              cb.contractLineId === lowestBidData.contractLineId;
            return (
              <td
                key={`cost-breakdown-${cb.contractLineId}`}
                className={cx('option-cell', {
                  'lowest-bid': activateLowestBid,
                })}
                data-testid={`cost-breakdown-vehicle-value-${cb.contractLineId}`}
              >
                <label className="option-label">
                  {getFormattedValue(
                    cb.perVehicleOptions[optionIndex].optionType,
                    cb.perVehicleOptions[optionIndex].optionTotalPrice,
                  )}
                </label>
              </td>
            );
          })}
        </tr>
      ))}

      {networkCostBreakDownData && networkCostBreakDownData.length ? (
        <tr>
          <td className="veh-option-title" colSpan="6">
            <strong>Required Options</strong>
          </td>
        </tr>
      ) : null}

      {!!finalModifiedData &&
        finalModifiedData.map((list) => {
          return (
            <tr>
              <td className="veh-options-desc">
                <label className="option-label">
                  {list.optionCode} - {list.optionDescription}
                </label>
              </td>

              {list.vendorValues.map((item) => {
                const activateLowestBid =
                  item.contractLineId === lowestBidData.contractLineId;
                return (
                  <td
                    className={cx('option-cell', {
                      'lowest-bid': activateLowestBid,
                    })}
                  >
                    <label className="option-label">
                      {item.optionTotalPrice
                        ? getFormattedValue(
                            item.optionType,
                            item.optionTotalPrice,
                          )
                        : 'NOT REQUIRED'}
                    </label>
                  </td>
                );
              })}
            </tr>
          );
        })}

      {firstContract?.perVehicleOptions &&
      firstContract?.perVehicleOptions?.length ? (
        <tr>
          <td
            data-testid="cost-breakdown-total-vehicle-options"
            className="total-veh-option-title"
          >
            <strong>Total Vehicle Options</strong>
          </td>
          {networkCostBreakDownData.map((cb) => {
            const activateLowestBid =
              cb.contractLineId === lowestBidData.contractLineId;
            return (
              <td
                key={`cost-breakdown-total-vehoption-${cb.contractLineId}`}
                className={cx('option-cell', {
                  'lowest-bid': activateLowestBid,
                })}
                data-testid={`cost-breakdown-vehicle-value-${cb.contractLineId}`}
              >
                <label className="option-label">
                  <strong>{formatCurrency(cb.perVehicleOptionsPrice)}</strong>
                </label>
              </td>
            );
          })}
        </tr>
      ) : null}
      <tr>
        <td
          data-testid="cost-breakdown-sub-total-unit-price"
          className="cost-breakdown-description"
        >
          <strong>Sub Total Unit Price </strong>
          <i>(Base+Options)</i>
        </td>
        {networkCostBreakDownData.map((cb) => {
          const activateLowestBid =
            cb.contractLineId === lowestBidData.contractLineId;
          return (
            <td
              data-testid={`cost-breakdown-subtotal-unitprice-${cb.contractLineId}`}
              key={`cost-breakdown-subtotal-unitprice-${cb.contractLineId}`}
              className={cx('option-cell', {
                'lowest-bid': activateLowestBid,
              })}
            >
              <label className="option-label">
                {formatCurrency(cb.baseAndOptionsPrice || 0)}
              </label>
            </td>
          );
        })}
      </tr>
      <tr>
        <td
          data-testid="cost-breakdown-quantity"
          className="cost-breakdown-description"
        >
          <strong>Quantity</strong>
        </td>
        {networkCostBreakDownData.map((cb) => {
          const activateLowestBid =
            cb.contractLineId === lowestBidData.contractLineId;
          return (
            <td
              data-testid={`cost-breakdown-quantity-${cb.contractLineId}`}
              key={`cost-breakdown-quantity-${cb.contractLineId}`}
              className={cx('option-cell', {
                'lowest-bid': activateLowestBid,
              })}
            >
              <label className="option-label">{cb.quantity}</label>
            </td>
          );
        })}
      </tr>
      {firstContract && firstContract?.perOrderOptions?.length ? (
        <>
          <tr
            data-testid="cost-breakdown-add-materials"
            className="cost-breakdown-description"
          >
            <td colSpan="6" className="additional-material">
              <strong>Additional Materials</strong>
            </td>
          </tr>
          {firstContract?.perOrderOptions?.map((option, optionIndex) => (
            <tr
              key={`cost-breakdown-add-material-${option.optionCode}`}
              data-testid={`cost-breakdown-add-material-${option.optionCode}`}
            >
              <td
                className="add-material-desc"
                data-testid={`cost-breakdown-add-material-label-${option.optionCode}`}
              >
                {option.optionCode} - {option.optionDescription}
                {option.quantity > 1 ? ` x ${option.quantity}` : ''}
              </td>
              {networkCostBreakDownData.map((cb) => {
                const activateLowestBid =
                  cb.contractLineId === lowestBidData.contractLineId;
                return (
                  <td
                    key={`cost-breakdown-add-material-${cb.contractLineId}`}
                    data-testid={`cost-breakdown-add-material-value-${cb.contractLineId}`}
                    className={cx('option-cell', {
                      'lowest-bid': activateLowestBid,
                    })}
                  >
                    <label className="option-label">
                      {getFormattedValue(
                        cb?.perOrderOptions[optionIndex]?.optionType,
                        cb?.perOrderOptions[optionIndex]?.optionTotalPrice,
                      )}
                    </label>
                  </td>
                );
              })}
            </tr>
          ))}
        </>
      ) : null}
      {firstContract?.perOrderOptions &&
      firstContract?.perOrderOptions?.length ? (
        <>
          <tr>
            <td
              data-testid="cost-breakdown-total-materials-price"
              className="total-material-price-title"
            >
              <strong>Total Materials Price</strong>
            </td>
            {networkCostBreakDownData.map((cb) => {
              const activateLowestBid =
                cb.contractLineId === lowestBidData.contractLineId;
              return (
                <td
                  data-testid={`cost-breakdown-total-material-price-${cb.contractLineId}`}
                  key={`cost-breakdown-total-material-price-${cb.contractLineId}`}
                  className={cx('option-cell', {
                    'lowest-bid': activateLowestBid,
                  })}
                >
                  <label className="option-label">
                    {formatCurrency(cb.perOrderOptionsPrice)}
                  </label>
                </td>
              );
            })}
          </tr>
        </>
      ) : null}
      <tr>
        <td
          data-testid="cost-breakdown-total-price"
          className="cost-breakdown-description"
        >
          <strong>Total price </strong>
          <i>[Base+Options] x [Quantity] + Additions materials ordered</i>
        </td>
        {networkCostBreakDownData &&
          networkCostBreakDownData.map((cb) => {
            const activateLowestBid =
              cb.contractLineId === lowestBidData.contractLineId;
            return (
              <td
                data-testid={`cost-breakdown-total-price-${cb.contractLineId}`}
                key={`cost-breakdown-total-price-${cb.contractLineId}`}
                className={cx('option-cell', {
                  'lowest-bid': activateLowestBid,
                })}
              >
                <label className="option-label">
                  {formatCurrency(cb.totalSellingPrice)}
                </label>
              </td>
            );
          })}
      </tr>
      <tr
        data-testid="cost-breakdown-expected-delivery"
        className="cost-breakdown-description"
      />
      <tr>
        <td className="cost-breakdown-description">Base delivery days</td>
        {networkCostBreakDownData &&
          networkCostBreakDownData.map((cb) => {
            const activateLowestBid =
              cb.contractLineId === lowestBidData.contractLineId;
            return (
              <td
                data-testid={`cost-breakdown-shipment-days-${cb.contractLineId}`}
                key={`cost-breakdown-shipment-days-${cb.contractLineId}`}
                className={cx('option-cell', {
                  'lowest-bid': activateLowestBid,
                })}
              >
                <label className="option-label">{cb.baseShippingDays}</label>
              </td>
            );
          })}
      </tr>
      <tr data-testid="cost-breakdown-add-shipping-days">
        <td className="cost-breakdown-description">
          Additional days due to options
        </td>
        {networkCostBreakDownData &&
          networkCostBreakDownData.map((cb) => {
            const activateLowestBid =
              cb.contractLineId === lowestBidData.contractLineId;
            return (
              <td
                data-testid={`cost-breakdown-add-shipping-days-${cb.contractLineId}`}
                key={`cost-breakdown-add-shipping-days-${cb.contractLineId}`}
                className={cx('option-cell', {
                  'lowest-bid': activateLowestBid,
                })}
              >
                <label className="option-label">
                  {cb.additionalShippingDays.additionalShippingDays}
                </label>
                {cb.additionalShippingDays.additionalShippingDays > 0 && (
                  <span className="tooltip">
                    <span className="info-icon">
                      <InfoIcon
                        role="img"
                        aria-label={
                          cb.additionalShippingDays.additionalShippingDays
                        }
                      />
                    </span>
                    <div className="tooltip-info">
                      <strong>Additional Days</strong>
                      <br />
                      <span className="option-code">
                        {cb.additionalShippingDays.optionCode}
                      </span>
                      <span className="added-days">
                        {cb.additionalShippingDays.additionalShippingDays} added
                        days
                      </span>
                      <i />
                    </div>
                  </span>
                )}
              </td>
            );
          })}
      </tr>
      <tr>
        <td
          data-testid="cost-breakdown-total-expected-days"
          className="cost-breakdown-description"
        >
          <strong>Total expected delivery days </strong>
          <i>(Base+Options)</i>
        </td>
        {networkCostBreakDownData &&
          networkCostBreakDownData.map((cb) => {
            const activateLowestBid =
              cb.contractLineId === lowestBidData.contractLineId;
            return (
              <td
                data-testid={`cost-breakdown-total-delivery-days-${cb.contractLineId}`}
                key={`cost-breakdown-total-delivery-days-${cb.contractLineId}`}
                className={cx('option-cell', {
                  'lowest-bid': activateLowestBid,
                })}
              >
                <label className="option-label">{cb.totalShippingDays}</label>
              </td>
            );
          })}
      </tr>
    </>
  );
};

const CostBreakdownItems = ({ lowestBidData, networkCostBreakDownData }) => {
  return [
    {
      title: 'Cost Breakdowns & Shipping Days',
      content: (
        <>
          <table className="option-table cost-breakdown" role="presentation">
            <tbody>
              <CostBreakdownContent
                lowestBidData={lowestBidData}
                networkCostBreakDownData={networkCostBreakDownData}
              />
            </tbody>
          </table>
        </>
      ),
      expanded: false,
    },
  ];
};

const addCostBreakdownScroll = (costBreakDown) => {
  return new SimpleBar(costBreakDown);
};

const CostBreakdownSection = ({ lowestBidData, networkCostBreakDownData }) => {
  useEffect(() => {
    const costBreakDown = document.querySelectorAll(
      '.cost-breakdown-accordion > div',
    )[0];
    if (costBreakDown) {
      addCostBreakdownScroll(costBreakDown);
    }
  }, []);
  return (
    <Accordion
      multiselectable={false}
      bodered={false}
      items={CostBreakdownItems({
        lowestBidData,
        networkCostBreakDownData,
      })}
      className="cost-breakdown-accordion"
    />
  );
};

CostBreakdownContent.propTypes = {
  lowestBidData: PropTypes.instanceOf(Object).isRequired,
  networkCostBreakDownData: PropTypes.instanceOf(Array).isRequired,
};

CostBreakdownItems.propTypes = {
  lowestBidData: PropTypes.instanceOf(Object).isRequired,
  networkCostBreakDownData: PropTypes.instanceOf(Array).isRequired,
};

CostBreakdownSection.propTypes = {
  lowestBidData: PropTypes.instanceOf(Object),
  networkCostBreakDownData: PropTypes.instanceOf(Array).isRequired,
};

CostBreakdownSection.defaultProps = {
  lowestBidData: {},
};

export default CostBreakdownSection;
