import React, { useState, useEffect } from 'react';
import { Alert } from '@gsa/afp-component-library';
import { useHistory } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useQueryParam } from '../../hooks/useQueryParam';
import { GET_REQUISITION_CART } from '../../services/data-layer';
import { REQUISITION_TYPE } from '../../pages/non-standard-purchase/constants';

const SubmitOrderSuccessMessage = ({ name }) => {
  const history = useHistory();
  const query = useQueryParam();
  const draftId = query.get('requisitionId');
  const draftName = name || query.get('draftName');

  const [enableMessage, setEnableMessage] = useState(true);
  const [showClone, setShowClone] = useState(true);
  const [getRequisitionCart] = useLazyQuery(GET_REQUISITION_CART, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ getRequisition }) => {
      if (
        [
          REQUISITION_TYPE.AREQ,
          REQUISITION_TYPE.MULTIPLE_AWARD_SCHEDULES,
          REQUISITION_TYPE.URGENT_REQUIREMENT,
        ].includes(getRequisition?.requisitionType)
      ) {
        setShowClone(false);
      }
    },
  });

  useEffect(() => {
    setTimeout(() => {
      setEnableMessage(false);
    }, 10000);
  }, []);

  useEffect(() => {
    if (draftId) {
      getRequisitionCart({ variables: { requisitionId: draftId } });
    }
  }, [draftId]);
  useEffect(() => {
    if (!enableMessage) {
      history.push('/my-requisitions');
    }
  }, [enableMessage]);

  const resubmitted = query.get('resubmittedStatus') === 'true';

  return (
    <>
      {enableMessage && (
        <Alert type="success" slim>
          <span data-testid="submit-requisition-success-message">
            {resubmitted ? (
              <>
                You have successfully resubmitted <strong>{draftName}.</strong>
              </>
            ) : (
              <>
                You have successfully submitted <strong>{draftName}.</strong>
              </>
            )}{' '}
            <a
              className="usa-link"
              href={`/requisition-details?requisitionId=${draftId}`}
            >
              View details
            </a>
            {showClone && (
              <>
                &nbsp;|&nbsp;
                <a
                  className="usa-link"
                  href={`/clone-requisition?requisitionId=${draftId}&draftName=${draftName}`}
                >
                  Clone this requisition
                </a>
              </>
            )}
          </span>
        </Alert>
      )}
    </>
  );
};

SubmitOrderSuccessMessage.propTypes = {
  name: PropTypes.string,
};

SubmitOrderSuccessMessage.defaultProps = {
  name: '',
};

export default SubmitOrderSuccessMessage;
