import React, { useState, useMemo, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import {
  Button,
  useModal,
  Alert,
  AFPTable,
  EmptyState,
  Checkbox,
  AFPTableRowAction,
  connectModal,
  RequiredFieldIndicator,
  TextInput,
} from '@gsa/afp-component-library';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { SUBMIT_CUSTOMER_RESPONSE } from '../../../services/data-layer';
import CustomerReviewAndConfirmPage from './CustomerReviewAndConfirmPage';
import AttachmentDownload from '../../ReviewDetails/Attachment/AttachmentDownload/AttachmentDownload';
import UploadNewFileModal from '../../../components/MultipleAdditionalRequirements/UploadNewFileModal/UploadNewFileModal';
import DeleteAdditionalReqFileModal from '../../../components/MultipleAdditionalRequirements/DeleteAdditionalReqFileModal/DeleteAdditionalReqFileModal';
import EditFileDocumentationModal from '../../../components/MultipleAdditionalRequirements/EditFileDocumentationModal/EditFileDocumentationModal';
import ErrorContext from '../../../context/ErrorContext/ErrorContext';
import ErrorActions from '../../../context/ErrorContext/ErrorActions';
import CancelRequisitionButton from '../../ReviewDetails/CancelRequisitionButton/CancelRequisitionButton';
import { getCustomerSubmitPayload } from '../urgent-requisition/utils/UrgReqCommonUtils';
import { ROW_ACTIONS } from '../constants';
import './CustomerQuoteEvaluation.scss';

const CustomerQuoteEvaluation = ({ requisitionCartData }) => {
  const history = useHistory();
  const draftId = requisitionCartData?.requisitionId;
  const [isQuoteSelected, setIsQuoteSelected] = useState(false);
  const [deleteRowIndex, setDeleteRowIndex] = useState(-1);
  const [rowToUpdate, setRowToUpdate] = useState(null);
  const [isLowestPrice, setIsLowestPrice] = useState(false);
  const [selectedVendorData, setSelectedVendorData] = useState(null);
  const { dispatch: errorDispatch } = useContext(ErrorContext);

  const [submitCustomerResponse] = useMutation(SUBMIT_CUSTOMER_RESPONSE, {
    fetchPolicy: 'no-cache',
  });

  const initialFormValues = {
    customerReviewDetails: [
      {
        lowBidJustification: '',
        certifyCheckBox: false,
        fundingDocs: [],
      },
    ],
  };
  const uploadNewFileModal = useModal();
  const DisplayUploadFileModal = connectModal(UploadNewFileModal);

  const deleteFileModal = useModal();
  const DisplayDeleteFileModal = connectModal(DeleteAdditionalReqFileModal);

  const updateFileModal = useModal();
  const DisplayUpdateFileModal = connectModal(EditFileDocumentationModal);

  const formProps = useForm({
    defaultValues: initialFormValues,
    reValidateMode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    reset,
    getValues,
  } = formProps;

  const {
    fields: fundingAttachments,
    append: quoteAppend,
    remove,
    update,
  } = useFieldArray({
    control: formProps.control,
    name: 'customerReviewDetails.fundingDocs',
  });
  const uploadedFundingAttachments = fundingAttachments;

  useEffect(() => {
    if (uploadedFundingAttachments.length > 0) {
      clearErrors('fundingDocumentation');
    }
  }, [uploadedFundingAttachments]);

  const validateErrorsAlert = () => {
    if (Object.keys(errors).length > 0) {
      errorDispatch({
        type: ErrorActions.ADD_ERROR,
        payload: {
          page: 'quoteEvaluation',
          form: 'vendorQuotesEvaluation',
          error: 'Please fill all required information',
        },
      });
    } else {
      errorDispatch({ type: ErrorActions.CLEAR_ERRORS });
    }
  };

  useEffect(() => {
    if (Object.keys(errors)?.length) {
      validateErrorsAlert();
    }
  }, [errors]);

  const setFundingDocError = () => {
    return setError('fundingDocumentation', {
      type: 'required',
      message: 'Funding documentation is required',
    });
  };

  const onSubmit = async () => {
    if (uploadedFundingAttachments.length === 0) {
      setFundingDocError();
    } else {
      const payload = getCustomerSubmitPayload(getValues(), selectedVendorData);
      await submitCustomerResponse({
        variables: payload,
      });

      history.push(
        `/my-requisitions?requisitionId=${draftId}&draftName=${requisitionCartData?.friendlyName}`,
      );
    }
  };

  const onError = () => {
    validateErrorsAlert();
    if (uploadedFundingAttachments.length === 0) {
      setFundingDocError();
    }
  };

  const handleTableActions = (event, row) => {
    setRowToUpdate(row);
    if (event === 'Edit') {
      updateFileModal.openModal();
    } else {
      setDeleteRowIndex(row.index);
      deleteFileModal.openModal();
    }
  };

  const handleDelete = () => {
    remove(deleteRowIndex);
    deleteFileModal.closeModal();
  };

  const columns = useMemo(
    () => [
      {
        Header: 'File name',
        accessor: 'filename',
        sortable: false,
        /* eslint-disable react/prop-types */
        Cell: ({ row: { original } }) => {
          return (
            <AttachmentDownload
              name={original?.name}
              metadataId={original?.metadataId}
            />
          );
        },
      },
      {
        Header: 'Note',
        accessor: 'note',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => original?.description,
      },
      {
        Header: 'Actions',
        id: 'table-row-action',
        sortable: false,
        headerClassName: 'cell-center',
        cellClassName: 'cell-center',
        /* eslint-disable react/prop-types */
        Cell: (props) => {
          const { row } = props;
          return (
            <AFPTableRowAction
              actions={ROW_ACTIONS}
              onSelectAction={(evt) => {
                handleTableActions(evt, row);
              }}
              {...props}
            />
          );
        },
      },
    ],
    [],
  );

  const SelectedVendorReview = ({ selectedData }) => {
    return (
      <FormProvider {...formProps}>
        <form
          data-testid="quote-evaluation-form"
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          <div className="vendor-review-info">
            <div className="section-heading">
              <div className="section-title">Review and confirm funding</div>
              <div className="title-desc">
                Review this urgent requirement and ensure the information is
                correct. Below, attach relevant funding documentation
                demonstrating funding has been obligated to fulfill this urgent
                requirement.
              </div>
            </div>
            <div className="select-vendor-btn-container">
              <div className="title">Selected vendor</div>
              <Button
                data-testid="choose-vendor-btn"
                type="button"
                variant="unstyled"
                label="Choose another vendor"
                leftIcon={{ name: 'undo', className: 'undo' }}
                onClick={() => {
                  setIsQuoteSelected(false);
                  reset(initialFormValues);
                  remove();
                }}
              />
            </div>
            <CustomerReviewAndConfirmPage vendorData={selectedData} />
            {!isLowestPrice && (
              <div className="top-padding-70">
                <div className="title">
                  Non low bid justification <RequiredFieldIndicator />
                </div>
                <div className="title-desc">
                  <b>You have selected the non low bid vendor. </b>
                  Please provide justification as to why the low bid is not the
                  lowest bid technically acceptable. (If you have supporting
                  documents, you may upload them along with your funding
                  confirmation documents below.)
                </div>
                <Controller
                  rules={{ required: 'This is a required field' }}
                  name="lowBidJustification"
                  render={({ field: { value, onChange, ref } }) => (
                    <TextInput
                      type="textarea"
                      onChange={onChange}
                      value={value}
                      inputRef={ref}
                      name="lowBidJustification"
                      inputClass="vendor-low-bid-input-text"
                      errorMessage={errors?.lowBidJustification?.message}
                    />
                  )}
                />
              </div>
            )}

            <div className="top-padding-70">
              <div className="title">
                Confirm funding <RequiredFieldIndicator />{' '}
              </div>
              <div className="title-desc">
                Attach relevant funding documents demonstrating that funding is
                obligated to fulfill this urgent requirement.
              </div>
              <div className="title-desc">
                <b>Note:</b> The government&apos;s payment terms are always net
                30 calendar days after receipt of a proper invoice.
              </div>
              {errors?.fundingDocumentation?.message && (
                <Alert type="error">
                  <div>Please upload at least one document</div>
                </Alert>
              )}
              <div className="upload-btn">
                <Button
                  data-testid="funding-upload-btn"
                  onClick={() => {
                    uploadNewFileModal.openModal();
                  }}
                  type="button"
                  variant="outline"
                  leftIcon={{ name: 'add', className: 'plus-icon' }}
                  label="Upload file"
                />
              </div>
              <div>
                <AFPTable
                  testId="funding-doc-attachments-table"
                  columns={columns}
                  data={fundingAttachments || []}
                />
                {!fundingAttachments.length && (
                  <EmptyState
                    hasBackground
                    containerStyles="margin-top-neg-2 padding-y-10"
                    topText="No files uploaded"
                  />
                )}
              </div>
            </div>

            <div
              className={`${
                errors?.certifyCheckBox?.message &&
                'usa-form-group usa-form-group--error'
              }`}
            >
              <div className="error-text-desc">
                {errors?.certifyCheckBox?.message}
              </div>
              <Controller
                rules={{
                  required:
                    'Please certify that you have uploaded funding documents ',
                }}
                name="certifyCheckBox"
                render={({ field: { value, onChange } }) => {
                  return (
                    <Checkbox
                      className="supporting-docs-checkbox"
                      name="certifyCheckbox"
                      checked={value}
                      onChange={onChange}
                      label="I hereby certify that I have uploaded all documents required for funding confirmation."
                    />
                  );
                }}
              />
            </div>
          </div>
          <div className="cutomer-review-btn-section">
            <CancelRequisitionButton requisitionId={draftId} />
            <Button
              data-testid="select-vendor-btn"
              type="submit"
              variant="primary"
              label="Submit to contracting officer"
              leftIcon={{ name: 'check' }}
              onClick={() => {}}
            />
          </div>
        </form>

        <DisplayUploadFileModal
          isOpen={uploadNewFileModal.isOpen}
          handleClose={uploadNewFileModal.closeModal}
          append={quoteAppend}
          reqType="URGENT_REQ_CUSTOMER_FUNDING_DOC"
          draftId={draftId}
        />

        <DisplayDeleteFileModal
          isOpen={deleteFileModal.isOpen}
          handleClose={deleteFileModal.closeModal}
          handleDelete={handleDelete}
          filename={rowToUpdate?.original?.name}
        />

        <DisplayUpdateFileModal
          isOpen={updateFileModal.isOpen}
          handleClose={updateFileModal.closeModal}
          rowToEdit={rowToUpdate}
          update={update}
        />
      </FormProvider>
    );
  };

  return isQuoteSelected ? (
    <SelectedVendorReview selectedData={selectedVendorData} />
  ) : (
    <>
      <div className="section-heading">
        <div className="section-title">
          Select vendor to complete requisition
        </div>
        <div className="title-desc">
          Please select the vendor you wish to fulfill the vehicle need. If it
          is the non-low bid, you will be prompted to enter a non-low bid
          justification.
        </div>
      </div>
      <div
        className={
          requisitionCartData?.vendorQuotes?.length > 0 &&
          'quote-evaluation-container'
        }
      >
        {requisitionCartData?.vendorQuotes?.map((data, index) => {
          const venNum = index + 1;
          return (
            <div className="vendor-details-section">
              <div className="select-vendor-btn-container">
                <div className="title">
                  Vendor {venNum}{' '}
                  {index === 0 && <span className="low-bid">LOWEST BID</span>}
                </div>
                <Button
                  data-testid={`select-vendor-btn-${index}`}
                  type="button"
                  variant="primary"
                  label="Select vendor and complete requisition"
                  rightIcon={{ name: 'arrow_forward' }}
                  onClick={() => {
                    setIsQuoteSelected(!isQuoteSelected);
                    setIsLowestPrice(index === 0);
                    setSelectedVendorData(data);
                  }}
                />
              </div>
              <CustomerReviewAndConfirmPage vendorData={data} />
            </div>
          );
        })}
      </div>
    </>
  );
};

CustomerQuoteEvaluation.propTypes = {
  requisitionCartData: PropTypes.instanceOf(Object).isRequired,
};

export default CustomerQuoteEvaluation;
