import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@gsa/afp-component-library';
import {
  getAreqOptionValue,
  getFormattedValue,
} from '../../../pages/VehicleRequisition/utils/ReviewDetailsUtils';
import SectionEditButton from '../SectionEditButton/SectionEditButton';
import { STEPS } from '../../../pages/VehicleRequisition/constants/VehicleRequisitionConstants';
import './OptionsPerVehicle.scss';
import VehicleRequisitionContext from '../../../context/VehicleRequisitionContext/VehicleRequisitionContext';

const OptionsPerVehicle = ({
  selectedContractCostBreakdown,
  isEditable,
  toggleViewSelectedOptions = null,
  options,
}) => {
  const {
    selectedVendorQuote,
    state: { selectedContractCostBreakdown: areqContractCostBreakdown, isAreq },
  } = useContext(VehicleRequisitionContext);

  return (
    <>
      <div role="tab" tabIndex="0" className="options-per-vehicle">
        <div className="title-section">
          <span>Options per vehicle</span>
          {toggleViewSelectedOptions && (
            <Button
              onClick={toggleViewSelectedOptions}
              type="button"
              variant="outline"
              size="small"
              // className="margin-bottom-1"
              data-testid="view options with vendors and prices"
              aria-label="View options with vendors and prices"
              leftIcon={{ name: 'edit' }}
              label="View options with vendors and prices"
            />
          )}

          <SectionEditButton
            showButton={isEditable}
            id="options-per-vehicle-edit"
            step={STEPS.COMPARE_SELECT}
          />
        </div>

        <div>
          <div className="review-options">
            {options.map((optionCategory) => (
              <div
                key={`review-options-option-category-${optionCategory.optionCategoryCode}`}
                className="content-block"
              >
                <span
                  className="review-options-option-category review-options-block"
                  data-testid={`review-options-option-category-${optionCategory.optionCategoryCode}`}
                >
                  {optionCategory.optionCategoryDescription}
                </span>
                {optionCategory.options.map((option) => (
                  <div
                    className="review-options-option-row"
                    key={`review-options-option-category-${optionCategory.optionCategoryCode}-${option.optionCode}`}
                    data-testid={`review-options-option-category-${optionCategory.optionCategoryCode}-${option.optionCode}`}
                  >
                    {isAreq ? (
                      <span>
                        {option.optionCode} - {option.optionDescription}
                        {option.optionQuantity > 1 && (
                          <> &#215; {option.optionQuantity}</>
                        )}
                      </span>
                    ) : (
                      <span>
                        {option.optionCode} - {option.optionDescription}
                        {option.optionQuantityRequired === 'V' && (
                          <> &#215; {option.quantity}</>
                        )}
                      </span>
                    )}
                    <span>
                      {selectedVendorQuote
                        ? getAreqOptionValue(
                            [
                              ...(areqContractCostBreakdown?.perVehicleOptions ||
                                []),
                              ...(areqContractCostBreakdown?.perOrderOptions ||
                                []),
                            ] || [
                              ...(selectedContractCostBreakdown?.perVehicleOptions ||
                                []),
                              ...(selectedContractCostBreakdown?.perOrderOptions ||
                                []),
                            ],
                            option.optionCode,
                          )
                        : getFormattedValue(
                            option.optionType,
                            option.optionTotalPrice,
                          )}
                    </span>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
        {!!selectedContractCostBreakdown?.perVehicleRequiredOptions?.length && (
          <div className="options-per-vehicle">
            <div className="title-section">Required Options</div>
            <div className="per-vehicle-list">
              {selectedContractCostBreakdown.perVehicleRequiredOptions
                .filter(
                  (requiredOption) => requiredOption?.optionType !== 'EXCLUDED',
                )
                .map((requiredOption) => {
                  return (
                    <div className="common-sub-section">
                      <span>
                        {requiredOption.optionCode} -{' '}
                        {requiredOption.optionDescription}
                      </span>
                      <span className="price-value">
                        {getFormattedValue(
                          requiredOption.optionType,
                          requiredOption.optionTotalPrice,
                        )}
                      </span>
                    </div>
                  );
                })}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

OptionsPerVehicle.propTypes = {
  selectedContractCostBreakdown: PropTypes.instanceOf(Object).isRequired,
  isEditable: PropTypes.bool,
  toggleViewSelectedOptions: PropTypes.func,
  options: PropTypes.instanceOf(Array).isRequired,
};

OptionsPerVehicle.defaultProps = {
  isEditable: false,
  toggleViewSelectedOptions: null,
};

export default OptionsPerVehicle;
