const AGENCY_FILTER = 'agencyCode';
const BUREAU_FILTER = 'bureauCode';
const OFFICE_FILTER = 'officeCode';
const AGENCY_AND_BUREAU_AND_OFFICE = 'agency_bureau_office';

export {
  AGENCY_FILTER,
  AGENCY_AND_BUREAU_AND_OFFICE,
  BUREAU_FILTER,
  OFFICE_FILTER,
};
