import React, { useEffect, useState, useMemo } from 'react';
import {
  Alert,
  Button,
  Checkbox,
  connectModal,
  TextInput,
  useModal,
} from '@gsa/afp-component-library';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useLazyQuery } from '@apollo/client';
import useBulkOrderModsState from './useBulkOrderModsState';
import UploadOrderModAttachments from './UploadOrderModAttachments';
import SubmitOrderModModal from './SubmitOrderModModal';
import ChangeSetModTable from './ChangeSetModTable';
import OrderModificationHeader from './OrderModificationHeader';
import { OrderModStatus } from './BulkOrderModTableHelper';
import CancelOrderModModal from './CancelOrderModModal';
import { resetFocusToFirstElement } from '../../utilities/commons';
import { GET_CHANGE_SET_BY_ID } from '../../services/data-layer';
import { OrderModificationType } from './BulkOrderModProvider';
import { validateChangeSets } from './change-sets/ChangeSetValidator';

export const OrderModSteps = {
  CREATE_ORDER_MOD: 1,
  SELECT_ORDER_MOD: 2,
  UPDATE_ORDER_MOD: 3,
  SUBMIT_ORDER_MOD: 4,
};

const SubmitOrderMod = () => {
  const {
    updatedDraft,
    currentStep,
    setCurrentStep,
    setOrderModRows,
    orderModAttachmentTypes,
    setSelectedFilters,
    changeSets,
    submitComment,
    setSubmitComment,
    setSelectedOptions,
    setChangeSetId,
    setAgencyContact,
    currentChangeSet,
    setCurrentChangeSet,
    setSelectedOrdersForMod,
    setCurrentChangeSetType,
    orderModAttachments,
    submitOrderModHasError,
    setSubmitOrderModHasError,
    submitOrderModErrorMessage,
    setSubmitOrderModErrorMessage,
    orderChanges,
  } = useBulkOrderModsState();
  const submitModal = useModal();
  const DisplaySubmissionModal = connectModal(SubmitOrderModModal);
  const [docsUploaded, setDocsUploaded] = useState(true);
  const [certifyCheckboxError, setCertifyCheckboxError] = useState(false);
  const [changeSetValidationErrors, setChangeSetValidationErrors] = useState([]);
  const formProps = useForm({
    defaultValues: {
      certifyCheckbox: false,
      additionalRequirements: [
        {
          attachments: [],
        },
      ],
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const {
    handleSubmit,
    // eslint-disable-next-line no-empty-pattern
    formState: {},
    control,
  } = formProps;

  const cancelModal = useModal();
  const DisplayCancelModal = connectModal(CancelOrderModModal);

  const onError = () => {
    setSubmitOrderModHasError(true);
  };
  const [getChangeSetDetails] = useLazyQuery(GET_CHANGE_SET_BY_ID, {
    onCompleted: async ({ getChangeSetById }) => {
      if (getChangeSetById) {
        setChangeSetId(getChangeSetById?.orderChangeSetModId);
        setCurrentChangeSet((prev) => ({ ...prev, ...getChangeSetById }));
        setCurrentChangeSetType(getChangeSetById?.type);
        setSelectedOrdersForMod(
          getChangeSetById.changeSetOrders?.map((changeSetOrder) => {
            return {
              caseNumber: changeSetOrder?.order?.caseNumber,
              standardItemId: changeSetOrder?.order?.standardItemId,
              orderId: changeSetOrder?.orderId,
              quantity: changeSetOrder?.order?.quantity,
              unitPriceToCustomer: changeSetOrder?.order?.unitPriceToCustomer,
              scheduleLine: changeSetOrder?.order?.scheduleLine,
              contractLineId: changeSetOrder?.order?.contractLineId,
              requisitionType: changeSetOrder?.order?.requisitionType,
              requisitionNumber: changeSetOrder?.order?.requisitionNumber,
              orderVehicles: changeSetOrder?.order?.orderVehicles,
              requisitionerAddress: changeSetOrder?.order?.requisitionerAddress,
              requisitionerContact: changeSetOrder?.order?.requisitionerContact,
              rest: { ...changeSetOrder?.order },
            };
          }),
        );
        setSelectedOptions(getChangeSetById?.modifications?.selectedOptions);
        // setOrderChanges(getChangeSetById?.modifications);
        setAgencyContact(getChangeSetById?.modifications?.contact);
      }
    },
  });
  const handleClickPrevButton = async () => {
    await getChangeSetDetails({
      variables: {
        changeSetId: currentChangeSet?.orderChangeSetModId,
      },
      fetchPolicy: 'network-only',
    });
    setCurrentStep(3);
  };
  const handleCreateNewChangeSet = () => {
    setOrderModRows([]);
    setCurrentChangeSet({
      type: 'MODIFY',
    });
    setSelectedFilters([]);
    setSelectedOrdersForMod([]);
    setCurrentChangeSetType('MODIFY');
    setCurrentStep(OrderModSteps.SELECT_ORDER_MOD);
  };

  useEffect(() => {
    if (
      currentStep === OrderModSteps.SUBMIT_ORDER_MOD &&
      changeSets &&
      changeSets.length <= 0
    ) {
      setCurrentStep(OrderModSteps.SELECT_ORDER_MOD);
    }
  }, [changeSets]);

  useEffect(() => {
    const uploadedFile = orderModAttachments?.filter(
      (attachment) => attachment?.attachmentType?.type === 'SF30',
    );
    if (uploadedFile?.length) {
      setDocsUploaded(true);
    }
  }, [orderModAttachments]);

  const isAdminOrderMod = useMemo(() => {
    if (!changeSets || !Array.isArray(changeSets)) return true;

    const changeSetTypes = changeSets.map((changeSet) => {
      const quantityChange = parseInt(
        changeSet.modifications.quantityChangePerOrder || '0',
        10,
      );
      const priceChange = parseFloat(
        changeSet.modifications.priceChangePerOrder || '0',
        10,
      );
      if (
        quantityChange !== 0 ||
        priceChange !== 0 ||
        changeSet.modifications.selectedOptions?.length > 0 ||
        changeSet.type === 'TERMINATE'
      ) {
        return OrderModificationType.CONTRACTUAL;
      }

      return OrderModificationType.ADMINISTRATIVE;
    });

    return changeSetTypes.every(
      (changeSetType) => changeSetType === OrderModificationType.ADMINISTRATIVE,
    );
  }, [orderChanges, changeSets]);

  const handleSubmitOrderModification = async (data) => {
    const changeSetErrors = validateChangeSets(changeSets);
    if (!changeSetErrors.isValid) {
      setSubmitOrderModHasError(true);
      setSubmitOrderModErrorMessage('One or more change set(s) has errors');
      setChangeSetValidationErrors(changeSetErrors.messages);
      resetFocusToFirstElement('review-modifications-section-container');
    }

    setCertifyCheckboxError(!data?.certifyCheckbox);

    const uploadedFile = orderModAttachments?.filter(
      (attachment) => attachment?.attachmentType?.type === 'SF30',
    );

    if (uploadedFile?.length) {
      setDocsUploaded(true);
    } else if (!isAdminOrderMod) {
      setDocsUploaded(false);
      document
        .getElementById('order-mod-attachment-title')
        .scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    if (
      changeSetErrors.isValid &&
      data?.certifyCheckbox &&
      !submitModal.isOpen &&
      (uploadedFile?.length || isAdminOrderMod)
    ) {
      submitModal.openModal();
    }
  };
  const cancelOrderMod = () => {
    cancelModal.openModal();
  };



  return (
    currentStep === OrderModSteps.SUBMIT_ORDER_MOD && (
      <>
        {submitOrderModHasError && (
          <Alert type="error" slim focused>
            <span data-testid="submit-order-mod-error-message">
              <strong>{submitOrderModErrorMessage}</strong>
              {changeSetValidationErrors?.length > 0 && (
                    changeSetValidationErrors.map((errors) => (
                        errors?.length > 0 && errors.map(
                            (error) => <li>{error}</li>)
                            ))
                    ) }
            </span>
          </Alert>
        )}
        <OrderModificationHeader />
        <div className="review-modifications-section-container">
          <h2 data-testid="review-mod-title">1. Review modifications</h2>
          {updatedDraft?.orderModStatus === OrderModStatus.DRAFT_MOD && (
            <div className="text-right">
              <Button
                label="Create a new change set"
                variant="outline"
                data-testid="create-new-change-set-btn"
                onClick={() => {
                  handleCreateNewChangeSet();
                }}
                leftIcon={{
                  name: 'edit',
                  type: 'button',
                }}
              />
            </div>
          )}
        </div>
        <ChangeSetModTable />
        <div className="review-modifications-section-container">
          <h2>2. Modification request acknowledgement and signatures</h2>
          <div className="order-mod-submit-checklist-container">
            <h3> Requirements checklist </h3>
            <ul>
              <li>
                <p>
                  (SF30) Modification request document
                  {!isAdminOrderMod && (
                    <span className="required"> (required) </span>
                  )}
                </p>
              </li>
              <li>
                <p>Other documentation</p>
              </li>
            </ul>
          </div>
        </div>
        <div
          id="order-mod-attachment-title"
          className="order-mod-attachment-title"
        >
          Documentation
        </div>
        {!docsUploaded && (
          <div className="attachments-alert">
            <Alert slim type="error">
              <div className="error-title">
                This section is missing the following required file documents.
              </div>
              <ul>
                <li className="error-link">
                  <p>SF30</p>
                </li>
              </ul>
              <div className="error-text">
                In order to continue, please upload all required file documents,
                and be sure to select the corresponding document category for
                each.
              </div>
            </Alert>
          </div>
        )}
        <div>
          You may load multiple files; however, the combined size of all files
          cannot exceed 4 MB. File name will be converted to acceptable system
          files. All special characters will be removed. File names should be
          alpha numeric, and can also include underscores, commas periods and
          blank spaces. File types supported include Word, Excel, text, PDF,
          JPEG, etc.
        </div>
        <FormProvider {...formProps}>
          <form
            data-testid="order-mod-submit-form"
            onSubmit={handleSubmit(handleSubmitOrderModification, onError)}
          >
            <div className="top-padding-70">
              <UploadOrderModAttachments
                attachmentTypes={orderModAttachmentTypes}
              />
            </div>
            <div
              className={`${
                certifyCheckboxError && 'usa-form-group usa-form-group--error'
              }`}
            >
              <div className="error-text-desc" data-testid="veh-error-text">
                {certifyCheckboxError
                  ? 'Please certify that you have uploaded all required documents'
                  : ''}
              </div>
              <Controller
                control={control}
                rules={{ required: false }}
                name="certifyCheckbox"
                render={({ field: { value, onChange } }) => {
                  return (
                    <Checkbox
                      className="other-docs-checkbox"
                      data-testid="ack-alert-checkbox"
                      checked={
                        updatedDraft?.orderModStatus ===
                        OrderModStatus.ATTACH_TO_MOD
                          ? true
                          : value
                      }
                      onChange={(event) => {
                        onChange(event);
                        setCertifyCheckboxError(false);
                      }}
                      label={
                        <>
                          I hereby certify that I have uploaded all documents
                          required for this order modification.
                          <span className="text-secondary text-bold margin-left-05">
                            *
                          </span>
                        </>
                      }
                    />
                  );
                }}
              />
            </div>{' '}
            <div className="submit-modifications-section-container">
              <h2>3. Submit modification</h2>
              <p className="review-modifcations-section-desc">
                Add optional comments below, which will stay in the activity
                tracker. Cancel the modification or submit it with included
                attachments.
              </p>
              <TextInput
                type="textarea"
                value={submitComment}
                onChange={(e) => setSubmitComment(e.target.value)}
                data-testid="submit-order-mod-comments"
                characterLimit={50}
              />
            </div>
            {updatedDraft?.orderModStatus === OrderModStatus.DRAFT_MOD && (
              <div className="button-section">
                {currentChangeSet?.orderChangeSetModId && (
                  <Button
                    variant="outline"
                    type="button"
                    data-testid="update-order-prev-btn"
                    onClick={() => {
                      handleClickPrevButton();
                    }}
                    leftIcon={{ name: 'arrow_back' }}
                    label="Previous"
                  />
                )}
                <Button
                  label="Cancel modification"
                  onClick={() => {
                    cancelOrderMod();
                  }}
                  type="button"
                  variant="secondary"
                  data-testid="cancel-order-mod-button"
                />
                <Button
                  variant="primary"
                  data-testid="submit-order-mod-btn"
                  type="submit"
                  label="Submit Order Modification"
                />
              </div>
            )}
          </form>
        </FormProvider>
        <DisplaySubmissionModal
          isOpen={submitModal.isOpen}
          onClose={() => submitModal.closeModal()}
        />
        <DisplayCancelModal
          isOpen={cancelModal.isOpen}
          orderModId={updatedDraft?.orderModificationId}
          orderModName={updatedDraft?.orderModName}
          onClose={() => cancelModal.closeModal()}
        />
      </>
    )
  );
};

export default SubmitOrderMod;
