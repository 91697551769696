import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Button } from '@gsa/afp-component-library';
import CheckedIcon from '../../../assets/images/checked-icon';
import { SUBMIT_FOR_CONTRACTING_REVIEW } from '../../../services/data-layer';
import './SubmitToEngineerButton.scss';

export const CO_REVIEW_STATUS = 'contracting-review';

const SubmitToCOButton = ({ requisitionId, requisitionName }) => {
  const history = useHistory();

  const [submitForCOReviewMutation] = useMutation(
    SUBMIT_FOR_CONTRACTING_REVIEW,
    {
      onError: (error) => {
        // TODO
        // not covered in user story yet
        // eslint-disable-next-line no-console
        console.log(
          'Submit For CO errored out: ',
          error?.graphQLErrors[0].message,
        );
      },
      onCompleted: () => {
        history.push(
          `/my-requisitions?requisitionId=${requisitionId}&reqName=${requisitionName}&reqStatus=${CO_REVIEW_STATUS}`,
        );
      },
    },
  );

  const handleSubmitToCO = () => {
    submitForCOReviewMutation({
      variables: {
        requisitionId,
      },
    });
  };

  return (
    <>
      <Button
        data-testid="submit-to-engineer-button"
        type="button"
        label="Submit to Contracting Officer"
        onClick={handleSubmitToCO}
      >
        <CheckedIcon />
        <span className="button-text">Submit to Contracting Officer</span>
      </Button>
    </>
  );
};

SubmitToCOButton.propTypes = {
  requisitionId: PropTypes.string.isRequired,
  requisitionName: PropTypes.string.isRequired,
};

export default SubmitToCOButton;
