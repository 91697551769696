import React, { useState, useEffect, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  Modal,
  Button,
  Spinner,
  Icon,
} from '@gsa/afp-component-library';
import { cloneDeep } from 'lodash';
import SimpleBar from 'simplebar';
import { useLazyQuery } from '@apollo/client';
import 'simplebar/dist/simplebar.css';
import './AddOptions.scss';
import {
  checkOptionDataIncluded,
  getSimplifiedPayload,
  modifiedNewOptions,
} from '../../utilities/CompareVehicleUtils';
import {
  getChangedValue,
  limitDeliveryOptionsByState,
} from './AddOptionsUtils';
import { GET_PURCHASE_COLLISION_FOR_ALL_VENDOR } from '../../services/data-layer';
import ConflictModalComponent from './ConflictModal';
import VehicleRequisitionContext from '../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import { useQueryParam } from '../../hooks/useQueryParam';
import AccordionContent from './components/AccordionContent/AccordionContent';
import PriceButton from './components/PriceButton/PriceButton';
import AreqOptions from '../Areq/AreqOptions/AreqOptions';
import { getRequisitionTransactionType } from '../../pages/VehicleRequisition/utils/RequisitionStepsUtils';

let oldAddOptionsData = [];
let identifyNoCollisionAfterSelection = [];

export const AddOptionsCard = ({
  addOptionsData,
  calculateTotalSellingPrice,
  lowestBidData,
  addOptionsAllContractData,
  displayingCostBreakdown,
  notifyUserRef,
  paginationCurrentPage,
  paginationOffset,
  displaySelectedOptions = false,
}) => {
  const query = useQueryParam();
  const requisitionId = query.get('requisitionId');
  const requisitionType = query.get('requisitionType');
  const {
    state,
    dispatch,
    getPurchaseCollisionDataOnYes,
    updateDraftRequisition,
  } = useContext(VehicleRequisitionContext);

  const {
    addOptionsState,
    draftRequisition,
    selectedItemsForPurchaseState,
    addOptionsDataWithCollisions,
    requiredOptionSessionState,
    getPurchaseCollisionInfo,
    addOptionErrors,
    deliveryOptionErrors,
    deliveryOptions,
    isAreq,
  } = state;

  const [oldValues, setOldValues] = useState([]);
  const [showConflictModal, setShowConflictModal] = useState(false);
  const [selectedOptionCode, setSelectedOptionCode] = useState();
  const [selectedOptionItem, setSelectedOptionItem] = useState({});
  const [selectedOptionCategoryCode, setSelectedOptionCategoryCode] =
    useState();
  const [conflictAPIData, setConflictAPIData] = useState([]);
  const [selectedItemsForPurchase, setSelectedItemsForPurchase] = useState([]);
  const [identifyRadioCheck, setIdentifyRadioCheck] = useState(false);
  const [standardItemSelected, setStandardItemSelected] = useState();
  const [selectedList, setSelectedList] = useState();
  const [resolvedOptions, setResolvedOptions] = useState([]);
  const [requiredOptionsState, setRequiredOptionsState] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const returnableData = [];
  const selectedItems = [];
  let openModal = false;
  let addOptionsLocal = [...addOptionsData];
  const prevAddOptionState = useRef(addOptionsState);
  const initialRender = useRef(true);
  const unSavedChangesModalRef = notifyUserRef;
  const selectedStateFrmSession = sessionStorage.getItem('selectedState');

  const selectedState =
    draftRequisition?.clientData?.clientState?.selectedState ||
    selectedStateFrmSession;

  // the delivery options are seperated from the allReqOptions,
  // the pagination is performed here:
  const start = paginationCurrentPage * paginationOffset;
  const end = start + paginationOffset;
  let localDeliveryOptions = cloneDeep(deliveryOptions);
  localDeliveryOptions.map((deliveryOptionCategoryItem) => {
    deliveryOptionCategoryItem.options.map((deliveryOptionItem) => {
      const modDeliveryOptionItem = deliveryOptionItem;
      modDeliveryOptionItem.vendorValues =
        deliveryOptionItem.vendorValues.slice(start, end);
      return modDeliveryOptionItem;
    });
    return deliveryOptionCategoryItem;
  });

  const [getPurchaseCollisionData, { data: getPurchaseCollision }] =
    useLazyQuery(GET_PURCHASE_COLLISION_FOR_ALL_VENDOR, {
      fetchPolicy: 'no-cache',
    });

  const addOptionScroll = (addOptions) => {
    return new SimpleBar(addOptions);
  };

  useEffect(() => {
    const addDeliveryOptions = document.querySelectorAll(
      '.add-delivery-options-accordion > div',
    )[0];
    if (addDeliveryOptions) {
      addOptionScroll(addDeliveryOptions);
    }

    const addOptions = document.querySelectorAll(
      '.add-options-accordion > div',
    )[0];
    if (addOptions) {
      addOptionScroll(addOptions);
    }
  }, []);

  const calculatePriceClick = () => {
    setIdentifyRadioCheck(false);

    const data = getChangedValue(addOptionsState);
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_IS_CALCULATE_PRICE_BUTTON_DISABLED,
      isCalculatePriceDisabled: true,
    });
    setOldValues(cloneDeep(data));

    const updatedSelectedVariables = cloneDeep(requiredOptionSessionState);
    calculateTotalSellingPrice(updatedSelectedVariables);
  };

  // NOTE: On each option change, check to see if it is an areq. If so,
  // run the calculation to determine if there are any unbuildables.
  // This should also include any changes for collision handling.
  useEffect(() => {
    if (!isAreq) {
      return;
    }
    calculateTotalSellingPrice(requiredOptionSessionState);
  }, [requiredOptionSessionState, isAreq]);

  useEffect(() => {
    if (!unSavedChangesModalRef?.current) {
      return () => {};
    }

    if (
      requiredOptionsState?.length > 0 &&
      !draftRequisition &&
      selectedStateFrmSession !== null
    ) {
      unSavedChangesModalRef.current.unSavedChanges = true;
    } else {
      unSavedChangesModalRef.current.unSavedChanges = false;
    }

    return () => {};
  }, [requiredOptionsState]);

  useEffect(() => {
    if (addOptionsDataWithCollisions.length) {
      addOptionsLocal = [...addOptionsDataWithCollisions];
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_ADD_OPTIONS_STATE,
        payload: addOptionsLocal,
      });
      setSelectedItemsForPurchase(selectedItemsForPurchaseState);
    } else {
      addOptionsLocal = [...addOptionsData];
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_ADD_OPTIONS_STATE,
        payload: addOptionsLocal,
      });
    }
    if (requisitionId && draftRequisition) {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_USE_REQUIRED_OPTIONS_FROM_SESSION,
        payload: requiredOptionSessionState,
      });
    }
    setRequiredOptionsState(requiredOptionSessionState);
  }, []);

  const updateDraftIfNeeded = () => {
    if (requisitionId) {
      updateDraftRequisition(requisitionId, {
        optionsSelected: requiredOptionSessionState,
        isAreq,
      });
    }
  };

  useEffect(() => {
    const data = getChangedValue(addOptionsState);
    const newValues = JSON.stringify(data);
    const previousValues = JSON.stringify(oldValues);
    let needToUpdate = newValues !== previousValues;

    // if there was one option got cancelled by user
    // and there is no option left on the list
    // we should allow the price to be updated
    if (oldValues.length && !data.length) {
      needToUpdate = true;
    }

    if (needToUpdate && !initialRender.current) {
      const hasError =
        data
          .map((i) => i.options)
          .filter((i) => {
            return i.filter((o) => o.hasError === true).length > 0;
          }).length > 0;

      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_IS_CALCULATE_PRICE_ERROR,
        payload: hasError,
      });

      setOldValues(cloneDeep(data));
    }
    initialRender.current = false;

    if (needToUpdate) {
      setOldValues(data);
    }

    prevAddOptionState.current = addOptionsState;
    if (!requisitionType) {
      updateDraftIfNeeded();
    }
  }, [addOptionsState]);

  useEffect(() => {
    if (!requisitionType) {
      updateDraftIfNeeded();
    }
  }, [isAreq]);

  useEffect(() => {
    if (showConflictModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showConflictModal]);

  useEffect(() => {
    const checkData =
      getPurchaseCollisionInfo?.getPurchaseCollisionForAllVendor
        ?.vendorCollisionsPerOption.length;
    if (checkData) {
      const itemsIncluded =
        getPurchaseCollisionInfo.getPurchaseCollisionForAllVendor
          .vendorCollisionsPerOption;
      setSelectedItemsForPurchase(itemsIncluded);
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_SELECTED_ITEMS_FOR_PURCHASE,
        payload: itemsIncluded,
      });

      if (addOptionsState.length) {
        addOptionsLocal = [...addOptionsState];
      }

      addOptionsLocal.forEach((optionList) => {
        optionList.options.forEach((option) => {
          option.vendorValues.forEach((vendorValue) => {
            const value = vendorValue;
            value.isIncludeExist = false;
            value.isExcludeExist = false;
            value.isRequiredExist = false;
            value.unResolvedExist = false;
          });
        });
      });

      const updatedIncludedOptions = checkOptionDataIncluded(
        itemsIncluded,
        addOptionsLocal,
      );
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_OPTIONS_WITH_COLLISIONS_PROPERTIES,
        addOptionsState: updatedIncludedOptions,
        addOptionsDataWithCollisions: updatedIncludedOptions,
      });
      setIsLoading(false);
      setShowConflictModal(false);
    }
  }, [getPurchaseCollisionInfo]);

  const conflictModalRadioHandler = (
    receivedItem,
    requiredOption,
    choosedItem,
  ) => {
    let resolvedVendor = resolvedOptions;
    if (resolvedVendor.length) {
      const optionsExist = resolvedVendor.filter(
        (item) => item.contractLineId === receivedItem.contractLineId,
      );
      if (optionsExist && optionsExist.length) {
        resolvedVendor.forEach((item) => {
          const resolvedItem = item;
          if (resolvedItem.contractLineId === receivedItem.contractLineId) {
            resolvedItem.selectedIndex = requiredOption.index;
          }
          return resolvedItem;
        });
      } else {
        resolvedVendor = [
          ...resolvedVendor,
          {
            contractLineId: receivedItem.contractLineId,
            optionCode: choosedItem.optionCode,
            selectedIndex: requiredOption.index,
          },
        ];
      }
    } else {
      resolvedVendor = [
        ...resolvedVendor,
        {
          contractLineId: receivedItem.contractLineId,
          optionCode: choosedItem.optionCode,
          selectedIndex: requiredOption.index,
        },
      ];
    }

    setResolvedOptions([...resolvedVendor]);
  };

  const collisionQueryVariables = (
    standardItemId,
    totalSelected,
    optionCode,
    totalSelectedOnYes,
    transactionType,
  ) => {
    if (optionCode && !totalSelectedOnYes) {
      return {
        standardItemId: parseInt(standardItemId, 10),
        selectedOptions: totalSelected,
        affectedOption: {
          optionCode,
          optionQuantity: 1,
        },
        // TODO: Fix this properly when leasing is implemented
        transactionType: transactionType || 'VEHICLE_SALE',
      };
    }

    return {
      standardItemId: parseInt(standardItemId, 10),
      selectedOptions: [...totalSelectedOnYes],
    };
  };

  const checkCollisionIdentification = (optionCode) => {
    addOptionsAllContractData.forEach((addOptionItem) => {
      addOptionItem.options.forEach((optionValue) => {
        optionValue.vendorValues.forEach((value) => {
          if (
            value.equipmentCode === optionCode &&
            value.contractLineEcRelation &&
            value.contractLineEcRelation.length !== 0
          ) {
            value.contractLineEcRelation.forEach((li) => {
              returnableData.push(li);
            });
            openModal = true;
          }
        });
      });
    });
  };

  const updateAddOptionsAutoSelection = (checked, allOptions) => {
    addOptionsState.forEach((addOption) => {
      addOption.options.forEach((li) => {
        const option = li;
        const checkOptionExist = allOptions.indexOf(option.optionCode);
        if (checkOptionExist > -1) {
          option.isChecked = checked;
        }
      });
    });
  };

  // pre-selected options
  const preselectedOptions = {};
  useEffect(() => {
    const extractCheckedOptions = (targetArray) => {
      targetArray.every((option) => {
        if (
          preselectedOptions[option.optionCode] !== undefined &&
          option.quantity
        ) {
          preselectedOptions[option.optionCode] = {
            isChecked: true,
            optionValue: option.quantity,
          };
        }
        return true;
      });
    };

    // requiredOptionSessionState comes from the saved requisition
    if (requiredOptionSessionState?.length) {
      requiredOptionSessionState.every((option) => {
        if (preselectedOptions[option.optionCode] === undefined) {
          preselectedOptions[option.optionCode] = {
            isChecked: option.isChecked,
            optionValue: option.optionQuantity > 0 ? option.optionQuantity : '',
          };
        }
        return true;
      });
    }

    // displayingCostBreakdown comes from page transition
    if (displayingCostBreakdown?.length) {
      displayingCostBreakdown.every((item) => {
        if (item.perOrderOptions?.length) {
          extractCheckedOptions(item.perOrderOptions);
        }
        if (item.perVehicleOptions?.length) {
          extractCheckedOptions(item.perVehicleOptions);
        }
        if (item.perVehicleRequiredOptions?.length) {
          extractCheckedOptions(item.perVehicleRequiredOptions);
        }
        return true;
      });
    }
    let updatedStatewithIncludedOptions = addOptionsData;

    if (selectedItemsForPurchaseState && selectedItemsForPurchaseState.length) {
      updatedStatewithIncludedOptions = checkOptionDataIncluded(
        selectedItemsForPurchase,
        addOptionsData,
      );
    }

    if (JSON.stringify(oldAddOptionsData) !== JSON.stringify(addOptionsData)) {
      oldAddOptionsData = cloneDeep(addOptionsData);
      const oldSelectedOptionList = getChangedValue(addOptionsState);
      const { oldValuesList = [], newAddOptionData } = modifiedNewOptions(
        oldSelectedOptionList,
        addOptionsData,
        requiredOptionSessionState,
      );

      if (oldValuesList.length) {
        setOldValues(cloneDeep(oldValuesList));
      }

      updatedStatewithIncludedOptions = newAddOptionData;
      if (
        selectedItemsForPurchaseState &&
        selectedItemsForPurchaseState.length
      ) {
        updatedStatewithIncludedOptions = checkOptionDataIncluded(
          selectedItemsForPurchase,
          newAddOptionData,
        );
      }
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_ADD_OPTIONS_STATE,
        payload: updatedStatewithIncludedOptions,
      });
    }

    if (requiredOptionSessionState?.length) {
      const updatedAddOptionState = updatedStatewithIncludedOptions.map(
        (addOptionItem) => {
          const item = addOptionItem;
          const updatedOption = item.options.map((option) => {
            const optionItem = option;
            const foundObj = requiredOptionSessionState.find(
              (a) => a.optionCode === option.optionCode,
            );
            if (foundObj) {
              optionItem.isChecked = true;
              if (foundObj.optionQuantity)
                optionItem.optionValue = foundObj.optionQuantity; //  Setting the value for inputs
            } else {
              optionItem.isChecked = false;
            }

            return optionItem;
          });
          item.options = updatedOption;
          return item;
        },
      );

      if (updatedAddOptionState.length) {
        dispatch({
          type: VehicleRequisitionContextActions.UPDATE_ADD_OPTIONS_STATE,
          payload: updatedAddOptionState,
        });
      }
    }
  }, [displayingCostBreakdown, addOptionsState.length, requisitionId]);

  // addOptionErrorRef is temp register for counting how mant errors
  // there are. Using useRef to save the memory.
  const addOptionErrorRef = useRef({});
  const onNumInputChange = (value, optionCode, justValidate = false) => {
    let hasError = false;
    const numValue = parseInt(value, 10);
    const isNumNan = Number.isNaN(numValue);
    const inputError = numValue > 999 || isNumNan || numValue <= 0;
    const updatedAddOptionState = addOptionsState.map((addOptionItem) => {
      const item = addOptionItem;
      const updatedOption = item.options.map((option) => {
        const optionItem = option;
        const errorKey = optionItem.optionCode;
        const hasNumInput =
          optionItem.quantityRequiredCode === 'V' ||
          optionItem.quantityRequiredCode === 'O';
        if (optionItem.optionCode === optionCode) {
          if (!justValidate) {
            optionItem.optionValue = value;
          }

          const valstr = value ? value.toString() : '';

          if (valstr.length > 4) {
            optionItem.optionValue = parseInt(valstr.slice(0, 4), 10);
          }

          if (!justValidate) {
            optionItem.hasError = inputError;
          }

          if (hasNumInput && optionItem.hasError && !hasError) {
            hasError = true;
            addOptionErrorRef.current[errorKey] = true;
          }

          if (hasNumInput && (!optionItem.hasError || !hasError)) {
            addOptionErrorRef.current[errorKey] = false;
          }
        } else if (
          hasNumInput &&
          optionItem.isChecked &&
          !optionItem.optionValue
        ) {
          // since this loop goes through the whole array,
          // we might use this chance to validate as well
          addOptionErrorRef.current[errorKey] = true;
        }
        return optionItem;
      });
      item.options = updatedOption;
      return item;
    });

    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_IS_CALCULATE_PRICE_ERROR_AND_BUTTON_DISABLED,
      payload: hasError,
      isCalculatePriceDisabled: hasError,
    });

    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_ADD_OPTIONS_STATE,
      payload: updatedAddOptionState,
    });

    const newRequiredOptions = requiredOptionSessionState.map((option) => {
      return {
        ...option,
        optionQuantity:
          option.optionCode === optionCode ? value : option.optionQuantity,
      };
    });

    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_USE_REQUIRED_OPTIONS_FROM_SESSION,
      payload: newRequiredOptions,
    });

    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_ADD_OPTIONS_ERRORS,
      payload: addOptionErrorRef.current,
    });
  };

  const onNumInputFocusChanged = (focused, optionCode) => {
    let hasError = false;
    const updatedAddOptionState = addOptionsState.map((addOptionItem) => {
      const item = addOptionItem;
      const updatedOption = item.options.map((option) => {
        const optionItem = option;
        const errorKey = optionItem.optionCode;

        if (optionItem.optionCode === optionCode) {
          if (optionItem.hasError && !hasError) {
            hasError = true;
          }
          optionItem.focused = focused;

          // if user selected the option with numInput
          // without enter the valid number, then
          // pop up the error message
          if (optionItem.optionValue === '' && !focused) {
            optionItem.optionValue = '';
            optionItem.hasError = true;
            addOptionErrorRef.current[errorKey] = true;
          }
        }
        return optionItem;
      });
      item.options = updatedOption;
      return item;
    });

    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_IS_CALCULATE_PRICE_ERROR_AND_BUTTON_DISABLED,
      payload: hasError,
      isCalculatePriceDisabled: hasError,
    });

    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_ADD_OPTIONS_STATE,
      payload: updatedAddOptionState,
    });

    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_ADD_OPTIONS_ERRORS,
      payload: addOptionErrorRef.current,
    });
  };
  const purchaseCollision = (selectedVendorCollisionInfo) => {
    if (selectedVendorCollisionInfo) {
      setConflictAPIData(selectedVendorCollisionInfo);
      identifyNoCollisionAfterSelection = [];
      selectedVendorCollisionInfo.vendorCollisionsPerOption.forEach((item) => {
        if (
          item.affectedExcludedOptions.length === 0 &&
          item.affectedIncludedOptions.length === 0 &&
          item.affectedRequiredOptions.length === 0
        ) {
          identifyNoCollisionAfterSelection.push(item);
        }
      });
    }
  };
  const handleRowCheckBoxClick = (optionCode, isFromModal = false) => {
    setIdentifyRadioCheck(false);
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_IS_CALCULATE_PRICE_BUTTON_DISABLED,
      isCalculatePriceDisabled: false,
    });
    checkCollisionIdentification(optionCode);
    let totalSelectedOnYes = [];
    if (requiredOptionSessionState?.length) {
      totalSelectedOnYes = cloneDeep(requiredOptionSessionState);
    } else {
      totalSelectedOnYes = cloneDeep(selectedItems);
    }

    const updatedAddOptionState = addOptionsState.map((addOptionItem) => {
      const item = addOptionItem;
      const updatedOption = item.options.map((list) => {
        const optionItem = list;
        if (
          optionItem.optionCode === optionCode &&
          !optionItem.isChecked &&
          !isFromModal &&
          openModal
        ) {
          if (returnableData.length !== 0) {
            setSelectedOptionItem(optionItem);
            setShowConflictModal(openModal);
            setSelectedOptionCode(optionCode);
            getPurchaseCollisionData({
              variables: collisionQueryVariables(
                item.standardItemId,
                totalSelectedOnYes,
                optionCode,
                null,
                getRequisitionTransactionType(
                  sessionStorage.getItem('agencyCode'),
                  sessionStorage.getItem('bureauCode'),
                ),
              ),
            }).then((result) => {
              purchaseCollision(result?.data?.getPurchaseCollisionForAllVendor);
            });
          }
        } else {
          if (isFromModal && optionItem.optionCode === optionCode) {
            /** 
               * let data = [];
              if (requiredOptionSessionState?.length) {
                data = requiredOptionSessionState;
              }
              */

            const simplifiedPayloadInfo = getSimplifiedPayload(
              optionItem,
              resolvedOptions,
            );
            totalSelectedOnYes = totalSelectedOnYes.filter(
              (opt) => opt.optionCode !== optionCode,
            );
            totalSelectedOnYes.push({
              optionCode,
              optionQuantity: 1,
              vendorRequiredOptionSelection: simplifiedPayloadInfo,
            });
            // console.log(totalSelectedOnYes);

            dispatch({
              type: VehicleRequisitionContextActions.UPDATE_USE_REQUIRED_OPTIONS_FROM_SESSION,
              payload: totalSelectedOnYes,
            });

            getPurchaseCollisionDataOnYes({
              variables: collisionQueryVariables(
                item.standardItemId,
                totalSelectedOnYes,
                false,
                totalSelectedOnYes,
                getRequisitionTransactionType(
                  sessionStorage.getItem('agencyCode'),
                  sessionStorage.getItem('bureauCode'),
                ),
              ),
            });

            // automatically unselect options that are excluded from all vendors
            if (conflictAPIData?.unselectOptions?.length) {
              updateAddOptionsAutoSelection(
                false,
                conflictAPIData.unselectOptions,
              );
            }

            // automatically select options that are required by all vendors
            if (conflictAPIData?.selectOptions?.required.length > 0) {
              updateAddOptionsAutoSelection(
                true,
                [].concat(conflictAPIData.selectOptions.required),
              );
            }

            setIsLoading(false);
            setResolvedOptions([]);
          }
          if (!openModal) {
            setConflictAPIData([]);
          }
          if (optionItem.optionCode === optionCode) {
            optionItem.isChecked = !optionItem.isChecked;
            if (!isFromModal || !resolvedOptions.length) {
              totalSelectedOnYes.push({
                optionCode,
                optionQuantity: optionItem.optionValue
                  ? optionItem.optionValue
                  : 1,
                isChecked: optionItem.isChecked,
              });
            }

            const filteredRequiredOptions =
              requiredOptionsState?.filter((option) => {
                return option.optionCode !== optionCode;
              }) || [];

            setRequiredOptionsState(filteredRequiredOptions);

            if (!optionItem.isChecked) {
              totalSelectedOnYes = totalSelectedOnYes.filter(
                (selectedOption) => {
                  return selectedOption.optionCode !== optionCode;
                },
              );

              if (returnableData.length !== 0) {
                setIsLoading(true);
                dispatch({
                  type: VehicleRequisitionContextActions.UPDATE_USE_REQUIRED_OPTIONS_FROM_SESSION,
                  payload: totalSelectedOnYes,
                });

                getPurchaseCollisionDataOnYes({
                  variables: collisionQueryVariables(
                    item.standardItemId,
                    false,
                    false,
                    totalSelectedOnYes,
                    getRequisitionTransactionType(
                      sessionStorage.getItem('agencyCode'),
                      sessionStorage.getItem('bureauCode'),
                    ),
                  ),
                });
              }
            }
            if (
              optionItem.isChecked &&
              (optionItem.quantityRequiredCode === 'V' ||
                optionItem.quantityRequiredCode === 'O')
            ) {
              optionItem.optionValue = '';
              optionItem.focused = true;
            }
            if (
              !optionItem.isChecked &&
              (optionItem.quantityRequiredCode === 'V' ||
                optionItem.quantityRequiredCode === 'O')
            ) {
              optionItem.optionValue = '';
              optionItem.focused = false;
              optionItem.hasError = false;
              onNumInputChange(null, optionItem.optionCode, true);
            }
          }
        }
        return optionItem;
      });
      item.options = updatedOption;
      return item;
    });
    setRequiredOptionsState(totalSelectedOnYes);
    if (!openModal) {
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_ADD_OPTIONS_STATE,
        payload: updatedAddOptionState,
      });
    }

    const removableOptionOnCollision = conflictAPIData?.unselectOptions;

    if (removableOptionOnCollision) {
      totalSelectedOnYes = totalSelectedOnYes.filter((item) => {
        return !(removableOptionOnCollision.indexOf(item.optionCode) > -1);
      });
    }

    if (
      conflictAPIData?.selectOptions?.included.length > 0 ||
      conflictAPIData?.selectOptions?.required.length > 0
    ) {
      const addedOptionsOnCollision = [].concat(
        conflictAPIData.selectOptions.included,
        conflictAPIData.selectOptions.required,
      );
      addedOptionsOnCollision.forEach((addedOption) => {
        totalSelectedOnYes.push({
          optionCode: addedOption,
          optionQuantity: 1,
        });
      });
    }

    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_USE_REQUIRED_OPTIONS_FROM_SESSION,
      payload: [...totalSelectedOnYes],
    });
  };

  const handleRadioSelection = (
    optionCode,
    optionCategoryCode,
    _checked,
    isFromModal = false,
  ) => {
    setIdentifyRadioCheck(true);
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_IS_CALCULATE_PRICE_BUTTON_DISABLED,
      isCalculatePriceDisabled: false,
    });
    setSelectedOptionCode(optionCode);
    setSelectedOptionCategoryCode(optionCategoryCode);

    checkCollisionIdentification(optionCode);
    let totalSelectedOnYes = [];
    if (requiredOptionSessionState?.length) {
      totalSelectedOnYes = cloneDeep(requiredOptionSessionState);
    } else {
      totalSelectedOnYes = cloneDeep(selectedItems);
    }

    const updatedAddOptionState = addOptionsState.map((addOptionItem) => {
      const item = addOptionItem;
      setStandardItemSelected(item.standardItemCode);
      if (
        item.selectionType === 'S' &&
        item.optionCategoryCode === optionCategoryCode
      ) {
        const updatedOption = item.options.map((option) => {
          const optionItem = option;
          const newSelected = [];

          newSelected.push({
            optionCode: optionItem.optionCode,
            optionQuantity: 1,
          });

          if (optionItem.optionCode !== optionCode) {
            const removedUnselectedRadio = totalSelectedOnYes.filter(
              (selectedItem) => {
                return newSelected.find(
                  (li) => li.optionCode !== selectedItem.optionCode,
                );
              },
            );
            totalSelectedOnYes = removedUnselectedRadio;
            setSelectedList(removedUnselectedRadio);
          }

          if (
            openModal &&
            !isFromModal &&
            !optionItem.isChecked &&
            optionItem.optionCode === optionCode
          ) {
            if (returnableData.length !== 0) {
              setSelectedOptionItem(optionItem);
              setShowConflictModal(openModal);
              setSelectedOptionCode(optionCode);
              getPurchaseCollisionData({
                variables: collisionQueryVariables(
                  addOptionItem.standardItemId,
                  totalSelectedOnYes,
                  optionCode,
                  null,
                  getRequisitionTransactionType(
                    sessionStorage.getItem('agencyCode'),
                    sessionStorage.getItem('bureauCode'),
                  ),
                ),
              }).then((result) => {
                purchaseCollision(
                  result?.data?.getPurchaseCollisionForAllVendor,
                );
              });
            }
          } else {
            if (isFromModal && optionItem.optionCode === optionCode) {
              /*  if (resolvedOptions.length) {
                let data = [];
                if (requiredOptionSessionState?.length) {
                  data = requiredOptionSessionState;
                } 
             */

              const simplifiedPayloadInfo = getSimplifiedPayload(
                optionItem,
                resolvedOptions,
              );

              totalSelectedOnYes = totalSelectedOnYes.filter(
                (opt) => opt.optionCode !== optionCode,
              );

              totalSelectedOnYes.push({
                optionCode,
                optionQuantity: 1,
                vendorRequiredOptionSelection: simplifiedPayloadInfo,
              });

              /*

                data.push({
                  optionCode,
                  optionQuantity: 1,
                  vendorRequiredOptionSelection: simplifiedPayloadInfo,
                });
                dispatch({
                  type: VehicleRequisitionContextActions.UPDATE_USE_REQUIRED_OPTIONS_FROM_SESSION,
                  payload: data,
                });
              } 
              */
              dispatch({
                type: VehicleRequisitionContextActions.UPDATE_USE_REQUIRED_OPTIONS_FROM_SESSION,
                payload: totalSelectedOnYes,
              });

              getPurchaseCollisionDataOnYes({
                variables: collisionQueryVariables(
                  item.standardItemId,
                  totalSelectedOnYes,
                  false,
                  totalSelectedOnYes,
                  getRequisitionTransactionType(
                    sessionStorage.getItem('agencyCode'),
                    sessionStorage.getItem('bureauCode'),
                  ),
                ),
              });

              // automatically unselect options that are excluded from all vendors
              if (conflictAPIData?.unselectOptions?.length) {
                updateAddOptionsAutoSelection(
                  false,
                  conflictAPIData.unselectOptions,
                );
              }

              // automatically select options that are required by all vendors
              if (conflictAPIData?.selectOptions?.required.length > 0) {
                updateAddOptionsAutoSelection(
                  true,
                  [].concat(conflictAPIData.selectOptions.required),
                );
              }
              setIsLoading(false);
              setResolvedOptions([]);
            }
            if (!openModal) {
              setConflictAPIData([]);
            }
            if (
              optionItem.optionCode === optionCode &&
              optionItem.isChecked === true
            ) {
              optionItem.isChecked = false;

              const removeUnselectedOption = totalSelectedOnYes.filter(
                (selectedItem) => {
                  return optionCode !== selectedItem.optionCode;
                },
              );
              totalSelectedOnYes = removeUnselectedOption;
              setIdentifyRadioCheck(false);
              if (returnableData.length !== 0) {
                setIsLoading(true);
                dispatch({
                  type: VehicleRequisitionContextActions.UPDATE_USE_REQUIRED_OPTIONS_FROM_SESSION,
                  payload: totalSelectedOnYes,
                });

                getPurchaseCollisionDataOnYes({
                  variables: collisionQueryVariables(
                    addOptionItem.standardItemId,
                    false,
                    false,
                    totalSelectedOnYes,
                    getRequisitionTransactionType(
                      sessionStorage.getItem('agencyCode'),
                      sessionStorage.getItem('bureauCode'),
                    ),
                  ),
                });
              }
            } else if (optionItem.optionCode === optionCode) {
              optionItem.isChecked = true;
              const foundOptionInTotal = totalSelectedOnYes.find(
                (opt) => opt.optionCode === optionCode,
              );
              if (!foundOptionInTotal) {
                totalSelectedOnYes.push({
                  optionCode,
                  optionQuantity: 1,
                });
              }
            } else {
              optionItem.isChecked = false;
            }
          }

          return optionItem;
        });
        item.options = updatedOption;
      }

      return item;
    });

    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_ADD_OPTIONS_STATE,
      payload: updatedAddOptionState,
    });

    setRequiredOptionsState(totalSelectedOnYes);
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_USE_REQUIRED_OPTIONS_FROM_SESSION,
      payload: [...totalSelectedOnYes],
    });
  };

  const handleDeliveryOptionSelection = (
    optionCode,
    _optionCategoryCode, // might be useful later on */
    checked,
  ) => {
    setIdentifyRadioCheck(true);
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_IS_CALCULATE_PRICE_BUTTON_DISABLED,
      isCalculatePriceDisabled: false,
    });
    const updatedDeliveryOptionState = deliveryOptions.map(
      (deliveryOptionItem) => {
        const item = deliveryOptionItem; // to avoid assigment to the function param
        if (item.selectionType === 'S') {
          const updatedOption = item.options.map((option) => {
            const optionItem = option; // to avoid assigment to the function param
            if (optionItem.optionCode === optionCode) {
              optionItem.isChecked = !checked;
            } else {
              optionItem.isChecked = false;
            }
            return optionItem;
          });
          item.options = updatedOption;
        }

        return item;
      },
    );

    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_DELIVERY_OPTIONS,
      payload: updatedDeliveryOptionState,
    });
  }; // eo handleDeliveryOptionSelection

  useEffect(() => {
    // Pre-select default delivery option in case shipment is (foriegn country)
    if (
      localDeliveryOptions.length === 1 &&
      localDeliveryOptions[0].options[0]?.optionCode === '1611'
    ) {
      handleDeliveryOptionSelection('1611');
    }
  }, []);

  const noSelectionHandler = () => {
    setShowConflictModal(false);
    setConflictAPIData([]);
    setResolvedOptions([]);
    if (identifyRadioCheck) {
      getPurchaseCollisionDataOnYes({
        variables: collisionQueryVariables(
          standardItemSelected,
          false,
          false,
          selectedList,
          getRequisitionTransactionType(
            sessionStorage.getItem('agencyCode'),
            sessionStorage.getItem('bureauCode'),
          ),
        ),
      });
    }
  };

  const optionSelectionClick = (checkRadioSelected) => {
    setConflictAPIData([]);
    setResolvedOptions([]);
    setIsLoading(true);
    if (checkRadioSelected) {
      handleRadioSelection(
        selectedOptionCode,
        selectedOptionCategoryCode,
        checkRadioSelected,
        true,
      );
    } else {
      handleRowCheckBoxClick(selectedOptionCode, true);
    }
  };

  useEffect(() => {
    const selectedVendorCollisionInfo =
      getPurchaseCollision?.getPurchaseCollisionForAllVendor;

    if (selectedVendorCollisionInfo) {
      setConflictAPIData(selectedVendorCollisionInfo);

      identifyNoCollisionAfterSelection = [];
      selectedVendorCollisionInfo.vendorCollisionsPerOption.forEach((item) => {
        if (
          item.affectedExcludedOptions.length === 0 &&
          item.affectedIncludedOptions.length === 0 &&
          item.affectedRequiredOptions.length === 0
        ) {
          identifyNoCollisionAfterSelection.push(item);
        }
      });
      if (
        identifyNoCollisionAfterSelection.length ===
        selectedVendorCollisionInfo.vendorCollisionsPerOption.length
      ) {
        handleRowCheckBoxClick(selectedOptionCode, true);
      }
    }
  }, [getPurchaseCollision]);

  const getDeliveryOptionsContent = () => {
    const errorLabel = deliveryOptionErrors > 1 ? 'errors' : 'error';

    if (selectedState) {
      localDeliveryOptions = limitDeliveryOptionsByState(
        localDeliveryOptions,
        selectedState,
      );
    }

    return [
      {
        title: (
          <div
            className="add-options-accordion-title"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            Delivery Options (choose one)
            {deliveryOptionErrors ? (
              <div className="add-options-accordion-title-error">
                <Icon className="usa-icon--size-3" iconName="error" />
                This section contains {`${deliveryOptionErrors} ${errorLabel}`}
              </div>
            ) : null}
          </div>
        ),
        content: (
          <>
            {localDeliveryOptions && !!localDeliveryOptions.length ? (
              <>
                <div className="option-table">
                  <table
                    className="add-option-main-container delivery-option-accordion"
                    role="presentation"
                  >
                    <tbody>
                      <AccordionContent
                        addOptionsState={localDeliveryOptions}
                        handleRowCheckBoxClick={handleRowCheckBoxClick}
                        onNumInputChange={onNumInputChange}
                        onNumInputFocusChanged={onNumInputFocusChanged}
                        handleRadioSelection={handleDeliveryOptionSelection}
                        lowestBidData={lowestBidData}
                      />
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <>Not Available</>
            )}
          </>
        ),
        expanded: false,
      }, // eo Delivery Option section
    ];
  };

  const getAddOptionsContent = () => {
    const areqColSpanCount =
      (addOptionsState[0]?.options[0]?.vendorValues?.length ?? 0) + 1;

    const errorLabel = addOptionErrors > 1 ? 'errors' : 'error';
    return [
      {
        title: (
          <div
            className="add-options-accordion-title"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            {displaySelectedOptions ? 'Selected Options' : 'Add Options'}
            {addOptionErrors ? (
              <div className="add-options-accordion-title-error">
                <Icon className="usa-icon--size-3" iconName="error" />
                This section contains {`${addOptionErrors} ${errorLabel}`}
              </div>
            ) : null}
          </div>
        ),
        content: (
          <>
            {addOptionsState && (
              <>
                <div className="option-table">
                  <table
                    className="add-option-main-container"
                    role="presentation"
                  >
                    <tbody>
                      <AccordionContent
                        displayCheckedOptionsOnly={displaySelectedOptions}
                        addOptionsState={addOptionsState}
                        handleRowCheckBoxClick={handleRowCheckBoxClick}
                        onNumInputChange={onNumInputChange}
                        onNumInputFocusChanged={onNumInputFocusChanged}
                        handleRadioSelection={handleRadioSelection}
                        lowestBidData={lowestBidData}
                      />
                      {displaySelectedOptions || (
                        <AreqOptions
                          colSpanCount={areqColSpanCount}
                          calculatePriceClick={calculatePriceClick}
                        />
                      )}
                    </tbody>
                  </table>
                  {isAreq || displaySelectedOptions || (
                    <PriceButton calculatePriceClick={calculatePriceClick} />
                  )}
                </div>
              </>
            )}
          </>
        ),
        expanded: displaySelectedOptions,
      }, // eo Add Option section
    ];
  };

  const modalDataAvailable = !!conflictAPIData && conflictAPIData.length;

  return (
    <>
      <div id="add-options">
        {displaySelectedOptions || (
          <Accordion
            multiselectable={false}
            bordered="true"
            items={getDeliveryOptionsContent()}
            className={`add-delivery-options-accordion ${
              addOptionErrors ? 'accordion-error' : ''
            }`}
          />
        )}
        <Accordion
          multiselectable={false}
          bordered="true"
          items={getAddOptionsContent()}
          className={`add-options-accordion ${
            addOptionErrors ? 'accordion-error' : ''
          }`}
        />
        {(showConflictModal || isLoading) && (
          <div className="afp-modal-overlay modalContainer conflict-modal">
            {!modalDataAvailable && (
              <Spinner size="large" className="margin-y-8" />
            )}

            {!isLoading &&
              conflictAPIData?.vendorCollisionsPerOption?.length &&
              identifyNoCollisionAfterSelection.length !==
                conflictAPIData?.vendorCollisionsPerOption?.length && (
                <Modal
                  title="Your selection requires the following changes"
                  className="collision-found-modal"
                  onClose={() => {
                    noSelectionHandler();
                  }}
                  actions={
                    <>
                      <Button
                        type="button"
                        className="cancel-button"
                        onClick={() => {
                          noSelectionHandler();
                        }}
                        label="No, cancel my selection"
                      />
                      <Button
                        type="button"
                        onClick={() => {
                          optionSelectionClick(identifyRadioCheck);
                        }}
                        label="Yes, apply changes"
                      />
                    </>
                  }
                >
                  <ConflictModalComponent
                    content={conflictAPIData}
                    selectedOptionItem={selectedOptionItem}
                    lowestOption={addOptionsState.length && addOptionsState[0]}
                    conflictModalRadioHandler={conflictModalRadioHandler}
                    resolvedOptions={[...resolvedOptions]}
                  />
                </Modal>
              )}
          </div>
        )}
      </div>
    </>
  );
};

AddOptionsCard.propTypes = {
  addOptionsData: PropTypes.instanceOf(Array),
  calculateTotalSellingPrice: PropTypes.func.isRequired,
  lowestBidData: PropTypes.instanceOf(Object),
  addOptionsAllContractData: PropTypes.instanceOf(Array),
  displayingCostBreakdown: PropTypes.instanceOf(Array),
  notifyUserRef: PropTypes.shape({}).isRequired,
  paginationCurrentPage: PropTypes.number,
  paginationOffset: PropTypes.number,
  displaySelectedOptions: PropTypes.bool,
};

AddOptionsCard.defaultProps = {
  addOptionsAllContractData: [],
  addOptionsData: [],
  lowestBidData: {},
  displayingCostBreakdown: [],
  paginationCurrentPage: 0,
  paginationOffset: 5,
  displaySelectedOptions: false,
};

export default AddOptionsCard;
