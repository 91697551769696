import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

function EngFuelSpecRow({ specData, lowestBidData }) {
  return (
    <tr data-testid={`vehicle-eng-fuel-${specData.label}-row`}>
      <td>
        <label className="option-label">{specData.label}</label>
      </td>
      {specData.values.map((item, index) => {
        const activateLowestBid =
          item.modelName === lowestBidData.modelName &&
          item.vendorName === lowestBidData.vendorName;
        return (
          <td
            data-testid={`vehicle-eng-fuel-${specData.label}-col-${index}`}
            key={`${item.modelName}-${item.vendorName}`}
            className={cx('option-cell', {
              'lowest-bid': activateLowestBid,
            })}
          >
            <label className="option-label">{item.option || '--'}</label>
          </td>
        );
      })}
    </tr>
  );
}

EngFuelSpecRow.propTypes = {
  specData: PropTypes.instanceOf(Object).isRequired,
  lowestBidData: PropTypes.instanceOf(Object),
};

EngFuelSpecRow.defaultProps = {
  lowestBidData: {},
};

const VehicleEngFuelSection = ({ vehicleEngFuelData, lowestBidData }) => {
  return (
    <>
      <div className="veh-overview__container">
        <header className="veh-overview__header">
          Engine & Fuel Specifications
        </header>
        <div className="veh-overview__body">
          <table className="usa-table full-width-table" role="presentation">
            <tbody>
              {vehicleEngFuelData.map((item) => (
                <EngFuelSpecRow
                  specData={item}
                  lowestBidData={lowestBidData}
                  key={item?.label}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

VehicleEngFuelSection.propTypes = {
  vehicleEngFuelData: PropTypes.instanceOf(Array).isRequired,
  lowestBidData: PropTypes.instanceOf(Object),
};

VehicleEngFuelSection.defaultProps = {
  lowestBidData: {},
};

export default VehicleEngFuelSection;
