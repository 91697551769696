import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextInput } from '@gsa/afp-component-library';
import classNames from 'classnames';
import './UpdateQuantityInput.scss';

const OptionQuantityInput = ({
  optionQuantity,
  optionType,
  setOptionQuantity,
  updateQtyHasError,
  setUpdateQtyHasError,
}) => {
  const [errorText, setErrorText] = useState(null);
  const validQuantity = (value) => {
    return value ? value >= 1 && value <= 999 : false;
  };

  // eslint-disable-next-line consistent-return
  const getLabel = () => {
    if (optionType === 'V') {
      return 'Enter quantity per vehicle (V): ';
    }
    if (optionType === 'O') {
      return 'Enter quantity per order (O): ';
    }
    return 'Enter quantity: ';
  };

  const handleValid = () => {
    setUpdateQtyHasError(false);
    setErrorText(null);
  };

  const handleInvalid = () => {
    setUpdateQtyHasError(true);
    setErrorText('Quantity can only be between 1 and 999');
  };

  const KEYCODE_PERIOD = 190;
  const KEYCODE_DASH = 189;
  const KEYCODE_PLUS = 187;
  const KEYCODE_SUBSTRACT = 109;
  const KEYCODE_DECIMAL_POINT = 110;

  const handleKeyDown = (event) => {
    const { keyCode } = event;
    switch (keyCode) {
      case KEYCODE_PERIOD:
      case KEYCODE_DASH:
      case KEYCODE_PLUS:
      case KEYCODE_SUBSTRACT:
      case KEYCODE_DECIMAL_POINT:
        event.preventDefault();
        break;
      default:
        break;
    }
  };

  const onChange = (event) => {
    const val = event?.target?.value;
    if (val === '') {
      setUpdateQtyHasError(true);
      return setOptionQuantity(val);
    }
    setUpdateQtyHasError(false);
    // eslint-disable-next-line no-unused-expressions
    return validQuantity(val) && setOptionQuantity(val);
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    validQuantity(optionQuantity) ? handleValid() : handleInvalid();
  }, [optionQuantity]);

  return (
    <div className="mod-option-quantity-section">
      <div
        className={classNames('option-quantity-input', {
          error: errorText,
        })}
      >
        <TextInput
          data-testid="quantity-input-test-id"
          label={getLabel()}
          name="option-quantity"
          type="text"
          onKeyDown={handleKeyDown}
          onChange={onChange}
          value={optionQuantity}
          errorMessage={errorText}
          showLabelError={false}
          errorMessagePlacementEnd="true"
          aria-invalid={updateQtyHasError ? 'true' : 'false'}
        />
      </div>
    </div>
  );
};

OptionQuantityInput.propTypes = {
  optionQuantity: PropTypes.string.isRequired,
  optionType: PropTypes.string.isRequired,
  setOptionQuantity: PropTypes.func.isRequired,
  updateQtyHasError: PropTypes.bool.isRequired,
  setUpdateQtyHasError: PropTypes.func.isRequired,
};

export default OptionQuantityInput;
