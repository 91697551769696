import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import {
  AFPTable,
  AFPTableRowAction,
  EmptyState,
  Link as GoToLink,
  Spinner,
} from '@gsa/afp-component-library';
import Moment from 'moment';

import {
  GET_ORDER_ATTACHMENT_TYPES,
 // GET_ORDER_ATTACHMENTS,
  GET_ORDER_ATTACHMENTS_BY_CASE_NUMBER
} from '../../../services/data-layer';
import OrderAttachmentUpload from './OrderAttachmentUpload';
import './OrderAttachments.scss';
import AttachmentDownload from '../../ReviewDetails/Attachment/AttachmentDownload/AttachmentDownload';

const OrderAttachments = ({ setTab, orderId, caseNumber }) => {
  useEffect(() => setTab('orderAttachments'), [setTab]);
  const [orderAttachments, setOrderAttachments] = useState([]);
  const [sort, setSort] = useState([['createdAt', 'ASC']]);
  const [orderAttachmentTypes, setOrderAttachmentTypes] = useState([]);

  const { data: orderAttachmentTypesData } = useQuery(
    GET_ORDER_ATTACHMENT_TYPES,
  );

  const { data, loadingOrderAttachments, refetch } = useQuery(
    GET_ORDER_ATTACHMENTS_BY_CASE_NUMBER,
    {
      variables: {
        caseNumber,
        type: '',
      },
    },
  );

  useEffect(() => {
    if (data && data?.getAllOrderAttachmentsByCaseNumber) {
      setOrderAttachments(data?.getAllOrderAttachmentsByCaseNumber);
    }
  }, [data]);

  useEffect(() => {
    if (
      orderAttachmentTypesData &&
      orderAttachmentTypesData?.getAllOrderAttachmentTypes
    ) {
      setOrderAttachmentTypes(
        orderAttachmentTypesData.getAllOrderAttachmentTypes,
      );
    }
  }, [orderAttachmentTypesData]);

  /* eslint-disable react/prop-types */
  const columns = useMemo(
    () => [
      {
        Header: 'Files',
        accessor: 'type',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          return (
            <AttachmentDownload
              name={original?.fileMetadata?.name}
              metadataId={original?.metadataId}
            />
          );
        },
      },
      {
        Header: 'Viewable by',
        accessor: 'isInternal',
        sortable: true,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) =>
          // eslint-disable-next-line react/prop-types
          original?.isInternal ? 'GSA only' : 'All',
      },
      {
        Header: 'Uploaded by',
        accessor: 'createdUserInfo',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          return (
            // eslint-disable-next-line react/prop-types
            <GoToLink href={`mailto:${original.createdUserInfo?.email}`}>
              {/* eslint-disable-next-line react/prop-types */}
              {original.createdUserInfo?.fullName}
            </GoToLink>
          );
        },
      },
      {
        Header: 'Date / Time',
        accessor: 'createdAt',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => {
          return (
            <>
              {/* eslint-disable-next-line react/prop-types */}
              {Moment(original.createdAt).format('MM/DD/YYYY')} &#8226;{' '}
              {/* eslint-disable-next-line react/prop-types */}
              {Moment(original.createdAt).format('hh:mm A')} ET
            </>
          );
        },
      },
      {
        Header: 'File category',
        accessor: 'attachmentType',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => original?.attachmentType?.description,
      },
      {
        Header: 'Comments',
        accessor: 'description',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => original?.description,
      },
      {
        Header: 'Actions',
        id: 'table-row-action',
        sortable: false,
        headerClassName: 'cell-center',
        cellClassName: 'cell-center',
        // eslint-disable-next-line react/prop-types
        Cell: (props) => <AFPTableRowAction actions={[]} {...props} />,
      },
    ],
    [],
  );

  const setTableSort = (newSort) => {
    setSort([newSort.split(' ')]);
  };

  const handleAttachmentUpload = () => {
    refetch({
      orderId,
      type: '',
    });
  };

  const OrderAttachmentsTable = () => (
    <>
      <AFPTable
        testId="order-vehicle-table"
        columns={columns}
        data={orderAttachments || []}
        onSort={setTableSort}
        defaultSort={sort}
      />
      {loadingOrderAttachments && <Spinner aria-busy="true" size="medium" />}
      {!loadingOrderAttachments && !orderAttachments?.length && (
        <EmptyState
          hasBackground
          containerStyles="margin-top-neg-2 padding-y-10"
          topText="No attachments found for this order"
        />
      )}
    </>
  );

  return (
    <div className="afp-order-attachments-container">
      <div className="afp-order-attachments-container-header">
        <div className="afp-order-attachments-container-header-text">
          Below are all files associated with this order. You may see who
          uploaded the attachment, when, what type of order event category the
          attachment falls within, and optional details.
        </div>
        <OrderAttachmentUpload
          orderId={orderId}
          handleAttachmentUpload={handleAttachmentUpload}
          orderAttachmentTypes={orderAttachmentTypes}
        />
      </div>
      <OrderAttachmentsTable />
    </div>
  );
};

OrderAttachments.propTypes = {
  setTab: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  caseNumber: PropTypes.string.isRequired,

};

export default OrderAttachments;
