import {
  Accordion,
  AFPTable,
  AFPTableRowAction,
  Button,
  EmptyState,
  Modal,
  Spinner,
  TextInput,
} from '@gsa/afp-component-library';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { assign, find, map } from 'lodash';
import RevertIcon from '../../../assets/images/revert-icon';
import SelectedIcon from '../../../assets/images/selected-icon.svg';
import useBulkOrderModsState from '../useBulkOrderModsState';
import './ModifyOptions.scss';
import OptionQuantityInput from './OptionQuantityInput';
import { GET_ORDER_DETAILS } from '../../../services/data-layer';

const ModifyOptions = ({
  selectedFilters,
  paintAndGraphicsAttachments,
  removeAttachment,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [updateOption, setUpdateOption] = useState({});
  const [showUpdateQtyModal, setShowUpdateQtyModal] = useState(false);
  const [optionQuantity, setOptionQuantity] = useState('');
  const [updateQtyHasError, setUpdateQtyHasError] = useState(false);
  // const [defaultSelectedOptions, setDefaultSelectedOptions] = useState([]);
  const [allAvailableOptions, setAllAvailableOptions] = useState([]);
  const [filteredOptionsData, setFilteredOptionsData] = useState([]);
  const [orderWithPO, setOrderWithPO] = useState([]);
  const [changeSetWithPOV, setChangeSetWithPOV] = useState(false);
  const [orderFromSameReq, setOrderFromSameReq] = useState(true);
  const [emptyTableDataMsg, setEmptyTableDataMsg] =
    useState('No options found');
  const [tableView, setTableView] = useState('all');
  const [tableTitle, setTableTitle] = useState('All available options');
  const [selectedContractLine, setSelectedContractLine] = useState(0);
  const [orderDetails, setOrderDetails] = useState({});
  const {
    selectedOptions,
    setSelectedOptions,
    currentChangeSet,
    selectedOrdersForMod,
    setChangeSetOrderWithPO,
    setOrderChanges,
    getOptionsData,
    optionsByContractData,
    optionsLoading,
    minRequiredOptions,
  } = useBulkOrderModsState();

  const mergeOptions = (allOptions, selectedOpts) => {
    const data = map(allOptions, (obj) =>
      assign(
        { ...obj },
        find(selectedOpts, (item) => item.optionCode === obj.optionCode),
      ),
    );
    setFilteredOptionsData(data);
  };

  const [getOrderDetails] = useLazyQuery(GET_ORDER_DETAILS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data) {
        setOrderDetails(data?.getOrderById);
      }
    },
  });
  const getActionName = (action) => {
    switch (action) {
      case 'No Change':
        return 'noChange';
      case 'Remove':
        return 'remove';
      case 'Add':
        return 'add';
      case 'Update quantity':
        return 'updateQty';
      default:
        return 'N/A';
    }
  };
  const getActions = (isSelectedFlag, quantityRequired) => {
    const actions = [{ label: 'No Change', icon: 'remove' }];
    if (isSelectedFlag) {
      actions.push({ label: 'Remove', icon: 'delete' });
    } else {
      actions.push({ label: 'Add', icon: 'add' });
    }
    if (['V', 'O'].includes(quantityRequired) && isSelectedFlag) {
      actions.push({ label: 'Update quantity', icon: 'update' });
    }
    return actions;
  };
  const testId = 'updated-option-qty';

  useEffect(() => {
    if (selectedOrdersForMod && selectedOrdersForMod.length > 0) {
      setChangeSetOrderWithPO(false);
      const firstContractLineId = selectedOrdersForMod[0]?.contractLineId;
      const firstRequisitionNumber = selectedOrdersForMod[0]?.requisitionNumber;
      const orderId = selectedOrdersForMod[0]?.orderId;
      let ordersFromSameReq = true;
      selectedOrdersForMod.forEach((order) => {
        if (order?.requisitionNumber !== firstRequisitionNumber) {
          ordersFromSameReq = false;
        }
      });
      if (!ordersFromSameReq) {
        setOrderFromSameReq(false);
      } else {
        setOrderFromSameReq(true);
        getOrderDetails({
          variables: {
            orderId,
          },
        });
      }
      if (firstContractLineId && firstContractLineId > 0) {
        setSelectedContractLine(firstContractLineId);
        if (selectedFilters && selectedFilters.length > 0) {
          selectedFilters.forEach((filter) => {
            if (filter.key === 'optionCode') {
              setSearchQuery(filter.value);
            }
          });
        }
      }
      const orderHavingPO = [];
      selectedOrdersForMod.forEach((order) => {
        let orderEquipments = [];
        if (order?.orderVehicles?.length > 0) {
            const firstVehicle = order?.orderVehicles.filter((vehicle) => vehicle?.accountingLineNumber === 1);
            orderEquipments = firstVehicle?.length > 0 ? firstVehicle[0].orderVehicleEquipments : [];
        }
        const orderWithPerOrderOption = orderEquipments?.filter(
          (option) => option.quantityType === 'O',
        );
          if (orderWithPerOrderOption && orderWithPerOrderOption.length > 0) {
          orderHavingPO.push(order.caseNumber);
        }
      });
      setOrderWithPO(orderHavingPO);
      if(orderHavingPO.length > 0) {
          setChangeSetOrderWithPO(true);
      }
      if (
        orderHavingPO.length > 0 &&
        selectedOrdersForMod.length !== orderHavingPO.length
      ) {
        setChangeSetWithPOV(true);
      }
    }
  }, [selectedOrdersForMod, currentChangeSet]);

  /**
   * Simplifies Option Data that is returned from API to display in table
   * @param optionsData
   * @returns {*[]}
   */
  const getSimplifiedOptionsData = (optionsData) => {
    const prevSelectedOptions = [];
    if (orderDetails && orderDetails?.orderVehicles?.length > 0) {
      const firstVehicle = orderDetails?.orderVehicles?.filter(
        (vehicle) => vehicle?.accountingLineNumber === 1,
      );
      const orderEquipments =
        firstVehicle?.length > 0 ? firstVehicle[0].orderVehicleEquipments : [];
      orderEquipments?.forEach((equipment) => {
        prevSelectedOptions.push({
          equipmentCode: equipment?.equipmentCode,
          quantity: equipment?.quantity,
          quantityRequired: equipment?.quantityRequired,
          unitPriceToCustomer: equipment?.unitPriceToCustomer,
          unitPriceToGsa: equipment?.unitPriceToGsa,
        });
      });
    }
    const returnableOptionData = [];
    optionsData.forEach((option) => {
      if (option.standardItemEquipmentCode.equipmentCode?.code === 'AREQ')
        return;
      if (option.standardItemEquipmentCode?.equipmentCode?.code) {
        const optionCode =
          option.standardItemEquipmentCode?.equipmentCode?.code;
        let isSelected = false;
        let quantityOnOption = 0;
        let unitPrice = option?.unitPrice ?? 0;
        if (prevSelectedOptions?.length > 0) {
          const selectedOption = prevSelectedOptions.filter(
            (opt) => opt.equipmentCode === optionCode,
          );
          if (selectedOption.length > 0) {
            isSelected = true;
            quantityOnOption = selectedOption[0].quantity;
            unitPrice = selectedOption[0].unitPriceToGsa ?? unitPrice;
          }
        }
        const { id } = option;
        const optionDetails = {
          isChecked: isSelected,
          id,
          optionQuantity: quantityOnOption,
          currentUnitPrice: unitPrice,
          optionTotalPrice: unitPrice * quantityOnOption,
          quantityRequired:
            option.standardItemEquipmentCode?.equipmentCode?.quantityRequired,
          optionCode,
          optionDescription:
            option.standardItemEquipmentCode?.equipmentCode?.title,
          additionalShippingDays: `${option.additionalShippingDays}`,
          action: 'noChange',
          inReq: (
            option?.standardItemEquipmentCode?.equipmentCode?.tags?.value || []
          ).some((tag) => tag.includes('IN_REQ')),
        };
        returnableOptionData.push(optionDetails);
      }
    });
    prevSelectedOptions.forEach((prevSelOption) => {
          const selectedNonAvailableOption = optionsData.find((opt) => opt.standardItemEquipmentCode.equipmentCode.code === prevSelOption.equipmentCode);
            if (!selectedNonAvailableOption) {
                returnableOptionData.push({
                isChecked: true,
                id: prevSelOption.id,
                optionQuantity: prevSelOption.quantity,
                currentUnitPrice: prevSelOption.unitPriceToGsa,
                optionTotalPrice: prevSelOption.unitPriceToGsa * prevSelOption.quantity,
                quantityRequired: prevSelOption.quantityRequired,
                optionCode: prevSelOption.equipmentCode,
                optionDescription: prevSelOption.equipmentCode,
                additionalShippingDays: '0',
                action: 'noChange',
                inReq: false,
                });
            }
    });
    if (minRequiredOptions) {
      return returnableOptionData.filter((option) => {
        return !minRequiredOptions.includes(option.optionCode);
      });
    }

    return returnableOptionData;
  };

  /**
   * Filter options by search key word
   */
  const filterOptionsData = () => {
    setTableView('all');
    setTableTitle('All available options');
    const optionData = allAvailableOptions;
    const filteredData = optionData.filter(
      (item) => item.optionCode.toLowerCase() === searchQuery.toLowerCase(),
    );
    if (!filteredData || filteredData.length < 1) {
      setEmptyTableDataMsg('No options found');
    }
    setFilteredOptionsData(filteredData);
  };

  useEffect(() => {
    if (optionsByContractData && orderDetails) {
      const optionReturned = getSimplifiedOptionsData(
        optionsByContractData?.getContractLineOptionsForMod,
      );

      setAllAvailableOptions(optionReturned);
      if (searchQuery) {
        const filteredData = optionReturned.filter(
          (item) => item.optionCode.toLowerCase() === searchQuery.toLowerCase(),
        );
        setFilteredOptionsData(filteredData);
      } else {
        setFilteredOptionsData(optionReturned);
      }
    }
  }, [optionsByContractData, orderDetails]);

  useEffect(() => {
    if (updateOption && updateOption.optionCode) {
      let updatedModOptions = [];

      if (selectedOptions?.length > 0) {
        const prevModifiedOptions = [...selectedOptions];
        updatedModOptions = prevModifiedOptions.filter(
          (item) => item.optionCode !== updateOption.optionCode,
        );

        if (updateOption.action !== 'noChange') {
          updatedModOptions.push(updateOption);
        }
        setOrderChanges((prevState) => {
          const newState = { ...prevState };
          newState.selectedOptions = updatedModOptions;
          return newState;
        });

        setSelectedOptions(updatedModOptions);
      } else {
        setOrderChanges((prevState) => {
          const newState = { ...prevState };
          newState.selectedOptions = [updateOption];
          return newState;
        });

        setSelectedOptions([updateOption]);
      }
    }
  }, [updateOption]);

  useEffect(() => {
    if (tableView === 'all' && searchQuery === '') {
      setEmptyTableDataMsg('No options found');
      setFilteredOptionsData(allAvailableOptions);
    }
    if (tableView === 'all' && searchQuery) {
      setEmptyTableDataMsg('No options found');
      filterOptionsData();
      // setFilteredOptionsData(allAvailableOptions);
    }
    if (tableView === 'modified') {
      setEmptyTableDataMsg('No options modified');
      setFilteredOptionsData(selectedOptions);
    }
    if (selectedOptions?.length > 0 && allAvailableOptions.length > 0) {
      mergeOptions(allAvailableOptions, selectedOptions);
    }
  }, [allAvailableOptions, selectedOptions]);
  const viewModifiedOptions = () => {
    setTableView('modified');
    setTableTitle('Modified options');
    setSearchQuery('');
    setFilteredOptionsData(selectedOptions);
    if (!selectedOptions || selectedOptions?.length < 1) {
      setEmptyTableDataMsg('No options modified');
    }
  };
  const viewAllOptions = () => {
    setTableView('all');
    setTableTitle('All available options');
    setSearchQuery('');
    if (allAvailableOptions.length > 0) {
      mergeOptions(allAvailableOptions, selectedOptions);
    }
  };

  const resetOptionSearch = () => {
    setTableView('all');
    setTableTitle('All available options');
    setSearchQuery('');
    filterOptionsData();
    const orderIds = selectedOrdersForMod?.map((order) => order.orderId);
    if (selectedContractLine) {
      getOptionsData({
        variables: {
          contractLineId: selectedContractLine,
          selectedOrders: orderIds,
        },
      });
    }
  };
  const updateOptionQuantity = (newOptionQuantity) => {
    const newQuantity = parseInt(newOptionQuantity ?? optionQuantity, 10);
    setUpdateOption((prevState) => {
      return {
        ...prevState,
        optionQuantity: newQuantity,
        optionTotalPrice: Number(
          (prevState.currentUnitPrice * newQuantity).toFixed(2),
        ),
      };
    });
    setShowUpdateQtyModal(false);
  };

  const handleRowAction = (e, row) => {
    const option = { ...row.original };
    const selectedAction = getActionName(e);
    option.action = selectedAction;
    // if adding a new option or updating the prev selected option,
    // update quantity based on quantityRequired type
    setUpdateOption(option);

    if (
      ['add', 'updateQty'].includes(selectedAction) &&
      ['V', 'O'].includes(option.quantityRequired)
    ) {
      setShowUpdateQtyModal(true);
      const quantityOnOption =
        option?.optionQuantity === 0 ? 1 : option?.optionQuantity;
      setOptionQuantity(quantityOnOption);
    } else if (
      selectedAction === 'add' &&
      !['V', 'O'].includes(option.quantityRequired)
    ) {
      updateOptionQuantity('1');
    } else if (selectedAction === 'remove') {
      updateOptionQuantity('0');
    } else if (selectedAction === 'noChange' && option.optionCode === 'CPT') {
      paintAndGraphicsAttachments.forEach((attachment) => {
        removeAttachment(attachment.orderModAttachmentId);
      });
    }
  };

  const MODIFY_OPTIONS_TABLE_COLUMNS = [
    {
      Header: 'Selected',
      accessor: 'isChecked',
      sortable: false,
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => {
        if (value) {
          return (
            <img
              className="selected-icon"
              src={SelectedIcon}
              alt="option selected icon"
            />
          );
        }
        return null;
      },
    },
    {
      Header: 'Option code',
      accessor: 'optionCode',
      // eslint-disable-next-line react/prop-types
      Cell: ({ row }) => {
        // eslint-disable-next-line react/prop-types
        const { optionCode, optionDescription } = row.original;
        return (
          <div>
            <span className="option-span">{optionCode}</span>
            <br />
            <span className="option-desc-span">{optionDescription}</span>
          </div>
        );
      },
    },
    {
      Header: 'Option Description',
      accessor: 'optionDescription',
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => {
        return (
          <div>
            <span className="option-span">{value}</span>
          </div>
        );
      },
    },
    {
      Header: 'Option type',
      accessor: 'quantityRequired',
    },
    {
      Header: 'Current unit price',
      accessor: 'currentUnitPrice',
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => {
        return (
          <span>
            {'$'}
            {value}
          </span>
        );
      },
    },
    {
      Header: 'Selected quantity',
      accessor: 'optionQuantity',
    },
    {
      Header: 'Total price',
      accessor: 'optionTotalPrice',
      // eslint-disable-next-line react/prop-types
      Cell: ({ value }) => {
        return (
          <span>
            {'$'}
            {value}
          </span>
        );
      },
    },
    /*    {
      Header: 'Actions',
      accessor: 'action',
      Cell: ActionColumn,
    }, */
    {
      Header: 'Action',
      sortable: false,
      // eslint-disable-next-line react/prop-types
      Cell: (props) => {
        // eslint-disable-next-line react/prop-types
        const { row } = props;
        return (
          <AFPTableRowAction
            actions={getActions(
              // eslint-disable-next-line react/prop-types
              row.values.isChecked,
              // eslint-disable-next-line react/prop-types
              row.values.quantityRequired,
            )}
            onSelectAction={(e) => handleRowAction(e, row)}
            {...props}
          />
        );
      },
    },
  ];
  const columns = useMemo(() => MODIFY_OPTIONS_TABLE_COLUMNS, []);
  const selectedOptionsContent = useMemo(() => {
    return [
      {
        id: 'optionChange',
        title: <div className="add-options-accordion-title">Options</div>,
        content: (
          <div>
            {orderFromSameReq && !changeSetWithPOV && !optionsLoading && (
              <div className="ordermod-options-accordion-container">
                <div className="ordermod-option-filter-container">
                  <div
                    data-testid="search-options-title"
                    className="options-note"
                  >
                    Search option codes{' '}
                  </div>
                  <div className="ordermod-option-filter-flex">
                    <div className="ordermod-option-filter-flex-left">
                      <TextInput
                        data-testid="option-search-box"
                        key="search"
                        name="search"
                        id="search"
                        value={searchQuery}
                        placeholder="Enter option code"
                        onChange={(e) => setSearchQuery(e.target.value)}
                        variant="unstyled"
                      />
                      <div className="option-search-button">
                        <Button
                          label="Search"
                          variant="primary"
                          data-testid="set-opt-filters"
                          onClick={filterOptionsData}
                        />
                      </div>
                      <div className="option-search-reset">
                        <button
                          aria-label="revert-button"
                          data-testid="revert-option-sel-button"
                          type="button"
                          className="revert-button"
                          onClick={() => {
                            resetOptionSearch();
                          }}
                        >
                          <RevertIcon />
                          <span className="button-text" />
                        </button>
                      </div>
                    </div>
                    <div className="ordermod-option-filter-flex-right">
                      <div className="modified-options-info">
                        <p>
                          {selectedOptions && selectedOptions.length > 0
                            ? `${selectedOptions?.length} option${
                                selectedOptions.length === 1 ? 's' : ''
                              } modified`
                            : 'No options modified'}
                        </p>
                      </div>
                      <div className="">
                        <Button
                          data-testid="view-mod-opt-button"
                          type="button"
                          label="View modified options"
                          variant="unstyled"
                          onClick={() => {
                            viewModifiedOptions();
                          }}
                        />
                      </div>
                      <div>
                        <p className="modified-options-separator">|</p>
                      </div>
                      <div className="">
                        <Button
                          data-testid="view-all-opt-button"
                          type="button"
                          label="View all"
                          variant="unstyled"
                          onClick={() => {
                            viewAllOptions();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-main-container">
                  <div className="options-note"> {tableTitle} </div>

                  {filteredOptionsData && (
                    <AFPTable
                      testId="ordermod-options-table"
                      columns={columns}
                      hiddenColumns={['optionDescription', 'quantityRequired']}
                      data={filteredOptionsData || []}
                    />
                  )}
                  {filteredOptionsData?.length < 1 && (
                    <EmptyState
                      hasBackground
                      containerStyles="margin-top-neg-2 padding-y-10"
                      topText={emptyTableDataMsg}
                    />
                  )}
                </div>
              </div>
            )}
            {!orderFromSameReq && (
              <div className="ordermod-options-accordion-container">
                <h4>
                  {' '}
                  Options cannot be modified if the selected orders are from
                  different requisition
                </h4>
              </div>
            )}
            {orderFromSameReq && changeSetWithPOV && (
              <div className="ordermod-options-accordion-container">
                <h4>
                  {' '}
                  Some of the selected orders have per order options. create a
                  separate modification/changeSet to modify the following orders
                </h4>
                {orderWithPO.map((order) => {
                  return (
                    <div className="po-order-list">
                      <div className="grid-col-2">
                        <Button
                          variant="unstyled"
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              behavior: 'smooth',
                            });
                          }}
                          label={order}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            {optionsLoading && (
              <div className="ordermod-options-accordion-container">
                <Spinner size="medium" className="margin-y-8" />
              </div>
            )}
          </div>
        ),
        expanded: false,
      },
    ];
  }, [
    selectedOptions,
    optionsLoading,
    searchQuery,
    filteredOptionsData,
    orderFromSameReq,
    changeSetWithPOV,
    orderWithPO,
  ]);

  return (
    <>
      <Accordion
        multiselectable={false}
        bordered="true"
        data-testid="accordionButton_options"
        items={selectedOptionsContent}
        className="add-options-accordion"
      />
      {showUpdateQtyModal && (
        <div className="afp-modal-overlay modalContainer" data-testid={testId}>
          <Modal
            title={`Update quantity for option ${updateOption.optionCode}`}
            className="save-draft-modal"
            onClose={() => {
              setShowUpdateQtyModal(false);
            }}
            actions={
              <div className="add-comment-button-row">
                <Button
                  type="button"
                  variant="unstyled"
                  data-testid={`${testId}-cancel-button`}
                  onClick={() => setShowUpdateQtyModal(false)}
                  label="Cancel"
                />
                <Button
                  type="button"
                  disabled={updateQtyHasError}
                  variant="default"
                  className="margin-left-3"
                  data-testid={`${testId}-submit-button`}
                  onClick={() => updateOptionQuantity()}
                  label="Submit"
                />
              </div>
            }
          >
            <div className="quantity-input">
              <OptionQuantityInput
                optionQuantity={optionQuantity}
                optionType={updateOption?.quantityRequired}
                setOptionQuantity={setOptionQuantity}
                updateQtyHasError={updateQtyHasError}
                setUpdateQtyHasError={setUpdateQtyHasError}
              />
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

ModifyOptions.propTypes = {
  selectedFilters: PropTypes.instanceOf(Array).isRequired,
  paintAndGraphicsAttachments: PropTypes.instanceOf(Array).isRequired,
  removeAttachment: PropTypes.func.isRequired,
};

export default ModifyOptions;
