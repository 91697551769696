import React, { useState, useMemo, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import {
  Button,
  useModal,
  Alert,
  AFPTable,
  EmptyState,
  Checkbox,
  AFPTableRowAction,
  connectModal,
  RequiredFieldIndicator,
  TextInput,
  Spinner,
  SelectDropdown,
} from '@gsa/afp-component-library';

import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import {
  GET_CONTRACT_BY_CONTRACT_NUMBER,
  GET_CONTRACT_LINES_BY_CONTRACT_NUMBER,
  SUBMIT_TO_GSA,
  GET_STANDARD_CODES,
} from '../../../services/data-layer';
import AttachmentDownload from '../../ReviewDetails/Attachment/AttachmentDownload/AttachmentDownload';
import UploadNewFileModal from '../../../components/MultipleAdditionalRequirements/UploadNewFileModal/UploadNewFileModal';
import DeleteAdditionalReqFileModal from '../../../components/MultipleAdditionalRequirements/DeleteAdditionalReqFileModal/DeleteAdditionalReqFileModal';
import EditFileDocumentationModal from '../../../components/MultipleAdditionalRequirements/EditFileDocumentationModal/EditFileDocumentationModal';
import ErrorContext from '../../../context/ErrorContext/ErrorContext';
import ErrorActions from '../../../context/ErrorContext/ErrorActions';
import VehicleRequisitionContext from '../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import { VehicleRequisitionContextActions } from '../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import { ROW_ACTIONS } from '../constants';
import ReturnOrderModal from '../../ReviewDetails/ReturnOrderModal';
import {
  placeOrderAcceptDoc,
  ContractDetails,
} from '../urgent-requisition/utils/UrgReqCommonUtils';
import './ContractingPlaceOrder.scss';
import RequisitionMessageLines from '../../ReviewDetails/tabs/ReqMessageLines/RequisitionMessageLines';

const BLANK = { label: '-select-', value: '' };

let checkForInvalidData = false;
const ContractingPlaceOrder = ({ requisitionCartData, attachmentTypes }) => {
  const history = useHistory();

  const draftId = requisitionCartData?.requisitionId;
  const [deleteRowIndex, setDeleteRowIndex] = useState(-1);
  const [rowToUpdate, setRowToUpdate] = useState(null);
  const { dispatch: errorDispatch, state: errorState } =
    useContext(ErrorContext);
  const [colors, setColors] = useState([]);
  const [scheduleLines, setScheduleLines] = useState([BLANK]);
  const [contractInfo, setContractInfo] = useState(null);
  const {
    dispatch,
    state: { urgentRequirementPlaceOrderInitialState },
  } = useContext(VehicleRequisitionContext);

  const [submitToGSA, { loading: submitLoading }] = useMutation(SUBMIT_TO_GSA);
  const uploadNewFileModal = useModal();
  const DisplayUploadFileModal = connectModal(UploadNewFileModal);

  const deleteFileModal = useModal();
  const DisplayDeleteFileModal = connectModal(DeleteAdditionalReqFileModal);

  const updateFileModal = useModal();
  const DisplayUpdateFileModal = connectModal(EditFileDocumentationModal);

  const formProps = useForm({
    defaultValues: {
      ...urgentRequirementPlaceOrderInitialState,
      quantity:
        urgentRequirementPlaceOrderInitialState.quantity ||
        requisitionCartData?.nonSopData?.quantity,
      color:
        urgentRequirementPlaceOrderInitialState.color ||
        requisitionCartData?.nonSopData?.color,
    },
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
    getValues,
    setValue,
  } = formProps;

  const {
    fields: vendorAcceptanceAttachments,
    append: quoteAppend,
    remove,
    update,
  } = useFieldArray({
    control: formProps.control,
    name: 'vendorAcceptanceAttachments',
  });

  const isContractDataExist =
    contractInfo && Object.keys(contractInfo).length > 0;
  checkForInvalidData =
    !contractInfo?.quantity ||
    !contractInfo?.contractTotalPrice ||
    !contractInfo?.contractUnitPrice;
  //  !contractInfo?.units;

  const validateErrorsAlert = (formField) => {
    if (vendorAcceptanceAttachments.length === 0) {
      errorDispatch({
        type: ErrorActions.ADD_ERROR,
        payload: {
          page: 'ContractingFinalReviewInfo',
          form: formField,
          error: 'Please fill all required information',
        },
      });
    } else {
      errorDispatch({ type: ErrorActions.CLEAR_ERRORS });
    }
  };

  useEffect(() => {
    if (vendorAcceptanceAttachments.length > 0) {
      clearErrors('vendorAcceptanceAttachments');
    }
  }, [vendorAcceptanceAttachments]);

  const setVendorAcceptanceDocError = () => {
    return setError('vendorAcceptanceAttachments', {
      type: 'required',
      message: 'Funding documentation is required',
    });
  };

  const setContractNumberError = () => {
    return setError('contractNumber', {
      type: 'required',
      message: 'Please enter a valid contract number',
    });
  };

  const setNoScheduleLinesError = () => {
    return setError('contractNumber', {
      type: 'required',
      message: 'This contract has no valid schedule lines',
    });
  };
  const setScheduleLineError = () => {
    return setError('scheduleLine', {
      type: 'required',
      message: 'Please enter a valid schedule line',
    });
  };

  useEffect(() => {
    if (
      errorState?.ContractingFinalReviewInfo?.vendorAcceptanceAttachments
        ?.size > 0
    ) {
      setVendorAcceptanceDocError();
    }
  }, [errorState]);

  const onSubmit = async () => {
    const formValues = getValues();
    if (formValues.vendorAcceptanceAttachments?.length === 0) {
      validateErrorsAlert('vendorAcceptanceAttachments');
      setVendorAcceptanceDocError();
    } else {
      const files = placeOrderAcceptDoc(vendorAcceptanceAttachments);
      const payload = {
        requisitionId: draftId,
        approvalComment: '',
        contractNumber: formValues.contractNumber,
        scheduleLine: formValues.scheduleLine,
        nonSopColor: formValues.color,
        nonSopQuantity: +formValues.quantity,
        attachments: {
          attachments: files,
        },
      };
      await submitToGSA({ variables: payload });
      history.push(
        `/my-requisitions?requisitionId=${draftId}&draftName=${requisitionCartData?.friendlyName}`,
      );
    }
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_URGENT_REQUIREMENT_PLACE_ORDER_STATE,
      payload: formValues,
    });
  };

  const onError = () => {
    if (getValues().vendorAcceptanceAttachments.length === 0) {
      validateErrorsAlert('vendorAcceptanceAttachments');
      setVendorAcceptanceDocError();
    }
  };

  const handleTableActions = (event, row) => {
    setRowToUpdate(row);
    if (event === 'Edit') {
      updateFileModal.openModal();
    } else {
      setDeleteRowIndex(row.index);
      deleteFileModal.openModal();
    }
  };

  const handleDelete = () => {
    remove(deleteRowIndex);
    deleteFileModal.closeModal();

    if (getValues().vendorAcceptanceAttachments.length === 0) {
      setVendorAcceptanceDocError();
      setValue('certifyCheckBox', false);
    }
    dispatch({
      type: VehicleRequisitionContextActions.UPDATE_URGENT_REQUIREMENT_PLACE_ORDER_STATE,
      payload: getValues(),
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: 'File name',
        accessor: 'filename',
        sortable: false,
        /* eslint-disable react/prop-types */
        Cell: ({ row: { original } }) => {
          return (
            <AttachmentDownload
              name={original?.name}
              metadataId={original?.metadataId}
            />
          );
        },
      },
      {
        Header: 'Note',
        accessor: 'note',
        sortable: false,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row: { original } }) => original?.description,
      },
      {
        Header: 'Actions',
        id: 'table-row-action',
        sortable: false,
        headerClassName: 'cell-center',
        cellClassName: 'cell-center',
        /* eslint-disable react/prop-types */
        Cell: (props) => {
          const { row } = props;
          return (
            <AFPTableRowAction
              actions={ROW_ACTIONS}
              onSelectAction={(evt) => {
                handleTableActions(evt, row);
              }}
              {...props}
            />
          );
        },
      },
    ],
    [],
  );

  const transformRespToOptions = (data, field) => {
    const list = [];
    data.forEach((i) => {
      list.push({
        label: i[field],
        value: i[field],
      });
    });
    return list;
  };

  useQuery(GET_STANDARD_CODES, {
    variables: {
      filters: {
        operator: 'EQ',
        key: 'code_metadata_id',
        value: 1,
      },
    },
    onCompleted: (data) => {
      const colorsData = data?.getStandardCodes?.rows;
      if (colorsData?.length) {
        setColors(
          transformRespToOptions(
            [{ id: '-1', title: '<No preference>' }, ...colorsData],
            'title',
          ),
        );
      }
    },
  });

  const [getContractInfo, { loading: contractInfoDataLoading }] = useLazyQuery(
    GET_CONTRACT_BY_CONTRACT_NUMBER,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const [getScheduleLines, { loading: scheduleLinesDataLoading }] =
    useLazyQuery(GET_CONTRACT_LINES_BY_CONTRACT_NUMBER, {
      fetchPolicy: 'no-cache',
    });

  const clearResultsData = () => {
    setValue('scheduleLine', '');
    setScheduleLines([BLANK]);
    setContractInfo(null);
  };

  const loadScheduleLines = async () => {
    const contractNumber = getValues().contractNumber?.trim();
    if (contractNumber) {
      setValue('scheduleLine', '');
      setScheduleLines([BLANK]);
      setContractInfo(null);
      const ret = await getScheduleLines({
        variables: { contractNumber },
      });
      if (ret?.data?.getContractByContractNumber) {
        setValue(
          'contractNumber',
          ret?.data.getContractByContractNumber?.contractNo,
        );
        const contractLines =
          ret?.data.getContractByContractNumber?.contractMods
            ?.flatMap((mod) => mod.contractLines)
            .filter((x) => x.contractLineFinancialRef !== null)
            .map((x) => ({ value: x.scheduleLine, label: x.scheduleLine }));
        if (!contractLines.length) {
          setNoScheduleLinesError();
        } else {
          setScheduleLines([BLANK, ...contractLines]);
          clearErrors('contractNumber');
        }
      } else setContractNumberError();
    } else setContractNumberError();
  };

  const handleGetContractInfo = async (value) => {
    if (!errors?.quantity?.size) {
      const { contractNumber, quantity } = getValues();
      setContractInfo(null);
      const qryResult = await getContractInfo({
        variables: {
          requisitionId: draftId,
          contractNumber,
          scheduleLine: value,
          updatedQuantity: +quantity,
        },
      });
      if (qryResult?.data) {
        const info = qryResult.data.getContractDetailsByContractNumber;
        setContractInfo(info);
      } else setScheduleLineError();
    }
  };

  const validateNumber = (value) => {
    return (
      (value >= 1 && value <= 999) || 'Quantity can only be between 1 and 999'
    );
  };

  return (
    <FormProvider {...formProps}>
      <form
        data-testid="co-final-review-form"
        onSubmit={handleSubmit(onSubmit, onError)}
      >
        {submitLoading && (
          <div className="afp-modal-overlay draft-requisition-loading">
            <Spinner size="large" className="margin-y-8" />
          </div>
        )}
        <div className="vendor-review-info">
          <div className="top-padding-70">
            <div className="title">
              {' '}
              Provide vendor acceptance
              <RequiredFieldIndicator />{' '}
            </div>
            <div className="title-desc" />
            {errors?.vendorAcceptanceAttachments?.message && (
              <Alert type="error">
                <div>Please upload at least one document</div>
              </Alert>
            )}
            <div className="upload-btn">
              <Button
                data-testid="acceptance-upload-btn"
                onClick={() => {
                  uploadNewFileModal.openModal();
                }}
                type="button"
                variant="outline"
                leftIcon={{ name: 'add', className: 'plus-icon' }}
                label="Upload file"
              />
            </div>
            <div>
              <AFPTable
                testId="funding-doc-attachments-table"
                columns={columns}
                data={vendorAcceptanceAttachments || []}
              />
              {!vendorAcceptanceAttachments.length && (
                <EmptyState
                  hasBackground
                  containerStyles="margin-top-neg-2 padding-y-10"
                  topText="No files uploaded"
                />
              )}
            </div>
          </div>

          <div
            className={`${
              errors?.certifyCheckBox?.message &&
              'usa-form-group usa-form-group--error'
            }`}
          >
            <div className="error-text-desc">
              {errors?.certifyCheckBox?.message}
            </div>
            <Controller
              rules={{
                required:
                  'Please certify that you have uploaded all required documents ',
              }}
              name="certifyCheckBox"
              render={({ field: { value, onChange } }) => {
                return (
                  <Checkbox
                    className="supporting-docs-checkbox"
                    name="certifyCheckBox"
                    checked={value}
                    onChange={onChange}
                    label="I hereby certify that I have uploaded all documents required for vendor acceptance*"
                  />
                );
              }}
            />
          </div>
        </div>
        <div className="vendor-review-info">
          <div className="top-padding-70">
            <div className="title">Verify selected quantity and color</div>
          </div>

          <div
            className={`${
              errors?.quantity?.size > 0 &&
              'usa-form-group usa-form-group--error'
            }`}
          >
            <Controller
              rules={{
                required: 'This is a required field',
                validate: validateNumber,
              }}
              name="quantity"
              render={({ field: { value, onChange, ref } }) => (
                <div className="contract-no-section">
                  <TextInput
                    data-testid="contract-no-section-field"
                    label={
                      <>
                        Quantity <RequiredFieldIndicator />
                      </>
                    }
                    onChange={(e) => {
                      onChange(e);
                      setContractInfo(null);
                      setValue('scheduleLine', '');
                    }}
                    value={value}
                    inputRef={ref}
                    className="input-contract-line"
                    name="quantity"
                    errorMessage={errors?.quantity?.message}
                  />
                </div>
              )}
            />
          </div>

          {colors.length && (
            <div
              className={`${
                errorState?.ContractingFinalReviewInfo?.color?.size > 0 &&
                'usa-form-group usa-form-group--error'
              }`}
            >
              <Controller
                rules={{
                  required: 'This is a required field',
                }}
                name="color"
                render={({ field: { value, onChange, ref } }) => (
                  <div className="color-section">
                    <SelectDropdown
                      data-testid="color-section-field"
                      name="color"
                      label={
                        <>
                          Color <RequiredFieldIndicator />
                        </>
                      }
                      id="color"
                      onChange={onChange}
                      value={value}
                      ref={ref}
                      options={colors}
                      errorMessage={errors?.color?.message}
                    />
                  </div>
                )}
              />
            </div>
          )}
        </div>
        <div className="vendor-review-info">
          <div className="top-padding-70">
            <div className="title">
              {' '}
              Provide contract details
              <RequiredFieldIndicator />{' '}
              {contractInfo && checkForInvalidData && (
                <div className="contract-line-error-alert">
                  <Alert type="error" slim>
                    The entered contract contains invalid data. Please review
                    this contract information in ROADS.
                  </Alert>
                </div>
              )}
            </div>
            <div className="title-desc">
              Enter your contract number and schedule line to view and verify
              your contract and vehicle details.
            </div>
          </div>

          <div
            className={`${
              errorState?.ContractingFinalReviewInfo?.contractNumber?.size >
                0 && 'usa-form-group usa-form-group--error'
            }`}
          >
            <Controller
              rules={{
                required: 'This is a required field',
              }}
              name="contractNumber"
              render={({ field: { value, onChange, ref } }) => (
                <div className="contract-no-section">
                  <TextInput
                    data-testid="contract-no-section-field"
                    label={
                      <>
                        Enter contract number <RequiredFieldIndicator />
                      </>
                    }
                    onChange={(e) => {
                      clearResultsData();
                      onChange(e);
                    }}
                    value={value}
                    inputRef={ref}
                    className="input-contract-line"
                    name="contractNumber"
                    errorMessage={errors?.contractNumber?.message}
                  />

                  <Button
                    data-testid="load-schedule-lines-btn"
                    type="button"
                    variant="outline"
                    label="Load Schedule Lines"
                    className="load-schedule-lines-btn"
                    onClick={() => {
                      loadScheduleLines(value);
                    }}
                  />
                </div>
              )}
            />
          </div>

          {scheduleLines.length > 1 && (
            <div
              className={`${
                errorState?.ContractingFinalReviewInfo?.scheduleLine?.size >
                  0 && 'usa-form-group usa-form-group--error'
              }`}
            >
              <Controller
                rules={{
                  required: 'This is a required field',
                }}
                name="scheduleLine"
                render={({ field: { value, onChange, ref } }) => (
                  <div className="schedule-line-section">
                    <SelectDropdown
                      data-testid="schedule-line-section-field"
                      name="scheduleLine"
                      label={
                        <>
                          Select a schedule line <RequiredFieldIndicator />
                        </>
                      }
                      id="scheduleLine"
                      className="select-schedule-line"
                      onChange={(e) => {
                        onChange(e);
                        handleGetContractInfo(e.target.value);
                      }}
                      value={value}
                      ref={ref}
                      options={scheduleLines}
                      errorMessage={errors?.scheduleLine?.message}
                    />
                  </div>
                )}
              />
            </div>
          )}

          {(contractInfoDataLoading || scheduleLinesDataLoading) && (
            <Spinner size="large" className="margin-y-9" />
          )}

          {isContractDataExist && (
            <ContractDetails contractLine={contractInfo} />
          )}
        </div>
        <div className="ur-message-lines">
          <RequisitionMessageLines requisitionId={draftId} isEditAllowed />
        </div>
        <div className="cutomer-place-order-btn-section">
          <ReturnOrderModal
            requisitionId={draftId}
            requisitionName={requisitionCartData?.friendlyName}
            requisitionCartValidations={null}
            canSubmitOrder
            visible
            isFromReqAction={false}
          />
          <Button
            data-testid="select-vendor-btn"
            type="submit"
            variant="primary"
            label="Place order"
            disabled={!isContractDataExist || checkForInvalidData}
            leftIcon={{ name: 'check' }}
          />
        </div>
      </form>

      <DisplayUploadFileModal
        isOpen={uploadNewFileModal.isOpen}
        handleClose={uploadNewFileModal.closeModal}
        append={quoteAppend}
        reqType="URGENT_REQ_CUSTOMER_SUPPORTING_DOC"
        draftId={draftId}
        attachmentTypes={attachmentTypes}
      />

      <DisplayDeleteFileModal
        isOpen={deleteFileModal.isOpen}
        handleClose={deleteFileModal.closeModal}
        handleDelete={handleDelete}
        filename={rowToUpdate?.original?.name}
      />

      <DisplayUpdateFileModal
        isOpen={updateFileModal.isOpen}
        handleClose={updateFileModal.closeModal}
        rowToEdit={rowToUpdate}
        update={update}
      />
    </FormProvider>
  );
};

ContractingPlaceOrder.propTypes = {
  requisitionCartData: PropTypes.instanceOf(Object).isRequired,
  attachmentTypes: PropTypes.instanceOf(Array),
};

ContractingPlaceOrder.defaultProps = {
  attachmentTypes: [],
};

export default ContractingPlaceOrder;
