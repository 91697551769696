import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';

import {
  PageTitle,
  Button,
  Breadcrumbs,
  SelectDropdown,
  Spinner,
} from '@gsa/afp-component-library';

import { useAppAbility, useCurrentUser } from '@gsa/afp-shared-ui-utils';
import { Link, useHistory } from 'react-router-dom';
import { sortBy } from 'lodash';
import SaveDraftRequisition from '../../../components/SaveDraftRequisition/SaveDraftRequisition';
import { MAS_ORDERING_TYPES, GSA_LIBRARY_URL } from './constants';
import MasRequisitionSteps from './MasRequisitionSteps';
import ShipmentOptions from '../../purchase/widgets/ShippmentOptions';
import { getUserScopeLevel } from '../../../services/auth-service';
import { StoreSubjects } from '../../../constants/constants';
import {
  GET_AGENCIES_BY_PERMISSION,
  GET_BUREAUS_BY_PERMISSION,
  GET_OFFICES_BY_PERMISSION,
} from '../../../services/data-layer';
import {
  canCreateRequisition,
  canViewRequisition,
} from '../../purchase/authorization';
import VehicleRequisitionContext from '../../../context/VehicleRequisitionContext/VehicleRequisitionContext';
import GSAAdvModal from './utils/GsaAdvanceModal';
import MasAdvModal from './utils/MasAdvanceModal';
import { useQueryParam } from '../../../hooks/useQueryParam';

import './MasRequisition.scss';
import {
  setOfficeSession,
  setAgencySession,
  setBureauSession,
} from './utils/ServiceUtils';
import { VehicleRequisitionContextActions } from '../../../context/VehicleRequisitionContext/VehicleRequisitionContextActions';
import { REQUISITION_TYPE } from '../constants';

const MasSchedulingPage = () => {
  const query = useQueryParam();
  const requisitionId = query.get('requisitionId');

  const { state, dispatch } = useContext(VehicleRequisitionContext);
  const { draftRequisition } = state;
  const history = useHistory();
  const dropdownInitialStates = [
    {
      value: '',
      label: '-select-',
      defaultValue: true,
    },
  ];

  const [gsaAdvModal, setGsaAdvModal] = useState(false);
  const [masAdvModal, setMasAdvModal] = useState(false);
  const [showForms, setShowForms] = useState(false);
  const [agencyCode, setAgencyCode] = useState('');
  const [bureauCode, setBureauCode] = useState('');
  const [validationTriggered, setValidationTriggered] = useState(false);
  const [officeCode, setOfficeCode] = useState('');
  const [userScopeLevel, setUserScopeLevel] = useState('NONE');
  const [agencies, setAgencies] = useState(dropdownInitialStates);
  const [bureaus, setBureaus] = useState(dropdownInitialStates);
  const [offices, setOffices] = useState(dropdownInitialStates);
  const [isDraftSaved, setIsDraftSaved] = useState(false);
  const [processMasReq, setProcessMasReq] = useState(false);

  const ability = useAppAbility();

  useEffect(() => {
    setTimeout(() => {
      dispatch({
        type: VehicleRequisitionContextActions.SET_REQUISITION_TYPE,
        payload: REQUISITION_TYPE.MULTIPLE_AWARD_SCHEDULES,
      });
    });
  }, [dispatch]);

  useEffect(() => {
    if (draftRequisition?.requisitionId) {
      setIsDraftSaved(true);
      history.push(
        `/mas-requisition?requisitionId=${draftRequisition?.requisitionId}`,
      );
    } else {
      setIsDraftSaved(false);
      setProcessMasReq(false);
    }
  }, [draftRequisition]);

  const getCurrentUser = useCurrentUser();
  let currentUser = {};
  if (getCurrentUser) currentUser = getCurrentUser.currentUser;

  const canViewOrCreateRequisition = () => {
    return canViewRequisition(ability) || canCreateRequisition(ability);
  };

  const [_getUserAgencies, { loading: getAgenciesLoading }] = useLazyQuery(
    GET_AGENCIES_BY_PERMISSION,
    {
      onCompleted: (info) => {
        if (info?.getAgenciesByPermission) {
          const formattedAgencies = sortBy(
            info.getAgenciesByPermission,
            'id',
          ).map(({ id: value, name: label, isDodInt, oldAgencyCode }) => ({
            label,
            value,
            isDod: Boolean(isDodInt),
            oldAgencyCode,
          }));
          setAgencies([...dropdownInitialStates, ...formattedAgencies]);
        }
      },
      onError: () => {
        // TODO
      },
      fetchPolicy: 'no-cache',
    },
  );

  const [_getUserBureaus, { loading: getBureausLoading }] = useLazyQuery(
    GET_BUREAUS_BY_PERMISSION,
    {
      onCompleted: (info) => {
        if (info?.getBureausByPermission) {
          const updateList = [...dropdownInitialStates];
          sortBy(info.getBureausByPermission, 'id').forEach((i) => {
            updateList.push({
              label: i.name,
              value: i.id,
            });
          });
          setBureaus(updateList);
        }
      },
      onError: () => {
        // TODO
      },
      fetchPolicy: 'no-cache',
    },
  );

  const [_getUserOffices, { loading: getOfficesLoading }] = useLazyQuery(
    GET_OFFICES_BY_PERMISSION,
    {
      onCompleted: (info) => {
        if (info?.getOfficesByPermission) {
          const updateList = [...dropdownInitialStates];
          info.getOfficesByPermission.forEach((i) => {
            updateList.push({
              label: i.officeName,
              value: i.officeCode,
            });
          });
          setOffices(updateList);
        }
      },
      onError: () => {
        // TODO
      },
      fetchPolicy: 'no-cache',
    },
  );

  useEffect(() => {
    if (currentUser?.abilities) {
      const userScope = getUserScopeLevel(
        currentUser?.abilities,
        'create',
        StoreSubjects.Requisition,
      );
      setUserScopeLevel(userScope);
    }
  }, [currentUser?.abilities]);

  const gsaAdvModalCancel = () => {
    setGsaAdvModal(false);
  };

  const masAdvModalCancel = () => {
    setMasAdvModal(false);
  };

  const masAdvModalConfirm = () => {
    setMasAdvModal(false);
    setShowForms(true);
  };

  const gsaAdvModalConfirm = () => {
    window.open(GSA_LIBRARY_URL, '_blank');
    setGsaAdvModal(false);
  };

  const handleOrderingProcess = (actionType) => {
    if (actionType === MAS_ORDERING_TYPES.MAS) {
      setMasAdvModal(true);
    }
    if (actionType === MAS_ORDERING_TYPES.GSA) {
      setGsaAdvModal(true);
    }
  };

  const OrderingOption = ({ title, content, handleProcess }) => {
    return (
      <div className="vehicle-ordering-options">
        <div className="purchase-option-title">{title} </div>
        <div className="purchase-option-desc">{content}</div>
        <Button
          label="Start here"
          data-testid={title}
          type="button"
          variant="standard"
          onClick={handleProcess}
        />
      </div>
    );
  };

  OrderingOption.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    handleProcess: PropTypes.func.isRequired,
  };

  const getUserAgencies = async (args) => {
    if (canViewOrCreateRequisition()) {
      await _getUserAgencies(args);
    }
  };

  const getUserBureaus = async (args) => {
    if (canViewOrCreateRequisition()) {
      await _getUserBureaus(args);
    }
  };

  const getUserOffices = async (args) => {
    if (canViewOrCreateRequisition()) {
      await _getUserOffices(args);
    }
  };

  useEffect(() => {
    const initialData = async () => {
      await getUserAgencies({
        variables: {
          subject: StoreSubjects.Requisition,
          operation: 'view',
        },
      });
    };

    initialData();
  }, []);

  const handleDropdownSelection = (id, dropdownType) => {
    if (dropdownType === 'agency') {
      setAgencyCode(id);
      setBureauCode('');
      setOfficeCode('');
      setBureaus(dropdownInitialStates);
      setOffices(dropdownInitialStates);

      if (id) {
        setAgencySession(id, agencies);
        getUserBureaus({
          variables: {
            agencyCode: id,
            subject: StoreSubjects.Requisition,
            operation: 'create',
          },
        });
      }
    }

    if (dropdownType === 'bureau') {
      setBureauCode(id);
      setOfficeCode('');
      setOffices(dropdownInitialStates);

      if (id) {
        setBureauSession(id, bureaus);
        getUserOffices({
          variables: {
            agencyCode,
            bureauCode: id,
            subject: StoreSubjects.Requisition,
            operation: 'create',
          },
        });
      }
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_INITIAL_ENTITY_NAME,
      });
    }

    if (dropdownType === 'office') {
      setOfficeCode(id);
      if (id) {
        setOfficeSession(id, offices);
      }
      dispatch({
        type: VehicleRequisitionContextActions.UPDATE_INITIAL_ENTITY_NAME,
      });
    }
  };

  const saveDraftSuccess = () => {
    return null;
  };

  const handleDraftSave = () => {
    setValidationTriggered(true);
    if (agencyCode && bureauCode) {
      setProcessMasReq(true);
    }
  };

  const handleCancel = () => {
    setProcessMasReq(false);
  };

  return (
    <div className="mas-main-section">
      <div className="bredcrumb-section">
        <Breadcrumbs
          trail={[
            <a key="home" href={`${window.AFP_CONFIG.appURLs.home}/home`}>
              Home
            </a>,
            <Link to="/Purchase">Purchase</Link>,
            <Link to="/non-standard-ordering">
              Non Standard Ordering Options
            </Link>,
          ]}
          current="Multiple Awards Scheduling (MAS)"
        />
      </div>
      {processMasReq && (
        <SaveDraftRequisition
          sin="210"
          onCancel={() => handleCancel()}
          saveDraftSuccess={saveDraftSuccess}
          forceSaveDraft
          setForceSaveDraft={setProcessMasReq}
          isFromUrgReq
        />
      )}
      {requisitionId || isDraftSaved ? (
        <div className="mas-req-main-section">
          <MasRequisitionSteps />
        </div>
      ) : (
        <>
          <div className="mas-main-section">
            <PageTitle title="Multiple Awards Scheduling (MAS)" />
            <div className="mas-title-desc">
              <span>
                Vehicle requests for requirements that can be fulfilled
                utilizing GSA Multiple Award Schedule contracts.
              </span>
              <br />
              <span>
                These orders can be completed by customer agencies directly
                through GSA Advantage or with GSA’s
              </span>
              <br />
              <span> assisted acquisition professionals.</span>
            </div>
          </div>
          {!showForms ? (
            <>
              <div className="mas-main-section">
                <div className="gsa-aquisition-prompt">
                  Will you be needing GSA Fleet Assisted Acquisition?
                </div>
              </div>
              <div className="mas-blocks">
                <Button
                  label="Yes. Create MAS Requisition"
                  data-testid="mas-create"
                  type="button"
                  variant="outline"
                  className="mas-button"
                  onClick={() => handleOrderingProcess(MAS_ORDERING_TYPES.MAS)}
                />
                <Button
                  label="No. Create requisition in GSA Advantage"
                  data-testid="mas-gsa-nav"
                  type="button"
                  variant="outline"
                  className="mas-button"
                  onClick={() => handleOrderingProcess(MAS_ORDERING_TYPES.GSA)}
                />
              </div>
            </>
          ) : (
            <>
              <div className="mas-main-section">
                <div className="gsa-aquisition-prompt">
                  Which organization should this order be associated with?
                </div>
                {(getAgenciesLoading ||
                  getBureausLoading ||
                  getOfficesLoading) && (
                  <Spinner aria-busy="true" size="medium" />
                )}
                <div>
                  <SelectDropdown
                    errorMessage={
                      validationTriggered && agencyCode === ''
                        ? 'Please select an agency'
                        : ''
                    }
                    label="Agency"
                    required
                    id="purchaseAgency"
                    name="select-agency"
                    options={agencies}
                    onChange={(e) =>
                      handleDropdownSelection(e.target.value, 'agency')
                    }
                    value={agencyCode}
                    data-testid="mas-select-agency"
                  />
                  <SelectDropdown
                    errorMessage={
                      validationTriggered && bureauCode === ''
                        ? 'Please select a bureau'
                        : ''
                    }
                    label="Bureau"
                    required
                    data-testid="mas-select-bureau"
                    id="purchaseBureau"
                    name="select-bureau"
                    options={bureaus}
                    onChange={(e) =>
                      handleDropdownSelection(e.target.value, 'bureau')
                    }
                    value={bureauCode}
                  />
                  <SelectDropdown
                    label="Office / Group"
                    required={userScopeLevel === 'OFFICE'}
                    data-testid="mas-select-office"
                    id="purchaseOffice"
                    name="select-office"
                    options={offices}
                    onChange={(e) =>
                      handleDropdownSelection(e.target.value, 'office')
                    }
                    value={officeCode}
                  />
                </div>
                <ShipmentOptions
                  onShipmentSelection={() => {}}
                  onFilterSubmit={handleDraftSave}
                  requestType="masReq"
                  setDefault
                />
              </div>
            </>
          )}
        </>
      )}
      {gsaAdvModal && (
        <GSAAdvModal
          gsaAdvModalCancel={gsaAdvModalCancel}
          gsaAdvModalConfirm={gsaAdvModalConfirm}
        />
      )}
      {masAdvModal && (
        <MasAdvModal
          masAdvModalCancel={masAdvModalCancel}
          masAdvModalConfirm={masAdvModalConfirm}
        />
      )}
    </div>
  );
};

const NonStandardOrdering = () => {
  return <MasSchedulingPage />;
};

export default NonStandardOrdering;
