import React from 'react';
import { cloneDeep } from 'lodash';

export const formatCurrency = (price) => {
  if (Number.isNaN(price) || price === undefined) {
    return '';
  }
  return price.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

export const optionTypeCheck = (
  optionTypeCode,
  unitPrice,
  isIncludeExist,
  isExcludeExist,
  isRequiredExist,
  unResolvedExist,
) => {
  if (isIncludeExist) {
    return <span className="included-option">INCLUDED</span>;
  }
  if (isExcludeExist) {
    return <span className="excluded-option">EXCLUDED</span>;
  }
  if (isRequiredExist) {
    return (
      <div>
        <span className="required-option">REQUIRED</span>
        <span>{formatCurrency(unitPrice)}</span>
      </div>
    );
  }
  if (unResolvedExist) {
    return <span className="unresolved-option">UNRESOLVED</span>;
  }
  if (optionTypeCode === 'Y') {
    return 'STANDARD';
  }
  if (optionTypeCode === 'N' || optionTypeCode === 'P') {
    return formatCurrency(unitPrice);
  }
  return 'N/A';
};

export const getChangedValue = (addOptionsState) => {
  const data = [];
  if (addOptionsState && addOptionsState.length) {
    addOptionsState.forEach((item) => {
      const options = [];
      item.options.forEach((optionList) => {
        const optionItem = optionList;
        if (optionItem.isChecked) {
          if (
            (optionItem.quantityRequiredCode === 'V' ||
              optionItem.quantityRequiredCode === 'O') &&
            optionItem.optionValue === 0
          ) {
            delete optionItem.optionValue;
          }
          options.push(optionItem);
        }
      });
      if (options.length) {
        const clonedItem = cloneDeep(item);
        clonedItem.options = options;
        data.push(clonedItem);
      }
    });
  }

  return data;
};

export const limitDeliveryOptionsByState = (deliveryOptions, countryState) => {
  const selectedState = countryState.substring(countryState.indexOf('-') + 1);

  const stateToOptionLookup = {
    AK: 'DDRA',
    GU: 'DDRG',
    HI: 'DDRH',
    PR: 'DDRP',
  };

  const limitedDeliveryOptions = deliveryOptions.map((deliveryOption) => {
    if (deliveryOption.optionCategoryCode === 'DDR') {
      // DDR is a valid option code
      // DDRx - needs to match the above mapping
      const filteredOptions = deliveryOption.options.filter(
        ({ optionCode }) => {
          return (
            optionCode.length !== 4 ||
            stateToOptionLookup[selectedState] === optionCode
          );
        },
      );

      return {
        ...deliveryOption,
        options: filteredOptions,
      };
    }

    return deliveryOption;
  });

  return limitedDeliveryOptions;
};
