import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Accordion } from '@gsa/afp-component-library';
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';
import './MinimumRequirementsSection.scss';

export const MinimumRequirementsSection = ({
  minimumRequirementsData,
  lowestBidData,
}) => {
  const minimumReqScroll = (minimumRequirements) => {
    return new SimpleBar(minimumRequirements);
  };

  useEffect(() => {
    const minimumRequirements = document.getElementById('minimum-requirements');
    if (minimumRequirements) {
      minimumReqScroll(minimumRequirements);
      const contentWrapper = minimumRequirements.getElementsByClassName(
        'simplebar-content-wrapper',
      )?.[0];
      if (contentWrapper) {
        contentWrapper.tabIndex = 0;
      }
    }
  }, []);

  const getInnerAccordionContent = (minReq) => {
    return (
      <table className="usa-table full-width-table" role="presentation">
        <tbody>
          {minReq.options.map((option) => (
            <tr key={option.optionCode}>
              <td
                data-testid={`${minReq.optionCategoryCode}-${option.optionCode}-optionDescription`}
              >
                {option.optionCode} - {option.optionDescription}
              </td>
              <td
                data-testid={`${minReq.optionCategoryCode}-${option.optionCode}-associationText`}
                className="contract-cell"
              >
                {option.associationText}
              </td>

              {option.vendorValues.map((value) => {
                const activateLowestBid =
                  value.modelName === lowestBidData?.modelName &&
                  value.vendorName === lowestBidData?.vendorName
                    ? 'lowest-bid'
                    : '';
                return (
                  <td
                    data-testid={`${minReq.optionCategoryCode}-${option.optionCode}-${value.vendorName}-${value.modelName}`}
                    key={`${minReq.optionCategoryCode}-${option.optionCode}-${value.vendorName}-${value.modelName}`}
                    className={`contract-cell ${activateLowestBid}`}
                  >
                    {value?.optionValue}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  const getAccordionGroupContent = () => {
    return (
      <>
        {minimumRequirementsData.map((minReq) => (
          <div className="min-req__container" key={minReq.optionCategoryName}>
            <header className="min-req__header">
              {minReq.optionCategoryName}
            </header>
            <div className="min-req__body">
              {getInnerAccordionContent(minReq)}
            </div>
          </div>
        ))}
      </>
    );
  };

  const getMainAccordionContent = () => {
    const accordionGroupProps = {
      items: [
        {
          title: 'Minimum Requirements',
          bordered: true,
          expanded: true,
          id: 'minimum-requirements',
          content: getAccordionGroupContent(),
        },
      ],
    };

    return (
      <div className="accordion-group-custom">
        <Accordion {...accordionGroupProps} />
      </div>
    );
  };
  return getMainAccordionContent();
};

MinimumRequirementsSection.propTypes = {
  id: PropTypes.string,
  minimumRequirementsData: PropTypes.instanceOf(Array).isRequired,
};

export default MinimumRequirementsSection;
